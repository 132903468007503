export const dateOptions={ year: "numeric", month: "long", day: "numeric" };

export const  colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    dragable: false,
    columnConfig: {
     
      Detail: {
        id: "Detail",
        label: "",
        sorting: false,
        hide: false,
      },
      Deal:{
        id: "Deal",
        label: "",
        sorting: false,
        hide: true,
      },
      Data: {
        id: "Data",
        label: "",
        sorting: false,
        hide: true,
      },
    },
  };