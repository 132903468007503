import React, { useEffect, useState } from "react";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import "./D_Deals.scss";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TextField, IconButton } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    background: "#fff",
    borderColor: "whitesmoke",
    borderRadius: "4px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function PipeLineFilter(props) {
  const classes = useStyles();
  const [dealData, setDealData] = React.useState([]);
  const [profileImageList, setProfileImageList] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.oidc.user);
  const dropDownFilters = props.item.content.content.dropdownFilters
    ? props.item.content.content.dropdownFilters
    : [];
  const [value, setValue] = React.useState({});
  const handleChange = (name) => (event) => {
    let queryString = event.target.value.toLowerCase();
    if (name === "searchField") {
      let obj = {};
      Object.keys(value).map((item) => {
        if (item !== name) {
          value[item] ? (obj[item] = value[item].toLowerCase()) : null;
        }
      });
      let filterData = [];
      for (
        let x = 0;
        x <= props.item.content.content.searchFilters.length - 1;
        x++
      ) {
        filterData = [
          ...filterData,
          ...dealData.filter((items) => {
            for (var key in obj) {
              if (
                items[key] === null ||
                items[key] === undefined ||
                handleOptionSetValue(items[key]).toLowerCase() !=
                  handleOptionSetValue(obj[key], false)
              )
                return false;
            }
            return (
              items[props.item.content.content.searchFilters[x]] &&
              items[props.item.content.content.searchFilters[x]]
                .toLowerCase()
                .indexOf(queryString) === 0
            );
          }),
        ];
      }
      props.pipeLineDealsControll({
        type: "pipelineData",
        data: [...new Set(filterData)],
      });
    } else {
      let obj = {};
      Object.keys(value).map((item) => {
        if (item !== name) {
          value[item] ? (obj[item] = value[item].toLowerCase()) : null;
        }
      });
      queryString !== "" ? (obj[name] = queryString) : null;
      let filterData = [];
      for (
        let x = 0;
        x <= props.item.content.content.searchFilters.length - 1;
        x++
      ) {
        filterData = [
          ...filterData,
          ...dealData.filter((items) => {
            for (var key in obj) {
              if (
                items[key] === null ||
                items[key] === undefined ||
                handleOptionSetValue(items[key]).toLowerCase() !=
                  handleOptionSetValue(obj[key])
              )
                return false;
            }
            return value.searchField
              ? items[props.item.content.content.searchFilters[x]] &&
                  items[props.item.content.content.searchFilters[x]]
                    .toLowerCase()
                    .indexOf(queryString) === 0
              : true;
          }),
        ];
      }
      props.pipeLineDealsControll({
        type: "pipelineData",
        data: [...new Set(filterData)],
      });
    }

    setValue({ ...value, [name]: event.target.value });
  };

  var isJsonParsable = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleFilterOptions = (dealDatas, id) => {
    let resultSet = [];
    resultSet = dealDatas.map((data) => {
      return data[id] && isJsonParsable(handleOptionSetValue(data[id]))
        ? ""
        : handleOptionSetValue(data[id]);
    });
    return [...new Set(resultSet ? resultSet : [])].sort(
      (firstName, secondName) => firstName.localeCompare(secondName)
    );
  };

  useEffect(() => {
    if (profileImageList && profileImageList.length > 0) {
      getImage();
    }
  }, [profileImageList]);

  const getImage = () => {
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(
      process.env.BULK_DOCUMENT_HELPER,
      {
        method: "POST",
        body: JSON.stringify(profileImageList),
        headers: {
          "file-operation": "download",
          "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
          subdomain: subdomain,
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.access_token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.json())
      .then((data) => {
        props.pipeLineDealsControll({ type: "imageData", data: data });
      });
  };

  useEffect(() => {
    let dfilter = { searchField: "" };
    dropDownFilters.map((item) => {
      dfilter[item.id] = "";
    });
    setValue({ ...dfilter });
    getDealData();
  }, []);
  useEffect(() => {
    const profileImageList = [];
    dealData &&
      dealData.length > 0 &&
      dealData.map((item) => {
        profileImageList.push({ file: "profile_" + item.id, type: "image" });
      });
    setProfileImageList(profileImageList.length > 0 ? profileImageList : []);
  }, [dealData]);

  let getDealData = async () => {
    setIsLoading(true);
    if (props.item.content.dealStatus) {
      let dealStatus = props.item.content.dealStatus;

      let dealType =
        props && props.item && props.item.content.dealType
          ? props.item.content.dealType
          : "";
      const payload =
        props.item &&
        props.item.content &&
        props.item.content.content &&
        props.item.content.content.payload
          ? props.item.content.content.payload
          : {
              filters: {},
              selectedProperties: [],
            };
      const response = await httpCall.httpPost(
        `/CRMChartData/getDealWithStatus?dealType=${dealType}&dealStatus=${dealStatus.join()}`,
        payload
      );
      setIsLoading(false);
      let apiRes = await response;
      setDealData(apiRes ? apiRes : []);
      props.pipeLineDealsControll({ type: "pipelineData", data: apiRes });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <Box id="dealFilter" grid>
          <Box sx={{ minWidth: 120 }}>
            <FormControl
              variant="outlined"
              style={{ float: "right" }}
              className={classes.formControl}
            >
              <TextField
                id="search-Textbox"
                size="small"
                variant="outlined"
                value={value.searchField}
                onChange={handleChange("searchField")}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      style={{ paddingRight: "10px" }}
                      disabled
                      size="small"
                    >
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
            {dropDownFilters.map((filter) => {
              return (
                <>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <Select
                      displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      value={value[filter.id]}
                      onChange={handleChange(filter.id)}
                    >
                      <MenuItem value="">{filter.title}</MenuItem>
                      {dealData.length > 0 &&
                        handleFilterOptions(dealData, filter.id).map(
                          (item, index) => {
                            return (
                              item && (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              )
                            );
                          }
                        )}
                    </Select>
                  </FormControl>
                </>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
}
PipeLineFilter.propTypes = {
  pipeLineDealsControll: PropTypes.object,
  item: PropTypes.object,
};
