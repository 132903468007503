import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import * as httpCall from "../../../../../../Utils/apis/apis";
import Select from "@material-ui/core/Select";
import { clearChartKeys } from "../../../../Pages/Common/PageLayout/P_PageLayout.Helper";
import { connect } from "react-redux";
import M_RadiosGroupConfig from "../../RadioGroup/M_RadiosGroupConfig";
import propTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";
import M_DateRangeFilter from "./M_DateRangeFilter";
import DateObject from "react-date-object";
import { portfolioapproval } from "./M_ToolbarFilterhelper";
import { filterRange } from "../../../../../../Utils/config/config";
import {
  customDateFormat,
  FromEpochFormat,
} from "../../../../../../Utils/Helpers/dateUtils";
import { handleJSON } from "../../../Common/Renderers/Views/Charts/M_ChartCard.Helper";
const useStyles = makeStyles((theme) => ({
  filtersPanel: {
    display: "flex",
    flexDirection: "row",
    // paddingTop: "5px",
    // paddingLeft: "2.5%"
  },

  filterSelect: {
    color: "white",
    "MuiInput-colorSecondary.MuiInput-underline": "Green !important",

    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    paddingLeft: "6px",
    paddingRight: "6px",
  },

  filterSelectOption: {
    background: "white",
    color: "black",
  },
  underline: {
    borderBottom: "2px solid white",
  },
  formControl: {
    minWidth: 150,
    background: "#fff",
    borderColor: "whitesmoke",
    borderRadius: "4px",
    color: "black",
    // marginLeft: "5px",
    marginBottom: "10px",
    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
      width: "102px",
      background: "#fff",
      borderColor: "whitesmoke",
      borderRadius: "4px",
      color: "black",
      // marginLeft: "5px",
      marginBottom: "5px",
      marginTop: "3px",
      marginLeft: "13vw",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const M_ToolbarFilter = (props) => {
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedText, setSelectedText] = React.useState("");
  const [selectedDataSet, setSelectedDataSet] = React.useState({});
  const [startDate, setStartDate] = React.useState(
    props.defaultValue
      ? props.defaultValue
      : new DateObject().subtract(365, "days").format("DD/MM/YYYY")
  );
  const { templateId } = useParams();
  const [endDate, setEndDate] = React.useState(
    props.endDateDefaultValue
      ? props.endDateDefaultValue
      : new DateObject().format("DD/MM/YYYY")
  );
  const availableOptionSets = props.optionSets;
  const classes = useStyles();
  const filterId = props && props.filterId ? props.filterId : "";
  const date = props && props.date ? props.date : "";
  let dateFormat = props && props.dateFormat ? props.dateFormat : "MMMM YYYY";
  useEffect(() => {
    getFilterOptions(props.optionSetName);
  }, []);
  //Storing Template Id when we change template
  useEffect(() => {
    localStorage.setItem(portfolioapproval.TEMPLATEID, props.templateId);
  }, [props.templateId]);
  useEffect(() => {
    if (filterOptions.length > 0) {
      if (props.type === filterRange.RANGE) {
        const commonParams = {
          filterName: props.filterName,
          value: selectedValue,
          FilterText: selectedText,
          type: props.type,
          to: endDate,
        };
        const dateParams = props.toSeletedDate
          ? { toDate: props.toSeletedDate }
          : { toDate: "" };

        props.dispatchFilters({ ...commonParams, ...dateParams });
      } else {
        props.dispatchFilters({
          filterName: props.filterName,
          value: selectedValue,
          FilterText: selectedText,
          data: selectedDataSet.find((ele) => ele.value === selectedValue),
        });
      }
    }
  }, [selectedValue, startDate, endDate]);
  const ArrayToJSon = (keyList, valueList) => {
    let jsonValues = {};
    keyList.forEach((element, index) => {
      jsonValues[element] = valueList[index];
    });
    return jsonValues;
  };
  function handleDateFilter(filterOptions) {
    const data = filterOptions;
    const uniqueYears = {};
    const processedData = data.reduce((result, item) => {
      const labelData =
        item.label === "All"
          ? item.label
          : customDateFormat(FromEpochFormat(Number(item.label)), dateFormat);
      const ValueData =
        item.label === "All"
          ? item.label
          : customDateFormat(FromEpochFormat(Number(item.label)), dateFormat);
      if (!uniqueYears[labelData]) {
        uniqueYears[labelData] = true;
        item.label === "All"
          ? result.push({
              label: labelData,
              value: ValueData,
              series: {
                label: labelData,
              },
            })
          : result.push({
              label: labelData.toString(),
              value: ValueData.toString(),
              series: {
                label: labelData.toString(),
              },
            });
      }
      return result;
    }, []);

    return processedData;
  }
  const getFilterOptions = useCallback(async (optionSetName) => {
    let optionSetFilters = [];
    //Setting Filter Value in LocalStorage
    const templateFilterValue = `${props.templateId}_${props.filterName}`;
    var filterValues = "";
    const filterVal = props.filters;
    for (let item in props.filters) {
      if (item === templateFilterValue) {
        filterValues = props.filters[item];
        localStorage.setItem(portfolioapproval.FILTERVALUE, filterValues);
      }
    }
    if (optionSetName === "fundsField") {
      optionSetFilters = [
        { label: "Fund-1", value: "Fund1" },
        { label: "Fund-2", value: "Fund2" },
        { label: "Fund-3", value: "Fund3" },
        { label: "Fund-4", value: "Fund4" },
      ];
    } else {
      if (availableOptionSets) {
        optionSetFilters = getOptions(optionSetName);
      }
      if (optionSetFilters.length === 0) {
        let mainDataSet = props.currentPage.data;
        let parameterKeys = {};
        props.toolbar &&
          props.toolbar.primary &&
          props.toolbar.primary.filters.every((filter) => {
            let filterChosen =
              filter &&
              filter.templateIds &&
              filter.templateIds.find((item) => item === templateId);
            if (filterChosen) {
              parameterKeys = filter.chartParameters
                ? { investmentId: props.investmentId }
                : filter.filterOption
                ? filter.filterOption
                : { investmentId: props.investmentId };
              return false;
            }
            return true;
          });
        for (let i in parameterKeys) {
          if (
            typeof parameterKeys[i] == "string" &&
            parameterKeys[i].toLowerCase() === "=crmid"
          ) {
            parameterKeys[i] = props.investmentId;
            continue;
          }
          if (
            typeof parameterKeys[i] == "string" &&
            parameterKeys[i].charAt(0) === "="
          ) {
            parameterKeys[i] = parameterKeys[i].substring(1);
            if (mainDataSet && mainDataSet[parameterKeys[i]]) {
              parameterKeys[i] = mainDataSet[parameterKeys[i]];
            } else {
              parameterKeys[i] = "";
            }
          }
        }
        const chartData = await getChart({
          chartId: optionSetName,
          parameters: parameterKeys,
        });
        if (chartData && chartData.data && chartData.data.series) {
          let categories = chartData.data.categories;
          const filterOptions = chartData.data.series.map((chartPoint) => {
            return {
              label: chartPoint.data[0],
              value: chartPoint.name[0],
              series: ArrayToJSon(categories, [...chartPoint.data]),
            };
          });
          optionSetFilters = date
            ? handleDateFilter(filterOptions)
            : filterOptions;
        } else if (
          props.type === filterRange.RANGE &&
          props.templateIds.includes(props.templateId)
        ) {
          optionSetFilters = [{ label: startDate, value: startDate }];
        } else {
          optionSetFilters = [{ label: "Select", value: null }];
        }
      }
    }
    var itemExist = false;

    const uniqueLabelsMap = new Map();
    let filteredData = optionSetFilters.reduce((accumulator, item) => {
      const label = handleJSON(item.label);

      if (!uniqueLabelsMap.has(label)) {
        uniqueLabelsMap.set(label, true);
        accumulator.push(item);
      }

      return accumulator;
    }, []);
    optionSetFilters = filteredData;
    setSelectedDataSet(optionSetFilters);
    setFilterOptions(optionSetFilters);
    optionSetFilters.forEach((item) => {
      if (item.value === props.defaultValue) {
        itemExist = true;
      }
    });
    const selectedFilter = optionSetFilters.find((item) =>
      handleOptionSetValue(item.value) === filterValues
        ? filterValues
        : itemExist
        ? props.defaultValue
        : ""
    );
    if (
      selectedFilter &&
      selectedFilter !== null &&
      selectedFilter !== undefined &&
      selectedFilter.label
    ) {
      setSelectedText(selectedFilter.label);
      setSelectedValue(
        filterVal[templateFilterValue] ? filterValues : props.defaultValue
      );
    } else if (optionSetFilters.length > 0) {
      var index = optionSetFilters.findIndex(function (item) {
        return handleOptionSetValue(item.label) === filterValues
          ? filterValues
          : props.defaultValue;
      });
      index = index == -1 ? 0 : index;
      setSelectedText(optionSetFilters[index].label);
      setSelectedValue(optionSetFilters[index].value);
    }
  });

  const getChart = async (chartRequest) => {
    let url = "/ChartData/getchartdata";
    let requestPayload = chartRequest;
    const inScopeFilters =
      props.toolbar &&
      props.toolbar.primary &&
      props.toolbar.primary.filters &&
      props.toolbar.primary.filters.filter(
        (item) =>
          item.templateIds && item.templateIds.indexOf(props.templateId) > -1
      );
    if (
      inScopeFilters &&
      inScopeFilters.length > 0 &&
      inScopeFilters[props.indexValue] &&
      inScopeFilters[props.indexValue].optionSetName
    ) {
      requestPayload.chartId = inScopeFilters[props.indexValue].optionSetName;
      const result = await httpCall.httpPost(url, requestPayload);
      let chData = result;
      localStorage.removeItem(portfolioapproval.FILTERVALUE);
      localStorage.setItem(
        portfolioapproval.FILTERVALUE,
        chData &&
          chData.data &&
          chData.data.series &&
          chData.data.series[0].name
      );
      return chData;
    }
  };

  const getOptions = (optionSetName) => {
    let options = [];
    let currentOptionSet = availableOptionSets.filter((optionSet) => {
      return optionSet.optionSetName === optionSetName;
    });
    if (currentOptionSet.length > 0) {
      options = currentOptionSet[0].options;
    }
    return options;
  };

  const getRadioOption = () => {
    let options = filterOptions.map((option) => option);
    return options;
  };

  const onFilterChange = (selectedOption) => {
    const { options, selectedIndex } = selectedOption.target;
    const FilterText = options[selectedIndex].text;
    localStorage.setItem(
      portfolioapproval.FILTERVALUE,
      options[selectedIndex].value
    );
    setSelectedText(FilterText);
    clearChartKeys();
    setSelectedValue(selectedOption.target.value);
  };
  const onRadioFilterChange = (selectedOption) => {
    clearChartKeys();
    setSelectedValue(selectedOption.value);
  };
  const filterChange = (fromDate, toDate) => {
    clearChartKeys();
    setStartDate(fromDate);
    setEndDate(toDate);
    setSelectedValue(fromDate);
  };
  let optionsNative = () => {
    let options = filterOptions.map((option) => (
      <option
        className={classes.filterSelectOption}
        key={handleOptionSetValue(option.value, false)}
        value={handleOptionSetValue(option.value, false)}
      >
        {handleOptionSetValue(option.label)}
      </option>
    ));
    return options;
  };

  return (
    <div
      key={`${props.templateId}_${props.filterName}_panel`}
      className={classes.filtersPanel}
    >
      {props.type == filterRange.RANGE ? (
        <div>
          {props.displayLabel ? (
            <p style={{ marginBottom: "0px" }}> {props.displayLabel} </p>
          ) : (
            <></>
          )}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            style={props.displayLabel ? { marginTop: "0px" } : {}}
          >
            <M_DateRangeFilter
              error={false}
              native={true}
              startDate={
                props && props.defaultValue === "All"
                  ? ""
                  : props.defaultValue
                  ? props.defaultValue
                  : new DateObject().subtract(365, "days")
              }
              endDate={
                props.endDateDefaultValue === "All"
                  ? ""
                  : props.endDateDefaultValue
                  ? props.endDateDefaultValue
                  : new DateObject()
              }
              align="horizontal"
              filterChange={filterChange}
            />
          </FormControl>
        </div>
      ) : (
        <>
          {props.type == "radio" ? (
            <M_RadiosGroupConfig
              className={classes.filterSelect}
              value={selectedValue}
              error={false}
              native={true}
              defaultValue={props.defaultValue}
              align="horizontal"
              options={getRadioOption()}
              onChange={onRadioFilterChange}
            />
          ) : (
            <div>
              {props.displayLabel ? (
                <p style={{ marginBottom: "0px" }}> {props.displayLabel} </p>
              ) : (
                <></>
              )}
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
                style={props.displayLabel ? { marginTop: "0px" } : {}}
              >
                <Select
                  id={filterId}
                  key={props.filterName + "_options"}
                  displayEmpty={false}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  value={selectedValue}
                  onChange={onFilterChange}
                  error={false}
                  native={true}
                  defaultValue={props.defaultValue}
                >
                  {optionsNative().length > 0 ? (
                    optionsNative()
                  ) : (
                    <MenuItem disabled value="">
                      <em>{props.filterLabel}</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          )}
        </>
      )}
    </div>
  );
};

M_ToolbarFilter.propTypes = {
  optionSets: propTypes.any,
  optionSetName: propTypes.any,
  filterName: propTypes.any,
  currentPage: propTypes.any,
  filterOption: propTypes.any,
  toolbar: propTypes.any,
  investmentId: propTypes.any,
  templateId: propTypes.any,
  indexValue: propTypes.any,
  filterId: propTypes.any,
  onRadioFilterChange: propTypes.func,
  filterLabel: propTypes.any,
  type: propTypes.any,
  defaultValue: propTypes.any,
  displayLabel: propTypes.any,
  templateIds: propTypes.any,
  date: propTypes.any,
  dateFormat: propTypes.any,
  dispatchFilters: propTypes.any,
  filters: propTypes.any,
  toSeletedDate: propTypes.any,
  endDateDefaultValue: propTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    filters: state.manageFilterValueReducer.filters,
  };
}

export default connect(mapStateToProps, undefined)(M_ToolbarFilter);
