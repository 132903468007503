function getMonthYearForGroup() {
  const getLocalState=JSON.parse(localStorage.getItem('state'));
  let monthValue, YearValue, finalText='';
    getLocalState && getLocalState.manageToolbarSelections && getLocalState.manageToolbarSelections.filters && getLocalState.manageToolbarSelections.filters.length>0 && getLocalState.manageToolbarSelections.filters.map(item=>{
      if(item.filterName==="month"){
        monthValue=item.FilterText
      }
      if(item.filterName==="year"){
        YearValue=item.FilterText
      }
    })     
    if(monthValue && YearValue){
      finalText=monthValue +" "+YearValue
    }    
    return  finalText
  } 
  export default getMonthYearForGroup;