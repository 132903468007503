/* eslint-disable */

import React from "react";
import { connect } from "react-redux";
import Routes from "../Routes/routes";
import O_Navigation from "./Factory/Organisms/Common/O_Navigation/O_Navigation";
import M_UnsupportedBrowser from "./Factory/molecules/common/error/M_UnsupportedBrowser";
import { isUnsupportedBrowser } from "../Utils/Helpers/common";
import "../Assets/css/main.scss";
export const App = (props) => {
  const isUnsupported = isUnsupportedBrowser();
  let user = props.oidc.user;

  if (isUnsupported) {
    return (
      <div style={styles.root}>
        <M_UnsupportedBrowser />
      </div>
    );
  }
  return (
    <React.Fragment>
      {user && <O_Navigation user={user} />}
      <Routes />
    
    </React.Fragment>
  );
};
function mapStateToProps(state, ownProps) {
  return state;
}

export default connect(mapStateToProps)(App);

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    flexShrink: 1,
    marginTop: "10%",
  },
};
