import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Select from "./A_Select";
import { Box } from "@material-ui/core";
import {
  FromEpochFormat,
  epochToDateFormatHelper,
} from "../../../../../Utils/Helpers/dateUtils";
import PropTypes from "prop-types";
import A_TableMultiSelect from "./A_TableMultiSelect";
import { handleJSON } from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard.Helper";
const useStyles = makeStyles(() => ({
  filterPosition: {
    display: "inline",
    marginLeft: "1.2rem",
  },
}));

const M_CardFilter = React.memo(function M_CardFilter(props) {
  const classes = useStyles();
  const [filtervalue, setFilterValue] = useState([]);
  const [filterstate, setFilterState] = useState("");
  const [filterid, setFilterId] = useState("");
  const [currentTab, setCurrentTab] = useState(-1);
  const [tabChanged, setTabChanged] = useState(false);
  useEffect(() => {
    const storedTab = localStorage.getItem("activetab");
    if (storedTab) {
      const parsedTab = JSON.parse(storedTab);
      setCurrentTab(parsedTab["Pipeline by Asset Type"]);
      setTabChanged(true);
    }
  }, [currentTab]);
  //Getting the Filter Value and update in state
  useEffect(() => {
    const updatedFilterValues = {};
    props.chartFilter.forEach((option) => {
      let storedValue = getFilterValue(option.id);
      storedValue = JSON.parse(localStorage.getItem(option.id));
      if (
        storedValue &&
        storedValue["date"] !== undefined &&
        storedValue["date"] !== null &&
        storedValue["year"] !== undefined &&
        storedValue["year"] !== null
      ) {
        storedValue = {
          ...storedValue,
          date: true,
          year: true,
        };
      }
      if (storedValue) {
        try {
          let array = [];
          let keyValue = filterPropsData(props.chart.data);
          let properties = storedValue;
          keyValue.map((item) => {
            Object.keys(item).forEach(function eachKey(key) {
              if (key === properties.id) {
                if (properties && properties.date && properties.year) {
                  if (
                    item[properties.id] !== null &&
                    item[properties.id] !== "0"
                  ) {
                    let dateEpoch = FromEpochFormat(
                      parseInt(item[properties.id])
                    );
                    let date = new Date(dateEpoch);
                    array.push(date.getFullYear());
                  }
                } else if (properties && properties.date && properties.month) {
                  if (
                    item[properties.id] !== null &&
                    item[properties.id] !== "0"
                  ) {
                    let dateEpoch = FromEpochFormat(
                      parseInt(item[properties.id])
                    );
                    let date = new Date(dateEpoch);

                    array.push(
                      date.toLocaleString("default", { month: "short" })
                    );
                  }
                } else if (
                  properties &&
                  properties.date &&
                  properties.dateFormat
                ) {
                  if (
                    item[properties.id] !== null &&
                    item[properties.id] !== "0"
                  ) {
                    let CustomDate = epochToDateFormatHelper(
                      parseInt(item[properties.id]),
                      properties.dateFormat
                    );

                    array.push(CustomDate);
                  }
                } else {
                  if (
                    item[properties.id] !== null &&
                    item[properties.id] !== "" &&
                    item[properties.id] !== "null" &&
                    item[properties.id] !== "[]"
                  ) {
                    array.push(item[properties.id]);
                  }
                }
              }
            });
          });
          if (properties && properties.date && properties.dateFormat) {
            array = array.filter((value) => !Number.isNaN(value));
          }
          let val = storedValue.value;
          let variable = [...new Set(array)];
          if (variable.includes(val)) {
            updatedFilterValues[option.id] = storedValue;
          }
        } catch {
          updatedFilterValues[option.id] = storedValue;
        }
      }
    });
    var size = Object.keys(updatedFilterValues).length;
    size > 0 && setFilterValue([updatedFilterValues]);
  }, [filterstate]);
  //Data Filter
  useEffect(() => {
    filtervalue.forEach((obj) => {
      for (const key in obj) {
        const innerObj = obj[key];
        let { id, value, date, year, dateFormat } = innerObj;
        let storedValue = JSON.parse(localStorage.getItem(id));
        if (storedValue.value == "") {
          value = "";
        }
        if (date !== undefined && year !== undefined) {
          innerObj.date = true;
          innerObj.year = true;
        }
        const newValue = Array.isArray(value)
          ? [...value]
          : {
              id: id,
              label: value,
              value: value,
              date: date,
              year: year,
              dateFormat: dateFormat,
            };
        Array.isArray(newValue)
          ? handleOptions(newValue, id)
          : handleChange(newValue, id);
      }
    });
  }, [filterid, props.newData]);

  //Getitng Filter Value
  const getFilterValue = (id) => {
    let storedValue = localStorage.getItem(id);
    storedValue = JSON.parse(storedValue);
    if (
      storedValue &&
      storedValue["date"] !== undefined &&
      storedValue["date"] !== null &&
      storedValue["year"] !== undefined &&
      storedValue["year"] !== null
    ) {
      storedValue = {
        ...storedValue,
        date: true,
        year: true,
      };
    }
    setFilterId(id);
    return storedValue ? storedValue : null;
  };
  const handleChange = (event, id) => {
    setTabChanged(false);
    if (event && event.date && event.year) {
      props.handleCardLevelFilterData(
        { id: id, value: event.value, date: event.date, year: event.year },
        props.chart.data
      );
    } else if (event && event.date && event.month) {
      props.handleCardLevelFilterData(
        {
          id: id,
          value: event.value,
          date: event.date,
          month: event.month,
        },
        props.chart.data
      );
      setFilterState({
        id: id,
        value: event.value,
        date: event.date,
        month: event.month,
      });
    } else if (event && event.date && event.dateFormat) {
      props.handleCardLevelFilterData(
        {
          id: id,
          value: event.value,
          date: event.date,
          dateFormat: event.dateFormat,
        },
        props.chart.data
      );
      setFilterState({
        id: id,
        value: event.value,
        date: event.date,
        dateFormat: event.dateFormat,
      });
    } else {
      props.handleCardLevelFilterData(
        { id: id, value: event.value },
        props.chart.data
      );
      setFilterState({ id: id, value: event.value });
    }
  };
  const handleOptions = (selectedValues, id) => {
    const filterData = [];
    selectedValues.length == 0 ? (filterData.value = "") : "";
    selectedValues &&
      selectedValues.forEach((selectedValue) => {
        let newObj = { id: id };
        if (selectedValue.date && selectedValue.year) {
          newObj.date = selectedValue.date;
          newObj.year = selectedValue.year;
          newObj.value = selectedValue.value;
          filterData.push(newObj);
        } else if (selectedValue.date && selectedValue.month) {
          newObj.date = selectedValue.date;
          newObj.month = selectedValue.month;
          newObj.value = selectedValue.value;
          filterData.push(newObj);
        } else if (selectedValue.date && selectedValue.dateFormat) {
          newObj.date = selectedValue.date;
          newObj.dateFormat = selectedValue.dateFormat;
          newObj.value = selectedValue.value;
          filterData.push(newObj);
        } else {
          newObj.value = selectedValue.value;
          filterData.push(newObj);
        }
      });
    setFilterState(filterData);
    props.handleCardLevelFilterData({ id: id, value: filterData });
  };
  //TODO commenting this to optimize this function later on.
  const getDropDownOptions1 = (properties) => {
    let array = [];
    let keyValue = filterPropsData(props.chart.data);

    keyValue.map((item) => {
      Object.keys(item).forEach(function eachKey(key) {
        if (key === properties.id) {
          if (properties && properties.date && properties.year) {
            if (item[properties.id] !== null && item[properties.id] !== "0") {
              let dateEpoch = FromEpochFormat(parseInt(item[properties.id]));
              let date = new Date(dateEpoch);
              array.push(date.getFullYear());
            }
          } else if (properties && properties.date && properties.month) {
            if (item[properties.id] !== null && item[properties.id] !== "0") {
              let dateEpoch = FromEpochFormat(parseInt(item[properties.id]));
              let date = new Date(dateEpoch);

              array.push(date.toLocaleString("default", { month: "short" }));
            }
          } else if (properties && properties.date && properties.dateFormat) {
            if (item[properties.id] !== null && item[properties.id] !== "0") {
              let CustomDate = epochToDateFormatHelper(
                parseInt(item[properties.id]),
                properties.dateFormat
              );

              array.push(CustomDate);
            }
          } else {
            if (
              item[properties.id] !== null &&
              item[properties.id] !== "" &&
              item[properties.id] !== "null" &&
              item[properties.id] !== "[]"
            ) {
              array.push(item[properties.id]);
            }
          }
        }
      });
    });
    if (properties && properties.date && properties.dateFormat) {
      array = array.filter((value) => !Number.isNaN(value));
    }
    const uniqueLabelsMap = new Map();
    let filteredData = array.reduce((accumulator, item) => {
      const label = handleJSON(item);

      if (!uniqueLabelsMap.has(label)) {
        uniqueLabelsMap.set(label, true);
        accumulator.push(item);
      }

      return accumulator;
    }, []);
    let variable = [...new Set(filteredData)];
    let optionset = [];
    variable.map((item) => {
      if (properties && properties.year) {
        optionset.push({
          value: item,
          label: item,
          year: properties.year,
          date: true,
          id: properties.id,
        });
      } else if (properties && properties.month) {
        optionset.push({
          value: item,
          label: item,
          month: properties.month,
          date: true,
          id: properties.id,
        });
      } else if (properties && properties.dateFormat) {
        optionset.push({
          value: item,
          label: item,
          dateFormat: properties.dateFormat,
          date: true,
          id: properties.id,
        });
      } else {
        optionset.push({ value: item, label: item, id: properties.id });
      }
    });
    //If Filter option is not present then update filter to Default state
    if (tabChanged && currentTab != -1) {
      props.chartFilter.forEach((option) => {
        let searchItem = false;
        let storedValue = JSON.parse(localStorage.getItem(option.id));
        if (storedValue && storedValue.value != "") {
          optionset.forEach((item) => {
            if (
              (item.id === storedValue.id &&
                item.value === storedValue.value) ||
              item.id !== storedValue.id
            ) {
              searchItem = true;
            }
          });
          if (optionset.length === 0) {
            searchItem = true;
          }
          if (!searchItem) {
            let storedValue = JSON.parse(localStorage.getItem(option.id));
            storedValue.value = "";
            if (
              storedValue.date ||
              storedValue.dateFormat ||
              storedValue.year
            ) {
              storedValue = { id: option.id, value: "" };
            }
            localStorage.setItem(option.id, JSON.stringify(storedValue));
          }
        }
      });
    }
    if (properties && properties.sorting) {
      optionset.sort((preValue, nextValue) => {
        const getLabel = (value) => {
          try {
            const parsedValue = JSON.parse(value);
            if (Array.isArray(parsedValue)) {
              return parsedValue[0];
            }
            return parsedValue.label || parsedValue.value || parsedValue;
          } catch (error) {
            return value;
          }
        };

        const labelA = getLabel(preValue.label);
        const labelB = getLabel(nextValue.label);

        return String(labelA).localeCompare(String(labelB));
      });
    }
    if (
      properties &&
      properties.defaultValue &&
      !localStorage.getItem(properties.id)
    ) {
      let object = {
        id: properties.id,
        value: optionset[0] ? optionset[0].label : "",
      };
      localStorage.setItem(properties.id, JSON.stringify(object));

      props.handleCardLevelFilterData(
        { id: properties.id, value: optionset[0] ? optionset[0].label : "" },
        props.chart.data
      );
    }
    return (
      <div className={classes.filterPosition}>
        {properties.multiple ? (
          <A_TableMultiSelect
            id={properties.id}
            variant="standard"
            defaultValue={
              localStorage.getItem(properties.id)
                ? JSON.parse(localStorage.getItem(properties.id))
                : []
            }
            label={properties.label ? properties.label : ""}
            minWidth={properties.minWidth ? properties.minWidth : ""}
            multiple={true}
            options={optionset}
            onChange={handleOptions}
          />
        ) : (
          <A_Select
            id={properties.id}
            variant="standard"
            defaultValue={
              localStorage.getItem(properties.id)
                ? JSON.parse(localStorage.getItem(properties.id)).value
                : ""
            }
            label={properties.label ? properties.label : ""}
            minWidth={properties.minWidth ? properties.minWidth : ""}
            native={true}
            options={optionset}
            onChange={handleChange}
          />
        )}
      </div>
    );
  };

  const filterPropsData = (data) => {
    let resArray = [];
    if (data && data.series && data.series.length > 0) {
      for (let index = 0; index < data.series.length; index++) {
        let object = {};
        for (let j = 0; j < data.series[index].data.length; j++) {
          if (data && data.dimensions && data.dimensions.length > 0) {
            let dimentionKey = data.dimensions[0];
            let dimentionValue = data.series[index].name[0];
            object[dimentionKey] = dimentionValue;
          }

          let keyName =
            data.categories && data.categories.length > 0 && data.categories[j]
              ? data.categories[j]
              : "_";
          let keyValue = data.series[index].data[j];
          object[keyName] = keyValue;
        }
        resArray.push(object);
      }
    }
    return resArray;
  };

  return (
    <Box style={{ display: "table", margin: "10 auto" }}>
      {props.chartFilter.map((option) => (
        <React.Fragment key={option.id}>
          {getDropDownOptions1(option)}
        </React.Fragment>
      ))}
    </Box>
  );
});
M_CardFilter.propTypes = {
  chart: PropTypes.any,
  chartFilter: PropTypes.any,
  handleCardLevelFilterData: PropTypes.func,
  handleCardLevelFilterDataMultiSelect: PropTypes.func,
  newData:PropTypes.any,
};

export default M_CardFilter;
