import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import theme from "../../../../themes/defaultTheme";
import "../../../../../Assets/css/main.scss";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result = stabilizedThis.map((el) => el[0]);
  return result;
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, col, action } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {action && (action.edit || action.delete) ? (
          <TableCell></TableCell>
        ) : (
          ""
        )}
        {col
          ? Object.keys(col).map((headCell) =>
              !col[headCell].hide ? (
                col[headCell].sorting ? (
                  <TableCell
                    key={col[headCell].id}
                    sortDirection={orderBy === col[headCell].id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === col[headCell].id}
                      direction={orderBy === col[headCell].id ? order : "asc"}
                      onClick={createSortHandler(col[headCell].id)}
                    >
                      {col[headCell].label}
                      {orderBy === col[headCell].id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={col[headCell].id}>
                    {col[headCell].label}
                  </TableCell>
                )
              ) : (
                ""
              )
            )
          : ""}
        {action && (action.edit || action.delete) ? (
          <TableCell></TableCell>
        ) : (
          ""
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  action: PropTypes.object,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  col: PropTypes.object.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    marginTop: "30px",
    marginBottom: "20px",
  },
  searchBox: {
    // marginTop: "30px",
    // marginBottom: "20px",
    //commenting and keeping for future ref
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    padding: "5px",
    marginRight: "0px",
    // marginTop: "18px",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { title } = props.config;
  const { searchable } = props.config;
  const { addButton } = props.config;
  const { data } = props;

  const handleChangeSearch = (event) => {
    // return results
    props.onChange(event, data);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {searchable ? (
        <A_TextField
          className={classes.searchBox}
          onChange={handleChangeSearch}
          label="Search"
          adornment={{ position: "start", value: <SearchIcon /> }}
        />
      ) : (
        ""
      )}
      {addButton ? (
        <AddIcon
          id="addButton"
          className={classes.addButton}
          onClick={props.handleAddButtonClick}
        />
      ) : (
        ""
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  handleAddButtonClick: PropTypes.func,
  onChange: PropTypes.func,
  numSelected: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  tableRow: {
    height: 40,
  },
  tableCell: {
    padding: "0px 16px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableContainer: {
    maxHeight: "40vh",
  },
  indent: {
    paddingLeft: "20px",
  },
  indent2: {
    paddingLeft: "40px",
  },
  bold: {
    fontWeight: "bold",
  },
  small: {
    fontSize: "10px",
  },
  total_row: {
    fontWeight: "bold",
    borderTop: "1px solid #888 !important",
    borderBottom: "1px solid #888 !important",
  },
  italic: {
    fontStyle: "italic",
  },
}));

const A_EnhancedTable = (props) => {
  const classes = useStyles();
  let ele = null;
  const { columnConfig } = props.configurations;
  const { configurations, isEditRightAlign } = props;
  const dataOrignal = props.data;
  const { action } = props.configurations;
  const { dragable } = props.configurations;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(dragable ? "sortOrder" : "");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [searchPage, setSearchPage] = React.useState(0);
  const dense = false;
  const [searchString, setSearchString] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    configurations && configurations.paginate
      ? 5
      : props.data && props.data.length > 5
      ? props.data.length + 1
      : 5
  );
  const [data, setData] = React.useState(props.data);

  React.useEffect(() => {
    setData(dataOrignal);
    if (!configurations || !configurations.paginate) {
      setRowsPerPage(
        dataOrignal && dataOrignal.length > 5 ? dataOrignal.length + 1 : 5
      );
    }
    handleSearchChange("", [], searchString);
  }, [dataOrignal]);
/* eslint-disable no-unused-vars */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // TODO in this code rows has not defined so commenting it for now as it can be a usable code
      // const newSelecteds = rows.map((n) => n.name);
      // setSelected(newSelecteds);
      // return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /* eslint-disable no-unused-vars */
  const handleSearchChange = (event, data = [], defaultString = null) => {
    let valuSearch = defaultString
      ? defaultString
      : !event || event === ""
      ? ""
      : event.target.value;

    if (!defaultString) {
      setSearchString(valuSearch);
      setPage(0);
    }

    if (!valuSearch || valuSearch == "") {
      setData(dataOrignal);
      setPage(searchPage);
      setSearchString(null);
      return;
    }
    let results = [];
    for (var i = 0; i < dataOrignal.length; i++) {
      for (let key in dataOrignal[i]) {
        if (
          typeof dataOrignal[i][key] == "string" &&
          dataOrignal[i][key].toLowerCase().indexOf(valuSearch.toLowerCase()) !=
            -1
        ) {
          results.push(dataOrignal[i]);
          break;
        }
      }
    }
    setData(results);
  };

  const handleDragStart = (e) => {
    ele = e.target;
    // e.target.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
    // e.dataTransfer.setData('text/html', e.target.innerHTML);
  };
  const handleDragOver = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.dataTransfer.dropEffect = "move";
    return false;
  };
  const handleDrop = (e) => {
    //sort order values
    let dragedElementOrderStr = ele.getAttribute("sortOrder");
    let dropedElementOrderStr =
      e.target.parentElement.getAttribute("sortOrder") == null
        ? e.target.parentElement.parentElement.getAttribute("sortOrder")
        : e.target.parentElement.getAttribute("sortOrder");
    if (dragedElementOrderStr == dropedElementOrderStr) {
      return;
    }
    // draged and droped elements
    let dragedElementArray = data.find(
      (item) => item.sortOrder == dragedElementOrderStr
    );
    let dropedElementArray = data.find(
      (item) => item.sortOrder == dropedElementOrderStr
    );

    const dragedElementOrder = dragedElementArray.sortOrder;
    const dropedElementOrder = dropedElementArray.sortOrder;

    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];
      if (currentItem.id === dragedElementArray.id) {
        currentItem.sortOrder = dropedElementOrder;
      } else {
        if (dragedElementOrder > dropedElementOrder) {
          //Dragged up
          if (
            dragedElementOrder >= currentItem.sortOrder &&
            currentItem.sortOrder >= dropedElementOrder
          ) {
            currentItem.sortOrder = currentItem.sortOrder + 1;
          }
        } else {
          //Dragged down
          if (
            dragedElementOrder <= currentItem.sortOrder &&
            currentItem.sortOrder <= dropedElementOrder
          ) {
            currentItem.sortOrder = currentItem.sortOrder - 1;
          }
        }
      }
    }

    let resArray = [...data];

    // setting sorted vales
    setData([...resArray]);

    // setting opacity
    ele.style.opacity = "";
    e.target.style.opacity = "";
    if (props.isDraged) {
      props.isDraged();
    }

    return false;
  };
  const isLinkTypeData = (row, configurations, key) => {
    return (
      <a href={row[configurations.columnConfig[key].linkName]}>{row[key]} </a>
    );
  };

  //nested Table column
  const isChildItem = (list) => {
    return list.map((item, index) => {
      return (
        <>
          <TableRow key={index}>
            <TableCell>{item.label}</TableCell>
            <br></br>
          </TableRow>
        </>
      );
    });
  };

  function numberWithCommas(value) {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return "Invalid input";
    }
    const formattedValue = number.toLocaleString("en-GB");
    return formattedValue;
  }

  const formatDataBasedOnType = (data, config, variance, row, cell) => {
    if (row.cellStyle && cell in row.cellStyle) {
      let classesArry = row.cellStyle[cell].split(",");
      let classesSeperated = "";
      for (let i = 0; i < classesArry.length; i++) {
        classesSeperated = classesSeperated + ` ${classes[classesArry[i]]}`;
      }

      return <span className={classesSeperated}> {data}</span>;
    }
    if (!config || !config.type) {
      return data;
    } else if (config && config.type == "text") {
      return data;
    } else if (config && config.clickable) {
      return (
        <a
          onClick={(event) => {
            // TODO commenting this code as loadchart is not defined but it can be a usable code
            // loadChart(event, config, data, row);
          }}
          href="javascript:void(0)"
        >
          {" "}
          {data}
        </a>
      );
    } else if (config && config.type == "number") {
      let withCommaNumber = !isNaN(data) ? numberWithCommas(data) : data;
      let val = !isNaN(data) ? parseInt(data) : data;
      let res = val.toFixed(config.format ? config.format : 2);
      let color = "";

      if (variance) {
        color = res > 0 ? "green" : "red";
      }

      if (config.currency) {
        res = config.currency + res;
      }
      return <span style={{ color: color }}> {config.isCommaEnable ? withCommaNumber : res}</span>;
    } else if (config && config.type == "date") {
      return getFormattedDate(data, config.format, "dd/dd/yyyy");
    } else if (config && config.type == "percentage") {
      let val = !isNaN(data) ? parseInt(data) : data;
      val = val * 100;
      let res = val.toFixed(config.format ? config.format : 1);
      let color = "";
      if (variance) {
        color = res > 0 ? "green" : "red";
      }
      return <span style={{ color: color }}> {res + "%"}</span>;
    }
  };
  // TODO This code has future use so commenting it for now
  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // TODO it has a future use so commenting it for now
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            config={configurations}
            data={data}
            onChange={handleSearchChange}
            handleAddButtonClick={props.handleAddButtonClick}
          />
          <TableContainer
            className={clsx({
              [classes.tableContainer]: configurations.isMaxHeight,
            })}
          >
            <Table
              className={props.minWidth ? props.minWidth : classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                col={columnConfig}
                action={action}
              />
              <TableBody>
                {data && data.length > 0 ? (
                  stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // Table column
                      //row style
                      let defaultStyle = {
                        cursor: dragable ? "move" : "default",
                      };
                      let rowStyle = row.style
                        ? { ...defaultStyle, ...row.style }
                        : defaultStyle;

                      return (
                        <TableRow
                          className={classes.tableRow}
                          draggable={dragable ? "true" : ""}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragStart={handleDragStart}
                          style={rowStyle}
                          key={index}
                          sortOrder={row.sortOrder ? row.sortOrder : ""}
                        >
                          {action ? (
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              style={{ cursor: "pointer" }}
                            >
                              {!isEditRightAlign && action.edit ? (
                                <EditIcon
                                  key={row.id}
                                  id={row.id}
                                  onClick={props.handleEditButtonClick}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {row
                            ? Object.keys(
                                configurations && configurations.columnConfig
                                  ? configurations.columnConfig
                                  : {}
                              )

                                //.sort()
                                .map((key) =>
                                  configurations &&
                                  configurations.columnConfig &&
                                  configurations.columnConfig[key] &&
                                  !configurations.columnConfig[key].hide ? (
                                    <TableCell
                                      className={classes.tableCell}
                                      key={key}
                                      style={
                                        configurations.columnConfig[key].style
                                      }
                                    >
                                      {configurations.columnConfig[key].link
                                        ? isLinkTypeData(
                                            row,
                                            configurations,
                                            key
                                          )
                                        : Array.isArray(row[key])
                                        ? isChildItem(row[key])
                                        : formatDataBasedOnType(
                                            row[key],
                                            configurations.columnConfig[key],
                                            configurations.columnConfig[key]
                                              .variance,
                                            row,
                                            key
                                          )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )
                                )
                            : ""}

                          {action ? (
                            <TableCell
                              className={classes.tableCell}
                              align="right"
                              style={{ cursor: "pointer" }}
                            >
                              {isEditRightAlign && action.edit ? (
                                <EditIcon
                                  key={row.id}
                                  id={row.id}
                                  onClick={props.handleEditButtonClick}
                                />
                              ) : (
                                ""
                              )}
                              {action.delete ? (                               
                                <DeleteIcon
                                  key={index}
                                  id={index}
                                  dataId={row.id}
                                  onClick={props.handleDeleteButtonClick}
                                />
                              ) : (
                                ""
                              )}
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow
                    style={{
                      height: dense ? 33 : 53,
                    }}
                  >
                    <TableCell colSpan={6} className={classes.tableRow}>
                      {configurations.noDataText}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {configurations && configurations.paginate ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};
A_EnhancedTable.defaultProps = {
  configurations: {
    title: "Heading",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: true,
    striped: true,
    columnConfig: {
      uname: {
        id: "uname",
        label: "User Name",
        sorting: false,
        hide: false,
      },
      ContactNo: {
        id: "ContactNo",
        label: "Contact No",
        sorting: true,
        hide: false,
      },
      email: {
        id: "email",
        label: "Email",
        sorting: true,
        hide: false,
      },
    },
  },

  data: [
    // {
    //     uname: "user 1",
    //     ContactNo: "9927927429",
    //     email: "vser1@gmail.com",
    // },
    // {
    //     uname: "user 2",
    //     ContactNo: "3342792742",
    //     email: "user2@gmail.com",
    // },
  ],
  isEditRightAlign: false
};
A_EnhancedTable.propTypes = {
  handleEditButtonClick: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  minWidth: PropTypes.string,
  handleAddButtonClick: PropTypes.object,
  configurations: PropTypes.object,
  data: PropTypes.array,
  isDraged: PropTypes.func,
  isEditRightAlign: PropTypes.bool
};
export default A_EnhancedTable;
