/* eslint-disable */
import { entityRecordActionType } from "./actionTypes";

export const getEntityRecord = (payload) => {
  return {
    type: entityRecordActionType.GET_ENTITY_RECORD,
    payload,
  };
};
export const getEntityRecordSucceeded = (payload) => {
  return {
    type: entityRecordActionType.GET_ENTITY_RECORD_SUCCEEDED,
    payload,
  };
};

export const getEntityRecordFailed = (payload) => {
  return {
    type: entityRecordActionType.GET_ENTITY_RECORD_FAILED,
    payload,
  };
};
