import * as React from "react";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useEffect } from "react";
const dropdownFilter = (props) => {
  const {
    setBorrowerToggle,
    selectedDropData,
    setSelectedDropData,
    dropdowndata,
    filterDropdownLabel,
  } = props;
  const handleChange = (event) => {
    setSelectedDropData(event.target.value);
  };
  //Fetching Dropdown Data
  useEffect(() => {
    if (selectedDropData) {
      setBorrowerToggle(true);
    } else setBorrowerToggle(false);
  }, [selectedDropData]);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl style={{ width: 200 }}>
        <InputLabel id="demo-simple-select-label">
          {filterDropdownLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedDropData}
          label={filterDropdownLabel}
          onChange={handleChange}
        >
          {dropdowndata &&
            dropdowndata.length > 0 &&
            dropdowndata.map((item, index) => {
              return (
                <MenuItem key={index} value={item.Value}>
                  {item.Value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default dropdownFilter;
