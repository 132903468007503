/* eslint-disable */
import React, { useEffect, useState } from "react";
import { PDFReader } from "reactjs-pdf-reader";
import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { httpPost } from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core/styles";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import { message } from "../../../../../Utils/config/messages";
const useStyles = makeStyles(() => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centerAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function M_PDFReader(props) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [pdfFileData, setPdfFileData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [havingPDF, setHavingPDF] = React.useState(false);

  const pageChange = (changeBy) => {
    let newPage = currentPage + changeBy;
    if (newPage === 0 || newPage > totalPage) {
      return;
    }
    setCurrentPage(currentPage + changeBy);
  };

  useEffect(() => {
    getPDF();
  }, [props.pdf]);

  const getPDF = async () => {
    if (!props.pdf) {
      setIsLoading(false);
      return;
    }
    if (props.directlink) {
      setPdfFileData(props.pdf);
      setIsLoading(false);
      setHavingPDF(true);
      return;
    }
    setIsLoading(true);
    let filepath = props.pdf;
    const url = `${process.env.DOCUMENT_HELPER}`;
    //important
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    // TODO - This header is common for all download/upload operation, we can switch to common function with some param
    let header = {
      filename: filepath,
      type: props.container,
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
    };
    const formData = {};
    let response = await httpPost(url, formData, {
      responseType: "blob",
      headers: header,
    });
    if (response.size) {
      let blobData = window.URL.createObjectURL(new Blob([response]));
      if (pdfFileData) {
        setPdfFileData("");
      }
      setPdfFileData(blobData);
      setIsLoading(false);
      setHavingPDF(true);
    } else {
      setIsLoading(false);
      setHavingPDF(false);
    }
  };

  return (
    <div>
      <>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </>
        ) : havingPDF ? (
          <>
            <>
              <Box
                style={{ backgroundColor: "whitesmoke" }}
                width={"100%"}
                overflow="auto"
                maxHeight={"60vh"}
              >
                {pdfFileData ? (
                  <PDFReader
                    scale={"1"}
                    url={pdfFileData}
                    page={currentPage}
                    onDocumentComplete={(total) => {
                      setTotalPage(total);
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
              <br />
              <A_Divider></A_Divider>
              <div className={classes.centerAligned}>
                {currentPage > 1 ? (
                  <IconButton changeBy={-1} onClick={() => pageChange(-1)}>
                    <ArrowBack />
                  </IconButton>
                ) : (
                  ""
                )}
                Page: {currentPage} {"  "}
                {totalPage > currentPage ? (
                  <IconButton
                    label="Next"
                    changeBy={1}
                    onClick={() => pageChange(1)}
                  >
                    <ArrowForward />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </>
          </>
        ) : props.showDefaultMessage ? (
          message.NO_DATA_FOUND
        ) : (
          ""
        )}
      </>
    </div>
  );
}
