/* eslint-disable */
import * as React from "react";
import * as checklistActions from "../../../../../store/actions/checklistActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as httpCall from "../../../../../Utils/apis/apis";
import M_ChecklistForm from "./M_ChecklistForm";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { checkListMasterData } from "../../../../../Utils/config/config";
import PropTypes from "prop-types";
import { message } from "../../../../../Utils/config/messages";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: 440,
    },
  },
  weareSorry: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  box: {
    width: "1000px",
    maxHeight: "10px",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "20px",
    marginLeft: "50px",
    marginRight: "500px",
  },
  tabPanel: {
    width: "1000px",
    height: "600px",
  },
  tab: {
    width: "1000px",
  },
  ButtonPosition: {
    marginTop: "7rem",
  },
  bar: {
    borderBottom: 1,
    borderColor: "divider",
  },
  form: {
    maxWidth: "md",
  },
  tabBar: {
    paddingTop: "1.4rem",
    paddingBottom: "1.4rem",
    backgroundColor: "#F0F0F0",
  },
  table: {
    minWidth: 700,
    color: "white",
  },
  tableHead: {
    backgroundColor: "black",
    textColor: "white",
  },
  style: {
    color: "white",
  },
  pointer: {
    cursor: "pointer",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CustomizedTables(props) {
  const classes = useStyles();
  const checkListHandler = (data) => {
    props.callBack(data);
  };

  let parsePercent = props.percent ? JSON.parse(props.percent) : [];
  const data = props.data;
  return (
    <>
      {data === null || (data && data.length === 0) ? (
        <div className={classes.weareSorry}>
          {" "}
          <p>{message.NO_CONTENT}</p>
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableCell>
                    <Typography className={classes.style}>Section</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className={classes.style}>
                      % Complete
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className={classes.style}>Skipped</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data && props.data.length > 0
                  ? props.data.map((row) => (
                      <StyledTableRow key={row.checklistSection}>
                        <StyledTableCell
                          onClick={() => checkListHandler(row.id)}
                          className={classes.pointer}
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            "background-color": "transparent",
                          }}
                        >
                          {row.checklistSection}
                        </StyledTableCell>

                        {parsePercent &&
                        parsePercent.filter((item) => {
                          return item.masterId === row.id;
                        }).length > 0 ? (
                          parsePercent
                            .filter((item) => {
                              return item.masterId === row.id;
                            })
                            .map((item) => {
                              return (
                                <>
                                  <StyledTableCell>
                                    {item.percent}%
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {item.skipped}
                                  </StyledTableCell>
                                </>
                              );
                            })
                        ) : (
                          <>
                            <StyledTableCell>0%</StyledTableCell>
                            <StyledTableCell>{0}</StyledTableCell>
                          </>
                        )}
                      </StyledTableRow>
                    ))
                  : "No content available"}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChecklistMaster = (props) => {
  const classes = useStyles();
  let { pageUrl } = useParams();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [traverse, setTraverse] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(true);
  const [checklistData, setChecklistData] = React.useState(
    props.checklistEditor &&
      props.checklistEditor.data &&
      props.checklistEditor.data != "Empty"
      ? props.checklistEditor.data
      : []
  );
  let workflowConfig = props.content.workflowConfig;
  const handleShowHide = (item) => {
    let hide = false;
    for (const key in item.show) {
      if (item.show.hasOwnProperty(key)) {
        const lowerCase = item.show[key].map((element) => {
          return element.trim().toLowerCase();
        });
        if (
          props.dealData[key] &&
          lowerCase.length > 0 &&
          !lowerCase.includes(props.dealData[key].trim().toLowerCase())
        ) {
          hide = true;
          break;
        }
      }
    }
    return hide;
  };
  const handleWorkflow = (workflow) => {
    setTraverse(true);
    let arrayWorkflow = [];
    workflow.map((item) => {
      if (item.show) {
        if (!handleShowHide(item)) {
          arrayWorkflow.push(item);
        }
      } else {
        arrayWorkflow.push(item);
      }
    });
    if (data.length === 0 && value === 0 && arrayWorkflow.length > 0) {
      let filterData =
        props.checklistEditor && props.checklistEditor.data
          ? props.checklistEditor.data.filter((item) => {
              return (
                item.workflowState === arrayWorkflow[0].value &&
                item.type !== checkListMasterData.CREDIT
              );
            })
          : null;

      setData(filterData);
      setLoading(false);
    }
    return arrayWorkflow;
  };
  const [workFlow, setWorkFlow] = React.useState(
    props.checklistEditor &&
      props.checklistEditor.workFlow &&
      props.checklistEditor.workFlow != "Empty" &&
      !traverse &&
      !firstTime
      ? handleWorkflow(props.checklistEditor.workFlow)
      : []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setData([]);
    setLoading(true);
    if (checklistData) {
      let filterData = checklistData.filter((item) => {
        return (
          item.workflowState === workFlow[newValue].value &&
          item.type !== checkListMasterData.CREDIT
        );
      });
      setData(filterData);
      setLoading(false);
    }
  };
  const [snackBar, setSnackBar] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [configuration, setConfiguration] = React.useState({});
  const checklistId = async (id) => {
    const response = await httpCall.httpGet(
      `${process.env.API_URL}/ChecklistData/getChecklistItems?id=${id}`
    );
    if (response === "Empty") {
      setSnackBar(true);
    }
    if (response !== "Empty") {
      let a = checklistData.filter((item) => {
        return item.id === id;
      })[0];

      let configuration = {
        name: a.checklistSection,
        id: id,
        response: response,
      };
      setConfiguration(configuration);
      setIsOpen(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await props.getChecklist({ pageUrl, workflowConfig });
      setFirstTime(false);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (firstTime) {
      setChecklistData([]);
      setWorkFlow([]);
      setData([]);
    } else {
      setChecklistData(props.checklistEditor.data);
      setWorkFlow(handleWorkflow(props.checklistEditor.workFlow));
      setLoading(false);
    }
  }, [props.checklistEditor]);
  const [field, setField] = React.useState("");
  const handleSnackbarClose = () => {
    setSnackBar(false);
  };

  React.useEffect(() => {
    setField(workFlow[value]);
    setChecklistData(props.checklistEditor.data);

    if (value === 0) {
      setData([]);
      if (checklistData !== undefined && workFlow.length > 0) {
        let a = checklistData.filter((item) => {
          return (
            item.workflowState === workFlow[value].value &&
            item.type !== checkListMasterData.CREDIT
          );
        });
        setData(a);
      }
    }
  }, [value]);

  return (
    <>
      <Box className={classes.Box}>
        <Box className={classes.bar}>
          <Tabs
            className={classes.tabBar}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {workFlow.length > 0
              ? workFlow.map((item, index) => (
                  <Tab label={item.label} {...a11yProps(index)} />
                ))
              : ""}
          </Tabs>
        </Box>
        {!loading &&
          workFlow.map((item, index) => (
            <TabPanel value={value} index={index}>
              <CustomizedTables
                data={data}
                callBack={checklistId}
                percent={props.percentComplete}
              />
            </TabPanel>
          ))}
      </Box>
      <A_SimpleDialog
        title={<h4>Checklist</h4>}
        open={isOpen}
        onClose={handleClose}
        dialogContent={
          <M_ChecklistForm
            data={configuration}
            itemResponse={props.data}
            percentComplete={props.percentComplete}
            close={handleClose}
          />
        }
        maxWidth="md"
      />
      <A_Snackbar
        open={snackBar}
        message="No checklist item found."
        autoHideDuration={3000}
        horizontal="center"
        type="error"
        handleClose={handleSnackbarClose}
      />
    </>
  );
};
function mapStateToProps(state) {
  return {
    checklistEditor: state.checklist,
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      ...checklistActions,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistMaster);
