import React, { useEffect, useRef } from "react";
import propTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

function RawHTMLRenderer({ html, lineLimit }) {
  const containerRef = useRef(null);
  const useStyles = makeStyles(() => ({
    lineLimit: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": lineLimit ? lineLimit : 2,
      "-webkit-box-orient": "vertical",
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = html ? html : "";
    }
  }, [html]);

  return (
    <div
      className={`${lineLimit ? classes.lineLimit : ""}`}
      ref={containerRef}
    />
  );
}

export default RawHTMLRenderer;
RawHTMLRenderer.propTypes = {
  html: propTypes.any,
  lineLimit: propTypes.any,
};