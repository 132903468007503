/* eslint-disable */
import { take, put, select } from "redux-saga/effects";
import { httpPost } from "../../Utils/apis/apis";
import { pptDownloadActionTypes } from "../actions/actionTypes";
import * as pptActions from "../actions/pptActions";
import * as httpCall from "../../Utils/apis/apis";
export function* getPPTSaga() {
  while (true) {
    const action = yield take(pptDownloadActionTypes.GET_PPT_DOWNLOAD);
    const { payload, pptId, pptName, id, dealid } = action.payload;
    const state = yield select();

    try {
      const res = yield httpPost(
        `${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`,
        payload,
        {
          responseType: "blob",
        }
      );
      const url1 = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", pptName + ".pptx");
      link.click();
      if (id && dealid) {
        let checklistPayload = {
          checklistCompleted: true,
          action: "update",
          id: id,
          dealId: dealid,
        };
        httpCall
          .httpPut("/DealChecklist/Update", checklistPayload)
          .then((response) => {
            location.reload();
          });
      }

      yield put(
        pptActions.getPPTDownloadSucceeded({
          ...res,
          getPPTDownloadSucceeded: true,
          isLoading: false,
        })
      );
    } catch (e) {
      yield put(
        pptActions.getPPTDownloadFailed({
          msg: "Some error occurred",
          data: "PPT",
          getPPTDownloadSucceeded: false,
          isLoading: false,
        })
      );
    }
  }
}
