/* eslint-disable */

import React from "react";
import { Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";

function ProtectedRoute({ component: Component, ...restProps }) {
  const isAuthenticated = restProps.user ? true : false;
  const history = useHistory();
  const location = {
    pathname: "/",
    state: { referrer: { pathname: history.location.pathname } },
  };
  return (
    <Route
      {...restProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : history.push(location)
      }
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.oidc.user,
  };
}
export default connect(mapStateToProps)(ProtectedRoute);
