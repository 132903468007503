import { manageFilterValueActionType } from "../actions/actionTypes";

let initialState = {
  filters: {},
};
const manageFilterValueReducer = (state = initialState, action) => {
  let templateId, filterName, value;

  switch (action.type) {
    case manageFilterValueActionType.ADD_NEW_FILTER_VALUE:
      templateId = action.templateFilterID;
      filterName = action.filterName;
      value = action.value;
      return {
        ...state,
        filters: {
          ...state.filters,
          [`${templateId}_${filterName}`]: value,
        },
      };
    case manageFilterValueActionType.UPDATE_FILTER_VALUE:
      templateId = action.templateFilterID;
      filterName = action.filterName;
      value = action.value;
      return {
        ...state,
        filters: {
          ...state.filters,
          [`${templateId}_${filterName}`]: value,
        },
      };
    case manageFilterValueActionType.RESET_FILTER_VALUE:
      return {
        ...state,
        filters: {},
      };
    default:
      return state;
  }
};
export default manageFilterValueReducer;
