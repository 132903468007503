/* eslint-disable */
import {manageExcelTemplateDownloadActionType} from "./actionTypes";
export const downloadExcel=(payload)=> {    
    return {
        type: manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL, 
      payload     
    };
  }
  export const downloadExcelSuccessed=(payload)=> {    
    return {
        type: manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL_SUCCESSED, 
      payload     
    };
  }
  export const downloadExcelFailed=(payload)=> {    
    return {
        type: manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL_FAILED, 
      payload     
    };
  }