import handlePositiveNumber from "./handlePositiveNumber";
const columnDataHandle = (res, config, str = "") => {
  if (
    res == null ||
    res == "null" ||
    res === "undefined" ||
    res === undefined
  ) {
    return "";
  } else if (res && !isNaN(res) && parseInt(res) && parseInt(res) > 0) {
    return config.symbol ? config.symbol + res + str : res + str;
  } else if (res && !isNaN(res) && parseInt(res) && parseInt(res) < 0) {
    return config.symbol
      ? config.symbol + "(" + handlePositiveNumber(res) + str + ")"
      : "(" + handlePositiveNumber(res) + str + ")";
  } else if (res && !isNaN(res) && parseFloat(res) && parseFloat(res) < 0) {
    return config.symbol
      ? config.symbol + "(" + handlePositiveNumber(res) + str + ")"
      : "(" + handlePositiveNumber(res) + str + ")";
  } else if (res && !isNaN(res) && parseFloat(res) && parseFloat(res) > 0) {
    return config.symbol ? config.symbol + res + str : res + str;
  } else if (res == "0" || res == "-0") {
    return "0";
  } else {
    return res;
  }
};

export default columnDataHandle;
