/* eslint-disable */
import { entityRecordActionType } from "../actions/actionTypes";

let initialState = {};
const entityRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case entityRecordActionType.GET_ENTITY_RECORD:
      let currState = { ...state };
      currState.isLoading = true;
      return currState;
    case entityRecordActionType.GET_ENTITY_RECORD_SUCCEEDED:
      return action.payload;
    case entityRecordActionType.GET_ENTITY_RECORD_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default entityRecordReducer;
