export const optionType = [
  { value: "$", label: "Currency" },
  { value: "#", label: "General" },
  { value: "header", label: "Header" },
  { value: "x", label: "Multiple" },
  { value: "number", label: "Number" },
  { value: "percentage", label: "Percentage" },
];
export const signOptions = [
  { label: "Negative", value: -1 },
  { label: "Positive", value: 0 },
  { label: "Unknown", value: 2 },
];
export const getDeleteData = (e, id, deletedLevel, contents) => {
  let check = 0;
  let deletedArray = [];
  let finalArray = [];
  let actionDeleteArray = [];
  const deletedRowIndex = contents.findIndex((row) => row.sortOrder === id);
  contents.map((item) => {
    if (item.sortOrder == id) {
      deletedArray.push({ ...item, action: "DELETE" });
    } else {
      deletedArray.push({ ...item });
      actionDeleteArray.push({ ...item });
    }
  });

  for (let i = deletedArray.length - 1; i >= 0; i--) {
    if (deletedRowIndex < i) {
      finalArray.push({ ...deletedArray[i] });
    } else if (
      deletedArray[i].level > deletedLevel &&
      deletedArray[i].action !== "DELETE" &&
      check === 0
    ) {
      finalArray.push({
        ...deletedArray[i],
        action: "UPDATE",
        level: deletedArray[i].level - 1

      });
    } else if (
      deletedArray[i].level == deletedLevel &&
      deletedArray[i].action !== "DELETE"
    ) {
      finalArray.push({ ...deletedArray[i] });
      check = 1;
    } else {
      // if (deletedArray[i].action !== "DELETE") {
      finalArray.push({ ...deletedArray[i] });
    }
    // }
  }

  return finalArray;
};

export const getShowEditedRowData = (id, shouldHide, contents) => {
  const finalArray = [];
  contents.forEach((item) => {
    if (item.sortOrder == id && !shouldHide) {
      finalArray.push({
        ...item,
        edited: true,
        action: item.action == "INSERT" ? "INSERT" : "UPDATE",
      });
    } else {
      finalArray.push({
        ...item,
        edited: false,
      });
    }
  });
  return finalArray;
};

export const isDisabledCheckCounterValidation = (
  currentLevel,
  id,
  type,
  index,
  parentName,
  itemId,
  contents
) => {
  return type == "positive"
    ? checkForLeftArrayDisabled(
        currentLevel,
        index,
        itemId,
        contents,
        parentName
      )
    : checkForRightArrayDisabled(currentLevel, parentName);
};

const checkForLeftArrayDisabled = (level, index, itemId, contents) => {
  let currentLevel = level;

  let lastIndex = contents && contents.length > 0 ? contents.length - 1 : 0;
  let array = contents.filter((item) => item.action !== "DELETE");
  let nextLevel =
    array && array.length > 1 && array[index + 1] && array[index + 1].level
      ? array[index + 1].level
      : 0;
 
  for (let i = 0; i < array.length; i++) {
    if (itemId) {
      if (
        array.length - 1 !== i &&
        array[i].parentName != null &&
        itemId == array[i].id
      ) {
        if (array[i].parentName == array[i + 1].parentName) {
          return true;
        }
      } else if (array.length - 1 == i &&
        array[i].parentName == null &&
        itemId == array[i].id) {
return false
      }
    }
  }
  if (
    itemId == contents[0].id &&
    currentLevel > 0 &&
    currentLevel < nextLevel
  ) {
    return true;
  } else {
    if (index == lastIndex) {
      return false;
    } else if (nextLevel < currentLevel) {
      return false;
    } else {
      return true;
    }
  }
};

const checkForRightArrayDisabled = (level, parentName) => {
  let currentLevel = level;
  if (currentLevel == 0) {
    return false;
  } else {
    if (parentName !== null) {
      return true;
    } else {
      return true;
    }
  }
};

export const setIsCalculated = (item, val) => {
  if (!val || val != "save") {
    let parents = [];
    for (let index = 0; index < item.length; index++) {
      if (item[index].action && item[index].action == "DELETE") {
        continue;
      }
      if (item[index].parentName) {
        parents.push(item[index].parentName);
      }
      let name = item[index].name;
      if (name == item[index].parentName) {
        continue;
      }
      if (
        parents &&
        parents.length > 0 &&
        parents.find((element) => element == name)
      ) {
        item[index].action =
          item[index].action == "NOCHANGE" ? "UPDATE" : item[index].action;
        item[index].isCalculated = true;
        if (parents.indexOf(name) > -1) {
          //keeping for now
          //parents.splice(parents.indexOf(name), 1);
          var newarr = parents.filter(function (a) {
            return a !== name;
          });
          parents = newarr;
        }
      } else {
        item[index].action =
          item[index].action == "NOCHANGE" ? "UPDATE" : item[index].action;
        item[index].isCalculated = false;
      }
    }

    return item;
  } else {
    return item;
  }
};
