import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabContainer } from "react-bootstrap";
import {
  getAllMaturityAssessment,
  getAllPillar,
  getAllSubPillar,
  getInvestmentByCrmId,
  getQuestionsJsonFromSurvey,
  getSubPillar,
  getSurveyBySurveyId,
  getAllMaturityAssessmentForSurveyAndInvestment,
} from "../ESG_API";
import { makeStyles } from "@material-ui/styles";
import { SurveyFilter, SurveyFilterYear } from "../components";
import SubmissionForm from "./SubmissionForm";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router";
import { object } from "prop-types";
import { Alert } from "@material-ui/lab";
import { SurveyStatusMap } from "../ESG_Config";

const useStyles = makeStyles({
  body: {
    height: "100px",
  },
  pillar: {
    color: "white",
    background: "rgba(38,52,64,1)",
    textDecoration: "none",
    border: "0px",
  },
  removeBoder: {
    textDecoration: "none",
    border: "0px",
  },
  removePadding: {
    "& input": {
      padding: "10px",
    },
    padding: "2px",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
  loader: {
    position: "absolute",
    left: "50%",
  },
  mrgBottom: {
    marginBottom: "5px",
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ESGMaturityMatrix() {
  const classes = useStyles();
  const [allPillars, setAllPillars] = useState([]);
  const [allSubPillars, setAllSubPillars] = useState([]);
  const [survrydata, setSurveyData] = useState([]);
  const [data, setData] = useState([]);
  const [subdata, setSubData] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [child, setChild] = useState(false);
  const [loading, setLoading] = useState(true);
  const [maturityAssessment, setMaturityAssessment] = useState([]);
  const [allMaturity, setAllMaturity] = useState([]);
  const [surveyStatus, setSurveyStatus] = useState(false);
  let { crmId } = useParams();

  const setAllMaturityAssessment = (data) => {
    let val = new Set();

    data.map((e) => {
      val.add(e.maturityAssessmentId);
    });

    const array = [...val];
    array.sort((x, y) => {
      return x - y;
    });
    setMaturityAssessment(array);
  };

  const getSubPillarforPillar = (data) => {
    const showData = data?.reduce((acc, item) => {
      const { categoryId } = item;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }
      acc[categoryId].push(item);
      return acc;
    }, {});
    setSubData(showData);
  };

  const mergeByPillars = (data) => {
    const showData = data?.reduce((acc, item) => {
      const { questionCategoryId } = item;
      if (!acc[questionCategoryId]) {
        acc[questionCategoryId] = [];
      }
      acc[questionCategoryId].push(item);
      return acc;
    }, {});
    setData(showData);
  };

  useEffect(() => {
    if (selectedSurveyId != "") {
      const statusRes = async () => {
        setLoading(true);
        const resStatus = await getSurveyBySurveyId(selectedSurveyId);
        let statusResult = (resStatus?.status === SurveyStatusMap.ScoreFreeze);
        setSurveyStatus(statusResult);
        if (!statusResult) {
          setLoading(false);
        }
      };
      statusRes();
    }
  }, [selectedSurveyId]);
  useEffect(() => {
    if (selectedSurveyId != "" && surveyStatus) {
      setLoading(true);
      const apiCalls = async () => {
        const pillars = await getAllPillar();
        const subpillars = await getSubPillar();
        const surveyData = await getQuestionsJsonFromSurvey(selectedSurveyId);
        const maturity = await getAllMaturityAssessment();
        const crmData = await getInvestmentByCrmId(crmId);
        const investmentId = crmData.investmentId;

        const responseData =
          await getAllMaturityAssessmentForSurveyAndInvestment(
            selectedSurveyId,
            investmentId
          );
        setAllPillars(pillars);
        setAllSubPillars(subpillars);
        setSurveyData(surveyData);
        mergeByPillars(surveyData);
        getSubPillarforPillar(responseData);
        setLoading(false);
        setAllMaturityAssessment(responseData);
        setAllMaturity(maturity);
      };
      apiCalls();
    }
  }, [selectedSurveyId, filtersurveyyear, surveyStatus]);

  return (
    <>
      <div>
        <SurveyFilter
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
        />
      </div>
      <div>
        <SurveyFilterYear
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          setSurveyFilterYear={setSurveyFilterYear}
        />
      </div>
      {!loading ? (
        surveyStatus && maturityAssessment.length != 0 ? (
          <TabContainer component={Paper} className={classes.body}>
            <Table aria-label="customized table">
              <TableHead style={{ background: "grey" }}>
                <TableRow>
                  <TableCell>Pillar</TableCell>
                  {maturityAssessment.map((e) => {
                    let maturity = allMaturity.find(
                      (item) => e == item.maturityassessmentid
                    );
                    return (
                      <TableCell id={maturity.maturityassessmentid}>
                        {maturity.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(subdata).map((element) => {
                  let val = allPillars.find(
                    (item) => element == item.questioncategoryid
                  );
                  return (
                    <TableRow>
                      <TableCell
                        style={{
                          color: "white",
                          background: "rgba(38,52,64,1)",
                          textDecoration: "none",
                          border: "0px",
                        }}
                      >
                        {val.name}
                      </TableCell>
                      {maturityAssessment.map((items) => {
                        const responseScoresCheck = (ele) => {
                          const newObject = {};
                          ele.forEach((item) => {
                            const { maturityAssessmentId } = item;

                            if (!newObject[maturityAssessmentId]) {
                              newObject[maturityAssessmentId] = [];
                            }
                            newObject[maturityAssessmentId].push(item);
                          });
                          const objectvalues = Object.values(newObject);
                          for (let i = 0; i < objectvalues.length; i++) {
                            const objValue = objectvalues[i];
                            const dashboardValue = objValue?.filter(
                              (data) => data.responseScore > 0
                            );
                            if (dashboardValue.length !== 0) {
                              objValue.forEach((data) => {
                                if (data.responseScore > 0) {
                                  data["color"] = "green";
                                } else {
                                  data["color"] = "grey";
                                }
                              });
                            } else {
                              objValue.forEach((data) => {
                                data["color"] = "red";
                              });
                            }
                          }
                        };

                        return (
                          <TableCell>
                            {subdata[element].map((ele) => {
                              const color = responseScoresCheck(
                                subdata[element]
                              );
                              if (ele.maturityAssessmentId == items) {
                                return (
                                  <div
                                    className={classes.mrgBottom}
                                    style={
                                      ele?.color
                                        ? { color: ele.color }
                                        : { color: "grey" }
                                    }
                                  >
                                    {ele?.dashboardStatement}
                                  </div>
                                );
                              }
                            })}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TabContainer>
        ) : (
          <Alert severity="info" style={{
            width: "auto",
            marginBottom: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}>
            There is no response to show, either the survey is not yet published
            or response is not submitted yet
          </Alert>
        )
      ) : (
        <div style={{ height: "30px" }}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
    </>
  );
}

export default ESGMaturityMatrix;
