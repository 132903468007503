import React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import O_GSTForms from "./O_GSTForms";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import PropTypes from "prop-types";
import * as toastActions from "../../../../../store/actions/toastActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toastSeverity,
  toastType,
} from "../../../../../Utils/config/toastConfig";
import A_IconButton from "../../../Atoms/Inputs/Buttons/A_IconButton";
import AddIcon from "@material-ui/icons/Add";

function O_AddSenarioButton(props) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [validCheck, setValidCheck] = React.useState(false);
  let { crmContactUserEmail } = getCRMContactInfo();
  const handleClose = () => {
    setValidCheck(false);
    setOpen(false);
  };

  const handleReload = () => {
    props.setReload(true);
  };
  const submitQuery = async () => {
    setDisableSubmit(true);
    let query = "res";
    let { ScenarioName, Region, Model, sensitivity = {} } = formData;
    if (
      [Region, Model, ScenarioName].some(
        (val) =>
          (Array.isArray(val) && val.join(",") === "") ||
          !val ||
          (typeof val === "string" && val.trim() === "") ||
          (typeof val === "string" && val.toLowerCase() === "base")
      ) ||
      (Object.entries(sensitivity) && Object.entries(sensitivity).length === 0)
    ) {
      setValidCheck(true);
      setDisableSubmit(false);
      return;
    } else {
      setValidCheck(false);
    }
    try {
      let scenarioDetails = Object.entries(sensitivity).map(
        ([key, value]) => `${key}:${value.value ? value.value : 0}`
      );
      let data = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "[JLForm].[Usp_GST_InsertGlobalSensitivityScenarios]",
            parameters: {
              ScenarioId: null,
              OperationType: "Add",
              ScenarioName,
              RegionCode: Region,
              AssetIds: Model.filter((item) => item).join(","),
              SensitivitiesAndValues: scenarioDetails.join(","),
              User: crmContactUserEmail,
            },
          },
        ],
      });
      if (data && data.res && data.res[0] && data.res[0].Message) {
        props.setToast({
          type: toastType.DELETE_CRM,
          severity: toastSeverity.ERROR,
          message: data.res[0].Message
            ? data.res[0].Message
            : "This scenario name is already exist.",
          status: true,
          duration: 5000,
        });
      } else {
        props.setToast({
          type: toastType.DELETE_CRM,
          severity: toastSeverity.SUCCESS,
          message: "Scenario Created",
          status: true,
        });
      }
    } catch (e) {
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.ERROR,
        message: "Something went wrong",
        status: true,
      });
    }
    setDisableSubmit(false);
    handleReload();
    handleClose();
  };

  return (
    <div>
      <>
        <div>
          <A_IconButton
            onClick={() => {
              setOpen(true);
            }}
            icon={<AddIcon />}
          />
        </div>
      </>

      <A_SimpleDialog
        open={open}
        title={<>Global Sensitivity Input</>}
        height="110"
        maxWidth="sm"
        dialogContent={
          <O_GSTForms
            onFromDataChange={setFormData}
            validation={validCheck}
            setDisableSubmit={setDisableSubmit}
          />
        }
        dialogActions={
          <>
            <A_Button
              disabled={disableSubmit}
              label="Finish"
              color={"primary"}
              onClick={submitQuery}
            />
          </>
        }
        onClose={handleClose}
      />
    </div>
  );
}

O_AddSenarioButton.propTypes = {
  content: PropTypes.any,
  setToast: PropTypes.any,
  setReload:PropTypes.any,
};
function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_AddSenarioButton);
