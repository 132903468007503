import { manageFilterValueActionType } from "./actionTypes";

//Add New Filter Value in Filters State
export const manageFilterValue = (templateFilterID, filterName, value) => {
  return {
    type: manageFilterValueActionType.ADD_NEW_FILTER_VALUE,
    templateFilterID,
    filterName,
    value,
  };
};
//Update Existing Filter Value of That Template
export const updateFilterValue = (templateFilterID, filterName, value) => {
  return {
    type: manageFilterValueActionType.UPDATE_FILTER_VALUE,
    templateFilterID,
    filterName,
    value,
  };
};
// Action to reset all filters
export const resetFiltersValue = () => {
  return {
    type: manageFilterValueActionType.RESET_FILTER_VALUE,
  };
};