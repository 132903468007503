/* eslint-disable */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, Tabs, Tab, AppBar } from "@material-ui/core";
import M_NewsDetails from "./M_NewsDetails";
import TablePagination from "@material-ui/core/TablePagination";
import { getChartData } from "./Helper/News.helper";
import { message } from "../../../../../../../Utils/config/messages";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  tabs: { maxHeight: "60vh", overflow: "auto" },
  appBar: { borderTop: "0.4rem" },
});
const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const M_NewsCard = ({ content }) => {
  const [value, setValue] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [dataPerPage, setDataPerPage] = React.useState(3);
  const [data, setData] = React.useState([]);
  const [tabData, setTabData] = React.useState(
    content.tabData ? content.tabData : []
  );
  const classes = useStyles();
  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(parseInt(event.target.value, 10));
    setPageIndex(0);
  };
  const fetchChartData = async () => {
    let res = await getChartData(
      content.newsTemplate ? content.newsTemplate : {},
      content.chartData ? content.chartData : {}
    );
    setData(res);
  };
  React.useEffect(() => {
    fetchChartData();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div>
      <>
        <AppBar position="static" color="default" classes={classes.AppBar}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={handleChange}
            value={value}
            aria-label="New Tabs"
          >
            {tabData.map((item, index) => (
              <CustomTab
                key={index}
                label={item.label ? item.label : ""}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabData.map((item, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            style={{ margin: "0" }}
          >
            <div className={classes.tabs}>
              {Array.isArray(data) && data.length > 0
                ? data.map((item, index) => {
                    if (
                      index >= pageIndex * dataPerPage &&
                      index < pageIndex * dataPerPage + dataPerPage
                    ) {
                      return (
                        <>
                          <M_NewsDetails {...item} content={content} />
                          <hr />
                        </>
                      );
                    } else {
                      return "";
                    }
                  })
                : message.NO_DATA_FOUND}
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={[3, 5, 10, 25]}
              count={data.length}
              rowsPerPage={dataPerPage}
              page={pageIndex}
              onChangePage={(event, value) => {
                setPageIndex(value);
              }}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TabPanel>
        ))}
      </>
    </div>
  );
};

export default M_NewsCard;
