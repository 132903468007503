import axios from "axios";
import { store } from "../../../../../store";
import { getPreviousUpload } from "../../../../../store/actions/previousUploadActions";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useSelector } from "react-redux";

// TODO - Refactor handleFileUpload , use saga for async behaviour
export const handleFileUpload = async (
  e = undefined,
  crmId,
  user,
  dropedFiles = null,
  content = null,
  fileName = null,
  setUploading = null,
  setChangesSaved = null,
  fileUploadOnView = false,
  pageUrl = undefined,
  nonCRM = true,
  directory,
  container,
  externalCall = null
) => {
  return new Promise((res, err) => {
    let resultFiles = [];
    let resultFileName = [];
    let filesList = null;

    if (
      dropedFiles &&
      dropedFiles[0] &&
      dropedFiles[0].files &&
      dropedFiles[0].files[0]
    ) {
      filesList = dropedFiles[0].files[0];
    } else if (e && e.target && e.target.files && e.target.files[0]) {
      filesList = e.target.files[0];
    }
    let name;
    if (filesList) {
      name = fileName + filesList.name;
      resultFileName.push(name);
      resultFiles.push(filesList);
    }

    if (!nonCRM) {
      if (Array.isArray(dropedFiles) && dropedFiles.length > 0) {
        let totalNumberOfFiles = 0;
        let numberOfFilesUploaded = 1;
        dropedFiles.map((dropedFile) => {
          totalNumberOfFiles = totalNumberOfFiles + dropedFile.files.length;
        });
        dropedFiles.map((dropedFile) => {
          let config = dropedFile.fileConfig;
          dropedFile.files.map(async (file) => {
            if (file["uploadedDoc"]) {
              numberOfFilesUploaded = file["deleteFile"]
                ? handleFileDelete(
                    file,
                    totalNumberOfFiles,
                    numberOfFilesUploaded
                  )
                : "";
            } else {
              if (config.allowedExtensions !== undefined) {
                const ext = name.split(".").pop();
                if (
                  config.allowedExtensions.includes(ext.toLowerCase()) ||
                  config.allowedExtensions.includes("*") ||
                  config.allowedExtensions.includes("image/*")
                ) {
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = async (e) => {
                    try {
                      let subdomain = new URL(
                        window.location.href
                      ).hostname.split(".")[0];
                      {
                        fileUploadOnView ? setUploading(true) : "";
                      }
                      {
                        let finalFileName =
                          content && content.fileNameImage
                            ? `${fileName}${content.fileNameImage}`
                            : `${fileName}${file.field ? file.field : ""}__${
                                file.name
                              }`;

                        let url = process.env.DOCUMENT_HELPER;
                        let header = {
                          filename: finalFileName,
                          type: config.containerType,
                          "file-operation": "upload",
                          "process-file": config.processFile
                            ? config.processFile
                            : "no",
                          "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
                          subdomain: subdomain,
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          "overwrite-file":
                            content && content.fileNameImage ? true : false,
                          Authorization: "Bearer " + user.access_token,
                        };
                        let formData = {
                          file: e.target.result,
                        };
                        if (config.apiDetails) {
                          let {
                            extraHeaders = {},
                            apiUrl,
                            extraPayloads = {},
                          } = config.apiDetails;
                          header = { ...header, ...extraHeaders };
                          url = apiUrl || url;
                          formData = {
                            ...formData,
                            ...extraPayloads,
                          };
                        }
                        axios
                          .post(url, formData, {
                            headers: header,
                          })
                          .then((response) => {
                            if (numberOfFilesUploaded === totalNumberOfFiles) {
                              if (externalCall && externalCall.url) {
                                if (response) {
                                  let data = response.data;
                                  let statusCode = response.status;
                                  httpPost(externalCall.url, {
                                    ...(externalCall.payload || {}),
                                    message: data,
                                    statusCode,
                                    file: finalFileName,
                                  });
                                }
                              }
                              location.reload();
                            } else {
                              numberOfFilesUploaded++;
                            }
                          })
                          .catch((error) => {
                            if (error.response) {
                              let data = error.response.data;
                              let statusCode = error.response.status;
                              httpPost(externalCall.url, {
                                ...(externalCall.payload || {}),
                                message: data,
                                statusCode,
                                file: finalFileName,
                              });
                            }
                          });

                        store.dispatch(
                          getPreviousUpload({
                            crmId: crmId,
                            pageUrl,
                            directory,
                            container,
                          })
                        );
                      }
                      {
                        fileUploadOnView ? setUploading(false) : "";
                      }

                      {
                        fileUploadOnView ? setChangesSaved(true) : "";
                      }
                      res();
                    } catch (e) {
                      {
                        fileUploadOnView ? setChangesSaved(false) : "";
                      }
                      err(e);
                    }
                  };
                } else {
                  fileUploadOnView ? setChangesSaved(false) : "";
                }
              }
            }
          });
        });
      }
    } else {
      for (
        let i = 0;
        i <
        (dropedFiles[0] && dropedFiles[0].files && dropedFiles[0].files.length);
        i++
      ) {
        let name;
        name = fileName + dropedFiles[0].files[i].name;
        if (content.allowedExtensions !== undefined) {
          const ext = name.split(".").pop();
          if (
            content.allowedExtensions.includes(ext.toLowerCase()) ||
            content.allowedExtensions.includes("*") ||
            content.allowedExtensions.includes("image/*")
          ) {
            let reader = new FileReader();
            reader.readAsDataURL(dropedFiles[0].files[i]);
            reader.onload = async (e) => {
              try {
                let subdomain = new URL(window.location.href).hostname.split(
                  "."
                )[0];
                {
                  fileUploadOnView ? setUploading(true) : "";
                }
                {
                  let url = process.env.DOCUMENT_HELPER;
                  let header = {
                    filename: name,
                    type: content.containerType,
                    "file-operation": "upload",
                    "process-file": content.processFile
                      ? content.processFile
                      : "no",
                    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
                    subdomain: subdomain,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.access_token,
                  };
                  let formData = {
                    file: e.target.result,
                  };
                  if (content.apiDetails) {
                    let {
                      extraHeaders = {},
                      apiUrl,
                      extraPayloads = {},
                    } = content.apiDetails;
                    header = { ...header, ...extraHeaders };
                    url = apiUrl || url;
                    formData = {
                      ...formData,
                      ...extraPayloads,
                    };
                  }
                  await axios
                    .post(url, formData, {
                      headers: header,
                    })
                    .then((response) => {
                      if (externalCall && externalCall.url) {
                        if (response) {
                          let data = response.data;
                          let statusCode = response.status;
                          httpPost(externalCall.url, {
                            ...(externalCall.payload || {}),
                            message: data,
                            statusCode,
                            file: name,
                          });
                        }
                      }
                    })
                    .catch((error) => {
                      if (error.response) {
                        let data = error.response.data;
                        let statusCode = error.response.status;
                        httpPost(externalCall.url, {
                          ...(externalCall.payload || {}),
                          message: data,
                          statusCode,
                          file: name,
                        });
                      }
                    });
                  store.dispatch(
                    getPreviousUpload({
                      crmId: crmId,
                      pageUrl,
                      directory,
                      container,
                    })
                  );
                }
                {
                  fileUploadOnView ? setUploading(false) : "";
                }

                {
                  fileUploadOnView ? setChangesSaved(true) : "";
                }
                res();
              } catch (e) {
                {
                  fileUploadOnView ? setChangesSaved(false) : "";
                }
                err(e);
              }
            };
          } else {
            fileUploadOnView ? setChangesSaved(false) : "";
          }
        }
      }
    }

    return;
  });
};

const handleFileDelete = async (
  file,
  totalNumberOfFiles,
  numberOfFilesUploaded
) => {
  const documentName = file.fullName;
  const url = `${process.env.DOCUMENT_HELPER}`;
  const httpConfig = commonFunctions.getHttpConfig();
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  let header = {
    filename: documentName,
    type: "generic",
    "file-operation": "delete",
    "process-file": "no",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Authorization: httpConfig.headers.Authorization,
  };
  axios
    .post(
      url,
      {
        method: "POST",
      },
      { responseType: "blob", headers: header }
    )
    .then((response) => {
      const url1 = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url1;

      if (numberOfFilesUploaded === totalNumberOfFiles) {
        location.reload();
      } else {
        numberOfFilesUploaded++;
      }
      // link.setAttribute("download", file.name);
      // document.body.appendChild(link);
      // link.click();
    });
  return numberOfFilesUploaded;
};

export const getDataFromSQLSp = async (spConfigs) => {
  const { chartSPs, spDetails } = spConfigs;
  return await httpPost(
    `/SQLQuery/getDataFromMultipleSPs?chartSPs=${
      chartSPs ? chartSPs : "false"
    }`,
    spDetails
  );
};

export const handleFileUploadFileShare = async (
  user,
  dropedFiles = null,
  fileName = null,
  setUploading = null,
  setChangesSaved = null,
  fileUploadOnView = false,
  portfolio,
  borrower,
  userEmail,
  userName,
  fieldName,
  proposalId
) => {
  return new Promise((res, err) => {
    let resultFiles = [];
    let resultFileName = [];
    let filesList = null;

    if (
      dropedFiles &&
      dropedFiles[0] &&
      dropedFiles[0].files &&
      dropedFiles[0].files[0]
    ) {
      filesList = dropedFiles[0].files[0];
    } else if (e && e.target && e.target.files && e.target.files[0]) {
      filesList = e.target.files[0];
    }
    const JSONFormatData = {
      spName: "Proposal.usp_PT_NewProposalentry",
      parameter: {},
      parametersAsJsonString: {
        id: fileName,
        fileName: "",
        portfolio: portfolio,
        borrower: borrower,
        createdDate: Date.now(),
        uploadDate: Date.now(),
        userEmail: userEmail,
        userName: userName,
        proposalId: proposalId,
      },
    };
    let name;
    if (filesList) {
      name = fileName + filesList.name;
      resultFileName.push(name);
      resultFiles.push(filesList);
    }
    let FileName = "";
    if (Array.isArray(dropedFiles) && dropedFiles.length > 0) {
      let totalNumberOfFiles = 0;
      let numberOfFilesUploaded = 1;
      dropedFiles.map((dropedFile) => {
        totalNumberOfFiles = totalNumberOfFiles + dropedFile.files.length;
      });
      dropedFiles.map((dropedFile) => {
        let config = dropedFile.fileConfig;
        dropedFile.files.map(async (file) => {
          if (file["field"] == fieldName) {
            FileName = file;
          }
          if (file["uploadedDoc"]) {
            numberOfFilesUploaded = file["deleteFile"]
              ? handleFileDelete(
                  file,
                  totalNumberOfFiles,
                  numberOfFilesUploaded
                )
              : "";
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
              try {
                let subdomain = new URL(window.location.href).hostname.split(
                  "."
                )[0];
                {
                  fileUploadOnView ? setUploading(true) : "";
                }
                {
                  let name1 = `${`ProposalTracker/Hipoges/${portfolio}/${borrower}`}/${fileName}/${
                    file.name
                  }`;

                  let url = process.env.FILESHARE_HELPER;
                  let header = {
                    filename: name1,
                    type: config.containerType,
                    "file-operation": "upload",
                    "process-file": config.processFile
                      ? config.processFile
                      : "no",
                    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
                    subdomain: "amitraqa",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.access_token,
                  };
                  let formData = {
                    file: e.target.result,
                  };
                  if (config.apiDetails) {
                    let {
                      extraHeaders = {},
                      apiUrl,
                      extraPayloads = {},
                    } = config.apiDetails;
                    header = { ...header, ...extraHeaders };
                    url = apiUrl || url;
                    formData = {
                      ...formData,
                      ...extraPayloads,
                    };
                  }
                  axios
                    .post(url, formData, {
                      headers: header,
                    })
                    .then(async () => {
                      if (numberOfFilesUploaded === totalNumberOfFiles) {
                        const payload = {
                          ...JSONFormatData,
                          parametersAsJsonString: JSON.stringify({
                            ...JSONFormatData.parametersAsJsonString,
                            fileName: FileName.name,
                          }),
                        };
                        const response = await httpPost(
                          `/SQLBasedForm/SQLFormOperations`,
                          payload,
                          "",
                          true
                        );
                        console.log("response", response);
                        return;
                      } else {
                        numberOfFilesUploaded++;
                      }
                    });
                }
                {
                  fileUploadOnView ? setUploading(false) : "";
                }

                {
                  fileUploadOnView ? setChangesSaved(true) : "";
                }
                res();
              } catch (e) {
                {
                  fileUploadOnView ? setChangesSaved(false) : "";
                }
                err(e);
              }
            };
          }
        });
      });
    }

    return;
  });
};
