export const security = {
  read: {
    groupids: [""],
    users: [""],
  },
  update: {
    groupids: [""],
    users: [""],
  },
  full: {
    groupids: [""],
    users: [],
  },
  privateRecord: false,
};
