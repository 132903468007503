export const constData = {
  CONTAINER: "Configs",
};
export const status = {
  PENDING: "Pending",
  WAITING_FOR_ASSIGNMENT: "waiting for assignment",
  WAITING_FOR_APPROVAL: "waiting for approval",
  WAITING_FOR_REVIEW: "waiting for review",
  COMPLETED: "Completed",
  APPROVE: "Approve",
  APPROVED: "Approved",
  REJECT: "Reject",
  REJECTED: "Rejected",
  REVIEW: "Signing Off",
  REVIEWED: "Reviewed",
  RESET: "Reset",
};
export const uiTypes = {
  DISABLED: "disabled",
  APPROVAL_SCREEN: "approval_screen",
  REVIEWER_SCREEN: "reviewer_screen",
  ASSIGNMENT_SCREEN: "assignment_screen",
};
