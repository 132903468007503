 /* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import theme from "../../../../Themes/defaultTheme";

const A_CircularDeternment = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          color={props.color}
          variant="static"
          value={props.value}
        />
        {props.showLabel && (
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
A_CircularDeternment.defaultProps = {
  color: "primary",
  value: 70,
  showLabel: true,
};

export default A_CircularDeternment;
