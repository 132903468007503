import * as httpCall from "../../../../../Utils/apis/apis";
export const getAnnouncement = async (payload) => {
  const response = await httpCall.httpPost(
    `/GenericGremlin/generic/getAnnoucements`,
    { ...payload, "@currDate": Math.floor(Date.now() / 1000) }
  );
  return response;
};
export const getBulkDocuments = async (payload) => {
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  const url = `${process.env.BULK_DOCUMENT_HELPER}`;

  let header = {
    "file-operation": "blob-items-link",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain,
  };

  let response = await httpCall.httpPost(url, payload, {
    responseType: "json",
    headers: header,
  });
  return response;
};
