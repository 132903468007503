import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../../Themes/defaultTheme";
import PropTypes from "prop-types";
const A_CircularIndeternment = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress color={props.color} size={props.size} />
    </ThemeProvider>
  );
};
A_CircularIndeternment.defaultProps = {
  color: "primary",
  size: "30",
};
A_CircularIndeternment.propTypes = {
  color: PropTypes.any,
  size: PropTypes.any,
};
export default A_CircularIndeternment;
