import React from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { connect } from "react-redux";
import { coords } from "../../../../Utils/config/config";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { getMapCoords } from "../DataDisplay/DataGrid/helper/download.helper";
const containerStyle = {
  width: "100%",
  height: "70%",
};
const useStyles = makeStyles(() => ({
  GoogleMap: {
    fontWeight: "bold",
  },
  InfoWindow: {
    fontWeight: 400,
  },
  showFieldData: {
    display: "inline",
  },
}));
function MapGoogle(props) {
  let showData =
    props && props.content && props.content.showField
      ? props.content.showField
      : "";
  const classes = useStyles();
  const [position, setPosition] = React.useState({});
  const [zoom, setZoom] = React.useState(16);
  const [marker, setMarker] = React.useState(true);
  const [showInfo, setShowInfo] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState(null);
  const [isAvailable, setIsAvailable] = React.useState(false);
  let countryName =
    props.data && props.data[showData[5]]
      ? JSON.parse(props.data[showData[5]])
      : "";
  const { crmId } = useParams();

  function addCrmId(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        addCrmId(obj[key]);
      } else if (obj[key] === "=crmId") {
        obj[key] = crmId;
      }
    }
  }
  const fetchData = async () => {
    if (props && props.content && props.content.mapData) {
      try {
        setIsAvailable(true);
        let paraMeters = props.content.mapData.parameeters;
        let { spSource, key, spName } = props.content.mapData;
        if (spSource === "CRM") {
          key = crmId;
        }
        addCrmId(paraMeters);
        let url = `${process.env.API_URL}/Geolocation/${spSource}/${key}/${spName}`;
        let response = await getMapCoords(url, paraMeters);
        if (response) {
          setPosition(JSON.parse(response));
        } else {
          setPosition(coords);
        }
      } catch (error) {
        setPosition(coords);
      }
    }
  };

  const findFromFilter = async () => {
    let filteredElement = props.toolbarSelections.filters.filter(
      (element) =>
        element.data &&
        element.data.series &&
        element.data.series.lat &&
        element.data.series.lat
    );
    if (filteredElement.length > 0) {
      let { lat, lng } = filteredElement[0].data.series;
      setPosition({ lat, lng });
      setZoom(3);
      setMarker(false);
    } else {
      if (props && props.content && props.content.mapData) {
        if (!isAvailable) fetchData();
      } else {
        setPosition(coords);
        setZoom(3);
        setMarker(false);
      }
    }
  };

  React.useEffect(() => {
    let geoLocation;
    try {
      geoLocation =
        props.data && props.data.geolocation
          ? JSON.parse(props.data.geolocation)
          : null;
    } catch (e) {
      geoLocation = "";
    }
    if (!geoLocation) {
      findFromFilter();
    } else {
      setPosition(geoLocation);
    }
  }, [props.toolbarSelections]);

  const toggleInfo = (marker) => {
    setActiveMarker(marker);
    setShowInfo(!showInfo);
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyD2u77z6reMmpdZxeL1cDkrv58d7SOpE0U">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={zoom}
      >
        {marker ? (
          <Marker
            position={position}
            onClick={() => {
              toggleInfo(position);
            }}
          />
        ) : (
          ""
        )}
        {showInfo && activeMarker && (
          <InfoWindow
            position={activeMarker}
            onCloseClick={() => toggleInfo(null)}
          >
            <div className={classes.InfoWindow}>
              <div className={classes.GoogleMap}>
                {props.data[showData[0]] ? props.data[showData[0]] : ""}
              </div>
              <div
                className={props.data[showData[0]] ? null : classes.GoogleMap}
              >
                {props.data[showData[1]] ? props.data[showData[1]] : ""}
              </div>
              <div className={classes.showFieldData}>
                <div>
                  {props.data[showData[2]] ? props.data[showData[2]] : ""}{" "}
                  {props.data[showData[3]] ? props.data[showData[3]] : ""}{" "}
                  {props.data[showData[4]] ? props.data[showData[4]] : ""}
                </div>
              </div>
              <div>
                {countryName && countryName[0] && countryName[0].title
                  ? countryName[0].title
                  : ""}
              </div>
              <a
                href={`https://maps.google.com/maps?q=${activeMarker.lat},${activeMarker.lng}`}
                target="_blank"
                rel="noreferrer"
              >
                View on Google Maps
              </a>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
}
MapGoogle.propTypes = {
  data: PropTypes.object,
  content: PropTypes.any,
  toolbarSelections: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

export default connect(mapStateToProps, undefined)(MapGoogle);
