export const portfolioapproval = {
  FILTERVALUE: "filterValue",
  TEMPLATEID: "templateId",
  TEMPLATEFILTERID: "templateFilterID",
  CURRENTPATHNAME:"currentPathName"
};
export function regexMatchAndReplaceFromDocument(inputText, regexPattern) {
  let placeholderMatches = inputText.matchAll(regexPattern);
  let currentValue = placeholderMatches.next();
  while (!currentValue.done) {
    let [placeholder, placeholderId] = currentValue;
    let customHtml =
      placeholderId &&
      document.getElementById(placeholderId) &&
      document.getElementById(placeholderId).innerHTML
        ? document.getElementById(placeholderId).innerHTML
        : "";
    if (customHtml) {
      inputText = inputText.replace(placeholder, customHtml);
    }
    currentValue = placeholderMatches.next();
  }
  return inputText;
}
