import { prevEntityActionType } from "../actions/actionTypes";

export const setPreviousEntity = (payload) => {
  return {
    type: prevEntityActionType.SET_PREVIOUS_ENTITY,
    payload,
  };
};

export const clearPreviousEntity = (payload) => {
  return {
    type: prevEntityActionType.CLEAR_PREVIOUS_ENTITY,
    payload,
  };
};
