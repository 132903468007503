 /* eslint-disable */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  section: {
    marginTop: "15px",
    marginBottom: "10px",
    fontSize: "20px",
    fontWeight: "600",
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function DisplaySectionHeader(props) {
    const classes = useStyles();
  return (
    <div>
      <p  className={classes.section}>{props.label}</p>
    </div>
  );
}

export default DisplaySectionHeader;
