import excel from "../../../../../../../Assets/Images/Defaults/excel.png";
import word from "../../../../../../../Assets/Images/Defaults/word.png";
import image from "../../../../../../../Assets/Images/Defaults/image.png";
import genericDoc from "../../../../../../../Assets/Images/Defaults/genericDoc.png";
import ppt from "../../../../../../../Assets/Images/Defaults/ppt.png";
import pdf from "../../../../../../../Assets/Images/Defaults/pdf.png";
const selectedIcon = (fileName) => {
  const ext = fileName.split(".").pop();
  switch (ext.toLowerCase()) {
    case "jpg":
    case "png":
    case "jpeg":
    case "gif":
    case "awif":
      return image;
    case "docx":
    case "doc":
      return word;
    case "xlsx":
    case "xlsm":
    case "xlsb":
    case "xls":
      return excel;

    case "ppt":
    case "pptx":
    case "pptm":
    case "potm":
    case "ppsx":
    case "pps":
    case "ppam":
      return ppt;

    case "pdf":
      return pdf;

    default:
      return genericDoc;
  }
};
export default selectedIcon;
