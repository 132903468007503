export const emailPrefix = "email-attachments/";

export const fileConst = {
  PROJECT: "Project",
  PORTFOLIOCOMPANY: "Portfolio Company",
  OTHER: "Other",
  DEAL: "deal",
  RECIPIENTS: "recipients",
  SUBJECT: "subject",
};
