import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import M_AddEntityList from "./M_AddEntityList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as entityRecordAction from "../../../../../../store/actions/entityRecordAction";

const useStyles = makeStyles((theme) => ({
  entityMenu: {
    marginTop:"50px",
    height:"95vh",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"1em",
      marginTop:"3em",
      minHeight:"97vh",
      scrollBehaviour:"smooth",

    },
    "& > .MuiPaper-root": {
      maxHeight: "calc(100% - 32px)",
      width: "290px",
      [theme.breakpoints.down('sm')]:{
        minWidth:"100vw",
      },
    },
  },
  AddButton: {
    // [theme.breakpoints.down('xs')]:{
    //   marginLeft:"-0.3em"
    // },
    "&:hover": {
      color: "#a9a9a9",
    },
  },
}));
const M_AddEntity = (props) => {
  const classes = useStyles();

  const [entityList, setEntityList] = useState(
    props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity
      ? props.entityRecord.data.entity
      : {}
  );
  useEffect(() => {
    const entitiesAvailable =
      props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity &&
      props.entityRecord.data.entity.length > 0
        ? true
        : false;
    if (!entitiesAvailable) {
      props.getEntityRecord();
    }
  }, []);

  useEffect(() => {
    if (
      props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity
    ) {
      setEntityList(props.entityRecord.data.entity);
    }
  }, [props.entityRecord]);

  //  entity menu open/close
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = "entityMenu";
  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        className={classes.AddButton}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AddIcon fontSize="inherit" color="inherit" />
      </IconButton>

      <Menu
        id={menuId}
        className={classes.entityMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <M_AddEntityList entitiesList={entityList} onFormClose={handleClose} />
      </Menu>
    </React.Fragment>
  );
};

M_AddEntity.propTypes = {
  entityRecord: PropTypes.any,
  getEntityRecord: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    entityRecord: state.entityRecord,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(entityRecordAction, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_AddEntity);
