export function getUniqueCodes(data, key) {
  const uniqueRegionCodes = [...new Set(data.map((item) => item[key]))];
  return uniqueRegionCodes.map((option) => ({
    label: option,
    value: option,
  }));
}
export const filterData = (
  originalData,
  selectedValue,
  oppFilterValue1,
  oppFilterValue2,
  oppFilterValue3,
  oppFilterValue4,
  currentFilterKey,
  oppFilterKey1,
  oppFilterKey2,
  oppFilterKey3,
  oppFilterKey4,
  oppFilterValueArray1 = [],
  oppFilterValueArray2 = [],
  oppFilterValueArray3 = [],
  oppFilterValueArray4 = []
) => {
  let data = [...originalData];
  try {
    if (!oppFilterValueArray1.some((item) => item.value === oppFilterValue1)) {
      oppFilterValue1 = "";
    }
    if (!oppFilterValueArray2.some((item) => item.value === oppFilterValue2)) {
      oppFilterValue2 = "";
    }
    if (!oppFilterValueArray3.some((item) => item.value === oppFilterValue3)) {
      oppFilterValue3 = "";
    }
    if (!oppFilterValueArray4.some((item) => item.value === oppFilterValue4)) {
      oppFilterValue4 = "";
    }
    if (selectedValue) {
      if (oppFilterValue1) {
        data = data.filter(
          (item) =>
            item[oppFilterKey1] === oppFilterValue1 &&
            item[currentFilterKey] === selectedValue
        );
      }
      if (oppFilterValue2) {
        data = data.filter(
          (item) =>
            item[oppFilterKey2] === oppFilterValue2 &&
            item[currentFilterKey] === selectedValue
        );
      }
      if (oppFilterValue3) {
        data = data.filter(
          (item) =>
            item[oppFilterKey3] === oppFilterValue3 &&
            item[currentFilterKey] === selectedValue
        );
      }
      if (oppFilterValue4) {
        data = data.filter(
          (item) =>
            item[oppFilterKey4] === oppFilterValue4 &&
            item[currentFilterKey] === selectedValue
        );
      }
      return data.filter((item) => item[currentFilterKey] === selectedValue);
    } else {
      if (oppFilterValue1) {
        data = data.filter((item) => item[oppFilterKey1] === oppFilterValue1);
      }
      if (oppFilterValue2) {
        data = data.filter((item) => item[oppFilterKey2] === oppFilterValue2);
      }
      if (oppFilterValue3) {
        data = data.filter((item) => item[oppFilterKey3] === oppFilterValue3);
      }
      if (oppFilterValue4) {
        data = data.filter((item) => item[oppFilterKey4] === oppFilterValue4);
      }
    }

    return data;
  } catch {
    return data;
  }
};
export const getFinalData = (
  currentValue,
  data1,
  data2,
  data3,
  data4,
  oldData,
  newData
) => {
  try {
    if (currentValue || data1 || data2 || data3 || data4) {
      return [...newData];
    } else {
      return [...oldData];
    }
  } catch {
    return [...oldData];
  }
};
export const UnmatchedFilterData = (
  originalData,
  selectedValue,
  oppFilterValue,
  oppFilterKey,
  currentFilterKey,
  oppFilterValueArray = []
) => {
  let data = [...originalData];
  try {
    if (
      !oppFilterValueArray.some((region) => region.value === oppFilterValue)
    ) {
      oppFilterValue = "";
    }
    if (selectedValue) {
      data = data.filter((item) =>
        oppFilterValue
          ? item[oppFilterKey] === oppFilterValue &&
            item[currentFilterKey] === selectedValue
          : item[currentFilterKey] === selectedValue
      );
    } else if (oppFilterValue) {
      data = data.filter((item) => item[oppFilterKey] === oppFilterValue);
    }

    return data;
  } catch {
    return data;
  }
};
