export const newsContentBasedOnType = (contentObject, paragraph = false) => {
  switch (contentObject.__type.toLowerCase()) {
    case "entityreference":
      return `<b>${contentObject.Name}</b>`;
    case "elink":
      return `<a href=${contentObject.Reference} target="_blank">${contentObject.Text}</a>`;
    default:
      if (paragraph)
        return `<span style='text-align:justify;'> ${contentObject.Value}</span>`;
      return `<div style='text-align:justify;'> ${contentObject.Value}</div>`;
  }
};
