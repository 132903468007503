import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  saveButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "primary",
    padding:"1.2rem",
    margin:"1.2rem 0"
  },
  bold:{fontWeight:500},
  enableSaveButton: {
    color: "#3c763d",
    backgroundColor: "#dff0d8",
    borderColor: "#d6e9c6",
  },
  disableSaveButton: {
    backgroundColor: "#fcf8e3",
    borderColor: "#faebcc",
  },
  messageBarDisabled: {
    marginTop: "0.4rem",
  },
  messageBarEnabled: {},
  button: {
    float: "right",
    marginRight: "0.8rem",
  },
  diffrenceText: { float: "left", marginTop: "0.4rem", marginLeft: "0.8rem" },
}));

export default useStyles;
