 /* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import A_Avatar from "../../../atoms/dataDisplay/avatars/A_Avatar";
import defaultAvatar from "../../../../../Assets/Images/Defaults/default_avatar.jpg";
import {getCurrentUser} from "../../../../../utils/helpers/common";
import { log_info, log_warning, log_error } from "../../../../../utils/logger/logger";
const M_UserAvatar =(props)=>{ 
    log_info('Logged-In successfully');
    log_warning('User initials not displayed on first load.');
    log_error('Javascript error occured.');
    let user =getCurrentUser();
    let userInitials = user.userInitials;    
    return (
        <React.Fragment>
            <A_Avatar size="medium" text={userInitials} src={props.logoImage? props.logoImage : defaultAvatar} onClick={props.onClick} />
        </React.Fragment>
    )
}
// function mapStateToProps(state, ownProps) {
//     let {user}=state.oidc;
//     return {user};
//   }
export default M_UserAvatar;
//export default connect(mapStateToProps)(M_UserAvatar);