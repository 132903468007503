import * as React from "react";
import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { message } from "../../../../../Utils/config/messages";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import * as common from "../../../../../Utils/Helpers/common";
import { Popover } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  tabConfig,
  columnHeaders,
  columnKeys,
  columnNameToKeyMap,
  prefixMappings,
} from "./CodeConstant";
import { httpPost } from "../../../../../Utils/apis/apis";
const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: "4px",
  },

  iconLabel: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "4px",
    cursor: "pointer",
  },
  iconLabelLarge: {
    fontSize: "16px",
    "& > *": {
      marginBottom: "6px",
    },
  },
  columnHeader: {
    color: "#fff",
  },
  iconButtonLabel: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      marginBottom: "4px",
    },
    iconButtonMainContainer: {
      display:"flex",
      justifyContent:"space-evenly"
    },
  },
  tabHeader: {
    backgroundColor: "#eeeeee",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));
const StyledTableCell = styled(TableCell)(() => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const QuarterlyKtTable = (props) => {
  const { content, startEditing } = props;
  const [savedData, setSavedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteid, setDeleteId] = useState();
  // const [value, setValue] = React.useState("1");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("1"); // Default to the first tab
  // const [tabData, setTabData] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedTab, setSelectedTab] = useState("KT");
  const [columnheaderkey, setColumnHeaderKey] = useState();
  const [spresponseData, setSpResponseData] = useState("");
  // const [sortedData, setSortedData] = useState([]);
  const handleMoreVertClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverIndex(rowIndex);
  };

  const handlePopoverClose = () => {
    setOpenPopoverIndex(null);
  };
  const convertCode = (code) => {
    for (const prefix in prefixMappings) {
      if (code && code.startsWith(prefix)) {
        return prefixMappings[prefix];
      }
    }
    return code;
  };

  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};

  const handleConfrimDelete = async () => {
    setIsOpen(false);
    const newData = Object.values(savedData).map((item) =>
      item.find((item) => item.id === deleteid)
    );
    let query = "res";
    let spResponse = await getDataFromSQLSp({
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: "Client.Usp_DeleteKTCode",
          parameters: {
            CodeCategory: convertCode(newData[0].Code),
            KTCodeID: newData[0].id,
          },
        },
      ],
    });
    let requestPayload = {
      CodeCategory: convertCode(newData[0].Code),
      KTCodeID: newData[0].id,
    };
    setSpResponseData(spResponse.res);
    httpPost(spResponse, requestPayload)
      .then((result) => {
        setSavedData(result);
        setDeleteId("");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const handleCancelDelete = () => {
    setIsOpen(false);
    setDeleteId("");
  };
  const handleDelete = (id) => {
    setDeleteId(id);
    handlePopup();
  };
  let handlePopup = () => {
    setIsOpen(true);
  };
  const handleTabClick = (tabValue) => {
    const selectedTabLabel = tabConfig.find(
      (tab) => tab.value === tabValue
    ).label;
    const shortLabel = selectedTabLabel.split(" ")[0];

    setActiveTab(tabValue);
    setSelectedTab(shortLabel);
    setSortConfig({ key: null, direction: "asc" });
    setSavedData([]);
    fetchKtCodeMapping(shortLabel);
  };

  const handleEdit = (data) => {
    props.updateEditData(data);
    startEditing();
  };
  const getActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="OK"
          autoFocus
          onClick={handleConfrimDelete}
        />
        <A_Button color="default" label="Cancel" onClick={handleCancelDelete} />
      </>
    );
  };
  const handleSortClick = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
    setColumnHeaderKey(columnKey);
    sortData(columnKey, direction);
  };

  const sortData = (columnKey, direction) => {
    const key = Object.keys(savedData)[0];
    const datasort = savedData[key].map((item) => ({ ...item }));
    columnKey = columnNameToKeyMap[columnKey ? columnKey : columnheaderkey];
    datasort.sort((a, b) => {
      const aValue = a[columnKey];
      const bValue = b[columnKey];

      if (typeof aValue !== "undefined" && typeof bValue !== "undefined") {
        if (aValue < bValue) {
          return direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
    setSavedData({
      [Object.keys(savedData)[0]]: datasort,
    });
  };

  const fetchKtCodeMapping = async (selectedTabValue) => {
    try {
      let { spDetails, chartSPs } = content.optionConfig;
      const updatedSPDetails = spDetails.map((sp) => ({
        ...sp,
        parameters: {
          ...sp.parameters,
          KTCodeCategory: selectedTabValue,
          QuarterCD: props.QuarterCode,
        },
      }));
      let spResponse = await getDataFromSQLSp({
        chartSPs,
        spDetails: updatedSPDetails,
      });
      setSavedData(spResponse);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    props.setSelectedTab(selectedTab);
  }, [selectedTab]);
  useEffect(() => {
    fetchKtCodeMapping(selectedTab);
  }, []);
  useEffect(() => {
    if (props.QuarterCode !== "") {
      fetchKtCodeMapping(selectedTab);
    }
  }, [spresponseData, props.newSavedData, props.QuarterCode]);
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(event, newValue) => handleTabClick(newValue)}
              className={classes.tabHeader}
              aria-label="tab"
            >
              {tabConfig.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  className={classes.tabHeader}
                />
              ))}
            </TabList>
          </Box>
          {tabConfig.map((tab) => (
            <TabPanel key={tab.value} value={tab.value}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: TableChart.header.style.background,
                      paddingRight: "0px",
                    }}
                  >
                    <TableRow>
                      {columnHeaders.map((header) => (
                        <StyledTableCell
                          key={header}
                          className={classes.columnHeader}
                          style={{
                            background: TableChart.header.style.background,
                            paddingRight: "0px",
                            color:
                              header === "MoreVertIcon"
                                ? "#757474"
                                : TableChart.header.style.color,
                          }}
                        >
                         <div style={{display:"flex"}}> {header}
                            {header !== "MoreVertIcon" && (
                              <TableSortLabel
                                active={sortConfig.key === header}
                                direction={sortConfig.direction}
                                onClick={() => handleSortClick(header)}
                              />
                          )}</div>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
  {savedData &&
  savedData[Object.keys(savedData)[0]] &&
  savedData[Object.keys(savedData)[0]].length > 0 ? (
    Object.values(savedData).map((dataGroup) => {
      const reversedDataGroup = [...dataGroup].reverse();
      return reversedDataGroup.map((data, rowIndex) => (
        <StyledTableRow key={rowIndex}>
          {columnKeys.map((key) => (
            <StyledTableCell key={key}>
              {data[key]}
            </StyledTableCell>
          ))}
          <StyledTableCell>
            <IconButton
              aria-label="more-vert"
              onClick={(event) =>
                handleMoreVertClick(event, rowIndex)
              }
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              id={`popover-more-vert-${rowIndex}`}
              open={openPopoverIndex === rowIndex}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                p={2}
              >
                <div className={classes.iconButtonMainContainer}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      handleEdit(data);
                      handlePopoverClose();
                    }}
                    className={classes.iconButton}
                  >
                    <EditIcon
                      fontSize="large"
                      className={classes.iconLabelLarge}
                    />
                    <Typography
                      variant="body2"
                      className={`${classes.iconLabel} ${classes.iconLabelLarge}`}
                    >
                      Edit
                    </Typography>
                  </IconButton>
                  <Divider />
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      handleDelete(data.id);
                      handlePopoverClose();
                    }}
                    className={`${classes.iconButton} ${classes.deleteIconButton}`}
                  >
                    <DeleteIcon
                      className={classes.iconLabelLarge}
                    />
                    <Typography
                      variant="body2"
                      className={`${classes.iconLabel} ${classes.iconLabelLarge}`}
                    >
                      Delete
                    </Typography>
                  </IconButton>
                </div>
              </Box>
            </Popover>
          </StyledTableCell>
        </StyledTableRow>
      ));
    })
  ) : (
    <TableRow>
      <TableCell colSpan={columnHeaders.length + 1}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100px" }}
        >
          <Typography variant="body1">
            {message.NO_DATA_AVAILABLE}
          </Typography>
        </Grid>
      </TableCell>
    </TableRow>
  )}
</TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          ))}
        </TabContext>
      </Box>

      <A_SimpleDialog
        id="2"
        open={isOpen}
        closePopUp={handlePopup}
        title="Delete"
        dialogContent={message.DELETE_WARNING}
        dialogActions={getActionItems()}
        onClose={handleCancelDelete}
      />
    </>
  );
};
QuarterlyKtTable.propTypes = {
  content: PropTypes.any,
  updateEditData: PropTypes.any,
  setSelectedTab: PropTypes.any,
  startEditing: PropTypes.any,
  isEditing: PropTypes.any,
  newSavedData: PropTypes.any,
  QuarterCode: PropTypes.any,
};
export default QuarterlyKtTable;
