/* eslint-disable */
import {manageExcelTemplateActionType} from "../actions/actionTypes";
import {manageExcelTemplateDownloadActionType} from "../actions/actionTypes";


// let initialState =[
//        {
//         tabTitle: 'Income Statement',
//         id:'1',
//         templateMetrics: [
//                 {
//                     sortOrder:1,
//                     parentName:null,
//                     name: "Cost of Computer",
//                     level: 1,
//                     type:"header",
//                     signConstraint:0,
//                     rowBreakAfter:false,
//                     showOnTemplate:true
                
//                 },
//                 {
//                     sortOrder:2,
//                     parentName:null,
//                     name: "Genral Office Costs",
//                     level: 1,
//                     type:"header",
//                     signConstraint:-1,
//                     rowBreakAfter:true,
//                     showOnTemplate:false
                
//                 }
//         ]
//         },
//         {
//             tabTitle: 'Balance Sheet',
//             id:'2',
//             templateMetrics: []
//         },
//         {
//             tabTitle: 'KPI',
//             id:'3',
//             templateMetrics: []
//         },
//         {
//             tabTitle: 'Cash Flow',
//             id:'4',
//             templateMetrics: []
//          },
//          {
//             tabTitle: 'Demo',
//             id:'5',
//             templateMetrics: [
//                 {
//                     sortOrder: 1,
//                     level: 3,
//                     name: "Broking ",
//                     rowBreakAfter: true,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "x",
//                     parentName: "Costs of Sales"
//                 },{
//                     sortOrder: 2,
//                     level: 3,
//                     name: "IQED",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header",
//                     parentName: "Costs of Sales"
//                 },{
//                     sortOrder: 3,
//                     level: 2,
//                     name: "Costs of Sales",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header",
//                     parentName: "EBITDA"
//                 },{
//                     sortOrder: 4,
//                     level: 2,
//                     name: "Grose Profit",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header",
//                     parentName: "EBITDA"
//                 },{
//                     sortOrder: 5,
//                     level: 3,
//                     name: "Staff Costs",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "#",
//                     parentName:"Genral Expenses"
//                 },{
//                     sortOrder: 6,
//                     level: 3,
//                     name: "Computer Costs",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "#",
//                     parentName:"Genral Expenses"
//                 },{
//                     sortOrder: 7,
//                     level: 2,
//                     name: "Genral Expenses",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header",
//                     parentName:"EBITDA"
//                 },{
//                     sortOrder: 8,
//                     level: 2,
//                     name: "Operation Costs",
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header",
//                     parentName:"EBITDA"
//                 },{
//                     sortOrder: 9,
//                     level: 1,
//                     name: "EBITDA",
//                     parentName:null,
//                     rowBreakAfter: false,
//                     showOnTemplate: true,
//                     signConstraint:0,
//                     type: "header"
//                 }
//             ]
//          }
//     ]

    let initialState = {

    };

const manageExcelTemplateReducer = (state = initialState,action) => {  
    switch(action.type){ 
        case manageExcelTemplateActionType.GET_TABS:
            return state; 
        case manageExcelTemplateActionType.GET_TABS_SUCCESSED:
            return action.payload; 
        case manageExcelTemplateActionType.GET_TABS_FAILED:
            return action.payload;   
        case manageExcelTemplateActionType.SET_TABS:  
            return action.payload;      
            break;
        case manageExcelTemplateActionType.SAVE_TABS:  
            return action.payload;      
            break;
        case manageExcelTemplateActionType.SAVE_TABS_SUCCESSED:
                return action.payload; 
        case manageExcelTemplateActionType.SAVE_TABS_FAILED:
                return action.payload;       
        // case manageExcelTemplateActionType.DOWNLOAD_EXCEL:
        //     return state; 
        // case manageExcelTemplateActionType.DOWNLOAD_EXCEL_SUCCESSED:
        //     return action.payload; 
        // case manageExcelTemplateActionType.DOWNLOAD_EXCEL_FAILED:
        //     return action.payload;     
        default:
        return state;
    }
  };


  export default manageExcelTemplateReducer;