/* eslint-disable */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box, Typography, List } from "@material-ui/core";
import PieChart from "@material-ui/icons/PieChart";
import "./kpicallbox.scss";
import * as MuiIcons from '@material-ui/icons'

export default function KpiCallBox(props) {
  return (
    <div id="kpiCallBox">
      <Grid container spacing={3} className="kpiCallBox">
        {props.item &&
          props.item !== null &&
          props.item.content &&
          props.item.content !== null &&
          props.item.content.kpiBox &&
          props.item.content.kpiBox.length > 0 &&
          props.item.content.kpiBox.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className="flexContainer">
                  <Box className="flex1stItem" style={{background:`${item.backGroundColor1 ? item.backGroundColor1: '#276890' }`}}>
                    {item.icon && item.icon!==undefined && (
                      <GetMaterialIcon iconName={item.icon} />
                    )}
                  </Box>
                  <Box className="flex2ndItem" style={{background:`${item.backGroundColor2 ? item.backGroundColor2: '#377ca7' }`}}>
                    <Typography className="content2nd1st">
                      {item.label}
                    </Typography>
                    <Typography className="content2st2nd">
                      <span id={item.id}></span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

const GetMaterialIcon = ({iconName})=>{
  const Icon = MuiIcons && MuiIcons[iconName] ? MuiIcons[iconName] : ''
return (
  <>
  {Icon ? <Icon className="iconFOnt" /> : '' }
  </>
)

}