 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../../Themes/defaultTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const A_Badge = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Badge
          badgeContent={props.badgeContent}
          max={props.max}
          color={props.color}
          //variant={props.variant}
          anchorOrigin={{
            vertical: props.vertical,
            horizontal: props.horizontal,
          }}
          invisible={props.invisible}
        >
          {props.badgeHolderComponent}
        </Badge>
      </div>
    </ThemeProvider>
  );
};
A_Badge.defaultProps = {
  color: "primary",
  badgeContent: 0,
  max: 100,
  //variant: "standard",
  badgeHolderComponent: <CircularProgress />,
  vertical: "top",
  horizontal: "right",
  invisible: false,
};
export default A_Badge;
