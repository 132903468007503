import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";

export const numberWithCommas = (num) => {
  if (num && typeof num === "string" && num.search("/") > -1) {
    return num;
  }
  if (num) {
    let str = num.toString().split(".");
    str[0] = str[0] ? str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
    return str.join(".");
  } else {
    return num;
  }
};

export const fundYesOrNo = (row, data) => {
  if (row && row[data] === false) {
    return "No";
  } else {
    return row && row[data] === true ? "Yes" : null;
  }
};

export const getInvestorType = (investorType) => {
  let typeValue = "";
  if (investorType) {
    switch (investorType.toString()) {
      case "1":
        typeValue = "Endowment / Foundation";
        break;
      case "2":
        typeValue = "Corporate Pension Fund";
        break;
      case "3":
        typeValue = "Insurance";
        break;
      case "4":
        typeValue = "Investment Company";
        break;
      case "5":
        typeValue = "High Net Worth Individual";
        break;
      case "6":
        typeValue = "Family Office";
        break;
      case "7":
        typeValue = "Sovereign Wealth Fund";
        break;
      case "8":
        typeValue = "Fund of Funds";
        break;
      case "9":
        typeValue = "Private Pension Fund";
        break;
      case "10":
        typeValue = "Asset Manager";
        break;
      case "11":
        typeValue = "Government / Public Sector";
        break;
      case "12":
        typeValue = "Consultant";
        break;
      case "13":
        typeValue = "Bank";
        break;
      case "14":
        typeValue = "Superannuation Scheme";
        break;
      case "15":
        typeValue = "Private Equity";
        break;
      case "118840001":
        typeValue = "Private Debt Fund";
        break;
      case "118840000":
        typeValue = "Wealth Manager";
        break;
      case "118840002":
        typeValue = "Hedge Fund";
        break;
      default:
        typeValue = investorType ? handleOptionSetValue(investorType) : "";
    }
  }
  return typeValue;
};

export const getRelationship = (relationship) => {
  let value = "";
  if (relationship) {
    switch (relationship.toString()) {
      case "1":
        value = "Tier 1";
        break;
      case "2":
        value = "Tier 2";
        break;
      case "3":
        value = "Existing";
        break;
      case "4":
        value = "Consultant";
        break;
      case "5":
        value = "To be qualified";
        break;
      case "6":
        value = "Prospect";
        break;
      case "7":
        value = "Dormant";
        break;
      default:
        value = relationship ? handleOptionSetValue(relationship) : "";
    }
  }
  return value;
};
