import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Grid from "@material-ui/core/Grid";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_Checkbox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import LookUpMaterics from "./LookUpMatrics";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useMediaQuery } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PropTypes from "prop-types";
import {
  getDeleteData,
  getShowEditedRowData,
  isDisabledCheckCounterValidation,
  setIsCalculated,
  optionType,
  signOptions,
} from "./ExcelTemplateContentHelper";
import { defaultValue } from "../../../../../Utils/config/config";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Tooltip } from "@material-ui/core";
import { filterTypes } from "./excel.Helper";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: "70px",
    textAlign: "center",
    minHeight: "70vh",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  dragable: {
    cursor: "move",
  },
  selctBox1: {
    minWidth: 125,
    height: "29px",
    marginLeft: "92px",
  },
  ExcelWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "325vw",
      height: "12vh",
    },
  },
  InputTextWrapperGrid: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "48px",
      marginTop: "4px",
      position: "relative",
    },
  },
  selctBox2: {
    minWidth: 125,
    height: "29px",
    marginLeft: "60px",
  },
  TypeName: {
    [theme.breakpoints.down("lg")]: {
      textAlign: "right",
    },
  },
  displayName: {
    [theme.breakpoints.up("xl")]: {
      marginRight: "-0.7em",
    },
  },
  SignName: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "44 !important",
    },
    [theme.breakpoints.up("xl")]: {
      textAlign: "center",
    },
  },

  lookupbutton: {
    margin: "5px",
    padding: "0px",
    marginLeft: "70px",
    height: "29px",
    color: "black",
    fontWeight: "500",
    border: "1px solid #b3b3b3",
  },
  checkboxLabel: {
    margin: 0,
    width: 0,
  },
  checkboxRoot: {
    padding: 0,
    [theme.breakpoints.down("lg")]: {
      marginLeft: "1.8em",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "1.4em",
    },
  },
  displayNameWrapper: {
    wordBreak: "break-all",
  },
  displayNameTextField: {
    width: "166px",
  },
  icons: {
    width: 30,
    height: 30,
  },
}));
const ExcelTemplateContent = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [contents, setContents] = React.useState(props.content);
  let dragedItemIndex = null;
  const currentItem = props.item;
  const [addRow, setAddRow] = React.useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [lookupMetricValue, setLookupMetricValue] = React.useState([]);
  const [lookupMetricInfo, setLookupMetricInfo] = React.useState();
  const [lookupMetricId, setLookupMetricId] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [addRowBetween, setAddRowBetween] = React.useState(false);
  const [carryForward, setCarryForward] = React.useState(null);

  const { investmentId } = props;
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const commonFilter = (id, key, valueToChange) => {
    let resultArray = contents.map((item) => {
      if (item.sortOrder == id) {
        item[key] = valueToChange;
        item[filterTypes.ACTION] =
          item[filterTypes.ACTION] &&
          item[filterTypes.ACTION] == filterTypes.INSERT
            ? filterTypes.INSERT
            : filterTypes.UPDATE;
      }
      return item;
    });

    setContents([...resultArray]);
  };

  const checkCounterValidation = (currentLevel, id, type) => {
    let res = contents.filter((item) => {
      if (item.sortOrder != id) {
        if (type == filterTypes.POSITIVE && item.level == currentLevel) {
          return true;
        } else if (
          type == filterTypes.NEGETIVE &&
          item.level == currentLevel - 1
        ) {
          return true;
        }
      } else {
        return false;
      }
    });
    if (res && res.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const moveChildLevel = (opration, item, ind, parentLevel) => {
    contents.sort((a, b) => a.sortOrder - b.sortOrder);

    for (let index = ind - 1; index > -1; index--) {
      let childLevel =
        contents &&
        contents[index] &&
        contents[index].level &&
        contents[index].level
          ? contents[index].level
          : 0;

      if (childLevel <= parentLevel) {
        break;
      } else if (childLevel > parentLevel) {
        contents[index].level =
          opration == filterTypes.SUBSTRACTION
            ? contents[index].level - 1
            : contents[index].level + 1;
      }
    }
    return contents;
  };

  const leftMoveHandle = (e, id, level, index) => {
    let item =
      contents && contents.length > 0 && contents[index]
        ? contents[index]
        : null;

    let resultArray = moveChildLevel(
      filterTypes.SUBSTRACTION,
      item,
      index,
      item.level
    );

    if (item && item.sortOrder == id && item.level > 0) {
      let res = checkCounterValidation(item.level, id, filterTypes.NEGETIVE);
      item.level = res ? item.level - 1 : item.level;
      item[filterTypes.ACTION] =
        item[filterTypes.ACTION] &&
        item[filterTypes.ACTION] == filterTypes.INSERT
          ? filterTypes.INSERT
          : filterTypes.UPDATE;
      contents[index] = item;
    }

    setContents([...resultArray]);
    setIsCalculated(setParentValue(), null);
  };
  const rightMoveHandle = (e, id, level, index) => {
    let item =
      contents && contents.length > 0 && contents[index]
        ? contents[index]
        : null;
    let resultArray = moveChildLevel(
      filterTypes.ADDITION,
      item,
      index,
      item.level
    );

    if (item && item.sortOrder == id) {
      let res = checkCounterValidation(item.level, id, filterTypes.POSITIVE);
      item.level = res ? item.level + 1 : item.level;
      item[filterTypes.ACTION] =
        item[filterTypes.ACTION] &&
        item[filterTypes.ACTION] == filterTypes.INSERT
          ? filterTypes.INSERT
          : filterTypes.UPDATE;
    }

    setContents([...resultArray]);
    setIsCalculated(setParentValue());
  };
  const typeChangeHandle = (e, id) => {
    commonFilter(id, filterTypes.TYPE, e.value);
  };

  const signedChangeHandle = (e, id) => {
    commonFilter(id, filterTypes.SIGN_CONSTRAINT, e.value);
  };
  const rowBreakCheckBoxChangeHandle = (e, id) => {
    commonFilter(id, filterTypes.ROW_BREAK_AFTER, e);
  };
  const showTemplateCheckboxChangeHandle = (e, id) => {
    commonFilter(id, filterTypes.SHOW_ON_TEMPLATE, e);
  };
  const handleRowDoubleClick = (e, id) => {
    //setAddRow(false);
    setAddRowBetween(null);
    showEditedRow(id);
  };

  const showEditedRow = (id, shouldHide) => {
    const finalArray = getShowEditedRowData(id, shouldHide, contents);
    setContents([...finalArray]);
  };
  const handleLookUpMatrics = (e, item, sortOrder, lookUpMetricInfo) => {
    const data = item;
    const id = item.sortOrder;
    setLookupMetricId(id);
    setLookupMetricValue(data);
    setLookupMetricInfo(
      lookUpMetricInfo &&
        lookUpMetricInfo.length > 0 &&
        lookUpMetricInfo[0].formula
        ? lookUpMetricInfo[0].formula
        : null
    );
    setCarryForward(
      lookUpMetricInfo &&
        lookUpMetricInfo.length > 0 &&
        lookUpMetricInfo[0].carryForward
        ? lookUpMetricInfo[0].carryForward
        : null
    );
    setOpenPopup(!openPopup);
  };
  const handlelookup = (id, value) => {
    if (value[0].carryForward !== null) {
      value[0].formula = "";
    }
    commonFilter(id, filterTypes.LOOKUP_METRIC_FLAG, true);
    commonFilter(id, filterTypes.LOOKUP_METRIC_INFO, value);
  };

  const handleEditedRow = (e, id) => {
    if (e.key === "Enter" || !e.key) {
      commonFilter(id, filterTypes.NAME, e.target.value);
      commonFilter(id, filterTypes.EDITED, undefined);
    }
  };

  const handleCallBack = (val) => {
    setOpenPopup(val);
  };

  const handleDeleteRow = (e, id, deletedLevel) => {
    const finalArray = getDeleteData(e, id, deletedLevel, contents);
    setContents([...finalArray]);
  };
  const Renderitems = (props) => {
    const classes = useStyles();
    let { item, metricContents } = props;
    let lastIndex = -1;
    for (let i = metricContents.length - 1; i >= 0; i--) {
      if (metricContents[i].action !== filterTypes.DELETE) {
        lastIndex = i;
        break;
      }
    }
    let ind = props.index;
    if (item && item.action && item.action == filterTypes.DELETE) {
      return "";
    }
    let lastMetricRow = lastIndex === ind;
    const [addIcon, setAddIcon] = React.useState(false);

    const handleAddRowBetween = (item) => (e) => {
      // setAddRow(false);
      setAddRowBetween(item.sortOrder);
      showEditedRow(null, true, e);
    };
    const handleAfterAddRowBetween = (e, order, ind, item) => {
      props.handleAfterAddRowBetweenParent(e, order, ind, item);
      setAddRowBetween(null);
    };
    const handleAddRowByEnter = (e, order, ind, item) => {
      if (e.key == "Enter") {
        e.preventDefault();
        props.handleAfterAddRowBetweenParent(e, order, ind, item);
        setAddRowBetween(null);
      }
    };
    const handleDragStart = (e, index) => {
      e.dataTransfer.effectAllowed = "move";
      dragedItemIndex = index;
    };
    const handleDragOver = (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.dataTransfer.dropEffect = "move";
      return false;
    };
    const handleDrop = (e, index) => {
      props.handleDragAndDroped(dragedItemIndex, index);
    };
    return (
      <>
        <Grid
          key={"row" + ind}
          id={"row" + ind}
          draggable="true"
          onDrop={(e) => handleDrop(e, ind)}
          onDragOver={handleDragOver}
          onDrag={(event) => handleDragStart(event, ind)}
          container
          onMouseEnter={() => setAddIcon(true)}
          onMouseLeave={() => setAddIcon(false)}
          spacing={1}
          style={{
            alignItems: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
            margin: "0px",
          }}
        >
          <Grid item xs={1}>
            <ArrowLeftIcon
              style={
                isDisabledCheckCounterValidation(
                  item.level,
                  item.sortOrder,
                  filterTypes.NEGETIVE,
                  ind,
                  item.parentName,
                  item.id,
                  contents
                )
                  ? {}
                  : { opacity: "0.2", pointerEvents: "none" }
              }
              key={"left" + ind}
              id={"left" + ind}
              onClick={(e) =>
                leftMoveHandle(e, item.sortOrder, item.level, ind)
              }
            />
            <ArrowRightIcon
              style={
                isDisabledCheckCounterValidation(
                  item.level,
                  item.sortOrder,
                  filterTypes.POSITIVE,
                  ind,
                  item.parentName,
                  item.id,
                  contents
                )
                  ? {}
                  : { opacity: "0.2", pointerEvents: "none" }
              }
              key={"right" + ind}
              id={"right" + ind}
              onClick={(e) =>
                rightMoveHandle(e, item.sortOrder, item.level, ind)
              }
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              cursor: "move",
              textAlign: "left",
              paddingLeft: item.level < 1 ? "0em" : item.level * 2 + "em",
            }}
            onDoubleClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
          >
            <Typography>
              <Box
                fontWeight={item.isCalculated ? "fontWeightBold" : ""}
                classes={{ root: classes.displayNameWrapper }}
              >
                {!item.edited ? (
                  item.name
                ) : (
                  <A_TextField
                    focus={true}
                    type="text"
                    key={"sortorder" + ind}
                    id={"sortorder" + ind}
                    label=""
                    defaultValue={item.name}
                    onKeyPress={(e) => handleEditedRow(e, item.sortOrder)}
                    onBlur={(e) => {
                      handleEditedRow(e, item.sortOrder);
                    }}
                  />
                )}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={2} style={{ cursor: "move" }}>
            <A_Select
              key={filterTypes.TYPE + ind}
              id={filterTypes.TYPE + ind}
              className={classes.selctBox1}
              options={optionType}
              defaultValue={item.type}
              onChange={(e) => typeChangeHandle(e, item.sortOrder)}
            />
          </Grid>
          <Grid item xs={2} style={{ cursor: "move" }}>
            <A_Select
              key={"signOptions" + ind}
              id={"signOptions" + ind}
              className={classes.selctBox2}
              options={signOptions}
              defaultValue={item.signConstraint}
              onChange={(e) => signedChangeHandle(e, item.sortOrder)}
            />
          </Grid>
          <Grid item xs={1} style={{ display: "flex", cursor: "move" }}>
            <A_Button
              className={classes.lookupbutton}
              key={"lookup" + ind}
              id={"lookup" + ind}
              color="primary"
              variant="outlined"
              label={
                item.lookupMetricInfo && item.lookupMetricInfo.length ? (
                  <div style={{ backgroundColor: "#fff", width: "45px" }}>
                    {calculateLookupValue(item.lookupMetricInfo)}
                  </div>
                ) : (
                  "Select"
                )
              }
              onClick={(e) =>
                handleLookUpMatrics(
                  e,
                  item,
                  item.sortOrder,
                  item.lookupMetricInfo
                )
              }
            />
          </Grid>

          <Grid item xs={1} style={{ cursor: "move" }}>
            <A_Checkbox
              key={"rowbreak" + ind}
              id={"rowbreak" + ind}
              checked={item.rowBreakAfter}
              onChange={(e) => rowBreakCheckBoxChangeHandle(e, item.sortOrder)}
              labelClass={classes.checkboxLabel}
              allClasses={{ root: classes.checkboxRoot }}
            />
          </Grid>
          <Grid item xs={1} style={{ cursor: "move" }}>
            <A_Checkbox
              key={"showtemplate" + ind}
              id={"showtemplate" + ind}
              checked={item.showOnTemplate}
              onChange={(e) =>
                showTemplateCheckboxChangeHandle(e, item.sortOrder)
              }
              labelClass={classes.checkboxLabel}
              allClasses={{ root: classes.checkboxRoot }}
            />
          </Grid>

          <Grid
            item
            xs={1}
            onDoubleClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
          >
            {/* <span style={{cursor:moveBy}} draggable="true"><DragHandleIcon/></span> */}
            {/* <EditIcon
              key={"edit" + ind}
              id={"edit" + ind}
              onClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
            /> */}
            <DeleteIcon
              key={"delete" + ind}
              id={"delete" + ind}
              onClick={(e) => handleDeleteRow(e, item.sortOrder, item.level)}
            />
          </Grid>
          {addIcon && !lastMetricRow ? (
            <div style={{ width: "100%", textAlign: "left" }}>
              <AddCircleOutlineIcon
                onClick={handleAddRowBetween(item)}
                style={{
                  position: "relative",
                  margin: "-12px",
                  left: "178px",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>
        {addRowBetween === item.sortOrder ? (
          <Grid
            className={classes.triggerElement}
            key={"row" + ind}
            id={"row" + ind}
            container
            spacing={1}
            style={{
              alignItems: "center",
              border: "1px solid rgba(224, 224, 224, 1)",
              margin: "0px",
            }}
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography style={{ padding: "10px" }}>
                <Box fontWeight={item.isCalculated ? "fontWeightBold" : ""}>
                  <A_TextField
                    type="text"
                    label="Add Metric"
                    key={"sortorder" + ind}
                    id={"sortorder" + ind}
                    defaultValue=""
                    onKeyPress={(e) =>
                      handleAddRowByEnter(e, item.sortOrder, ind, item)
                    }
                    onBlur={(e) => {
                      handleAfterAddRowBetween(e, item.sortOrder, ind, item);
                    }}
                    focus={true}
                  />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  };
  const handleCancelButton = () => {
    const contentsCopy = contents.filter(
      (content) => content.action !== "INSERT"
    );
    setContents(contentsCopy);
    setAddRowBetween(null);
  };
  const handleBlurEvent = (e) => {
    if (e && e.target && e.target.value) {
      let sortOrder = 0;
      sortOrder =
        contents && contents.length > 0
          ? contents[contents.length - 1].sortOrder + 1
          : (props.index + 1) * 1000 + 1;
      let currentObject = {
        investmentId: investmentId,
        sortOrder: sortOrder,
        showOnTemplate: true,
        signConstraint: 2,
        type: "$",
        rowBreakAfter: false,
        name: e.target.value,
        level: 0,
        previousMonthUsage: false,
        action: filterTypes.INSERT,
        isBalance: false,
        isFixed: false,
        isCalculated: false,
        scaling: defaultValue.scaling,
      };
      contents.push(currentObject);

      // set component state local
      setContents([...contents]);

      e.target.value = null;
      setAddRow(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlurEvent(e);
    }
  };

  const setParentValue = () => {
    let result = contents.map((item, index) => {
      const found = contents.find(
        (element, ind) => element.level < item.level && ind > index
      );
      if (found) {
        item["parentName"] = found.name;
      } else {
        item["parentName"] = null;
      }
      return item;
    });

    return result;
  };

  const handleSaveButton = () => {
    let requestJson =
      props.manageExcelTemplates && props.manageExcelTemplates.length > 0
        ? props.manageExcelTemplates.map((item) => {
            item.templateMetrics =
              item && item.templateMetrics && item.templateMetrics.length > 0
                ? setIsCalculated(item.templateMetrics, "save")
                : [];
            item.id = undefined;
            return item;
          })
        : [];
    if (props.deletedArray && props.deletedArray.length > 0) {
      requestJson = [...requestJson, ...props.deletedArray];
    }

    props.saveTabs({ data: requestJson, investmentId: investmentId });

    setIsLoading(true);
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const calculateLookupValue = (eqn) => {
    let eqnElements =
      eqn && eqn[0].formula != null && eqn[0].formula.split(" ");
    let count = 0;
    eqnElements &&
      eqnElements.map((item) => {
        if (item.charCodeAt(0) >= 48 && item.charCodeAt(0) <= 57) {
          count++;
        }
      });
    //check Again API Development Pending
    if (eqn[0].carryForward && eqn[0].carryForward !== "0") {
      count = 1;
    }
    return count;
  };
  React.useEffect(() => {
    setContents([...props.content]);
  }, [props.content && props.content[0] && props.content[0].id]);

  React.useEffect(() => {
    setAddRow(true);
  });
  React.useEffect(() => {
    // if(aftersaved){
    //     setAftersaved(false)
    //     return
    // }
    // setParentValue()
    let result = setParentValue();
    let arr =
      props.manageExcelTemplates && props.manageExcelTemplates.length > 0
        ? props.manageExcelTemplates.map((row) => {
            if (row.id && row.id == currentItem.id) {
              row.templateMetrics = [...result];
            }
            return row;
          })
        : [];
    props.setTabs([...arr]);
  }, [contents]);

  React.useEffect(() => {
    if (isLoading && props.manageExcelTemplates.saveTabsSuccessed) {
      setIsLoading(false);
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Changes saved successfully",
      });
      //props.setTabs([...props.manageExcelTemplates.data])
      if (Array.isArray(props.manageExcelTemplates.data)) {
        props.setTabValue(props.index, [...props.manageExcelTemplates.data]);
      }
      // setAftersaved(true)
      // props.setTabValue(props.index,[...props.manageExcelTemplates.data])
      // let json = props.manageExcelTemplates.data[props.index].templateMetrics[0];
      // setContents(json)
      // props.manageExcelTemplates.data[props.index]
    }
  }, [
    props.manageExcelTemplates && props.manageExcelTemplates.saveTabsSuccessed,
  ]);

  React.useEffect(() => {
    if (props.manageExcelTemplates.errorMessage && isLoading) {
      // loading ends
      setIsLoading(false);
      // saved  message
      setChangesSaved({
        status: true,
        type: "error",
        msg: props.manageExcelTemplates.errorMessage,
      });
      props.setTabs([...props.manageExcelTemplates.data]);
    }
  }, [props.manageExcelTemplates && props.manageExcelTemplates.errorMessage]);

  const handleAddRowBetweenParentEvent = (e, order, ind, item) => {
    if (e.target.value) {
      let currentObject = {
        investmentId: investmentId,
        sortOrder: order + 1,
        showOnTemplate: true,
        signConstraint: 2,
        type: "$",
        rowBreakAfter: false,
        name: e.target.value,
        level: item && item.level ? item.level : 0,
        previousMonthUsage: false,
        action: filterTypes.INSERT,
        isBalance: false,
        isFixed: false,
        isCalculated: false,
      };
      contents.splice(ind + 1, 0, currentObject);
      contents.join();

      for (let index = ind + 2; index < contents.length; index++) {
        contents[index][filterTypes.SORT_ORDER] =
          contents[index] && contents[index].sortOrder
            ? contents[index].sortOrder + 1
            : index;
        contents[index][filterTypes.ACTION] =
          contents &&
          contents[index] &&
          contents[index][filterTypes.ACTION] &&
          (contents[index][filterTypes.ACTION] == filterTypes.INSERT ||
            contents[index][filterTypes.ACTION] == filterTypes.DELETE)
            ? contents[index][filterTypes.ACTION]
            : filterTypes.UPDATE;
      }

      // set component state local
      setContents([...contents]);
      e.target.value = null;
      //setAddRow(false);
    }
  };

  const handleDragAndDroped = (dragInd, dropInd) => {
    let tempArray = contents;
    let tempOrder = tempArray[dragInd][filterTypes.SORT_ORDER];
    let tempLevel = tempArray[dragInd][filterTypes.LEVEL];
    let tempIscalculated = tempArray[dragInd][filterTypes.IS_CALCULATED];

    tempArray[dragInd][filterTypes.ACTION] =
      tempArray[dragInd][filterTypes.ACTION] == filterTypes.INSERT
        ? filterTypes.INSERT
        : filterTypes.UPDATE;
    tempArray[dropInd][filterTypes.ACTION] =
      tempArray[dropInd][filterTypes.ACTION] == filterTypes.INSERT
        ? filterTypes.INSERT
        : filterTypes.UPDATE;

    tempArray[dragInd][filterTypes.SORT_ORDER] =
      tempArray[dropInd][filterTypes.SORT_ORDER];
    tempArray[dropInd][filterTypes.SORT_ORDER] = tempOrder;

    tempArray[dragInd][filterTypes.IS_CALCULATED] =
      tempArray[dropInd][filterTypes.IS_CALCULATED];
    tempArray[dropInd][filterTypes.IS_CALCULATED] = tempIscalculated;

    tempArray[dragInd][filterTypes.LEVEL] =
      tempArray[dropInd][filterTypes.LEVEL];
    tempArray[dropInd][filterTypes.LEVEL] = tempLevel;

    setContents([...tempArray]);
  };
  return (
    <div>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          className={classes.ExcelWrapper}
        >
          <Grid
            container
            spacing={2}
            style={{
              color: "white",
              backgroundColor: "rgb(38, 52, 64)",
              paddingTop: 20,
              paddingBottom: 10,
              alignItems: "center",
              border: "2px solid rgba(224, 224, 224, 1)",
              margin: "0px",
            }}
          >
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Move Left/Right
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                fontWeight: "bold",
                marginLeft: "3.2em",
                paddingLeft: "3em",
              }}
              className={classes.displayName}
            >
              Display Name
            </Grid>

            {/* <Grid item xs={1} style={{ fontWeight: "bold" }}>
                    Move Down
                    </Grid> */}
            {/* <Grid item xs={1} style={{ fontWeight: "bold" }}>
            Level
          </Grid> */}
            <Grid
              item
              xs={2}
              style={{ fontWeight: "bold" }}
              className={classes.TypeName}
            >
              Type
            </Grid>
            <Grid
              item
              xs={2}
              style={{ fontWeight: "bold" }}
              className={classes.SignName}
            >
              Signed
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Advanced
            </Grid>

            <Grid
              item
              xs={1}
              style={{ fontWeight: "bold", cursor: "pointer" }}
              className={classes.IconStyle}
            >
              <Tooltip title="Row Break">
                <PlaylistAddIcon className={classes.icons} />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ fontWeight: "bold", cursor: "pointer" }}
              className={classes.IconStyle}
            >
              <Tooltip title=" Show in Template">
                <VisibilityIcon className={classes.icons} />
              </Tooltip>
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}></Grid>
          </Grid>

          {contents && contents.length > 0
            ? contents
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => {
                  return (
                    <>
                      {
                        <Renderitems
                          item={item}
                          index={index}
                          handleAfterAddRowBetweenParent={
                            handleAddRowBetweenParentEvent
                          }
                          handleDragAndDroped={handleDragAndDroped}
                          metricContents={contents}
                        />
                      }
                    </>
                  );
                })
            : ""}
        </Grid>

        <Grid
          container
          spacing={3}
          style={
            isMobile
              ? { marginTop: "50vh", position: "absolute" }
              : { paddingBottom: "40px" }
          }
        >
          <Grid item xs={1}>
            {/* Move Up */}
          </Grid>
          <Grid item xs={2} className={classes.InputTextWrapperGrid}>
            {!addRow ? (
              /*<AddIcon onClick={handleAddRowClick} /> */ ""
            ) : (
              <A_TextField
                key={"displayname"}
                id={"displayname"}
                type="text"
                label="Enter Display Name"
                defaultValue={null}
                onKeyPress={handleKeyDown}
                onBlur={handleBlurEvent}
                className={[
                  classes.displayNameWrapper,
                  classes.displayNameTextField,
                ]}
              />
            )}
          </Grid>

          <Grid item xs={1}>
            {/* Move Down */}
          </Grid>
          {/* <Grid item xs={1}> */}
          {/* Level */}
          {/* </Grid> */}
          <Grid item xs={2}>
            {/* Type */}
          </Grid>
          <Grid item xs={2}>
            {/* Signed */}
          </Grid>
          <Grid item xs={1}>
            {/* Use Previous */}
          </Grid>
          <Grid item xs={1}>
            {/* Use Previous */}
          </Grid>
          <Grid item xs={1}>
            {/* Row Break */}
          </Grid>
          <Grid item xs={1}>
            {/* Show in Template */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={8}>
                        
                </Grid> */}
          {/* <Grid item xs={4}>
                        <A_Button  color="primary" label="Add Record" onClick={handleAddRowClick} />
                        <A_Button  color="primary" label="Cancel" onClick={handleCancelButton} />
                        <A_Button color="primary" label="Save" onClick={handleSaveButton}/>
                </Grid> */}
          {/* <Grid item xs={0}>
                        
                </Grid> */}
        </Grid>

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={3000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
        {openPopup && (
          <LookUpMaterics
            openPopup={openPopup}
            handlelookup={handlelookup}
            lookupMetricId={lookupMetricId}
            investmentId={investmentId}
            callBack={handleCallBack}
            carryForward={carryForward}
            lookupMetricValue={lookupMetricValue}
            lookupMetricInfo={lookupMetricInfo}
            content={props.content}
            tabData={props.tabData ? props.tabData : []}
          />
        )}
      </div>

      <div>
        <footer
          style={{
            display: "flex",
            width: "100%",
            position: "sticky",
            flexDirection: "column",
          }}
        >
          <Grid style={{ textAlign: "right" }} item xs={12}>
            {!(props.isdownloadExcelPopup || openPopup) ? (
              <A_Button
                color="primary"
                label="Cancel"
                onClick={handleCancelButton}
              />
            ) : (
              ""
            )}
            {!(props.isdownloadExcelPopup || openPopup) ? (
              <A_Button
                color="primary"
                label="Save"
                disabled={isLoading}
                endIcon={
                  isLoading ? (
                    <A_CircularIndeternment color="secondary" size={20} />
                  ) : (
                    ""
                  )
                }
                onClick={handleSaveButton}
              />
            ) : (
              ""
            )}
          </Grid>

          {/* <BottomNavigation >
                <BottomNavigationAction label="Recents" value="recents" icon={<A_Button  color="primary" label="Add Record" onClick={handleAddRowClick} />} />
    
        </BottomNavigation> */}
        </footer>
      </div>
    </div>
  );
};

ExcelTemplateContent.propTypes = {
  investmentId: PropTypes.string,
  item: PropTypes.object,
  content: PropTypes.array,
  action: PropTypes.any,
  sortOrder: PropTypes.any,
  handleAfterAddRowBetweenParent: PropTypes.func,
  handleDragAndDroped: PropTypes.func,
  level: PropTypes.any,
  parentName: PropTypes.any,
  id: PropTypes.any,
  isCalculated: PropTypes.any,
  edited: PropTypes.any,
  type: PropTypes.any,
  signConstraint: PropTypes.any,
  lookupMetricInfo: PropTypes.any,
  length: PropTypes.number,
  rowBreakAfter: PropTypes.any,
  showOnTemplate: PropTypes.any,
  onCancelRowChange: PropTypes.func,
  index: PropTypes.number,
  manageExcelTemplates: PropTypes.array,
  map: PropTypes.func,
  templateMetrics: PropTypes.array,
  deletedArray: PropTypes.array,
  saveTabs: PropTypes.func,
  setTabs: PropTypes.func,
  saveTabsSuccessed: PropTypes.any,
  setTabValue: PropTypes.func,
  data: PropTypes.array,
  errorMessage: PropTypes.string,
  isdownloadExcelPopup: PropTypes.bool,
  tabData: PropTypes.bool,
  metricContents: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(manageExcelTemplateActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelTemplateContent);
