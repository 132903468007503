import React from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Grid,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import A_TextField from "../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import MainQuestionnaire from "./MainQuestionnaire";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as esgActions from "../../../../../../store/actions/esgActions";
import A_DatePicker from "../../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import { esgBodyCss } from "../Helper/Esg.css";
import A_List from "../../../../Atoms/DataDisplay/Lists/A_List";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Autocomplete from "../../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import { handleQuestionnaireUpdate } from "../Helper/EsgHelper.api";
import handleEmailUsers from "../Helper/EsgHelper.api";
import { message } from "../../../../../../Utils/config/messages";
const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(esgBodyCss);

function EsgQuestionnaireBody(props) {
  const [tempVal, settempVal] = useState("");
  const [isTabDelete, setIsTabDelete] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const { crmId } = props;
  let editData =
    props.esgRecord &&
    props.esgRecord.data &&
    Array.isArray(props.esgRecord.data) &&
    props.esgRecord.data.filter((ques) => ques.id == crmId);
  const classes = useStyles();
  const [success, setSucces] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [showTextField, setShowTextField] = React.useState(false);
  const [userList, setUserList] = React.useState(
    crmId
      ? editData.length > 0
        ? editData[0].assignedUsers && Array.isArray(editData[0].assignedUsers)
          ? editData[0].assignedUsers.map((item) => ({
              primaryText: item.emailId,
              icon: "User",
            }))
          : []
        : []
      : []
  );
  const closeDeleteBox = () => {
    setIsTabDelete(false);
  };
  const defaultSection = {
    title: "Questionnaire",
    questions: [
      {
        title: "",
        questionWeight: 4,
        answerType: "",
        textLimit: 0,
        answerOptions: [],
      },
    ],
  };
  const [tabNames, setTabNames] = React.useState(
    crmId
      ? editData.length > 0
        ? editData[0].sections
        : []
      : [{ ...defaultSection }]
  );

  const [showSaveBox, setShowSaveBox] = React.useState(false);
  const [initdate,setinitdate] = useState("")
  const [showTextLimit, setShowTextLimit] = React.useState(false);
  const [finalSave, setFinalSave] = React.useState(
    crmId ? (editData.length > 0 ? editData[0] : null) : null
  );
  const [sectionInd, setSectionInd] = React.useState(null);
  const [lookupData, setLookupData] = React.useState(
    crmId ? (editData.length > 0 ? editData[0].assignedUsers : []) : []
  );

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const onClickPostAdd = () => {
    setShowTextField(true);
  };
  const handleBlurEvent = (e) => {
    setShowTextField(false);
    if (e.target.value) {
      let arrLength = tabNames.length + 1;
      const newSection = { ...defaultSection };
      newSection.title = e.target.value;
      setTabNames([...tabNames, newSection]);
      let tempFinalData = { ...finalSave };
      tempFinalData["sections"] = [...tabNames, newSection];
      setFinalSave(tempFinalData);
      setValue(arrLength - 1);
    } else {
      let tempFinalData = [...finalSave];
      tempFinalData[sections] = [...tabNames];
      setFinalSave(tempFinalData);
      setTabNames([...tabNames]);
    }
  };
  const handleTabTextfield = (e, index) => {
    var newTabNames = [...tabNames];
    newTabNames[index]["title"] = e.target.value;
    setTabNames(newTabNames);
    setSectionInd(null);
    setValue(index);
  };
  const handleTabEdit = (e, id) => {
    if (e.key === "Enter") {
      handleTabTextfield(e, id);
    }
  };
  const handleKeyDownAddTextBox = (e, id) => {
    if (e.key === "Enter") {
      if (!id) {
        handleBlurEvent(e);
      } else {
        handleEditedBlurEvent(e, id);
      }
    }
  };
  const handleDoubleClick = (tab,ind) => {
    settempVal(tab[ind].title);
    setSectionInd(ind);
  };
  const handleDeleteTab = (e, id) => {
    setIsTabDelete(true);
    if (id == 0) {
      setShowTextField(false);
    } else {
      let deleteTab = tabNames.filter((item) => item.id == id);
      tabNames.pop(deleteTab);
    }
  };
  const handlequestions = (
    sectionInd,
    questionInd,
    val,
    del = false,
    showText = false
  ) => {
    setShowTextLimit(showText);
    let questionObj = [...tabNames];
    if (questionInd == null) {
      questionObj[sectionInd].questions.push(val);
    } else {
      if (del) {
        questionObj[sectionInd].questions = questionObj[
          sectionInd
        ].questions.filter((item, index) => index != questionInd);
      } else {
        questionObj[sectionInd].questions[questionInd] = val;
      }
    }

    setTabNames(questionObj);
  };
  const handleQuestionnaireSave = () => {
    setShowSaveBox(true);
  };
  let handlePopup = () => {
    let data = !showSaveBox;
    setShowSaveBox(data);
  };
  const handleQuestionnaireSavee = () => {
    let questionObj = { ...finalSave };
    questionObj["sections"] = [...tabNames];
    questionObj["assignedUsers"] = [...lookupData];
    setFinalSave(questionObj);
    if (crmId) {
      handleQuestionnaireUpdate(questionObj);
      let emailString = lookupData.map((user) => user.emailId).join(";");
      let currentUser = props.user;
      handleEmailUsers(emailString, currentUser, crmId);
    } else {
      let emailString = lookupData.map((user) => user.emailId).join(";");
      let currentUser = props.user;
      props.insertEsgRecord({ questionObj, emailString, currentUser });
    }
    setShowSaveBox(false);
    setSucces(true);
  };
  const handleClose = () => {
    setSucces(false);
  };
  const handleQuesName = (e) => {
    let questionObj =
      finalSave !== null
        ? { ...finalSave }
        : { surveyBaseInfo: {}, sections: {} };
    questionObj["surveyBaseInfo"] = questionObj["surveyBaseInfo"]
      ? questionObj["surveyBaseInfo"]
      : {};
    questionObj["surveyBaseInfo"]["name"] = e.target.value;
    questionObj["sections"] = [...tabNames];

    setFinalSave(questionObj);
  };
  const handleDateChange = (date, id) => {
    setinitdate(date);
    let questionObj =
      finalSave !== null
        ? { ...finalSave }
        : { surveyBaseInfo: {}, sections: {} };
    questionObj["surveyBaseInfo"] = questionObj["surveyBaseInfo"]
      ? questionObj["surveyBaseInfo"]
      : {};
    questionObj["surveyBaseInfo"]["expiryDate"] = date;
    questionObj["sections"] = [...tabNames];
    setFinalSave(questionObj);
  };
  const handleLookup = (selectedValue, id) => {
    let newUserList = [...userList];
    newUserList.push({
      primaryText:
        selectedValue.data && selectedValue.data.title
          ? selectedValue.data.title
          : "",
      icon: "User",
    });
    setUserList(newUserList);
    let newLookupData = [...lookupData];
    newLookupData.push({
      crmId: selectedValue.data.id,
      emailId: selectedValue.data.email,
    });
    setLookupData(newLookupData);
  };

  const SaveBox = () => {
    return (
      <div>
        <div className={classes.mainDiv}>
          <A_TextField
            fullWidth={true}
            label="Enter Questionnaire Name"
            className={classes.textField1}
            defaultValue={
              finalSave &&
              finalSave.surveyBaseInfo &&
              finalSave.surveyBaseInfo.name
                ? finalSave.surveyBaseInfo.name
                : ""
            }
            onChange={(e) => handleQuesName(e)}
          />
        </div>
        <div className={classes.mainDiv}>
          <A_DatePicker
            id="singleYear"
            defaultValue={initdate}
            className={classes.datePickerCss}
            onChange={handleDateChange}
            label="Expiry Date"
          />
        </div>
        <div className={classes.saveButtonCss}>
          <A_Button
            label="Send"
            color="primary"
            onClick={handleQuestionnaireSavee}
            disabled={props.disabled}
          />
        </div>
      </div>
    );
  };
  return (
    <Grid container>
      <Grid item xs="9">
        <Card className={classes.cardCss}>
          {props.esgRecord.isLoading ? (
            <div className={classes.skeleton}>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </div>
          ) : (
            <>
              <AppBar
                position="static"
                color="default"
                className={classes.appbar}
              >
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  {tabNames && tabNames.length > 0
                    ? tabNames.map((item, index) => {
                        return sectionInd == index ? (
                          <>
                          <A_TextField
                            label="Enter Tab Name"
                            defaultValue={tempVal}
                            onBlur={(e) => handleTabTextfield(e, index)}
                            onKeyPress={(e) => handleTabEdit(e, index)}
                            focus={true}
                          />
                          <ClearIcon
                        className={classes.postAddIcon}
                      />
                      </>
                        ) : (
                          <CustomTab
                            key={index}
                            onClick={(e) => {
                              handleChange(index);
                            }}
                            onDoubleClick={() => handleDoubleClick(tabNames,index)}
                            label={item && item.title ? item.title : ""}
                            {...a11yProps(index)}
                          />
                        );
                      })
                    : ""}

                  {showTextField ? (
                    <>
                      <A_TextField
                        label="Enter Tab Name"
                        defaultValue=""
                        onBlur={(e) => handleBlurEvent(e)}
                        onKeyPress={handleKeyDownAddTextBox}
                        focus={true}
                      />
                      <ClearIcon
                        className={classes.postAddIcon}
                        onClick={(e) => handleDeleteTab(e, item.id)}
                      />
                    </>
                  ) : (
                    <>
                      <AddCircleOutlineIcon
                        className={classes.postAddIcon}
                        onClick={onClickPostAdd}
                      />
                    </>
                  )}
                </Tabs>
              </AppBar>
              <CardContent>
                {Array.isArray(tabNames) &&
                  tabNames.map((section, sectionInd) => (
                    <TabPanel value={value} index={sectionInd}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell width="40%">Questions</TableCell>
                            <TableCell width="30%">Comments</TableCell>
                            <TableCell width="10%">Scale</TableCell>
                            <TableCell width="10%"></TableCell>
                            <TableCell width="10%"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {section &&
                          section.questions &&
                          Array.isArray(section.questions)
                            ? section.questions.map(
                                (questionStructure, questionInd) => (
                                  <MainQuestionnaire
                                    questionStructure={questionStructure}
                                    handlequestions={handlequestions}
                                    sectionId={sectionInd}
                                    quesId={questionInd}
                                    key={questionInd}
                                  />
                                )
                              )
                            : ""}
                        </TableBody>
                      </Table>

                      <div className={classes.button}>
                        <A_Button
                          label="Save"
                          color="primary"
                          onClick={handleQuestionnaireSave}
                          disabled={props.disabled}
                        />
                      </div>
                    </TabPanel>
                  ))}
              </CardContent>
            </>
          )}
        </Card>
        {showSaveBox == true ? (
          <A_SimpleDialog
            open={showSaveBox}
            title="New"
            fullScreen={false}
            maxWidth={"xs"}
            className={classes.dialogBoxHeight}
            onClose={handlePopup}
            closePopUp={handlePopup}
            dialogContent={SaveBox()}
          />
        ) : (
          ""
        )}
        <A_Snackbar
          open={success}
          message={"Questionnaire successfully saved"}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleClose}
        />
      </Grid>
      <Grid item xs={3}>
        <Card className={classes.autocomplete} style={{height:"100%"}}>
          <CardHeader
            title={
              <A_Autocomplete
                className={classes.lookup}
                label="Add Users"
                size="medium"
                entity={{}}
                onChange={handleLookup}
                fullWidth={true}
                id="assignedUsers"
                resultTemplate={"2239df2d-751c-4bd2-bef5-85c339d29549"}
                destinationVertex={"contact"}
                filters={{ isMinervaUser: true }}
              />
            }
          ></CardHeader>
          <CardContent >
            <div className={classes.list} style={{overflow:"scroll",wordBreak:"break-word"}}>
              <A_List
                className={classes.font}
                listItems={userList}
                onClose={props.handleDrawer}
              />
              <A_Divider />
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
function mapStateToProps(state) {
  return {
    esgRecord: state.esgRecord,
    entityRecord: state.entityRecord,
    user: state.oidc.user.profile.email,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(esgActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsgQuestionnaireBody);
