/* eslint-disable  no-unused-vars */
import React from "react";
import { showField } from "../../../../../Utils/Helpers/common";
import _ from "lodash";
import {budgetLinecalculations} from './helperTexts';
export const getRemaining = (
  { Total, [budgetLinecalculations.SPEND]: SpendInvoiced },
  val
) => {
  return (
    (parseInt(Total) || 0) -
    ((parseInt(SpendInvoiced) || 0) + (parseInt(val) || 0))
  );
};
// TODO -- Variable rowIndex and index are unused here but need to analysis its impact first for remove these, for now use eslint disable.
export const getOnBlurData = (e, row, conf, key, { rowIndex }, data) => {
  const value = e.target.value;
  const totalKey = budgetLinecalculations.THISINVOICE;
  let totalCost = 0;
  const finalArray = [];
  _.cloneDeep(data).forEach((item, index) => {
    if (JSON.stringify(item) === JSON.stringify(row)) {
      totalCost = totalCost + (parseFloat(value) || 0);

      const Remaining = getRemaining(item, value);
      finalArray.push(
        _.cloneDeep({
          ...item,
          [key]: value,
          [budgetLinecalculations.REMAININGBUDGET]:
            Remaining < 0
              ? `<span style= "background-color: coral; padding:2px 5px">\n${Remaining}\n</span>`
              : Remaining,
        })
      );
    } else if (item.counterParty === "Total") {
      const Remaining = getRemaining(item, totalCost);

      finalArray.push(
        _.cloneDeep({
          ...item,
          [totalKey]: totalCost,
          [budgetLinecalculations.REMAININGBUDGET]:
            Remaining < 0
              ? `<span style= "background-color: coral; padding:2px 5px">\n${Remaining}\n</span>`
              : Remaining,
          remainingNum: Remaining,
        })
      );
    } else {
      totalCost = totalCost + (parseFloat(item[totalKey]) || 0);

      finalArray.push(_.cloneDeep({ ...item }));
    }
  });
  return finalArray;
};

export const isLinkTypeData = (row, configurations, key) => {
  if (
    configurations &&
    configurations.columnConfig &&
    configurations.columnConfig[key].pageUrl &&
    configurations.columnConfig[key].crmId
  ) {
    let crmId = row[configurations.columnConfig[key].crmId];
    let pageUrl = configurations.columnConfig[key].pageUrl;
    let templateId = configurations.columnConfig[key].templateId
      ? configurations.columnConfig[key].templateId
      : "";
    let url = `${pageUrl}/${crmId}/${templateId}`;
    return <a href={url}>{row[key]} </a>;
  } else {
    return (
      <a href={row[configurations.columnConfig[key].linkName]}>{row[key]} </a>
    );
  }
};
export const ShowHide = (data, rowData, status) => {
  try {
    if (status) {
      let showCondition =
        data && data.action && data.action.show ? data.action.show : "";
      let newRowData;
      let addNewRow = [];
      newRowData = Object.entries(rowData);
      addNewRow = newRowData.map(([key, value]) => {
        var rowOutput;
        if (!Number.isInteger(value)) {
          rowOutput = value
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/  +/g, "");
        } else {
          rowOutput = value;
        }
        return [key, rowOutput];
      });
      let finalRowData = Object.fromEntries(addNewRow);
      return showField(showCondition, finalRowData);
    } else {
      return true;
    }
  } catch {
    return true;
  }
};
