import * as React from "react";
import * as helpers from "../../../../../Utils/Helpers/chartHelpers";
import propTypes from "prop-types";
function executeJS(obj) {
  let jsData = Function(
    " return (function(configs,chartData,helpers){let newConfig=configs; " +
      obj +
      " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return chartData; })"
  )();
  return jsData;
}
const IsJsonString = (str) => {
  let config;
  try {
    config = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return config;
};
export default function O_Pdfchart(props) {
  const contentRef = React.useRef();
  let chartData = {};
  try {
    chartData =
      props.chart && props.chart.data
        ? JSON.parse(JSON.stringify(props.chart.data))
        : {};
  } catch (error) {}
  const [configurations, setConfigurations] = React.useState(
    props.chart &&
      props.chart.chartConfiguration &&
      props.chart.chartConfiguration.configuration
      ? IsJsonString(props.chart.chartConfiguration.configuration)
      : {}
  );
  const getHTMLData = () => {
    let templateData = "";
    templateData = executeJS(props.chart.chartConfiguration.javaScript)(
      configurations,
      chartData,
      helpers
    );
    if (typeof templateData === "string") {
      const fragment = document.createRange().createContextualFragment(
        `<div>${templateData}</div>`
      );
      if (contentRef.current) {
        contentRef.current.appendChild(fragment);
      }
    } 
  };
  React.useEffect(() => {
    getHTMLData();
  }, []);
  return (
    <>
      <div ref={contentRef} />
    </>
  );
}
O_Pdfchart.propTypes = {
  chart: propTypes.object,
};
