export const genericListEnums={
    TITLE:"title",
    DOCUMENT:"document",
    ID:"id",
    CONTAINER:"container",
    CONTENT_GENERIC_PAYLOAD:"content.genericPayloads",
    TEMPLATE_JSON:"template.templateJson",
    SORT_BY:"sortby",
    MEETING: 'meeting', 
    COMPANY: 'company', 
    EMAIL: 'email',
    DATA:"Data",
    HEADING:"Heading",
    SUBHEADING:"Subheading",
}


