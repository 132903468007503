import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import * as esgApi from "../ESG_API/index";
import "../ESG.css";
import emitter from "./Emitter";
import {SurveyStatusMap} from "../ESG_Config";

const SurveyFilterYear = (props) => {
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const {
    selectedSurveyId,
    setSelectedSurveyId,
    setSurveyFilterYear,
    publishedtoggle,
    surveyClosetoggle,
    publishedAndClosetoggle,
    heading
  } = props;
  const [survey, setSurvey] = useState([]);
  const handleChange = (event) => {
    localStorage.setItem("FilterSurveyYear", event.target.value);
    setSurveyFilterYear(event.target.value);
    setSelectedSurvey(event.target.value);
    emitter.emit("dataEvent", event.target.value);
  };

  useEffect(() => {
    localStorage.removeItem("Survey");
  }, []);
  //Remove Dulpicate Year
  const removeDuplicateYears = (survey) => {
    let uniqueYears = {};
    let uniqueSurvey = survey.filter((item) => {
      if (!uniqueYears[item.year]) {
        uniqueYears[item.year] = true;
        return true;
      }
      return false;
    });
    uniqueSurvey.sort((a, b) => b.year - a.year);
    return uniqueSurvey;
  };

  //Get Question Answer Type
  const getAllSurvey = async () => {
    let response = await esgApi.getSurvey();
    if (publishedtoggle) {
      response =
        response &&
        response.filter((item) => {
          return item.status == 1;
        });
      }
      if(surveyClosetoggle){
        response =
        response &&
        response.filter((item) => {
          return (item.status == SurveyStatusMap.Closed || item.status == SurveyStatusMap.ScoreFreeze);
        });
      }
      
    if(publishedAndClosetoggle){
      response = response.filter((survey) => {
        return (survey.status == 1 || survey.status == 2);
      });
    }
    setSurvey(response);
    setSelectedSurvey(response.length > 0 ? response[0].year : "");
    setSelectedSurveyId(response.length > 0 ? response[0].surveyId : "");
    setSurveyFilterYear(response.length > 0 ? response[0].year : "");
  };
  useEffect(() => {
    getAllSurvey();
  }, []);
  useEffect(() => {
    if (selectedSurveyId) {
      localStorage.setItem("Survey", JSON.stringify(selectedSurveyId));
    }
  }, [selectedSurveyId]);

  let uniqueSurvey = removeDuplicateYears(survey);
  return (
    <>{ heading &&
     <div style={{
        position: "fixed",
        top: "90px",
        left: "269px",
        zIndex: "2",
        fontSize:"14px",
        color:"white",
        fontWeight:"600"
      }}>{heading}</div> }
    <div
      style={{
        position: "fixed",
        top: "106px",
        left: "267px",
        zIndex: "2",
        backgroundColor: "#ffff",
        width: "151px",
        height: "40px",
        borderRadius: "4px",
      }}
    >
      <FormControl>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={selectedSurvey}
          onChange={handleChange}
          style={{ width: "125px", marginTop: "2px", paddingLeft: "14px" }}
          disableUnderline
        >
          {uniqueSurvey &&
            uniqueSurvey.map((item) => {
              return (
                <MenuItem value={item.year} key={item.surveyId}>
                  {item.year}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
    </>
  );
};
SurveyFilterYear.propTypes = {
  selectedSurveyId: PropTypes.string,
  setSelectedSurveyId: PropTypes.func,
  setSurveyFilterYear: PropTypes.string,
  filtersurveyyear: PropTypes.func,
  publishedtoggle: PropTypes.any,
  publishedAndClosetoggle: PropTypes.any,
  surveyClosetoggle:PropTypes.any
};
export default SurveyFilterYear;
