/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import M_MultiValueList from "../../../Molecules/Common/DealAdministartion/M_MultiValueList";
import M_IRCdetail from "../../../Molecules/Common/DealAdministartion/M_IRCdetail";
import { Divider } from "@material-ui/core";
import { useParams } from "react-router";
import * as httpCall from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import defLogo from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
import M_ListDetails from "../../../Molecules/Common/DealAdministartion/M_ListDetails";
import M_ListDetailCard from "../../../Molecules/Common/DealAdministartion/M_ListDetailCard";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";
const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
});
export default function O_IRCDetails(props) {
  const classes = useStyles();
  const { crmId, pageUrl } = useParams();
  const [data, setData] = useState([]);
  const [selectedIRC, setSelectedIRC] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [selectedRecord , setSelectedRecord] = useState({})
  const [change, setChange] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleID = (record) => {
    setSelectedIRC(record && record.id ? record.id : "");
    props.callBack(record && record.id ? record.id : "");
    setSelectedRecord(record)
  };
  const handleSelect = (id, ind) => {
    return selectedIRC ? id == selectedIRC : ind == 0;
  };
  let getData = async () => {
    if (data.length == 0) {
      setIsLoading(true);
    }

    let newData = [];
    let payload =
      props.content && props.content.payload ? props.content.payload : "";

    if (
      payload &&
      payload.request &&
      payload.request.sourceId &&
      payload.request.sourceId != ""
    ) {
      payload.request.sourceId = crmId;
    }
    let response = await httpCall.httpPost(`/IRC/getIRCRecord`, payload);

    setIsLoading(false);
    if (!response && response.length == 0) {
      setData([]);
      return;
    }
    const newDate = response.sort(function (a, b) {
      return new Date(b.ircDate) - new Date(a.ircDate);
    });
    for (let i in newDate) {
      if (response[i].id) {
        if (selectedIRC == undefined && i == 0) {
          setSelectedIRC(response[i].id);
          props.callBack(response[i].id);
        }
      }
    }
    setSelectedData(response);
    setData(response);
  };
  const handleDelete = async (id) => {
    let url = `/CRMData/delete?pageUrl=${pageUrl}&crmId=${id}&softDelete=true`;
    let response = await httpCall.httpDelete(url);
    setChange(!change);
    if (response == true) {
      await httpCall.httpDelete(
        `/DealChecklist/Delete?DealId=${crmId}&IRCid=${id}`
      );
    }

    return response;
  };
  useEffect(() => {
    getData();
  }, [change]);

  return (
    <>
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}

      <Box display={"flex"}>
        {!isLoading ? (
          data.length > 0 ? (
            <>
              {props.content && props.content.displayIRCList ? (
                <Box
                  width={"40%"}
                  borderRight={"2px solid #f0f0f0"}
                  style={{ maxHeight: "50vh", overflow: "auto" }}
                >
                  {props.content &&
                    props.content.templatePayloads &&
                    Array.isArray(
                      props.content && props.content.templatePayloads
                    ) &&
                    props.content.templatePayloads.map((payload) => (
                      <M_ResultCard
                        height="auto"
                        content={payload}
                        stateObj={false}
                        onSelect={handleID}
                        selectedId={selectedIRC}
                      />
                    ))}
                </Box>
              ) : (
                ""
              )}

              <Box
                width={
                  props.content && props.content.displayIRCList ? "60%" : "100%"
                }
                style={{ maxHeight: "50vh", overflow: "auto" }}
              >
                <M_ListDetails
                  type="irc"
                  id={selectedIRC}
                  ircData={selectedData}
                  resPayload={
                    props.content && props.content.resPayload
                      ? props.content.resPayload
                      : ""
                  }
                  config={
                    props.content && props.content.ircTabs
                      ? props.content.ircTabs
                      : []
                  }
                  content={props.content}
                  record={selectedRecord}
                />
              </Box>
            </>
          ) : (
            <div className={classes.noData}>
              <p>WE ARE SORRY, NO CONTENT FOUND!</p>
            </div>
          )
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
