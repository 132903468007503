 /* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ThemeProvider } from "@material-ui/styles";
import theme from "../../../../Themes/defaultTheme";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const  A_Accordion=(props) =>{
  const classes = useStyles();
  let accordions = props.accordions.map((accordion) => (
    <Accordion key ={accordion.summary} defaultExpanded={accordion.defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{accordion.summary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {accordion.detail}
          </Typography>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          {props.actions}
        </AccordionActions>
      </Accordion>
        
    ));
  return (
    <ThemeProvider theme={theme}>
        <div className={classes.root}>
            {accordions}
            
        </div>
    </ThemeProvider>
    
  );
  
}
A_Accordion.defaultProps = {
accordions:[
    
],
actions: []
};

export default A_Accordion;