import { manageToolbarSelectionsActionType } from "../actions/actionTypes";

let initialState = {
  filters: [],
  filterLength: 0,
};

const manageToolbarSelectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case manageToolbarSelectionsActionType.ADD_FILTER:
      return { ...state, filters: [...action.payload]};
    case manageToolbarSelectionsActionType.RESET_FILTERS:
      return {...state, filters: [] };
    case manageToolbarSelectionsActionType.TOOLBAR_FILTER_LENGTH:
      return { ...state, filterLength:action.payload};
    default:
      return state;
  }
};

export default manageToolbarSelectionsReducer;
