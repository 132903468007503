import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import theme from "../../../../Themes/defaultTheme";
import Skeleton from "@material-ui/lab/Skeleton";
import { Alert } from "@material-ui/lab";
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../store/actions/toastActions";
import { message } from "../../../../../Utils/config/messages";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { httpGet, httpPut, httpPost } from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import DoneIcon from "@material-ui/icons/Done";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "5rem",
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  customTable: {
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "fixed",
    "& th, & td": {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "left",
      tableLayout: "fixed",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
    "& th": {
      backgroundColor: "#f2f2f2",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "1rem",
    },
  },
  button: {
    marginBottom: 5,
    textAlign: "right ",
  },
  linearProgress: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
  triggerMessage: {
    backgroundColor: "#337ab7",
    marginTop: "11px",
    color: "#fff",
    padding: "10px",
    textAlign: "center",
  },
}));

const ESG_DD = (props) => {
  const { content } = props;
  let { crmId } = useParams();
  const classes = useStyles();
  const { crmContactUserId, crmContactUserEmail } = getCRMContactInfo();
  const [loading, setLoading] = React.useState(true);
  const [level, setLevel] = React.useState(0);
  const [chartData, setChartData] = React.useState([]);
  const [isEligible, setIsEligible] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [isDisable, setIsDisable] = React.useState(false);
  const [infoMessage, setInfoMessage] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [secondButtonLoading, setSecondButtonLoading] = React.useState(false);
  let title;
  if (content && content.item && content.item.header) {
    title = content.item.header.title;
  }
  const firstApprovalKey =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.firstApprovalKey
      ? content.item.content.content.firstApprovalKey
      : "";
  const firstApprovalApiKey =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.firstApprovalApiKey
      ? content.item.content.content.firstApprovalApiKey
      : message.ESG_DD_FIRST_APPROVAL;
  const invoiceApprovalConfig =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.invoiceApprovalConfig
      ? content.item.content.content.invoiceApprovalConfig
      : "";
  let ApprovalMessage =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.ApprovalMessage
      ? content.item.content.content.ApprovalMessage
      : message.PLEASE_WAIT;
  let showSecondButton =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.showSecondButton
      ? content.item.content.content.showSecondButton
      : false;
  let buttonsLabels =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.buttonsLabels
      ? content.item.content.content.buttonsLabels
      : "PDF";
  let downloadfileName =
    content &&
    content.item &&
    content.item.content &&
    content.item.content.content &&
    content.item.content.content.downloadfileName
      ? content.item.content.content.downloadfileName
      : "invoice.pdf";
  const CurrentPageData =
    props && props.currentPage && props.currentPage.data
      ? props.currentPage.data
      : [];

  useEffect(() => {
    getApprovalsData();
  }, []);

  const handleOnChange = (event) => {
    setComment(event.target.value);
  };

  const getApprovalsData = async () => {
    try {
      setLoading(true);
      let urlGetApproverRecords = `${process.env.API_URL}/ESGDDSubmission/getApproverRecords?invoiceId=${crmId}&invoiceApprovalConfig=${invoiceApprovalConfig}`;
      let resultDataValue = await httpGet(urlGetApproverRecords);
      const currentLevel =
        resultDataValue.length > 0 &&
        resultDataValue &&
        resultDataValue.findIndex((object) => {
          return object.Approved === null || object.Approved != true;
        });
      if (
        resultDataValue.response &&
        resultDataValue.response.data &&
        resultDataValue.response.data.StatusCode == 400 &&
        resultDataValue.response.data.Message == firstApprovalApiKey
      ) {
        let UserID = CurrentPageData[firstApprovalKey];
        UserID = UserID && JSON.parse(UserID)[0].id;
        if (crmContactUserId == UserID) {
          setIsEligible(true);
          setLoading(false);
          setLevel(0);
          return;
        }
      }

      if (
        resultDataValue &&
        resultDataValue[currentLevel] &&
        resultDataValue[currentLevel].Email == crmContactUserEmail
      ) {
        setChartData(
          resultDataValue &&
            Array.isArray(resultDataValue) &&
            resultDataValue.length > 0
            ? resultDataValue
            : []
        );
        setIsEligible(true);
        setLoading(false);
        setLevel(currentLevel);
        return;
      }
      let isEmailFound = false;
      if (resultDataValue.length > 0) {
        for (const item of resultDataValue) {
          if (item.Email === crmContactUserEmail) {
            isEmailFound = true;
            break;
          }
        }
      }
      if (isEmailFound) {
        setChartData(
          resultDataValue &&
            Array.isArray(resultDataValue) &&
            resultDataValue.length > 0
            ? resultDataValue
            : []
        );
        setLoading(false);
        setIsEligible(false);
        setLevel(currentLevel);
        return;
      }
      setChartData([]);
      setIsEligible(false);
      setLoading(false);
      setLevel(currentLevel ? currentLevel : 0);
    } catch {
      setChartData([]);
      setIsEligible(false);
      setLoading(false);
      setLevel(0);
    }
  };
  const SubmitOrReject = async (type) => {
    try {
      if (type != "reject") {
        setInfoMessage(true);
      }
      const payload = {
        comments: comment,
        invoiceId: crmId,
        status: type == "reject" ? false : true,
        stage: level + 1,
      };
      let res;
      let res1, res2;
      if (invoiceApprovalConfig === "kyc-submission-config") {
        let urlForSubmit1 = `${process.env.API_URL}/ESGDDSubmission/UpdateESGSubmission?invoiceApprovalConfig=${invoiceApprovalConfig}`;
        let urlForSubmit2 = `${process.env.API_URL}/ESGDDSubmission/UpdateESGSubmission?invoiceApprovalConfig=idd-submission-config`;

        // Promise.all with await to call both APIs in parallel and wait for their responses
        [res1, res2] = await Promise.all([
          httpPut(urlForSubmit1, payload),
          httpPut(urlForSubmit2, payload),
        ]);
      } else {
        let urlForSubmit = `${process.env.API_URL}/ESGDDSubmission/UpdateESGSubmission?invoiceApprovalConfig=${invoiceApprovalConfig}`;
        res = await httpPut(urlForSubmit, payload);
      }

      if (
        (res && res.isAxiosError) ||
        (res1 && res1.isAxiosError) ||
        (res2 && res2.isAxiosError)
      ) {
        props.setToast({
          type: toastSeverity.ERROR,
          severity: toastSeverity.ERROR,
          message: message.SOMETHING_WENT_WRONG,
          status: true,
        });
      } else {
        props.setToast({
          type: toastSeverity.SUCCESS,
          severity: toastSeverity.SUCCESS,
          message: `${type !== "reject" ? "Approved" : "Rejected"}`,
          status: true,
        });
      }
      await getApprovalsData();
      setIsDisable(false);
      setComment("");
      if (type != "reject") {
        setInfoMessage(false);
      }
    } catch {
      props.setToast({
        type: toastSeverity.ERROR,
        severity: toastSeverity.ERROR,
        message: message.SOMETHING_WENT_WRONG,
        status: true,
      });
    }
  };

  const genericGremlinSP = async (edge = "") => {
    let url = `/GenericGremlin/generic/getSubmissionId`;
    let requestPayload = {
      "@crmId": crmId,
      "@edgeLabel": edge,
    };
    const edgeId = await httpPost(url, requestPayload);
    return edgeId && edgeId[0] && edgeId[0].id;
  };
  const handleExportMenu = async (edgeLabel) => {
    try {
      if (edgeLabel == "has_iddsubmission") {
        setSecondButtonLoading(true);
      } else {
        setButtonLoading(true);
      }
      let edgeID = await genericGremlinSP(edgeLabel);
      const url = `${process.env.DOCUMENT_HELPER}`;
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let fileName = `${edgeID}/invoice.pdf`;
      let header = {
        filename: fileName,
        type: "inboundinvoice",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        "dest-filename": downloadfileName,
      };
      const response = await httpPost(url, "", {
        responseType: "blob",
        headers: header,
      });
      const url1 = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", downloadfileName);
      document.body.appendChild(link);
      link.click();
      setButtonLoading(false);
      if (edgeLabel == "has_iddsubmission") {
        setSecondButtonLoading(false);
      }
    } catch {
      // intentialy added comment
      setButtonLoading(false);
      setSecondButtonLoading(false);
    }
  };

  const TableComponent = ({ chartData }) => {
    if (chartData.length === 0) return message.NO_DATA_FOUND;
    return (
      <>
        <table className={`${classes.customTable}`}>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Approved</th>
              <th>Date</th>
              <th>Comments</th>
              <th>Name</th>
              {/* <th>Email</th> */}
            </tr>
          </thead>
          <tbody>
            {chartData.map((item, index) => (
              <tr key={index}>
                <td>{item.Stage}</td>
                <td>
                  {item.Approved ? (
                    <CheckIcon style={{ color: "rgb(0 183 53)" }} />
                  ) : (
                    <ClearIcon style={{ color: "red" }} />
                  )}
                </td>
                <td>
                  {item.Date
                    ? (() => {
                        const date = new Date(item.Date * 1000);
                        const day = date.getDate().toString().padStart(2, "0");
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = date.getFullYear();
                        return `${day}/${month}/${year}`;
                      })()
                    : "-"}
                </td>
                <td>{item.Comments}</td>
                <td>{item.Name}</td>
                {/* <td>{item.Email}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderResult = chartData.length > 0 && (
    <TableComponent chartData={chartData} />
  );

  return (
    <ThemeProvider theme={theme}>
      <Card
        className={classes.root}
        style={{ overflow: "visible" }}
        elevation={2}
      >
        <Box className={classes.card}>
          <CardHeader
            style={{ width: "100%", height: "1rem" }}
            title={
              <React.Fragment>
                <Typography variant="h6" id="tableTitle" component="div">
                  {title}
                </Typography>
              </React.Fragment>
            }
            action={
              level != 0 &&
              !isDisable && (
                <>
                  <A_Button
                    label={
                      !buttonLoading
                        ? Array.isArray(buttonsLabels) && buttonsLabels[0]
                          ? buttonsLabels[0]
                          : buttonsLabels
                        : "DOWNLOADING..."
                    }
                    color="primary"
                    style={{ marginTop: "-12px" }}
                    onClick={() =>
                      handleExportMenu(
                        invoiceApprovalConfig == "kyc-submission-config"
                          ? "has_kycsubmission"
                          : "has_esgsubmission"
                      )
                    }
                    disabled={buttonLoading}
                    startIcon={<GetAppIcon />}
                    endIcon={
                      buttonLoading ? (
                        <A_CircularIndeternment color="secondary" size={20} />
                      ) : (
                        <></>
                      )
                    }
                  />
                  {showSecondButton && (
                    <A_Button
                      label={
                        !secondButtonLoading
                          ? Array.isArray(buttonsLabels) && buttonsLabels[1]
                            ? buttonsLabels[1]
                            : buttonsLabels
                          : "DOWNLOADING..."
                      }
                      color="primary"
                      //className={classes.button}
                      style={{ marginTop: "-12px" }}
                      onClick={() => handleExportMenu("has_iddsubmission")}
                      disabled={secondButtonLoading}
                      startIcon={<GetAppIcon />}
                      endIcon={
                        secondButtonLoading ? (
                          <A_CircularIndeternment color="secondary" size={20} />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </>
              )
            }
            classes={{
              title: classes.title,
            }}
          />
        </Box>
        <A_Divider />
        {isDisable && (
          <div className={classes.linerProgress}>
            <LinearProgress width="100%" />
          </div>
        )}
        {infoMessage && level == 0 && isEligible && (
          <div className={classes.triggerMessage}>
            {Array.isArray(ApprovalMessage) && ApprovalMessage[0]
              ? ApprovalMessage[0]
              : ApprovalMessage}
          </div>
        )}
        {infoMessage && level == 1 && isEligible && (
          <div className={classes.triggerMessage}>
            {Array.isArray(ApprovalMessage) && ApprovalMessage[1]
              ? ApprovalMessage[1]
              : ApprovalMessage}
          </div>
        )}
        {infoMessage && level == 2 && isEligible && (
          <div className={classes.triggerMessage}>
            {Array.isArray(ApprovalMessage) && ApprovalMessage[2]
              ? ApprovalMessage[2]
              : ApprovalMessage}
          </div>
        )}
        <CardContent
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {!loading && chartData.length > 0 && renderResult}
            {!loading &&
              !isEligible &&
              level != -1 &&
              chartData.length == 0 && (
                <div style={{ marginTop: "2rem" }}>
                  <Alert severity="info">{message.NOT_AUTHORIZED}</Alert>
                </div>
              )}
            {loading && (
              <>
                <Skeleton variant="text" width={"100%"} height={60} />
                <Skeleton variant="text" width={"100%"} height={80} />
                <Skeleton variant="rect" width={"100%"} height={118} />
              </>
            )}
            {!loading && !isEligible && level == -1 && (
              <div style={{ marginTop: "2rem" }}>
                <Alert severity="success">
                  {Array.isArray(ApprovalMessage) && ApprovalMessage[3]
                    ? ApprovalMessage[3]
                    : "Completed"}
                </Alert>
              </div>
            )}
          </div>

          {!loading && (
            <CardActions style={{ marginTop: "10px" }}>
              {isEligible ? (
                <>
                  <div style={{ maxWidth: "300px" }}>
                    <p>My Comments:</p>
                    <A_TextField
                      variant="standard"
                      inputType="value"
                      sx={{ width: 222 }}
                      id="standard-basic"
                      label="+Add"
                      value={comment || ""}
                      defaultValue={comment || ""}
                      fullWidth
                      onChange={handleOnChange}
                    />
                    <A_Button
                      label="Approve"
                      size={"large"}
                      style={{
                        marginTop: "10px",
                        textTransform: "none",
                        backgroundColor: `${isDisable ? "#EBEBE4" : "#16a34a"}`,
                        color: "white",
                      }}
                      disabled={isDisable}
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        setIsDisable(true);
                        SubmitOrReject("approve");
                      }}
                    />
                    {level != 0 && (
                      <A_Button
                        label="Reject"
                        size={"large"}
                        style={{
                          marginTop: "10px",
                          textTransform: "none",
                          backgroundColor: isDisable ? "#EBEBE4" : "#dc4c4c",
                          color: "white",
                        }}
                        disabled={isDisable}
                        startIcon={<ClearIcon style={{ color: "white" }} />}
                        onClick={() => {
                          setIsDisable(true);
                          SubmitOrReject("reject");
                        }}
                      />
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </CardActions>
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

ESG_DD.propTypes = {
  action: PropTypes.any,
  content: PropTypes.any,
  onSelect: PropTypes.func,
  setToast: PropTypes.func.isRequired,
  chartData: PropTypes.array,
  currentPage: PropTypes.any,
};

ESG_DD.defaultProps = {
  variant: "elevation",
  elevation: 3,
  header: { zoom: false },
  cardStyle: {
    root: {
      maxWidth: "100%",
      height: "100%",
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 0,
    },
  },
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ESG_DD);
