import React from "react";
import { onDownload } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import { Typography } from "@material-ui/core";
import "./M_Tabs.css";
import { emailPrefix } from "../../../Organisms/Common/IcEmail/email.helper";
export default function M_TabsDocument({ attachments }) {
  let files =
    attachments && typeof attachments === "string"
      ? attachments.split(",")
      : [];
  if (files.length == 0) {
    return <></>;
  }
  return (
    <div>
      <Typography className="document-head">Documents</Typography>
      {files.map((value, index) => {
        let path = value.split("/");
        let fileName = path[path.length - 1];
        return (
          <div key={index}>
            <Typography className="document-filename">
              <span
                className="document"
                onClick={() => {
                  onDownload(value.split(emailPrefix).pop(), "email");
                }}
              >
                {fileName}
              </span>
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
