import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
import grayHR from "./../../../../Assets/Images/Defaults/grayHR.png";
import bambooHr from "../../../../Assets/Images/Defaults/bambooHR.png";
import PISTime from "../../../../Assets/Images/Defaults/PISTime.jpg";
const useStyles = makeStyles({
  setListItemtitle: {
    marginLeft: "0",
  },
  spanPrimary: {
    fontWeight: "400",
  },
  Avatar: {
    width: "40",
    height: "30",
  },
});

function QuickLinks() {
  const classes = useStyles();
  const data = [
    {
      title: "GreytHR",
      link: "https://equipped.greythr.com",
      icon: grayHR,
    },
    {
      title: "BambooHR",
      link: "https://anacapfp.bamboohr.com/login.php?r=%2Fhome%2F",
      icon: bambooHr,
    },
    {
      title: "PISTime",
      link: "http://prod.pistime.com/ClockViewR5/LoginR5.aspx",
      icon: PISTime,
    },
  ];

  return (
    <>
      {data.map((data) => (
        <div key={data.icon}>
          <ListItem button onClick={data.link}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ListItemAvatar className={classes.setListItemAvatar}>
                  <Avatar
                    variant="square"
                    className={classes.Avatar}
                    src={data.icon}
                  />
                </ListItemAvatar>
              </Grid>

              <Grid item xs={8}>
                <ListItemText
                  className={classes.setListItemtitle}
                  primary={
                    <span className={classes.spanPrimary}>
                      <a
                        target="_blank"
                        href={data.link}
                        rel="noopener noreferrer"
                      >
                        {data.title}
                      </a>
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        </div>
      ))}
    </>
  );
}

export default QuickLinks;
