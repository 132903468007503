import { getIrcSlotActionTypes } from "../actions/actionTypes";

let initialState = {};
const getIrcSlotReducer = (state = initialState, action) => {
  switch (action.type) {
    case getIrcSlotActionTypes.GET_IRC_SLOT:
      return action.payload;
    case getIrcSlotActionTypes.GET_IRC_SLOT_SUCCEEDED:
      return action.payload;
    case getIrcSlotActionTypes.GET_IRC_SLOT_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default getIrcSlotReducer;
