const getGroupArr = (groups) => {
  let resArr = [];
  groups && groups.length > 0
    ? groups.reduce((previousValue, currentValue) => {
        currentValue && currentValue.span && parseInt(currentValue.span)
          ? resArr.push(previousValue + parseInt(currentValue.span))
          : null;
        return currentValue && currentValue.span && parseInt(currentValue.span)
          ? previousValue + parseInt(currentValue.span)
          : null;
      }, 0)
    : [];
  return resArr;
};

export default getGroupArr;
