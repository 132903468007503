import React from "react";
import { getComponentFromConfig } from "../../../Organisms/Common/GenericList/Helper/GenericList.Helper";
import propTypes from "prop-types";

export default function M_MultiListDetails(props) {
  return (
    <div>
      {getComponentFromConfig(
        props.data,
        null,
        props.content ? props.content : {},
        props.reload ? props.reload : {} 
      )}
    </div>
  );
}
M_MultiListDetails.propTypes = {
  data: propTypes.any,
  content: propTypes.any,
  reload: propTypes.func
};
