import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import "./O_GSTPerformance.scss";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import * as XLSX from "xlsx";
import A_SimpleSelect from "../../../Atoms/Inputs/Selects/A_SimpleSelect";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import * as common from "../../../../../Utils/Helpers/common";
import GetAppIcon from "@material-ui/icons/GetApp";
import { isInteger } from "lodash";

const useStyles = makeStyles((theme) => ({
  borderedCell: {
    padding: "8px",
    borderLeft: "1px solid #dbdbdb",
    borderRight: "1px solid #dbdbdb",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      textAlign: "right",
    },
  },
  redText: {
    color: "#FF0000",
  },
  greenText: {
    color: "#008000",
  },
  whiteColor: {
    color: "#fff",
    backdropFilter: "blur(10pc)",
  },
  borderedCell1: {
    textAlign: "right",
    paddingRight: "20px",
    borderLeft: "1px solid #dbdbdb",
    borderRight: "1px solid #dbdbdb",
  },
}));

export default function O_GSTPerformance() {
  const [pageData, setPageData] = React.useState({});
  const [filterValues, setFilterValue] = React.useState({});
  const [kpiData, setKpiData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [kpiDataFilter, setKpiDataFilter] = React.useState([]);
  const [Deltapct, setDeltaPct] = React.useState("");
  const [Icon, setIcon] = React.useState({
    base: "down",
    delta: "down",
    name: "down",
    new: "down",
  });

  let classes = useStyles();
  let tableRef = React.useRef(null);
  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};
  React.useEffect(() => {
    getPageData();
  }, []);
  React.useEffect(() => {
    const handleQuarterChange = async () => {
      setOpen(true);
      if (filterValues.RegionCode && filterValues.ScenarioId) {
        // Reset scenario and other data
        let tempValues = { ...filterValues };
        tempValues["ScenarioId"] = -1;
        setFilterValue(tempValues);
        setKpiData([]);
        setKpiDataFilter([]);
        await getScenario();
      } else {
        await getMetric();
      }
      setOpen(false);
    };
    handleQuarterChange();
  }, [filterValues.Quarter]);
  React.useEffect(() => {
    getMetric();
  }, [filterValues.ScenarioId]);
  React.useEffect(() => {
    getScenario();
  }, [filterValues.RegionCode]);

  const executeSPandGetData = async (details) => {
    setOpen(true);
    try {
      let spResponse = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [...details],
      });
      let tempPageData = { ...pageData };
      tempPageData = { ...tempPageData, ...spResponse };
      // if (tempPageData.Scenarios.length == 0) {
      //   setKpiData([]);
      // }
      setPageData(tempPageData);
      return spResponse;
    } catch (_) {
      setOpen(false);
      //log information if you want display in console
    }
  };
  const getPageData = async () => {
    await executeSPandGetData([
      {
        id: "Months",
        spName: "[JLForm].[Usp_GST_GetMonthParam]",
        parameters: {},
      },
      {
        id: "Regions",
        spName: "[JLForm].[Usp_GST_GetRegionParam]",
        parameters: {},
      },
      {
        id: "Scenarios",
        spName: "[JLForm].[Usp_GST_GetScenarioParam]",
        parameters: {},
      },
    ]);
    setOpen(false);
  };
  const getScenario = async () => {
    setOpen(true);
    const { RegionCode, Quarter } = filterValues;
    if (RegionCode && Quarter) {
      await executeSPandGetData([
        {
          id: "Scenarios",
          spName: "[JLForm].[Usp_GST_GetScenarioParam]",
          parameters: {
            ValuationMonth: Quarter,
            RegionCode,
          },
        },
      ]);
    }
    setOpen(false);
  };
  const getMetric = async () => {
    let quarterChanged = false;
    if (
      filterValues.Quarter &&
      !filterValues.RegionCode &&
      !filterValues.ScenarioId
    ) {
      quarterChanged = true;
    }
    setOpen(true);
    try {
      const { RegionCode, Quarter, ScenarioId } = filterValues;
      if (
        (RegionCode && Quarter && ScenarioId && ScenarioId != -1) ||
        quarterChanged
      ) {
        let name = "Metric";
        let metricResponse = await executeSPandGetData([
          {
            id: name,
            spName: "[JLForm].[Usp_GST_GetPerformanceMetric]",
            parameters: quarterChanged
              ? {
                  Quarter: filterValues.Quarter,
                  RegionCode: "",
                  ScenarioId: "",
                }
              : { ...filterValues },
          },
        ]);
        let metricResponseForDeltaPct = await executeSPandGetData([
          {
            id: name,
            spName: "[JLForm].[Usp_GST_GetPerformanceMetric]",
            parameters: quarterChanged
              ? {
                  Quarter: filterValues.Quarter,
                  RegionCode: "",
                  ScenarioId: "",
                  DeltaPct: 1,
                }
              : { ...filterValues, DeltaPct: 1 },
          },
        ]);
        setDeltaPct(
          metricResponseForDeltaPct && metricResponseForDeltaPct.Metric[0]
        );
        let baseValues = metricResponse[name].find(
          (item) => item["Scenario"] === "Base Scenario"
        );
        baseValues = ConvertValue(baseValues);
        let newValues = metricResponse[name].find(
          (item) => item["Scenario"] === "Stress Scenario"
        );
        newValues = ConvertValue(newValues);
        let deltaValues = metricResponse[name].find(
          (item) => item["Scenario"] === "Delta"
        );
        deltaValues = ConvertValue(deltaValues);
        let metric = Object.keys(metricResponse[name][0])
          .filter(
            (item) => !["Scenario", "AssetID", "ValuationMonth"].includes(item)
          )
          .map((item) => ({
            name: item,
            base: baseValues[item],
            new: newValues[item],
            delta: deltaValues[item],
          }));
        setKpiData(metric);
        setKpiDataFilter(metric);
      }
      setOpen(false);
    } catch (_) {
      setOpen(false);
      //log error
    }
  };

  const handleInputChange = (event) => {
    let searchInput = event.target.value;
    setSearchTerm(searchInput);
    const filteredData =
      kpiDataFilter.length >= 0 &&
      kpiDataFilter.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchInput.toLowerCase());
          }
          return false;
        });
      });
    setKpiData([...filteredData]);
  };
  const getFormattedData = (number) => {
    const roundedNumber = Math.abs(number).toFixed(2);
    const formattedNumber = parseFloat(roundedNumber).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    let data = `${"("}${formattedNumber}${")"}`;
    return data;
  };
  const ConvertValue = (obj) => {
    try {
      let newObj = {};
      Object.keys(obj).forEach(function (key) {
        switch (key) {
          case "WADR":
          case "ARR":
          case "ARR - to Val Date":
          case "Cash on Cash IRR (to Val Date)":
          case "IRR (HTM)":
          case "IRR (to Exit)":
          case "Committed IRR (to Val Date)":
          case "IRR (HTM) Committed":
          case "IRR (to Exit) Committed":
          case "Operational discount rate 1":
          case "Operational discount rate 2":
          case "Construction Premium":
          case "Ramp up":
          case "Director's Premium":
          case "Construction premium (start period 1)":
          case "Construction premium (start period 2)":
          case "Construction premium (start period 3)":
          case "Construction completion for period 2":
          case "Construction completion for period 3":
          case "Proportion of EBL held at par":
          case "Par value of EBL":
          case "IRR":
            newObj[key] = `${(obj[key] * 100).toFixed(2)}%`;
            break;
          case "NPV":
          case "NPV GBP":
          case "NPV Regional":
          case "NPV GBP 5% Power Price":
          case "NPV GBP 0.25% Inflation":
          case "MM":
          case "MM - to Val Date":
          case "Cash on Disposal":
            newObj[key] = `${
              obj[key] >= 0
                ? obj[key] == 0
                  ? obj[key]
                  : obj[key].toFixed(2)
                : getFormattedData(obj[key])
            }`;
            break;
          default:
            newObj[key] = obj[key];
        }
      });
      return newObj;
    } catch {
      return obj;
    }
  };
  //Unit Mapping
  const unitMapping = {
    npv: "USD(k)",
    "npv gbp": "GBP(k)",
    "npv regional": "USD(k)",
    "npv gbp 0.25% inflation": "GBP(k)",
    "npv gbp 5% power price": "GBP(k)",
    wadr: "%",
    arr: "%",
    "arr - to val date": "%",
    mm: "Rate",
    "mm - to val date": "Rate",
    "cash on disposal": "USD(k)",
    "cash on cash irr (to val date)": "%",
    "irr (htm)": "%",
    "irr (to exit)": "%",
    "committed irr (to val date)": "%",
    "irr (htm) committed": "%",
    "irr (to exit) committed": "%",
    "investment period": "Years",
    "operational discount rate 1": "%",
    "operational discount rate 2": "%",
    "construction premium": "%",
    "ramp up": "%",
    "director's premium": "%",
    "fx rate": "Rate",
    "op rate switch date": "Date",
    "construction start": "Date",
    "construction end": "Date",
    "construction premium (start period 1)": "%",
    "construction premium (start period 2)": "%",
    "construction premium (start period 3)": "%",
    "construction completion for period 2": "%",
    "construction completion for period 3": "%",
    "length of ramp up period": "Months",
    "proportion of ebl held at par": "%",
    "par value of ebl": "%",
    "jl financial close": "Date",
    "expected disposal date": "Date",
    "apply calculated adjustment": "Switch",
    "investment committed date": "Date",
    "final committed payment date": "Date",
    irr: "%",
    "min dscr": "Rate",
  };
  //Number contain Numeric Value
  const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

  //Checking number is percentage
  const isPercentage = (value) =>
    typeof value === "string" && value.trim().endsWith("%");

  //Checking number enclosing with paranthesis
  const isNumberInParentheses = (value) =>
    typeof value === "string" &&
    value.trim().startsWith("(") &&
    value.trim().endsWith(")");

  const parseNumber = (value) => {
    if (typeof value !== "string") return null;

    const cleanedValue = value.replace(/[^\d.-]/g, "");
    const parsedValue = parseFloat(cleanedValue);

    if (isNaN(parsedValue)) return null;

    // Check if the value represents a percentage
    if (value.includes("%")) {
      return parsedValue / 100; // Convert percentage to decimal
    }

    // Check if the value is in parentheses (negative number)
    if (value.includes("(") && value.includes(")")) {
      return `(${parsedValue})`;
    }

    return parsedValue;
  };
  const getValue = React.useMemo(() => {
    return (value, name) => {
      if (isNumeric(value) || isPercentage(value)) {
        const parsedValue = parseNumber(value);
        if (parsedValue !== null) {
          let formattedValue = parsedValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          if (value && isPercentage(value)) {
            if (parsedValue < 0) {
              // Format negative percentage in red with parentheses
              formattedValue = (
                <span style={{ color: "red" }}>
                  ({value.replace('-','')})
                </span>
              );
            } else {
              formattedValue = value;
            }
          } else if (value && isNumberInParentheses(value)) {
            // Format value in parentheses with red color
            formattedValue = (
              <span style={{ color: "red" }}>({formattedValue})</span>
            );
          }

          return formattedValue;
        }
      } else if (
        name === "Op rate switch date" ||
        name === "Construction start" ||
        name === "Construction End" ||
        name === "Construction end" ||
        name === "JL Financial close" ||
        name === "Expected disposal date" ||
        name === "Investment committed date" ||
        name === "Final committed payment date"
      ) {
        let formattedValue;
        if (value !== 0) {
          const dateParts = value.split("-");
          formattedValue = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        } else {
          formattedValue = "-";
        }
        return formattedValue;
      }

      if (value === 0) {
        return value;
      } else {
        return value ? value : "-";
      }
    };
  }, []);
  const exportToExcel = () => {
    let valueObj = pageData.Scenarios.filter(
      (item) => item.ScenarioId === filterValues.ScenarioId
    );
    const table = tableRef.current;
    const ws = XLSX.utils.table_to_sheet(table, { raw: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Performance-Metric");
    XLSX.writeFile(
      wb,
      "Performance-Metric_" +
        valueObj[0].ScenarioName +
        "_" +
        filterValues.RegionCode +
        ".xlsx"
    );
  };

  const getSortedRow = (id, data, dir) => {
    if (data.length > 0) {
      data.sort((a, b) => {
        let scenarioNameA;
        let scenarioNameB;
        if (dir == "down") {
          scenarioNameA = a[id].toLowerCase();
          scenarioNameB = b[id].toLowerCase();
        } else {
          scenarioNameA = b[id].toLowerCase();
          scenarioNameB = a[id].toLowerCase();
        }
        if (scenarioNameA < scenarioNameB) {
          return -1;
        }
        if (scenarioNameA > scenarioNameB) {
          return 1;
        }
        return 0;
      });
      let arrowDir = dir == "down" ? "up" : "down";
      let obj = { ...Icon, [id]: arrowDir };
      setIcon(obj);
      setKpiData([...data]);
    }
  };

  const DirectionArrow = (id, data, dir) => {
    return dir === "down" ? (
      <ArrowDownwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, data, "down")}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, data, "up")}
      />
    );
  };
  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: "4px",
          }}
        ></div>
        <div
          className="searchBarSec"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          {/* <h4 style={{}}>Scenario Details</h4> */}
          <span
            className="searchBarDiv"
            style={{ display: "flex", gap: "16px" }}
          >
            <A_SimpleSelect
              options={pageData.Months}
              optionLabel={"Month"}
              optionValue={"Month"}
              label={"Quarter"}
              onChange={(value) => {
                let tempValues = { ...filterValues };
                tempValues["Quarter"] = value;
                setFilterValue(tempValues);
              }}
              size="small"
            />
            <A_SimpleSelect
              optionLabel={"RegionCode"}
              optionValue={"RegionCode"}
              options={pageData.Regions}
              label={"Region"}
              onChange={(value) => {
                let tempValues = { ...filterValues };
                tempValues["RegionCode"] = value;
                tempValues["ScenarioId"] = -1;
                setFilterValue(tempValues);
                setKpiData([]);
                setKpiDataFilter([]);
              }}
              size="small"
            />
            {Object.hasOwn(filterValues, "Quarter") &&
              Object.hasOwn(filterValues, "RegionCode") &&
              pageData &&
              pageData.Scenarios &&
              pageData.Scenarios.length > 0 && (
                <A_SimpleSelect
                  optionLabel={"ScenarioName"}
                  optionValue={"ScenarioId"}
                  options={pageData.Scenarios}
                  defaultValue={filterValues.ScenarioId}
                  label={"Scenario"}
                  onChange={(value) => {
                    let tempValues = { ...filterValues };
                    tempValues["ScenarioId"] = value;
                    setFilterValue(tempValues);
                    setKpiData([]);
                    setKpiDataFilter([]);
                  }}
                  size="small"
                />
              )}
          </span>
          <div className="searchSec">
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleInputChange}
              adornment={{ position: "start", value: <SearchIcon /> }}
              size="small"
            />
            {kpiData.length > 0 && (
              <A_Button
                color="primary"
                onClick={exportToExcel}
                label={<GetAppIcon />}
                style={{ padding: "7px", marginLeft: "10px" }}
              />
            )}
          </div>
        </div>

        <Divider></Divider>
        <div
          className="perfromanceTable"
          style={{ overflow: "auto", maxHeight: "500px" }}
        >
          <Table ref={tableRef} aria-label="customized table" size="small">
            <TableHead
              style={{ padding: "10px", fontSize: "5px" }}
              className="stickyTable"
            >
              <TableRow>
                <TableCell
                  className={`${classes.borderedCell} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                  colSpan={2}
                >
                  Share
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                  align="center"
                  colSpan={4}
                >
                  JL (100%)
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                  align="center"
                  colSpan={5}
                >
                  KKR (50%)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={`${classes.borderedCell} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Metric
                  {DirectionArrow("name", kpiData, Icon["name"])}
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Unit
                </TableCell>

                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Base Case
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Stress Scenario
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Delta
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Delta %
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Base Case
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  New Scenario
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Delta
                </TableCell>
                <TableCell
                  className={`${classes.borderedCell1} ${classes.whiteColor}`}
                  style={{ backgroundColor: style.background }}
                >
                  Delta %
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kpiData.length > 0 ? (
                kpiData.map((kpi, index) => {
                  // Determine the color class for the delta cell based on whether kpi.delta is negative or positive
                  const isNegativeValue =
                    /^\(\d+\.\d+\)$/.test(kpi.delta) || /[()]/.test(kpi.delta);
                  const deltaCellDataColor = isNegativeValue
                    ? classes.redText
                    : classes.greenText;
                  const deltaCellDataColorDeltaPct =
                    Deltapct[kpi.name] < 0
                      ? classes.redText
                      : classes.greenText;
                  //Detemine the Unit as per Metric Name
                  const unit = unitMapping[kpi.name.toLowerCase()] || "";
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.borderedCell}>
                        {getValue(kpi.name)}
                      </TableCell>
                      <TableCell className={classes.borderedCell}>
                        {unit}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {unit !== "Months"
                          ? (kpi.name.includes("MM - to Val Date") ||
                              kpi.name.includes("MM") ||
                              kpi.name.includes("Min DSCR")) &&
                            isNumeric(getValue(kpi.base, kpi.name))
                            ? Number(getValue(kpi.base, kpi.name)).toFixed(1) +
                              "x"
                            : isInteger(getValue(kpi.base, kpi.name))
                            ? getValue(kpi.base, kpi.name).toFixed(2)
                            : getValue(kpi.base, kpi.name)
                          : getValue(kpi.base, kpi.name)}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {unit !== "Months"
                          ? (kpi.name.includes("MM - to Val Date") ||
                              kpi.name.includes("MM") ||
                              kpi.name.includes("Min DSCR")) &&
                            isNumeric(getValue(kpi.new, kpi.name))
                            ? Number(getValue(kpi.new, kpi.name)).toFixed(1) +
                              "x"
                            : isInteger(getValue(kpi.new, kpi.name))
                            ? getValue(kpi.new, kpi.name).toFixed(2)
                            : getValue(kpi.new, kpi.name)
                          : getValue(kpi.new, kpi.name)}
                      </TableCell>
                      <TableCell
                        className={`${classes.borderedCell1} ${deltaCellDataColor}`}
                      >
                        {unit !== "Date"
                          ? unit === "Months"
                            ? getValue(kpi.delta, kpi.name)
                            : isInteger(getValue(kpi.delta, kpi.name))
                            ? getValue(kpi.delta, kpi.name).toFixed(2)
                            : getValue(kpi.delta, kpi.name)
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={`${classes.borderedCell1} ${deltaCellDataColorDeltaPct}`}
                      >
                        {unit !== "Date"
                          ? Deltapct[kpi.name] !== undefined
                            ? unit === "Months"
                              ? Deltapct[kpi.name]
                              : Number(Deltapct[kpi.name]) >= 0
                              ? `${Number(Deltapct[kpi.name]).toFixed(2)}%`
                              : `(${Math.abs(
                                  Number(Deltapct[kpi.name])
                                ).toFixed(2)})%`
                            : "-"
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {"-"}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {"-"}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {"-"}
                      </TableCell>
                      <TableCell className={classes.borderedCell1}>
                        {"-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    className={classes.borderedCell}
                    style={{ textAlign: "center" }}
                    colSpan={10}
                  >
                    No content found. Please select/change the region or
                    scenario.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    </>
  );
}
