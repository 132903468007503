/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Chip from "@material-ui/core/Chip";
import theme from "../../../../Themes/defaultTheme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  selectLabel: {
    marginTop: "10px",
    width: "50%",
    paddingBottom: 5,
  },
  fullWidth: {
    width: "100%",
  },
  minWidth: {
    width: "180px",
  },
}));
const convertStringToArry = (val) => {
  if (Array.isArray(val)) {
    return val;
  } else {
    return val.length === 0 ? [] : val.split(",");
  }
};

const A_MultiSelect = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    convertStringToArry(props.defaultValue)
  );

  /* starts here handling groupBy */
  //getting distinct groups

  const handleChange = (event) => {
    let currValue = event.target.value;
    let selectedItems = [];
    if (currValue.length > 0) {
      currValue.forEach((selectedItem) => {
        let selectedOption = props.options.filter(function (option) {
          return option.value == selectedItem;
        });
        if (selectedOption.length > 0) {
          selectedItems.push(selectedOption[0]);
        }
      });
      setValue(currValue);
      props.onChange(selectedItems, props.id);
    } else {
      setValue(event.target.value);
      props.onChange(event.target.value, props.id);
    }
  };

  const handleChangeNative = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setValue(value);
    props.onChange(value, props.id);
  };

  let groups = [
    ...new Set(
      props.options.map((item) =>
        item[props.groupBy] !== undefined ? item[props.groupBy] : ""
      )
    ),
  ];

  //getting filtered options by group
  const filterOptionsByGroup = (group) => {
    let options = props.options.map((option) => {
      if (option[props.groupBy] == group) {
        return (
          <MenuItem
            key={handleOptionSetValue(option.value, false)}
            value={handleOptionSetValue(option.value, false)}
          >
            {handleOptionSetValue(option.label)}
          </MenuItem>
        );
      }
    });
    return options;
  };

  //returning combined groups with relative options for final render.
  let groupByOptions = groups.map((group) => {
    return (
      <div key={group}>
        <ListSubheader>{group}</ListSubheader> {filterOptionsByGroup(group)}
      </div>
    );
  });

  /* ends here handling groupBy */

  //non-group by options.
  let options = props.options.map((option) => {
    return (
      <MenuItem
        key={handleOptionSetValue(option.value, false)}
        value={handleOptionSetValue(option.value, false)}
      >
        {handleOptionSetValue(option.label)}
      </MenuItem>
    );
  });
  let optionsNative = () => {
    let options = props.options.map((option) => (
      <option
        key={handleOptionSetValue(option.value, false)}
        value={handleOptionSetValue(option.value, false)}
      >
        {handleOptionSetValue(option.label)}
      </option>
    ));
    if (props.displayEmpty) {
      options.unshift(<option key={-1} value=""></option>);
    }

    return options;
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={props.error}
        variant={props.variant}
        className={`${classes.formControl} ${
          props.fullWidth ? classes.fullWidth : ""
        } ${props.minWidth ? classes.minWidth : ""}`}
        required={props.required}
      >
        <InputLabel
          shrink
          htmlFor={props.id + "_label"}
          className={classes.selectLabel}
          id={props.id + "_label"}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId={props.id + "_label"}
          id={props.id}
          value={value}
          native={props.native}
          variant={props.variant}
          multiple={props.multiple}
          disabled={props.disabled}
          required={props.required}
          autoWidth={props.autoWidth}
          displayEmpty={props.displayEmpty}
          inputProps={{ readOnly: props.readOnly }}
          color={props.color}
          className={props.className}
          onChange={props.native ? handleChangeNative : handleChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {props.groupBy !== undefined
            ? groupByOptions
            : props.native
            ? optionsNative()
            : options}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
      {/* <InputLabel id={props.id + "_label"}>{props.label}</InputLabel>
      <Select
        labelId={props.id + "_label"}
        id={props.id}
        value={props.value}
        variant={props.variant}
        multiple={props.multiple}
        disabled={props.disabled}
        autoWidth={props.autoWidth}
        displayEmpty={props.displayEmpty}
        inputProps={{ readOnly: props.readOnly }}
        color={props.color}
        onChange={props.onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        <MenuItem value="" disabled>
          <em>None</em>
        </MenuItem>
        {props.groupBy !== undefined ? groupByOptions : options}
      </Select> */}
    </ThemeProvider>
  );
};
A_MultiSelect.defaultProps = {
  id: "xxxx",
  label: "Select",
  value: ["net", "javascript"],
  variant: "outlined",
  color: "primary",
  multiple: true,
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  //groupBy: "area", //uncomment this to see group by in action with default options given below.
  options: [
    { label: ".Net", value: "net", area: "backend" },
    { label: "Javascript", value: "javascript", area: "frontend" },
    { label: "Java", value: "java", area: "backend" },
    { label: "None of above", value: "none" },
    { label: "Jquery", value: "jquery", area: "frontend" },
    { label: "Accounts", value: "accounts" },
  ],
};
A_MultiSelect.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
};
export default A_MultiSelect;
