import * as React from "react";
import A_TextField from "../TextFields/A_TextField";
import theme from "../../../../Themes/defaultTheme";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./support.css";

const useStyles = makeStyles(() => ({
  mainDiv: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
  },

  formValueFieldContainer: {
    width: "222px",
  },
  percentageContainer: {
    width: "222px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const A_FormTextField = (props) => {
  const classes = useStyles();

  const roundof = (value) => {
    if (typeof value === "number") return parseFloat(value.toFixed(4));
    else return value;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainDiv}>
        <div className={classes.formValueField}>{props.label}</div>
        {props.dataType && props.dataType === "percentage" ? (
          <div className={classes.percentageContainer}>
            <A_TextField
              id="textValueglobal"
              label=""
              variant="standard"
              defaultValue={roundof(props.value)}
              inputType="value"
              onChange={props.onChange}
              type="number"
              sx={{ width: 222 }}
            ></A_TextField>
            <p
              style={{
                position: "absolute",
                width: "240px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              %
            </p>
          </div>
        ) : (
          <div className={classes.formValueFieldContainer}>
            <A_TextField
              id="textValueglobal"
              label=""
              variant="standard"
              defaultValue={roundof(props.value)}
              inputType="value"
              onChange={props.onChange}
              sx={{ width: 222 }}
              type="number"
            ></A_TextField>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
A_FormTextField.defaultProps = {
  label: "",
  dataType: "",
  value: "",
};
A_FormTextField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  dataType: PropTypes.string,
  value: PropTypes.string,
};
export default A_FormTextField;
