import React from "react";
import {
  FromEpochFormat,
  customDateFormat,
} from "../../../../Utils/Helpers/dateUtils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import AnnouncementDetails from "./AnnouncementDetails";
import { toEpochFormat } from "../../../../Utils/Helpers/dateUtils";
import {
  getAnnouncement,
  getBulkDocuments,
} from "./Helper/Announcement.helper";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import AnnouncementDetailsHeader from "./AnnouncementDetailsHeader";
import { containerType } from "../../../../Utils/config/config";
import propTypes from "prop-types";

const useStyles = makeStyles({
  image: {
    padding: "0.4rem",
  },
  skeleton: {
    marginTop: "0.4rem",
  },
});
export default function Annoucment({ content }) {
  const classes = useStyles();
  const [approvedData, setApprovedData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [openPopOver, setOpenPopOver] = React.useState(false);
  const [detailsData, setDetailsData] = React.useState({});

  const getAnnouncment = async () => {
    try {
      setLoading(true);
      let convertedFormData = content.announcementData
        ? content.announcementData
        : {};
      let announcementResponse = await getAnnouncement(convertedFormData);
      let currentDate = toEpochFormat(new Date());
      let approvedData = announcementResponse.filter((item) => {
        if (item.approved && item.publishOn <= currentDate) {
          if (item.removeDate && item.removeDate < currentDate) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
      setApprovedData(approvedData);
      setLoading(false);
      return approvedData;
    } catch (error) {
      setLoading(false);
      return [];
    }
  };

  React.useEffect(() => {
    bulkDownload();
  }, []);

  const bulkDownload = async () => {
    try {
      setImageLoading(true);
      let convertedFormData = [];
      let announcements = await getAnnouncment();
      let crmIds = [];
      if (
        announcements &&
        Array.isArray(announcements) &&
        announcements.length > 0
      ) {
        for (let announcement in announcements) {
          crmIds.push(
            announcements[announcement]["_partitionKey"] ||
              announcements[announcement]["id"]
          );
        }
        convertedFormData = [
          {
            items: crmIds,
            type: containerType.IMAGE,
          },
          {
            items: crmIds,
            type: containerType.GENERIC,
          },
        ];
        let announcementImages = await getBulkDocuments(convertedFormData);
        for (let containerIndex in announcementImages) {
          announcementImages &&
            announcementImages[containerIndex].map((documentImage) => {
              let BulkCrmId =
                documentImage.file && documentImage.file.split("/")[0];
              announcements.map((announcement) => {
                if (
                  announcement["_partitionKey"] === BulkCrmId ||
                  announcement["id"] === BulkCrmId
                ) {
                  if (containerIndex === "image") {
                    announcement["imageLink"] = documentImage.link;
                  } else {
                    if (announcement["documentLink"] === undefined) {
                      announcement["documentLink"] = documentImage.file;
                    }
                  }
                }
              });
            });
        }
        setApprovedData(announcements);
      }
      setImageLoading(false);
    } catch (error) {
      setImageLoading(false);
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const handleClick = (cardData) => {
    setDetailsData(cardData);
    setOpenPopOver(true);
  };

  const handlePopClose = () => {
    setOpenPopOver(false);
  };

  return (
    <>
      {loading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <Carousel responsive={responsive} showDots={true}>
          {approvedData && approvedData.length > 0 ? (
            approvedData.map((item) => (
              <div
                className="bgColor boxShadow  overflowHidden SlideMargin"
                key={item._partitionKey || item.id}
                onClick={() => handleClick(item)}
              >
                <button className="cardMain" type="button">
                  <div
                    className={`bgImage imageSize ${classes.image}`}
                    style={{
                      backgroundImage: `url(${
                        item.imageLink
                          ? item.imageLink
                          : content.defaultImageUrl
                          ? content.defaultImageUrl
                          : ""
                      })`,
                    }}
                    title=""
                  >
                    {item.imageLink == undefined && imageLoading ? (
                      <>
                        <Skeleton
                          className={classes.skeleton}
                          variant="circular"
                          width={40}
                          height={40}
                        >
                          <Avatar />
                        </Skeleton>
                        <Skeleton
                          variant="rectangular"
                          className={classes.skeleton}
                          width={210}
                          height={40}
                        />
                        <Skeleton
                          className={classes.skeleton}
                          variant="rounded"
                          width={210}
                          height={40}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="pad16">
                    <p style={{fontSize:"1.2rem"}}className="DisBlock bottomText fontTitle">
                      {item.headline}
                    </p>
                   {item && item.tagline &&( <p style={{textAlign:"left",fontSize:"1rem" , marginBottom:0}}>
                      {item.tagline}
                    </p>
                    )}
                    <p style={{fontSize:"0.8rem"}}className="DisBlock bottomText fontitalic">
                      {`Posted by ${
                        item["firstName"] ? item["firstName"] : ""
                      } on ${customDateFormat(
                        FromEpochFormat(item.publishOn).toString(),
                        1
                      )}`}
                    </p>
                  </div>
                </button>
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>
      )}
      <>
        {openPopOver && (
          <A_SimpleDialog
            title={
              <AnnouncementDetailsHeader
                title={detailsData.headline ? detailsData.headline : ""}
                subTitle={`${
                  detailsData["firstName"] ? detailsData["firstName"] : ""
                } ${
                  detailsData["lastName"] ? detailsData["lastName"] : ""
                } , ${customDateFormat(
                  FromEpochFormat(detailsData.publishOn).toString(),
                  1
                )}`}
              />
            }
            maxWidth="md"
            handleOnClose={handlePopClose}
            onClose={handlePopClose}
            detailsData={detailsData}
            dialogContent={
              <AnnouncementDetails
                pdf={detailsData.documentLink}
                directlink={false}
                redirectlink={
                  detailsData.redirectlink ? detailsData.redirectlink : ""
                }
                html={detailsData.articalBody ? detailsData.articalBody : ""}
              />
            }
            open={openPopOver}
          />
        )}
      </>
    </>
  );
}

Annoucment.propTypes = {
  content: propTypes.object,
};
