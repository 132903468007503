/* eslint-disable */
import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";

export const renderTextField = (
  row,
  configurations,
  key,
  { type = "number", onChange, onBlur, rowIndex } = {}
) => {
  const _onChange = (e) => {
    onChange && onChange(e, row, configurations, key, { rowIndex });
  };
  const _onBlur = (e) => {
    onBlur && onBlur(e, row, configurations, key, { rowIndex });
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        //className={classes.searchBox}
        onChange={_onChange}
        id={key}
        key={key}
        onBlur={_onBlur}
        label=""
        type={type}
        variant="outlined"
        defaultValue={row[key]}
      />
      <FormHelperText style={{ color: "green", width: "20ch" }}>
        Allocated : {row[key]}
      </FormHelperText>
    </div>
  );
};

// export const isNullOrUndefined = (value) => {
//   return typeof value === 'undefined' || value === null;
// }
