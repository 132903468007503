import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { message } from "../../../../Utils/config/messages";
import propTypes from "prop-types";

const useStyles = makeStyles({
  overflow: {
    overflow: "auto",
  },

  message: {
    paddingLeft: "43%",
  },
});

const Image = (props) => {
  const classes = useStyles();
  let { crmId } = useParams();
  const user = useSelector((state) => state.oidc.user);
  const [Image, setImage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("Loading...");

  const getImage = async (id) => {
    let imageAsUrl = "";
    let imageName =
      props.currentPage &&
      props.currentPage.data &&
      props.content &&
      props.content.imageName &&
      props.currentPage.data[props.content.imageName]
        ? "/" + props.currentPage.data[props.content.imageName]
        : "/image";
    let subdomain = new URL(window.location.href).hostname.split(".")[0];

    try {
      const response = await fetch(`${process.env.DOCUMENT_HELPER}`, {
        method: "GET",
        headers: {
          filename: id + imageName,
          type:
            props.content && props.content.containerType
              ? props.content.containerType
              : "image",
          "file-operation": "download",
          "process-file": "no",
          "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
          subdomain: subdomain,
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.access_token,
        },
      });

      if (!response.ok) {
        setImage("");
        setLoadingMessage(message.NO_IMAGE_FOUND);
      } else {
        const blobData = await response.blob();

        if (blobData.size === 0) {
          setImage("");
          setLoadingMessage(message.NO_IMAGE_FOUND);
        } else {
          imageAsUrl = URL.createObjectURL(blobData);

          const blobResponse = await fetch(imageAsUrl);
          if (!blobResponse.ok) {
            setImage("");
            setLoadingMessage(message.NO_IMAGE_FOUND);
          }

          const textData = await blobResponse.text();

          setImage(textData);
        }
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getImage(crmId);
  }, []);

  return (
    <Box sx={{ p: "0 0" }} className={classes.overflow}>
      {Image == "" ? (
        <p className={classes.message}> {loadingMessage}</p>
      ) : (
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setLoadingMessage(message.NO_IMAGE_FOUND);
            setImage("");
          }}
          src={Image}
          alt={"image"}
        />
      )}
    </Box>
  );
};
Image.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
};

export default Image;
