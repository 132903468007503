import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import M_MultiValueList from "../../../Molecules/Common/DealAdministartion/M_MultiValueList";
import { Divider } from "@material-ui/core";
import { httpGet } from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import TodayIcon from "@material-ui/icons/Today";
import defLogo from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
import M_ListDetails from "../../../Molecules/Common/DealAdministartion/M_ListDetails";
import PropTypes from "prop-types";
import { message } from "../../../../../Utils/config/messages";
import { useSelector } from "react-redux";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import {
  meetingPreference,
  dateFormat,
} from "../../../../../Utils/config/config";

const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  icon: {
    height: "40px",
    width: "50px",
    color: "#3d5afe",
  },
});
function M_MeetingPreference(props) {
  const classes = useStyles();
  var logo = defLogo;
  const [data, setData] = useState({});
  const [selectedIRC, setSelectedIRC] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [propsdata, setPropsData] = useState({});
  const [currentuser, setCurrentuser] = React.useState("");
  const [currentuserdata, setcurrentuserdata] = React.useState("");

  const completeData = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0]
      ? state.getLoggedInUserReducer.data[0]
      : ""
  );
  var userid = completeData !== "" ? (userid = completeData.id) : (userid = "");

  const getDateTime = (newtime) => {
    var time;
    var date;
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    if (isNaN(newtime)) {
      let newDateJSON = newtime ? JSON.parse(newtime) : "";
      let newDate = new Date(newDateJSON.dateTime + "Z");
      let year = newDate.getFullYear();
      let Month = newDate.getMonth() + 1;
      let day = newDate.getDate();
      date = year + "-" + Month + "-" + day;
      let h = newDate.getHours();
      let m = addZero(newDate.getMinutes());
      time = h + ":" + m;
    } else {
      let epochfiltered = FromEpochFormat(parseInt(newtime));
      time = getFormattedDate(epochfiltered, "HH:mm");
      date = getFormattedDate(epochfiltered, dateFormat.meetingPreference);
    }
    return `Date :${date} Time:${time}`;
  };
  const handleID = (id, item) => {
    setPropsData(item);
    setSelectedIRC(id);
  };
  const callback = () => {
    getData();
  };
  const handleSelect = (id, ind) => {
    return selectedIRC ? id == selectedIRC : ind == 0;
  };
  let getData = async () => {
    if (data.length == 0) {
      setIsLoading(true);
    }
    if (currentuser !== "") {
      let isComp =
        props && props.content && props.content.isComp
          ? props.content.isComp
          : false;
      var response = await httpGet(
        `${process.env.MEETING_PREFERENCE}/Meeting/GetMeetings?userId=${currentuser}&isComp=${isComp}`
      );
      let filteredData = response.filter((item) => item.tagged == false);
      if (filteredData.length > 0) {
        setPropsData(filteredData[0]);
      }
      setIsLoading(false);
      setData(filteredData);
    }
  };
  useEffect(() => {
    if (userid == "") {
      setIsLoading(false);
    } else {
      setCurrentuser(userid);
      var currentuser = completeData ? completeData : "";
      setcurrentuserdata(currentuser);
      getData();
    }
  }, [currentuser]);

  return (
    <>
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}

      <Box display={"flex"}>
        {!isLoading ? (
          data.length > 0 ? (
            <>
              <Box
                width={"60%"}
                borderRight={"2px solid #f0f0f0"}
                style={{ maxHeight: "70vh", overflow: "auto" }}
              >
                {data.map((item, index) => {
                  return (
                    <Box
                      key={item}
                      onClick={() => {
                        handleID(item.id, item);
                      }}
                    >
                      <M_MultiValueList
                        title={logo}
                        heading={item.subject}
                        subHeading={getDateTime(item.startDT)}
                        key={item}
                        modify="true"
                        Icon={<TodayIcon className={classes.icon} />}
                        data={item}
                        selected={handleSelect(item.id, index, item)}
                      ></M_MultiValueList>
                      <Divider></Divider>
                    </Box>
                  );
                })}
              </Box>

              <Box
                width={
                  props.content && props.content.displayIRCList ? "60%" : "100%"
                }
                style={{ maxHeight: "50vh", overflow: "auto" }}
              >
                <M_ListDetails
                  type={meetingPreference.MEETINGS}
                  data={propsdata}
                  loggedinUserData={currentuserdata}
                  loggedinUser={currentuser}
                  modify="true"
                  callback={callback}
                  default="Select"
                  {...props}
                />
              </Box>
            </>
          ) : (
            <div className={classes.noData}>
              <p>{message.NO_CONTENT}</p>
            </div>
          )
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
M_MeetingPreference.propTypes = {
  callback: PropTypes.func,
  layouts: PropTypes.array,
  getLoggedInUser: PropTypes.func,
  loggedinUser: PropTypes.func,
  loggedIn: PropTypes.array,
  currentPageId: PropTypes.string,
  content: PropTypes.any,
};
export default M_MeetingPreference;
