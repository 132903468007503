import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import React from "react";
import "./O_GSTTabs.scss";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { Skeleton } from "@material-ui/lab";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import O_GSTForms from "./O_GSTForms";
//import { httpPost } from "../../../../../Utils/apis/apis";
import * as XLSX from "xlsx";
import O_GSTCopyForms from "./O_GSTCopyForms";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { bindActionCreators } from "redux";
import {
  toastSeverity,
  toastType,
} from "../../../../../Utils/config/toastConfig";
import * as toastActions from "../../../../../store/actions/toastActions";
import { message } from "../../../../../Utils/config/messages";
import * as common from "../../../../../Utils/Helpers/common";
import GetAppIcon from "@material-ui/icons/GetApp";
import O_AddSenarioButton from "./O_AddSenarioButton";
import A_IconButton from "../../../Atoms/Inputs/Buttons/A_IconButton";
const useStyles = makeStyles(() => ({
  borderedCell: {
    padding: "8px",
    borderLeft: "1px solid #dbdbdb",
    borderRight: "1px solid #dbdbdb",
  },
}));
function O_GSTTables(props) {
  const [reload,setReload] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [copyOpen, setCopyOpen] = React.useState(null);
  const [deleteId, setDelete] = React.useState(null);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageData, setPageData] = React.useState({});
  const [formData, setFormData] = React.useState({});
  const [runQuery, setRunQueue] = React.useState([]);
  const [validCheck, setValidCheck] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [pageDataFilter, setPageDataFilter] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const [runPopUp, setRunPopUp] = React.useState(false);
  const [Icon, setIcon] = React.useState({
    "Scenario Name": "down",
    "Region Code": "down",
    AssetName: "down",
    SensitivitesAndValues: "down",
  });
  const handleInputChange = (event) => {
    let searchInput = event.target.value;
    setSearchTerm(searchInput);
   const filteredData = pageDataFilter.Scenario.filter(item =>
      item['Scenario Name'].toLowerCase().includes(searchInput.toLowerCase()) ||
      item['Region Code'].toLowerCase().includes(searchInput.toLowerCase()) ||
      item['AssetName'].toLowerCase().includes(searchInput.toLowerCase()) ||
      item['SensitivitesAndValues'].toLowerCase().includes(searchInput.toLowerCase())
  );   
    let Scenario = { Scenario: [...filteredData] };
    setPageData(Scenario);
  };

  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};
  const order = "asc";
  const orderBy = "";
  let { crmContactUserEmail } = getCRMContactInfo();
  let dispatch = useDispatch();
  let classes = useStyles();

  React.useEffect(() => {
    getPageData();
  }, [dispatch, reload]);

  const executeSPandGetData = async ({ details }) => {
    try {
      let spResponse = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [{ ...details }],
      });
      let tempPageData = { ...pageData };
      tempPageData[details.id] = spResponse[details.id] || [];
      setPageData(tempPageData);
      setPageDataFilter(tempPageData);
    } catch (_) {
      //log information if you want display in console
    }
  };
  const handleDelete = async () => {
    try {
      await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: "delete",
            spName: "[JLForm].[Usp_GST_InsertGlobalSensitivityScenarios]",
            parameters: {
              ScenarioId: deleteId,
              OperationType: "Delete",
              ScenarioName: "",
              RegionCode: "",
              AssetIds: "",
              SensitivitiesAndValues: "",
              User: "",
            },
          },
        ],
      });
      setDelete(null);
      getPageData();
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.SUCCESS,
        message: message.DELETE_CRM_RECORD,
        status: true,
      });
    } catch (error) {
      //log information if you want display in console
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.ERROR,
        message: "Something went wrong",
        status: true,
      });
    }
  };
  const getPageData = async () => {
    setIsLoading(true);
    await executeSPandGetData({
      query: "Scenario",
      details: {
        id: "Scenario",
        spName: "[JLForm].[Usp_GST_GetScenarioDetails]",
        parameters: {},
      },
    });
    setIsLoading(false);
  };
  const getSensitivity = (value) => {
    // Split the input into an array of key-value pairs
    const keyValuePairs = value.split(",");
    // Sort the array alphabetically by keys
    keyValuePairs.sort((a, b) => {
      const keyA = a.split(":")[0];
      const keyB = b.split(":")[0];
      return keyA.localeCompare(keyB);
    });
    // Join the sorted array back into a string
    const output = keyValuePairs.join(",");
    value =
      output &&
      output.split(",").map((row, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {row.split(":").map((cell) => {
            const parsedNumber = parseFloat(cell);
            return isNaN(parsedNumber) ? (
              <span key={cell}>{cell}</span>
            ) : (
                <span key={cell}>
                  {parsedNumber > 0
                    ? <span style={{color:"green"}}>{parsedNumber.toFixed(2)}%</span>
                    : <span style = {{color:"red"}}>{`(${Math.abs(parsedNumber).toFixed(2)})%`}</span>}
                </span>
            );
          })}
        </div>
      ));
    return value;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReload = () => {
    getPageData();
  };
  const handleCopyClose = () => {
    setValidCheck(false);
    setCopyOpen(null);
  };
  const submitQuery = async () => {
    let query = "res";
    setDisableSubmit(true);
    let { ScenarioName, Region, Model, sensitivity = {} } = formData;
    if (
      [Region, Model, ScenarioName].some(
        (val) =>
          (Array.isArray(val) && val.join(",") === "") ||
          !val ||
          (typeof val === "string" && val.trim() === "") ||
          (typeof val === "string" && val.toLowerCase() === "base")
      ) ||
      (Object.entries(sensitivity) && Object.entries(sensitivity).length === 0)
    ) {
      setValidCheck(true);
      setDisableSubmit(false);
      return;
    } else {
      setValidCheck(false);
    }
    try {
      let scenarioDetails = Object.entries(sensitivity).map(
        ([key, value]) => `${key}:${value.value ? value.value : 0}`
      );
      await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "[JLForm].[Usp_GST_InsertGlobalSensitivityScenarios]",
            parameters: {
              ScenarioId: null,
              OperationType: "Add",
              ScenarioName,
              RegionCode: Region,
              AssetIds: Model.filter((item) => item).join(","),
              SensitivitiesAndValues: scenarioDetails.join(","),
              User: crmContactUserEmail,
            },
          },
        ],
      });
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.SUCCESS,
        message: "Scenario Copied",
        status: true,
      });
    } catch (e) {
      props.setToast({
        type: toastType.DELETE_CRM,
        severity: toastSeverity.ERROR,
        message: "Something went wrong",
        status: true,
      });
    }
    setDisableSubmit(false);
    handleReload();
    handleCopyClose();
  };
  function updateOrAddShowButtonStatus(scenarioArray, scenarioId) {
    for (let i = 0; i < scenarioArray.length; i++) {
      if (scenarioArray[i].ScenarioId === scenarioId) {
        scenarioArray[i]["Run Status"] = scenarioArray[i]["Run Status"] + 1;
        break; // Assuming there's only one scenario with the specified ScenarioId
      }
    }
    return scenarioArray;
  }
  const executeScenario = async (scenarioDetails) => {
    setRunQueue([...runQuery, scenarioDetails["Scenario Name"]]);
    let query = "sensitivity";
    await getDataFromSQLSp({
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: "JLForm.Usp_GST_RunScenario",
          parameters: {
            ScenarioId: scenarioDetails.ScenarioId,
            User: crmContactUserEmail,
          },
        },
      ],
    });
    setRunQueue(
      runQuery.filter((item) => item !== scenarioDetails["Scenario Name"])
    );
    let updatedArray = updateOrAddShowButtonStatus(
      [...pageData.Scenario],
      scenarioDetails.ScenarioId
    );
    let Scenario = { Scenario: [...updatedArray] };
    setPageData(Scenario);
  };
  const formateDataAndExport = () => {
    let tempScenario = [...pageData["Scenario"]];
    let reports = [];
    const merge = [];
    tempScenario.map((scenario) => {
      let SensitivitiesAndValues = scenario["SensitivitesAndValues"].split(",");
      let currentIndex = reports.length + 1;
      let endIndex = reports.length + SensitivitiesAndValues.length;
      merge.push([
        { s: { r: currentIndex, c: 0 }, e: { r: endIndex, c: 0 } },
        { s: { r: currentIndex, c: 1 }, e: { r: endIndex, c: 1 } },
        { s: { r: currentIndex, c: 2 }, e: { r: endIndex, c: 2 } },
      ]);
      for (let i in SensitivitiesAndValues) {
        let [Sensitivity, Value] = SensitivitiesAndValues[i].split(":");
        if (i == 0) {
          reports.push({
            Name: scenario["Scenario Name"],
            Region: scenario["Region Code"],
            Model: scenario["AssetName"],
            Sensitivity,
            Value,
          });
        } else {
          reports.push({
            Name: "",
            Region: "",
            Model: "",
            Sensitivity,
            Value,
          });
        }
      }
    });

    convertToExcel(
      reports,
      "ScenarioManager",
      merge.flatMap((item) => item)
    );
  };
  const convertToExcel = (jsonData, sheetName, mergeDetails = []) => {
    const sheet = XLSX.utils.json_to_sheet(jsonData);
    sheet["!merges"] = mergeDetails;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    XLSX.writeFile(workbook, "Input_ScenarioManager.xlsx");
  };
  // function convertAndMultiply(inputString) {
  //   try {
  //     const numericValue = parseFloat(inputString);
  //     const result = numericValue * 100;
  //     return result.toFixed(0); // Rounds to 0 decimal places
  //   } catch {
  //     return inputString;
  //   }
  // }
  const getSensitivityStructure = (value) => {
    let structure = {};
    let values = value.split(",");
    for (let val of values) {
      let [key, value] = val.split(":");
      // value = convertAndMultiply(value);
      structure[key] = { checkValue: true, value };
    }
    return structure;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;

      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const renderPagingnation = () => {
    return pageData.Scenario && pageData.Scenario.length > 5 ? (
      <div className={classes.root}>
        <TablePagination
          variant="outlined"
          shape="rounded"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pageData.Scenario.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    ) : (
      ""
    );
  };
  const getSortedRow = (id, data, dir) => {
    if (data.length > 0) {
      data.sort((a, b) => {
        let scenarioNameA;
        let scenarioNameB;
        if (dir == "down") {
          scenarioNameA = a[id].toLowerCase();
          scenarioNameB = b[id].toLowerCase();
        } else {
          scenarioNameA = b[id].toLowerCase();
          scenarioNameB = a[id].toLowerCase();
        }
        if (scenarioNameA < scenarioNameB) {
          return -1;
        }
        if (scenarioNameA > scenarioNameB) {
          return 1;
        }
        return 0;
      });
      let Scenario = { Scenario: [...data] };
      let arrowDir = dir == "down" ? "up" : "down";
      let obj = { ...Icon, [id]: arrowDir };
      setIcon(obj);
      setPageData(Scenario);
    }
  };
  const DirectionArrow = (id, data, dir) => {
    return dir === "down" ? (
      <ArrowDownwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, data, "down")}
      />
    ) : (
      <ArrowUpwardIcon
        fontSize="small"
        onClick={() => getSortedRow(id, data, "up")}
      />
    );
  };
  const onRunClick = (row) => {
    executeScenario(row);
    setRunPopUp(true);
  };
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              gap: "4px",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <p style={{ fontSize: "20px", color: "rgba(62, 61, 61, 0.87)" }}>
              Scenario Manager
            </p>
            <div className="leftHeader">
              <div className="searchSubHeader">
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleInputChange}
                  adornment={{ position: "start", value: <SearchIcon /> }}
                  size="small"
                />
              </div>
              <O_AddSenarioButton setReload={setReload} />
              <A_IconButton
                color="primary"
                onClick={formateDataAndExport}
                icon={<GetAppIcon />}
              />
            </div>
          </div>
          <Divider></Divider>
          <div
            style={{ overflow: "auto", maxHeight: "500px" }}
            className="perfromanceTable"
          >
            <Table aria-label="customized table">
              <TableHead className="stickyTable">
                <TableRow>
                  <TableCell
                    className={classes.borderedCell}
                    style={{
                      backgroundColor: style.background,
                      color: "white",
                      width: "12vw",
                    }}
                    width={"30%"}
                  >
                    Name{" "}
                    {DirectionArrow(
                      "Scenario Name",
                      pageData.Scenario,
                      Icon["Scenario Name"]
                    )}
                  </TableCell>

                  <TableCell
                    className={classes.borderedCell}
                    style={{
                      backgroundColor: style.background,
                      color: "white",
                    }}
                  >
                    Region{" "}
                    {DirectionArrow(
                      "Region Code",
                      pageData.Scenario,
                      Icon["Region Code"]
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.borderedCell}
                    style={{
                      backgroundColor: style.background,
                      color: "white",
                    }}
                  >
                    Model{" "}
                    {DirectionArrow(
                      "AssetName",
                      pageData.Scenario,
                      Icon["AssetName"]
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.borderedCell}
                    style={{
                      backgroundColor: style.background,
                      color: "white",
                    }}
                  >
                    Sensitivity & Values
                    {DirectionArrow(
                      "SensitivitesAndValues",
                      pageData.Scenario,
                      Icon["SensitivitesAndValues"]
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.borderedCell}
                    style={{
                      backgroundColor: style.background,
                      color: "white",
                      width: "17vw",
                    }}
                    align="center"
                  >Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageData.Scenario && pageData.Scenario.length > 0 ? (
                  stableSort(pageData.Scenario, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const scenarioName = row["Scenario Name"];
                      const regionCode = row["Region Code"];
                      const assetName = row["AssetName"];
                      const sensitivitiesAndValues =
                        row["SensitivitesAndValues"];
                      const scenarioId = row["ScenarioId"];

                      return (
                        <TableRow key={scenarioId || scenarioName}>
                          <TableCell
                            className={classes.borderedCell}
                            component="th"
                            scope="row"
                          >
                            {scenarioName}
                          </TableCell>
                          <TableCell className={classes.borderedCell}>
                            {regionCode}
                          </TableCell>
                          <TableCell className={classes.borderedCell}>
                            {assetName}
                          </TableCell>
                          <TableCell className={classes.borderedCell}>
                            <div
                              style={{
                                display: "flex",
                                gap: "4px",
                                flexDirection: "column",
                              }}
                            >
                              {getSensitivity(sensitivitiesAndValues)}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                          >
                            <A_Button
                              style={{
                                backgroundColor:
                                  row && row["Run Status"] === 0
                                    ? "#46C263"
                                    : "",
                                color: "white",
                              }}
                              // disabled={runQuery.includes(scenarioName)}
                              // onClick={() => {
                              //   executeScenario(row);
                              // }}
                              disabled={
                                row && row["Run Status"] === 0 ? false : true
                              }
                              onClick={() => onRunClick(row)}
                              label={"Run"}
                              size={"small"}
                            />
                            <A_Button
                              label="Copy"
                              size={"small"}
                              onClick={() => setCopyOpen(row)}
                            />
                            <A_Button
                              color={"secondary"}
                              style={{
                                backgroundColor: "#FC3D39",
                                color: "white",
                              }}
                              size={"small"}
                              onClick={() => {
                                setDelete(scenarioId);
                              }}
                              label="Delete"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className={classes.borderedCell}
                      style={{ textAlign: "center" }}
                      colSpan={5}
                    >
                      WE ARE SORRY, NO CONTENT FOUND!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <A_SimpleDialog
            open={open}
            title={<>Global Sensitivity Input</>}
            height="110"
            maxWidth="sm"
            dialogContent={<O_GSTForms onFromDataChange={setFormData} />}
            dialogActions={
              <>
                <A_Button
                  label="Finish"
                  color={"primary"}
                  onClick={submitQuery}
                />
              </>
            }
            onClose={handleClose}
          />
          <A_SimpleDialog
            open={copyOpen}
            title={<>Global Sensitivity Input</>}
            height="110"
            maxWidth="sm"
            dialogContent={
              <O_GSTCopyForms
                onFromDataChange={setFormData}
                ButtonStatus={setDisableSubmit}
                validation={validCheck}
                formData={{
                  // ScenarioName: copyOpen && copyOpen["Scenario Name"],
                  ScenarioName: "",
                  Region: copyOpen && copyOpen["Region Code"],
                  Model: copyOpen && copyOpen["AssetName"].split(","),
                  sensitivity:
                    copyOpen && copyOpen["SensitivitesAndValues"]
                      ? getSensitivityStructure(
                          copyOpen["SensitivitesAndValues"]
                        )
                      : {},
                }}
              />
            }
            dialogActions={
              <>
                <A_Button
                  label="Finish"
                  color={"primary"}
                  disabled={
                    !copyOpen || !copyOpen["Scenario Name"] || disableSubmit
                  }
                  onClick={submitQuery}
                />
              </>
            }
            onClose={handleCopyClose}
          />
          {renderPagingnation()}
          <A_SimpleDialog
            open={deleteId}
            title={<>Warning</>}
            height="110"
            maxWidth="sm"
            dialogContent={<>Are you sure you want to delete this scenario? </>}
            dialogActions={
              <>
                <A_Button
                  label="Finish"
                  color={"primary"}
                  onClick={handleDelete}
                />
              </>
            }
            onClose={() => {
              setDelete(null);
            }}
          />
          <A_SimpleDialog
            open={runPopUp}
            title={<>Running</>}
            height="110"
            maxWidth="sm"
            dialogContent={
              <>
              Performance metrics and Cashflows are being generated. An email will be sent to the user once the scenario is complete.
              </>
            }
            dialogActions={
              <>
                <A_Button
                  label="Close"
                  color={"primary"}
                  onClick={() => {
                    setRunPopUp(false);
                  }}
                />
              </>
            }
            onClose={() => {
              setRunPopUp(false);
            }}
          />
        </>
      )}
    </>
  );
}
O_GSTTables.propTypes = {
  reload: PropTypes.any,
  setToast: PropTypes.any,
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_GSTTables);
