/* eslint-disable */
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as oidcReducer } from "redux-oidc";
import toast from "./toastReducer";
import currentPage from "./currentPageReducer";
import managePage from "./managePageReducer";
import manageForm from "./manageFormReducer";
import manageExcelTemplates from "./manageExcelTemplateReducer";
import manageExcelTemplateDownload from "./manageExcelTemplateDownload";
import manageMenus from "./manageMenusReducer";
import entityRecord from "./entityRecordReducer";
import esgRecord from "./esgReducer";
import manageToolbarSelections from "./manageToolbarSelectionsReducer";
import checklist from "./checklistReducer";
import getLoggedInUserReducer from "./getLoggedInUserReducer";
import previousUpload from "./previousUploadReducer";
import pptReducer from "./pptReducer";
import getIrcSlotData from "./getIrcSlotReducer";
import dealTeamGetORdel from "./dealTeamReducer";
import manualUpdateReducer from "./manualUpdateReducer";
import getDTMetricReducer from "./getDTMetricReducer";
import getIrReportReducer from "./irReportReducer";
import previousEntityReducer from "./previousEntityReducer";
import manageFilterValueReducer from "./manageFilterValueReducer";
import ReloadResultTemplate from "./ReloadResultTemplate";
import proposalTrackerReducer from "./proposalTackerReducer";
const mainReducer = combineReducers({
  routing: routerReducer,
  oidc: oidcReducer,
  checklist,
  currentPage,
  entityRecord,
  esgRecord,
  getLoggedInUserReducer,
  manageExcelTemplateDownload,
  manageExcelTemplates,
  manageForm,
  manageMenus,
  managePage,
  manageToolbarSelections,
  previousUpload,
  toast,
  pptReducer,
  getIrcSlotData,
  dealTeamGetORdel,
  manualUpdateReducer,
  getDTMetricReducer,
  getIrReportReducer,
  previousEntity: previousEntityReducer,
  manageFilterValueReducer,
  ReloadResultTemplate,
  proposalTrackerReducer,
});

export default mainReducer;
