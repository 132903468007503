/* eslint-disable */
import React, { useState, useCallback } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../store/actions/currentPageActions";

import RecordMergeTable from "./RecordMergeTable";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
  },
}));

const RecordMerge = (props) => {
  const [primaryData, setPrimaryData] = React.useState(props.currentPage.data);
  const [secondary, setSecondary] = React.useState(
    props.currentPage.pageConfig.fields
  );

  return (
    <RecordMergeTable
    searchOn={props.searchOn}
      primaryData={primaryData}
      secondary={secondary}
      pageId={props.currentPage.pageConfig.id}
      handleClosePopup={props.handleClosePopup}
    />
  );
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordMerge);

