import { prevEntityActionType } from "../actions/actionTypes";

const initialState = {};

const previousEntityReducer = (state = initialState, action) => {
  let currState = {
    ...state,
  };
  switch (action.type) {
    case prevEntityActionType.SET_PREVIOUS_ENTITY:
      currState = { ...currState, prevEntity: action.payload };
      return currState;
    case prevEntityActionType.CLEAR_PREVIOUS_ENTITY:
      return initialState;
    default:
      return state;
  }
};

export default previousEntityReducer;
