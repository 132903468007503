
export const itemTypes={
    DEAL_STATUS: 'dealStatus',
    CHART: 'chart',
    GUIDELINES: 'guidelines',
    MAPS: 'maps',
    RESULT_TEMPLATE: 'resultTemplate',
    CHART_ENTRY: 'chartEntry',
    COMPLETION: 'completion',
    ACTIVE_FACILITY: 'activeFacility',
    LENDERS_APPROACHED: 'lendersApproached'
}
