/* eslint-disable */
import { loggedInUserIdActionTypes } from "./actionTypes";
export const getLoggedInUser = (payload) => {
  return {
    type: loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE,
    payload,
  };
};
export const getLoggedInUserSucceeded = (payload) => {
  return {
    type: loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE_SUCCEEDED,
    payload,
  };
};
export const getLoggedInUserFailed = (payload) => {
  return {
    type: loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE_FAILED,
    payload,
  };
};