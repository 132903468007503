/* eslint-disable no-unused-vars */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import theme from "../../../../Themes/defaultTheme";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { propTypes } from "react-addons-css-transition-group";


const A_FormHelper =({ fieldHelperText }) => {

    return (
        <ThemeProvider theme={theme}>
           {fieldHelperText && fieldHelperText.trim() && <Tooltip title={<div style={{fontSize: '12px'}}>{fieldHelperText}</div>} arrow className="helperIcon" placement="top">
                <ErrorOutlineRoundedIcon style={{color:"#000",width:"16px",height:"16px"}} />
            </Tooltip>}
        </ThemeProvider>
    );
};

A_FormHelper.defaultProps = {
    fieldHelperText: "",
};

A_FormHelper.propTypes = {
    fieldHelperText: propTypes.string,
};
export default A_FormHelper;
