 /* eslint-disable */
import * as React from "react";
import theme from "../../../../Themes/defaultTheme";
import A_Button from "../Buttons/A_Button";
import A_CircularIndeternment from "../../Feedback/ProgressIndicators/A_CircularIndeternment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formFieldsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  formButtonField: {
    paddingLeft: "-120px",
    paddingTop: "10px",
    
  },
}));

const A_FormButton = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.formFieldsWrapper}>
      <div className={classes.formButtonField}>
        <A_Button
          color="primary"
          label={props.saveLabel}
          disabled={props.saveDisabled}
          endIcon={
            props.isSaveInProgress && (
              <A_CircularIndeternment color="secondary" size={20} />
            )
          }
          onClick={props.onSave}
        />

        <A_Button
          label={props.cancelLabel}
          endIcon={
            props.isCancelInProgress && (
              <A_CircularIndeternment color="secondary" size={20} />
            )
          }
          onClick={props.onCancel}
        />
      </div>
    </div>
  );
};

export default A_FormButton;
