import React from "react";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TripOriginIcon from "@material-ui/icons/TripOrigin";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ContactlessIcon from "@material-ui/icons/Contactless";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PeopleIcon from "@material-ui/icons/People";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import BusinessIcon from "@material-ui/icons/Business";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import EventNoteIcon from "@material-ui/icons/EventNote";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import HomeIcon from "@material-ui/icons/Home";
import Assessment from "@material-ui/icons/Assessment";
import PieChart from "@material-ui/icons/PieChart";
import Payment from "@material-ui/icons/Payment";
import PersonIcon from "@material-ui/icons/Person";
import DoneIcon from "@material-ui/icons/Done";
import LockIcon from "@material-ui/icons/Lock";

const IconList = (props) => {
  var currentIcon = props.icon;
  try {
    let obj = JSON.parse(props.icon);
    currentIcon = obj.value;
  } catch (e) {
    currentIcon = props.icon;
  }
  let val;
  switch (currentIcon) {
    case "HomeWorkIcon":
      val = <HomeWorkIcon />;
      break;
    case "TripOriginIcon":
      val = <TripOriginIcon />;
      break;
    case "ContactSupportIcon":
      val = <ContactSupportIcon />;
      break;
    case "AccountBalanceIcon":
      val = <AccountBalanceIcon />;
      break;
    case "NotificationsActiveIcon":
      val = <NotificationsActiveIcon />;
      break;
    case "ContactlessIcon":
      val = <ContactlessIcon />;
      break;
    case "SupervisedUserCircleIcon":
      val = <SupervisedUserCircleIcon />;
      break;
    case "DashboardIcon":
      val = <DashboardIcon />;
      break;
    case "SettingsIcon":
      val = <SettingsIcon />;
      break;
    case "InsertChartIcon":
      val = <InsertChartIcon />;
      break;
    case "PersonPinIcon":
      val = <PersonPinIcon />;
      break;
    case "PeopleIcon":
      val = <PeopleIcon />;
      break;
    case "MonetizationOnIcon":
      val = <MonetizationOnIcon />;
      break;
    case "AllInclusiveIcon":
      val = <AllInclusiveIcon />;
      break;
    case "ShowChartIcon":
      val = <ShowChartIcon />;
      break;
    case "BusinessIcon":
      val = <BusinessIcon />;
      break;
    case "InsertLinkIcon":
      val = <InsertLinkIcon />;
      break;
    case "EventNoteIcon":
      val = <EventNoteIcon />;
      break;
    case "CallSplitIcon":
      val = <CallSplitIcon />;
      break;
    case "AccountCircle":
      val = <HomeIcon />;
      break;
    case "Assessment":
      val = <Assessment />;
      break;
    case "PieChart":
      val = <PieChart />;
      break;
    case "Payment":
      val = <Payment />;
      break;
    case "User":
      val = <PersonIcon />;
      break;
    case "LockIcon":
      val = <LockIcon />;
      break;
    case "DoneIcon":
      val = <DoneIcon />;
      break;
    default:
      val = "";
      break;
  }
  return val;
};
export default IconList;
