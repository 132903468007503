import { toEpochFormat } from "../../../../../Utils/Helpers/dateUtils";

export const groupBy = function (xs, key) {
  return {
    ...xs.reduce(function (rv={}, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {}),
  };
};
export let diff_hours = (dt1) => {
  let checkDate = new Date(dt1);
  let today = new Date();
  let difference = Math.abs((today.getTime() - checkDate.getTime()) / 1000);
  difference = difference / (60 * 60);
  difference = difference.toFixed(2);
  let differenceInHours = Math.floor(difference);
  let differenceInMinutes = difference - differenceInHours;
  differenceInMinutes = differenceInMinutes * 60;
  differenceInMinutes = differenceInMinutes.toFixed(2);
  let finalTime = differenceInHours + differenceInMinutes / 100;
  finalTime = finalTime.toFixed(2);
  return Math.abs(finalTime);
};
export const sortByKey = (array, key = "") => {
  if (Array.isArray(array) || typeof array == "object") {
    return array.sort(function (a, b) {
      return new Date(a[key]) - new Date(b[key]);
    });
  } else {
    array;
  }
};
export let changeDateFormat = (i) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hours: "numeric",
  };
  var today = new Date(i);
  return today.toLocaleDateString("en-US", options);
};

export const dateCheck = (slotDate, noOfDays) => {
  let showDate = new Date(slotDate);
  let status = false;
  let todaysDate = new Date();
  let finalDate = new Date();
  finalDate.setDate(
    todaysDate.getDate() + (noOfDays || noOfDays === 0 ? noOfDays : 365)
  );
  let showDateEpoch = toEpochFormat(showDate, false);
  let finalEpochDate = toEpochFormat(finalDate, false);
  if (showDateEpoch < finalEpochDate) {
    status = true;
  }
  return status;
};