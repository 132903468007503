import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Alert, AlertTitle } from "@material-ui/lab";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../../../Atoms/Inputs/Selects/A_Select";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import A_CircularIndeternment from "../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import * as validators from "../../../../../../../Utils/Helpers/validations";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import {
  IconButton,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Tabs,
  Tab,
  Box,
  TablePagination,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import propTypes from "prop-types";
import { message } from "../../../../../../../Utils/config/messages";
import A_CheckBox from "../../../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import { IDS, labelTypes, reportEditorTypes } from "../../reportEditor.Helper";
const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

const useStyles = makeStyles((theme) => ({
  container: {
    width: 450,
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: 450,
      marginLeft:"-39.5vw",
      marginTop:"-4vh",
    },
  },
  inputcontrol: {
    width: 350,
  },
  inputcontrol1: {
    marginTop: "1rem",
  },
  inputcontrolDropdown: {
    width: 400,
  },
  section: {
    display: "inline-flex",
    position: "absolute",
    right: "8px",
    marginTop: "5rem",
  },
  btnshow: {
    marginTop: "10px",
  },
  seperatorRow: {
    width: "435px !important",
  },
  seperatorRowText: {
    fontSize: "15px",
    backgroundColor: "lightblue",
    height: "30px",
    color: "black",
    borderRadius: 4,
    textAlign: "center",
  },
  createrecordbutton: {
    textTransform: "none",
  },
  BoxPadding: {
    width: "435",
  },
  BoxWidth: {
    width: "450",
  },
  accordian: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white !important",
    },
    "&.MuiAccordion-root": {
      border: "none !important",
      boxShadow: "none !important",
      background: "white !important",
    },
  },
  expandIcon: {
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-expandIcon": {
      transform: "rotate(0deg) !important",
      position: "absolute",
      left: 0,
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg) !important",
      position: "absolute",
      left: 0,
    },
  },
  customExpandableContainer: {
    height: "125px",
  },
  customExpandableContainers: {
    height: "200px",
  },
  heading: {
    margin: "12px 30px !important",
  },
  inputcontrolAccordian: {
    width: "370px",
  },
  inputcontrolAccordians: {
    width: "350px",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  ProceedButton: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const M_AddReportForm = (props) => {
  const classes = useStyles();
  const [newReportName, setNewReportName] = useState("");
  const [isLoadingSaveReport, setIsLoadingSaveReport] = useState(false);
  const [isErrorSaveReport, setIsErrorSaveReport] = useState(false);
  const [validationSummary, setValidationSummary] = useState(undefined);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const order = "asc";
  const orderBy = "";
  const [disable, setDisable] = React.useState(true);
  const [scaling, setScaling] = React.useState(1);
  const [isBalance, setIsBalance] = React.useState(false);
  const [balanceSheet, setBalanceSheet] = React.useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    props.onShowAddnewReportForm();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTextboxes = (e) => {
    let validations = {};
    setValidationSummary(validations);
    setNewReportName(e.target.value);
  };

  const handleValidations = () => {
    let isValidated = true;
    let validations = {};
    if (!validators.isRequired(newReportName)) {
      isValidated = false;
      validations[IDS.TXT_NEW_REPORT] = {
        error: true,
        msg: message.REQUIRED_FIELD,
      };
    }
    //report name duplicate
    if (validators.isExists(newReportName, "reportName", props.reports)) {
      isValidated = false;
      validations[IDS.TXT_NEW_REPORT] = {
        error: true,
        msg: message.REPORT_NAME_ERROR,
      };
    }
    setValidationSummary(validations);
    return isValidated;
  };
  const handleReportSave = async () => {
    if (handleValidations()) {
      setIsLoadingSaveReport(true);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/create-report/${props.investmentId}`;
      let requestPayload = {
        reportName: newReportName,
      };
      const result = await httpCall.httpPost(url, requestPayload);
      if (reportEditorTypes.MESSAGE in result) {
        setIsErrorSaveReport(result);
        setIsLoadingSaveReport(false);
      } else {
        let currReports = [...props.reports];
        let newReportObject = {
          value: result.reportId,
          label: result.reportName,
          ...result,
        };
        currReports.push(newReportObject);
        setIsLoadingSaveReport(false);
        setValidationSummary(undefined);
        props.onReportSave(currReports);
      }
    }
  };
  const setBalance = (e) => {
    setBalanceSheet(e);
    if (e.value == "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const handleExcelReportChange = async () => {
    let selectedValue = balanceSheet;
    if (selectedValue) {
      setIsLoadingSaveReport(true);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/create-report/${props.investmentId}`;
      let requestPayload = {
        reportName: selectedValue.value,
      };
      const result = await httpCall.httpPost(url, requestPayload);
      if (reportEditorTypes.MESSAGE in result) {
        setIsErrorSaveReport(result);
        setIsLoadingSaveReport(false);
      } else {
        let currReports = [...props.reports];
        let newReportObject = {
          value: result.reportId,
          label: result.reportName,
          ...result,
        };
        currReports.push(newReportObject);
        setIsLoadingSaveReport(false);
        setValidationSummary(undefined);
        props.onReportSave(currReports, true, scaling, isBalance);
      }
    }
  };

  const handleCancel = () => {
    setNewReportName("");
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const renderPagingnation = () => {
    return props.reports && props.reports.length > 5 ? (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.reports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ) : (
      ""
    );
  };

  return (
    <section className={classes.container}>
      <Grid container spacing={5} justify="center">
        <Box border={1} borderColor={"black"} className={classes.BoxWidth}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label={labelTypes.BASIC_TABS_EXAMPLE}
              centered
            >
              <CustomTab
                key={0}
                label={labelTypes.REPORT}
                {...a11yProps(0)}
              ></CustomTab>
              <CustomTab
                key={1}
                label={labelTypes.TEMPLATES}
                {...a11yProps(1)}
              ></CustomTab>
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <TableContainer>
              {!props.showAddnewReportForm && (
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                  aria-label={labelTypes.DENSE_TABLE}
                >
                  <TableBody>
                    {props.reports && props.reports.length > 0 == 0 && (
                      <div className={classes.noData}>
                        <p>{message.NO_CONTENT}</p>
                      </div>
                    )}
                    {props.reports && props.reports.length > 0
                      ? stableSort(props.reports, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((report) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell>
                                    <Grid container spacing={1}>
                                      <Grid item xs={9}>
                                        <p style={{ marginTop: "19px" }}>
                                          {report.label}
                                        </p>
                                      </Grid>
                                      <Grid item spacing={3}>
                                        <IconButton>
                                          <EditIcon
                                            key={report.id}
                                            id={report.id}
                                            onClick={() => {
                                              props.onReportsChange(report);
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                      : ""}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            {renderPagingnation()}
          </TabPanel>
          <TabPanel value={tabValue} index={1} className={classes.BoxPadding}>
            {props.showAddnewReportForm && props.excelReports.length >= 0 && (
              <>
                <Grid item xs={12}>
                  <Accordion classes={{ root: classes.accordian }}>
                    <AccordionSummary
                      className={classes.expandIcon}
                      expandIcon={<ArrowRightIcon />}
                      aria-controls="panel1a-content"
                      id={IDS.PANEL_HEADER}
                    >
                      <Typography className={classes.heading}>
                        Create report from a template section
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.customExpandableContainers}
                    >
                      <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                          <A_Select
                            className={classes.inputcontrol}
                            style={{height:"57px"}}
                            label={labelTypes.CREATE_REPORT_TEMPLATE}
                            helperText=""
                            native={true}
                            variant="filled"
                            options={props.excelReports}
                            onChange={(e) => setBalance(e)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {" "}
                          <A_TextField
                            disabled={disable}
                            id={IDS.TXT_NEWSCALING}
                            type="number"
                            className={classes.inputcontrolAccordians}
                            label={labelTypes.SCALING}
                            required={false}
                            onChange={(e) => setScaling(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.ProceedButton}>
                            <div>
                              <A_CheckBox
                                disabled={disable}
                                checked={false}
                                label={labelTypes.IS_BALANCE}
                                labelPlacement="start"
                                size="medium"
                                color="primary"
                                onChange={(e) => setIsBalance(e)}
                              />
                            </div>
                            <div>
                              <A_Button
                                disabled={disable}
                                label={labelTypes.PROCEED}
                                color="primary"
                                onClick={handleExcelReportChange}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion classes={{ root: classes.accordian }}>
                    <AccordionSummary
                      className={classes.expandIcon}
                      expandIcon={<ArrowRightIcon />}
                      aria-controls="panel1a-content"
                      id={IDS.PANEL_HEADER}
                    >
                      <Typography className={classes.heading}>
                        Create custom Report
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.customExpandableContainer}
                    >
                      {" "}
                      <Grid item xs={12} className={classes.inputcontrol1}>
                        <A_TextField
                          id={IDS.TXT_NEW_REPORT}
                          className={classes.inputcontrolAccordian}
                          label={labelTypes.REPORT_NAME}
                          required={true}
                          error={
                            validationSummary &&
                            validationSummary.txtNewReport &&
                            validationSummary.txtNewReport.error
                          }
                          helperText={
                            validationSummary &&
                            validationSummary.txtNewReport &&
                            validationSummary.txtNewReport.msg
                          }
                          defaultValue={newReportName ? newReportName : ""}
                          inputType={"value"}
                          onChange={handleTextboxes}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {isLoadingSaveReport && (
                          <section>
                            {" "}
                            <span>Saving</span> <A_CircularIndeternment />
                          </section>
                        )}
                        {!isLoadingSaveReport && (
                          <section className={classes.section}>
                            <A_Button
                              label={labelTypes.CANCEL}
                              color="default"
                              variant="outlined"
                              onClick={handleCancel}
                            />
                            <A_Button
                              label={labelTypes.SAVE}
                              disabled={isLoadingSaveReport}
                              color="primary"
                              onClick={handleReportSave}
                            />
                          </section>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  {isErrorSaveReport && (
                    <Alert severity="error" style={{ width: "425px" }}>
                      <AlertTitle>{isErrorSaveReport.name}</AlertTitle>
                      <strong>{message.NO_CONTENT}</strong>
                      <br />
                      {isErrorSaveReport.message}
                    </Alert>
                  )}
                </Grid>
              </>
            )}
          </TabPanel>
        </Box>
      </Grid>
    </section>
  );
};
TabPanel.propTypes = {
  children: propTypes.node,
  index: propTypes.any.isRequired,
  value: propTypes.any.isRequired,
};
M_AddReportForm.propTypes = {
  onShowAddnewReportForm: propTypes.func,
  investmentId: propTypes.any,
  reports: propTypes.any,
  excelReports: propTypes.any,
  onReportSave: propTypes.func,
  showAddnewReportForm: propTypes.any,
  onReportsChange: propTypes.func,
};

export default M_AddReportForm;
