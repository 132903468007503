/* eslint-disable */
import handlePositiveNumber from "./handlePositiveNumber";
const TableFunction = (num, data = "") => {
  if (num == 0 && parseFloat(data) < 0 && parseFloat(data) > -1) {
    return "(" + handlePositiveNumber(num) + ")";
  } else if (num == 0 && parseFloat(data) > 0 && parseFloat(data) < 1) {
    return num;
  } else if (num == 0 || num === "" || num === null || num == "NaN") {
    return (num = "-");
  } else {
    return num;
  }
};

export default TableFunction;
