import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import CloseIcon from "@material-ui/icons/Close";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import { portfolioapproval } from "../Layouts/layout.Helper";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    margin: "2px",
    marginTop: "25px",
  },
  hidden: {
    display: "none",
  },
  shown: {
    display: "block",
  },
  approveButton: {
    backgroundColor: "#4CAF50",
    textTransform: "none",
    color: "white",
    fontWeight: "600",
    alignItems: "center",
    "&:hover": {
      background: "#3d8c40",
    },
  },
  rejectButton: {
    backgroundColor: "red",
    color: "white",
    fontWeight: "600",
    marginLeft: "10px",
    textTransform: "none",
    alignItems: "center",
    "&:hover": {
      background: "#9d4343",
    },
  },
  add: {
    width: "100%",
  },
}));

function PortfolioApproval(props) {
  const { crmId, pageUrl } = useParams();
  const [waitLoad, setwaitLoad] = React.useState(true);
  const [showPrimaryapprover, setshowPrimaryapprover] = React.useState(true);
  const [comments, setcomments] = React.useState("");
  const [defaultFilterval, setdefaultFilterval] = React.useState("");
  const [filterVal, setfilterVal] = React.useState([]);
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  let { crmContactUserId } = getCRMContactInfo();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  const classes = useStyles();
  const getChart = async () => {
    try {
      let apiPayload = {
        label: "contact",
        properties: {
          email: loggedInUserData,
        },
      };
      let filterPayload = {
        chartId: props.content.filterchartId,
        parameters: { investmentId: crmId },
      };
      const filterVals = await httpPost(
        `${process.env.API_URL}/ChartData/getchartdata`,
        filterPayload
      );
      const transformedData =
        filterVals &&
        filterVals.data &&
        filterVals.data.series &&
        filterVals.data.series.map((item) => {
          return {
            label: item.data[0],
            value: item.name[0],
          };
        });
      const defaultFiltervalues =
        filterVals &&
        filterVals.data &&
        filterVals.data.series &&
        filterVals.data.series.map((item) => {
          return item.name[0];
        })[0];
      setdefaultFilterval(defaultFiltervalues);
      setfilterVal(transformedData);
      const res = await httpPost(
        `${process.env.API_URL}/CRMData/getRecordByProperty`,
        apiPayload
      );
      if (res && res.length > 0 && res[0] && res[0].id) {
        let uri = await getDataFromSQLSp({
          chartSPs: true,
          spDetails: [
            {
              id: "Status",
              spName: "spCheckInvestmentApproval",
              parameters: {
                investmentId: crmId,
                contactId: res[0].id,
              },
            },
          ],
        });
        if (uri.Status[0]["Result"] === true && res.length > 0) {
          setwaitLoad(false);
          localStorage.setItem(portfolioapproval.PRIMARYAPPROVER, true);
        } else {
          setshowPrimaryapprover(false);
          localStorage.setItem(portfolioapproval.PRIMARYAPPROVER, false);
        }
        return uri;
      } else {
        setshowPrimaryapprover(false);
        setwaitLoad(false);
      }
    } catch (e) {
      setwaitLoad(false);
      console.error("Error occured in PortfolioApproval", e);
    }
  };
  React.useLayoutEffect(() => {}, []);
  React.useLayoutEffect(() => {
    getChart();
  }, []);
  const approveAndReject = async (sub) => {
    let resUpdate = {};

    try {
      resUpdate = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: crmId,
            spName:
              sub === true
                ? portfolioapproval.APPROVESTOREDPROCEDURE
                : portfolioapproval.REJECTSTOREDPROCEDURE,
            parameters: {
              source: defaultFilterval,
              username: userid,
              comments: comments,
            },
          },
        ],
      });
      resUpdate.then(props.getCurrentPage({ pageUrl, crmId }));
      return resUpdate;
    } catch (e) {
      return (resUpdate = { message: e.message });
    }
  };
  function handleChange(event) {
    setcomments(event.target.value);
  }
  function selectedFilter(e) {
    if (e.value.length !== 0) {
      setdefaultFilterval(e.value);
    }
  }
  return waitLoad === true ? (
    <div style={{ textAlign: "center" }}>
      {showPrimaryapprover ? (
        <CircularProgress
          style={{ width: "50px", height: "50px", marginTop: "15px" }}
        />
      ) : (
        <>
          {showPrimaryapprover == false &&
            portfolioapproval.NOTTHEDESIGNATEDAPPROVER}
        </>
      )}
    </div>
  ) : defaultFilterval && defaultFilterval != "" && showPrimaryapprover ? (
    <>
      <A_Select
        style={{
          marginRight: 0,
          marginBottom: 20,
        }}
        variant="outlined"
        fullWidth={true}
        inputProps={{ style: { textAlign: "center" } }}
        native={true}
        defaultValue={defaultFilterval}
        options={filterVal}
        onChange={selectedFilter}
      />
      <div className="" style={{ fontFamily: "Roboto" }}>
        <strong>
          <h4 style={{ color: "rgb(182 149 98)" }}>
            {portfolioapproval.DATAAPPROVAL}
          </h4>
        </strong>
        <div>{portfolioapproval.NEWDATA}</div>
        <div className="">
          <InputLabel>
            <div className="">
              <TextField
                id="standard-basic"
                label="My Comments:"
                onChange={handleChange}
                className={classes.add}
              ></TextField>
            </div>
          </InputLabel>
          <div className={classes.button}>
            <Button
              className={classes.approveButton}
              onClick={() => {
                approveAndReject(true);
              }}
            >
              <DoneIcon />
              {portfolioapproval.APPROVE}
            </Button>

            <Button
              className={classes.rejectButton}
              onClick={() => {
                approveAndReject(false);
              }}
            >
              {" "}
              <CloseIcon />
              {portfolioapproval.REJECT}
            </Button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {localStorage.getItem(portfolioapproval.PRIMARYAPPROVER) === "true"
        ? portfolioapproval.NODATATOAPPROVE
        : showPrimaryapprover == false &&
          portfolioapproval.NOTTHEDESIGNATEDAPPROVER}
    </>
  );
}
PortfolioApproval.propTypes = {
  content: PropTypes.any,
  options: PropTypes.any,
  getCurrentPage: PropTypes.func,
};

export default PortfolioApproval;
