import React, { useState, useRef } from "react";
import "./styles.css";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";

const M_DateRangeFilter = (props) => {
  const datePickerRef = useRef();
  const [values, setValues] = useState([props.startDate, props.endDate]);
   React.useEffect(() => {
    if (
      values &&
      values[0] &&
      values[0] !== props.startDate &&
      values[1] &&
      values[1] != props.endDate
    ) {
      datePickerRef.current.closeCalendar();
      props.filterChange(
        values[0].format("DD/MM/YYYY"),
        values[1] ? values[1].format("DD/MM/YYYY") : ""
      );
    }
  }, [values]);
   return (
    <DatePicker
      ref={datePickerRef}
      render={<InputIcon />}
      editable={false}
      hideOnScroll
      multiple
      numberOfMonths={2}
      format="DD/MM/YYYY"
      rangeHover
      value={values}
      onChange={setValues}
      range
    ></DatePicker>
  );
};

export default M_DateRangeFilter;
