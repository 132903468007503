/* eslint-disable */
import { dealTeamActionType } from "./actionTypes";

export const delEdge = (payload) => {
  return {
    type: dealTeamActionType.DEL_DEAL_TEAM_EDGE,
    payload,
  };
};

export const delEdgeSucceeded = (payload) => {
  return {
    type: dealTeamActionType.DEL_DEAL_TEAM_EDGE_SUCCEEDED,
    payload,
  };
};

export const delEdgeFailed = (payload) => {
  return {
    type: dealTeamActionType.DEL_DEAL_TEAM_EDGE_FAILED,
    payload,
  };
};

export const getEdge = (payload) => {
    return {
      type: dealTeamActionType.GET_DEAL_TEAM_EDGE,
      payload,
    };
  };
  
  export const getEdgeSucceeded = (payload) => {
    return {
      type: dealTeamActionType.GET_DEAL_TEAM_EDGE_SUCCEEDED,
      payload,
    };
  };
  
  export const getEdgeFailed = (payload) => {
    return {
      type: dealTeamActionType.GET_DEAL_TEAM_EDGE_FAILED,
      payload,
    };
  };

