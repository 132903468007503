 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import theme from "../../../../Themes/defaultTheme";
import MuiAlert from "@material-ui/lab/Alert";
import { Slide } from "@material-ui/core";

function handleTransition(props) {
  return <Slide {...props} direction="up"></Slide>;
}
const A_Snackbar = (props) => {
  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
  ));

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: props.vertical,
          horizontal: props.horizontal,
        }}
        open={props.open}
        autoHideDuration={props.autoHideDuration}
        onClose={props.handleClose}
        severity={props.type}
        message={props.message}
        TransitionComponent={handleTransition}
        action={<React.Fragment>{props.action}</React.Fragment>}
      >
        <Alert onClose={props.handleClose} severity={props.type}>
          {props.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
A_Snackbar.defaultProps = {
  open: false,
  autoHideDuration: 5000,
  message: "Placeholder for message",
  vertical: "bottom",
  horizontal: "right",
  type: "success",
};
export default A_Snackbar;
