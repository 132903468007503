import React from "react";
import { onDownload } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
const useStyles = makeStyles({
  textLabel: {
    wordBreak: "break-word",
    width: 1000,
  },
  button: {
    background: "none",
    border: "none",
    color: "blue",
    cursor: "pointer",
    margin: "0px",
    padding: "0px",
  },
  textField: {
    marginLeft: "35%",
  },
});

const ViewFieldAsLink = (props) => {
  const classes = useStyles();
  const isLink = fetchMyAPI();
  var containerType =
    props && props.content && props.content.containerType
      ? props.content.containerType
      : "";
  var allData;
  function fetchMyAPI() {
    if (props.files.length > 0) {
      var response = props.files;
      allData = response;
      let fileName = [];
      let finalFileNames = [];
      response.map((item) => {
        fileName.push(item.file.split("_").pop());
      });
      for (let file in allData) {
        let tempFile = allData[file]["file"].split("/").pop();
        let field = tempFile.split("__");

        if (field.length > 0 && field[0] === props.FieldId) {
          fileName.push(field.pop());
          finalFileNames.push(allData[file]["file"]);
        }
      }
      return finalFileNames;
    } else {
      return [];
    }
  }

  const openLink = (file) => {
    onDownload(file, containerType, true);
  };
  return (
    <>
      {isLink && isLink.length > 0 && isLink != ""
        ? isLink.map((item) => {
            return (
              <div key={item}>
                <span className={classes.button} onClick={() => openLink(item)}>
                  {item.split("_").pop()}
                </span>
              </div>
            );
          })
        : isLink}
    </>
  );
};
ViewFieldAsLink.propTypes = {
  files: PropTypes.any,
  FieldId: PropTypes.any,
  content: PropTypes.any,
};

export default ViewFieldAsLink;
