import { getObjectFromJSONString } from "../../Navigations/AddEntity/M_ManageEntityForm.helper";
const storageName = "navigationHistory";
export const getBreadCrumps = () => {
  let navigationHistory =
    getObjectFromJSONString(sessionStorage.getItem(storageName), "", []) || [];
  let [previous, current] = navigationHistory;
  return current && previous;
};

export const flushBreadCrumps = () => {
  sessionStorage.removeItem(storageName);
};

export const setBreadCrumps = (currentLocation) => {
  let tempCurrentLocation = { ...currentLocation };
  let navigationHistory =
    getObjectFromJSONString(sessionStorage.getItem(storageName), "", []) || [];
  let [previous, current] = navigationHistory;
  if (
    !tempCurrentLocation.pageUrl ||
    !tempCurrentLocation.crmId ||
    !tempCurrentLocation.templateId ||
    !tempCurrentLocation.title
  ) {
    return;
  }
  if (previous) {
    if (current) {
      let { url, title } = current;
      let [pageUrl, crmId, templateId] = url.slice(1).split("/");

      if (
        pageUrl !== tempCurrentLocation.pageUrl ||
        crmId !== tempCurrentLocation.crmId
      ) {
        let newRoute = {
          url: `/${tempCurrentLocation.pageUrl}/${tempCurrentLocation.crmId}/${tempCurrentLocation.templateId}`,
          title: tempCurrentLocation.title,
        };
        navigationHistory = [current, newRoute];
      } else if (
        templateId !== tempCurrentLocation.templateId ||
        title !== currentLocation.title
      ) {
        let newRoute = {
          url: `/${pageUrl}/${crmId}/${tempCurrentLocation.templateId}`,
          title: tempCurrentLocation.title,
        };
        navigationHistory = [previous, newRoute];
      }
    } else {
      let newRoute = {
        url: `/${tempCurrentLocation.pageUrl}/${tempCurrentLocation.crmId}/${tempCurrentLocation.templateId}`,
        title: tempCurrentLocation.title,
      };
      navigationHistory = [previous, newRoute];
    }
  } else {
    let newRoute = {
      url: `/${tempCurrentLocation.pageUrl}/${tempCurrentLocation.crmId}/${tempCurrentLocation.templateId}`,
      title: tempCurrentLocation.title,
    };
    navigationHistory = [newRoute];
  }

  sessionStorage.setItem(storageName, JSON.stringify(navigationHistory));
};

export const getBreadCrumpsV2 = () => {
  let navigationHistory =
    getObjectFromJSONString(sessionStorage.getItem(storageName), "", []) || [];
  let previousRouteIndex = navigationHistory.length - 2;
  return navigationHistory[previousRouteIndex];
};
export const routeBack = () => {
  let navigationHistory =
    getObjectFromJSONString(sessionStorage.getItem(storageName), "", []) || [];
  navigationHistory.pop();
  sessionStorage.setItem(storageName, JSON.stringify(navigationHistory));
};
export const setBreadCrumpsV2 = (currentNavigation) => {
  let navigationHistory =
    getObjectFromJSONString(sessionStorage.getItem(storageName), "", []) || [];
  let { pageUrl, crmId, templateId, title } = currentNavigation;
  if (!pageUrl || !crmId || !templateId) {
    return "Invalid Route";
  }
  if (!title || title === "") {
    currentNavigation.title = pageUrl;
  }
  let routesDetails = [...navigationHistory];
  let tempCurrentNavigation = currentNavigation;
  let lastIndex = routesDetails.length - 1;
  let current = routesDetails[lastIndex];
  if (current) {
    if (
      current.pageUrl !== tempCurrentNavigation.pageUrl ||
      current.crmId !== tempCurrentNavigation.crmId
    ) {
      routesDetails.push(tempCurrentNavigation);
    } else if (
      current.templateId &&
      tempCurrentNavigation.templateId &&
      current.templateId !== tempCurrentNavigation.templateId
    ) {
      routesDetails[lastIndex] = tempCurrentNavigation;
    }else if (current.pageUrl === tempCurrentNavigation.pageUrl&&
      current.crmId === tempCurrentNavigation.crmId && current.title !== tempCurrentNavigation.title){
        routesDetails[lastIndex].title = tempCurrentNavigation.title
      }
  } else {
    routesDetails.push(tempCurrentNavigation);
  }
  sessionStorage.setItem(storageName, JSON.stringify(routesDetails));
};
