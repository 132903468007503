 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../../Themes/defaultTheme";



const A_DotBadge = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
     
    },
    badgeStyle1: props.badgeStyle1
  }));
  const classes = useStyles();
  {console.log("classsnamee", props)}
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Badge
          badgeContent={props.badgeContent}
          color={props.color}
          variant="dot"
          className={classes.badgeStyle1}
          anchorOrigin={{
            vertical: props.vertical,
            horizontal: props.horizontal,
          }}
          invisible={props.invisible}
        >
          {props.badgeHolderComponent}
        </Badge>
      </div>
    </ThemeProvider>
  );
};
A_DotBadge.defaultProps = {
  color: "primary",
  vertical: "top",
  horizontal: "right",
  invisible: false,
  className:"hii",
};
export default A_DotBadge;
