export const newsFields = {
  LINK: "Link",
  TITLE: "Title",
  SNIPPET: "Snippet",
  ITEMS: "Items",
  CATEGORY: "category",
  SOURCENAME: "SourceName",
  MODIFICATIONDATETIME: "ModificationDateTime",
  ARTICLEREF: "ArticleRef",
};
