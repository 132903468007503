import { TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import A_Select from "../../../../Atoms/Inputs/Selects/A_Select";
import A_TextField from "../../../../Atoms/Inputs/TextFields/A_TextField";

export default function EsgResponseForm(props) {
  const handleField = (e) => {
    props.handleSectionResponse(
      props.sectionIndex,
      props.questionIndex,
      "response",
      e.target.value
    );
  };
  const handleScoreField = (e) => {
    props.handleSectionResponse(
      props.sectionIndex,
      props.questionIndex,
      "score",
      parseInt(e.target.value)
    );
  };
  const handleDropdownField = (e) => {
    props.handleSectionResponse(
      props.sectionIndex,
      props.questionIndex,
      "response",
      e.value
    );
  };

  const AnswerType = (data) => {
    switch (data.answerType) {
      case "text":
        return (
          <A_TextField
            multiline={true}
            variant="standard"
            label="Type here"
            onChange={handleField}
            fullWidth={true}
          />
        );
      case "number":
        return (
          <A_TextField
            type={"number"}
            variant="standard"
            label="Type here"
            onChange={handleField}
            fullWidth={true}
            helperText="enter number only"
          />
        );
      case "options":
        return (
          <A_Select
            label="Options"
            key={props.id}
            native={true}
            variant="standard"
            fullWidth={true}
            options={data.answerOptions.map((options) => ({
              value: options,
              label: options,
            }))}
            onChange={handleDropdownField}
          />
        );
    }
  };
  return (
    <>
      <TableRow>
        <TableCell align="left" component="th" width="60%" scope="row">
          <Typography>
            {props && props.responseData && props.responseData.title}
          </Typography>
        </TableCell>

        <TableCell width="10%" align="left">
          <A_TextField
            variant="standard"
            label="Score"
            type={"number"}
            defaultValue="10"
            onChange={handleScoreField}
          />
        </TableCell>
        <TableCell align="left" width="30%">
          {AnswerType(props.responseData)}
        </TableCell>
      </TableRow>
    </>
  );
}
