import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
// import Checkbox from "@material-ui/core/Checkbox";

export default function EnhancedTableHead(props) {
  const {
    // classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Pillar",
      numeric: false,
      disablePadding: true,
      label: "Pillar",
    },
    {
      id: "SubPillar",
      numeric: true,
      disablePadding: false,
      label: "Sub Pillar",
    },
    {
      id: "QuestionText",
      numeric: true,
      disablePadding: false,
      label: "Question Text",
    },
    {
      id: "SubQuestions",
      numeric: true,
      disablePadding: false,
      label: "Sub Questions",
    },
    {
      id: "ResponseType",
      numeric: true,
      disablePadding: false,
      label: "Response Type",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ background: "#757474", color: "white" }}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#757474", color: "white" }}
          >
            <TableHead
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableHead>
          </TableCell>
        ))}
        <TableCell
          padding="checkbox"
          style={{ background: "#757474", color: "white" }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
