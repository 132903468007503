export const esgBodyCss = {
    textField: {
      width: "650",
      //height: "50px"
    },
    inputcontrolDropdown: {
      width: "390px",
    },
    dialogBoxHeight: {
      height: "70vh",
    },
    postAddIcon: {
      margin: "14px 12px",
      cursor: "pointer",
    },
     
  
    textField1: {
      height: "50px",
    },
    btnshow: {
      marginTop: "22px",
    },
    button: {
      float: "right",
      margin: "1rem 0 1rem 0",
    },
    lookup:{
      width:"100%"
    },
    saveButtonCss:{
     textAlign:"center",
     marginTop:"0.8rem"
    },
    datePickerCss:{
      width:"100%",

    },
    mainDiv:{
      margin :"0.4rem 0.8rem 0 0.4rem"
    },
    cardCss:{
      marginRight: "1.2rem"
    },
    skeleton:{
      margin: "2rem"
    },
    appBar:{
      padding: "0.5rem"
    }, 
    list:{
      maxHeight: "60vh"
    }
  }

export const esgMainQuestionnaireCss = {
  textField: {
  },
  inputcontrolDropdown: {
    // width: "390px",
  },
  dialogBoxHeight: {
    height: "70vh",
  },

  postAddIcon: {
    margin: "14px 12px",
    cursor: "pointer",
  },
   
add:{
  fontSize: "38px"
},
  textField1: {
    width: "300",
    height: "50px",
  },
  btnshow: {
    marginTop: "22px",
    position: "relative",
    bottom: "20px",
    left: "0.5rem"
  },
  button: {
    float: "right",
    margin: "1rem 0 1rem 0",
  },
  optionsView: { marginTop: " 1.7rem", marginLeft: "1rem" },
  grid:{
    paddingLeft:"0.4rem"
  },
  saveButtonCss:{
    float:"right"
  }

}
export const esgHeaderCss = {
  container: {
    width: 450,
    //marginLeft: "10%",
  },

  root: {
    textAlign: "center",
    position: "relative",
    top: "75px",
  },
  questionStyle: {
    marginBottom: "20px",
    padding: "15px",
    minWidth: "350px",
  },
  inputcontrol: {
    width: 450,
  },
  inputcontrolDropdown: {
    width: 400,
  },
  btnshow: {
    marginTop: "10px",
  },
  seperatorRow: {
    //border: "1px solid red",
    width: "450px !important",
  },
  seperatorRowText: {
    fontSize: "15px",
    backgroundColor: "lightblue",
    height: "30px",
    color: "black",
    padding: 5,
    borderRadius: 4,
    textAlign: "center",
  },
 
}