export const filterPayload = {
  id: "3f7c1e19-6b95-4060-b1db-17c3c2f82f3e",
  label: "deal",
  type: "vertex",
  properties: {},
  edges: [],
  duplicateDetection: null,
  submitToStoredProc: "",
  validation: null,
  security: {
    read: {
      groupids: [""],
      users: [""],
    },
    update: {
      groupids: [""],
      users: [""],
    },
    full: {
      groupids: [""],
      users: [],
    },
    privateRecord: false,
  },
  inVProperty: null,
};
export const investorType = [
  {
    id: "118840002",
    label: "Hedge Fund",
    sno: 1,
  },
  {
    id: "10",
    label: "Asset Manager",
    sno: 2,
  },
  {
    id: "13",
    label: "Bank",
    sno: 3,
  },
  {
    id: "12",
    label: "Consultant",
    sno: 4,
  },
  {
    id: "2",
    label: "Corporate Pension Fund",
    sno: 5,
  },
  {
    id: "1",
    label: "Endowment / Foundation",
    sno: 6,
  },
  {
    id: "6",
    label: "Family Office",
    sno: 7,
  },
  {
    id: "8",
    label: "Fund of Funds",
    sno: 8,
  },
  {
    id: "11",
    label: "Government / Public Sector",
    sno: 9,
  },
  {
    id: "5",
    label: "High Net Worth Individual",
    sno: 10,
  },
  {
    id: "3",
    label: "Insurance",
    sno: 11,
  },
  {
    id: "4",
    label: "Investment Company",
    sno: 12,
  },
  {
    id: "118840001",
    label: "Private Debt Fund",
    sno: 13,
  },
  {
    id: "15",
    label: "Private Equity",
    sno: 14,
  },
  {
    id: "9",
    label: "Private Pension Fund",
    sno: 15,
  },
  {
    id: "16",
    label: "Public Pension Fund",
    sno: 16,
  },
  {
    id: "7",
    label: "Sovereign Wealth Fund",
    sno: 17,
  },
  {
    id: "14",
    label: "Superannuation Scheme",
    sno: 18,
  },
  {
    id: "118840000",
    label: "Wealth Manager",
    sno: 19,
  },
];
export const RelationShip = [
  {
    id: "1",
    label: "Tier 1",
    sno: 1,
  },
  {
    id: "2",
    label: "Tier 2",
    sno: 2,
  },
  {
    id: "3",
    label: "Existing",
    sno: 3,
  },
  {
    id: "4",
    label: "Consultant",
    sno: 4,
  },
  {
    id: "5",
    label: "To be qualified",
    sno: 5,
  },
  {
    id: "6",
    label: "Prospect",
    sno: 6,
  },
  {
    id: "7",
    label: "Dormant",
    sno: 7,
  },
  {
    id: "8",
    label: "Tier 3",
    sno: 8,
  },
];
export const content = [
  {
    label: "City",
    name: "City",
    type: "String",
  },
  {
    label: "Bite size(€m)",
    type: "Heading",
  },
  {
    label: "min",
    name: "Asp_BiteSizeMin",
    type: "(text Input)Numeric",
  },
  {
    label: "max",
    name: "Asp_BiteSizeMax",
    type: "(text Input)Numeric",
  },
  {
    label: "Investment Interest",
    type: "Heading",
  },
  {
    label: "PE",
    name: "Ana_InterestinPEFund",
    type: "Checkbox",
  },
  {
    label: "AOF",
    name: "Ana_InterestinDebtFund",
    type: "Checkbox",
  },
  {
    label: "CIF",
    name: "ana_InterestinCreditIncomeFund",
    type: "Checkbox",
  },
  {
    label: "PE co-invest",
    name: "CoinvestProbability",
    type: "Checkbox",
  },
  {
    label: "AOF co-invest",
    name: "Ana_DebtConinvestProbability",
    type: "Checkbox",
  },
  {
    label: "Contact Preferences",
    type: "Heading",
  },
  {
    label: "LPAC",
    name: "ana_mail_pref_LPAC",
    type: "Checkbox",
  },
  {
    label: "Announcements",
    name: "ana_mail_pref_announcements",
    type: "Checkbox",
  },
  {
    label: "Legal amendments",
    name: "ana_mail_pref_legal",
    type: "Checkbox",
  },
  {
    label: "AGM",
    name: "ana_mail_pref_agm",
    type: "Checkbox",
  },
];
