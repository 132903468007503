export const reportEditorTypes={
    DEFAULT_CRMID:"4E6A21C1-CEEA-E811-80D3-005056BE5CE6",
    STANDARD: "standard",
    NO_DATA:"No data available.",
    MESSAGE:"message",
    PERCENTAGE:"percentage",
    SUCCESS:"success",
    SUCCESS_MSG:"Record Saved Successfully",
    DELETE_MSG:"Are you sure you want to delete this row?",
    DISPLAY_NAME_APPENDED: {
        id: "displayNameAppended",
        label: "Display Name",
        sorting: false,
        hide: false,
    },
    METRIC_NAME:{
        id: "metricName",
        label: "Metric",
        sorting: false,
        hide: false,
    },
    DECIMAL_PLACES:{
        id: "decimalPlaces",
        label: "Decimal Places",
        sorting: false,
        hide: false,
    },
    ROW_BREAK_AFTER_TEXT: {
        id: "rowBreakAfterText",
        label: "Row Break",
        sorting: false,
        hide: false,
      }

}

export const dataTypes = [
    { id: "General", label: "General", value: "General" },
    { id: "Percentage", label: "Percentage", value: "Percentage" },
    { id: "Multiple", label: "Multiple", value: "Multiple" },
  ];
export const rowTypes = [
    { id: "standard", label: "Standard", value: "standard" },
    { id: "preCalculated", label: "Pre calculated", value: "preCalculated" },
    { id: "ratio", label: "Ratio", value: "ratio" },
  ];
  
export const annualisationPeriodBasisOptions = [
    { id: "d", label: "Days", value: "d" },
    { id: "m", label: "Months", value: "m" },
  ];
export const formattingClasses = [
    { label: "indent", value: "indent" },
    { label: "indent2", value: "indent2" },
    { label: "bold", value: "bold" },
    { label: "small", value: "small" },
    { label: "total row", value: "total_row" },
    { label: "italic", value: "italic" },
  ];

export const labelTypes={
    METRIC_TYPE: 'Metric type',
    YTD_METRIC: 'YTD metric',
    DENOMINATOR_METRIC: 'Denominator Metric',
    ALTERNATIVE_DISPLAY_NAME: 'Alternative display name',
    ROW_STYLING: 'Row Styling',
    DECIMAL_PLACES: 'Decimal Places',
    ROW_BREAK_AFTER: 'Row break after',
    FLIP_VARIANCE_COLOURS: 'Flip variance colours',
    SHOW_IN_REPORT: 'Show in report',
    ANNUALISE_NUMERATOR: 'Annualise numerator',
    PERIOD_BASIS_TO_USE: 'Period basis to use',
    MONTHS_AVERAGE_DENOMINATOR: 'n+1 months average denominator',
    BALANCE_METRIC: 'Balance Metric',
    DATA_TYPE: 'Data type',
    SCALING: 'Scaling',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    BASIC_TABS_EXAMPLE: 'basic tabs example',
    DENSE_TABLE: 'a dense table',
    CREATE_REPORT_TEMPLATE: 'Create report from a template section',
    IS_BALANCE: 'isBalance',
    PROCEED: 'Proceed',
    REPORT_NAME: 'Report Name',
    REPORT:"Reports",
    TEMPLATES:"Templates"
  }


export const IDS={
    ROW_TYPE: 'rowType',
    METRIC_ID: 'metricId',
    YTD_METRIC: 'ytdMetric',
    RATIO_DENOMINATOR: 'ratioDenominator',
    DISPLAY_NAME: 'displayName',
    CLASSES: 'classes',
    DECIMAL_PLACES: 'decimalPlaces',
    ROW_BREAK_AFTER: 'rowBreakAfter',
    INVERSE_PARITY: 'inverseParity',
    ENABLED: 'enabled',
    RATIO_ANNUALISE: 'ratioAnnualise',
    ANNUALISATION_PERIOD_BASIS: 'annualisationPeriodBasis',
    RATIO_N_PLUS_ONE_AVG: 'ratioNPlusOneAvg',
    IS_BALANCE: 'isBalance',
    RATIO_DATA_TYPE: 'ratioDataType',
    SCALING: 'scaling',
    PANEL_HEADER: 'panel1a-header',
    TXT_NEWSCALING: 'txtNewscaling',
    TXT_NEW_REPORT: 'txtNewReport'
  }

  export const row={
    PRECALCULATED:"preCalculated",
    RADIO:"ratio"
  }


