/* eslint-disable */
import {manageExcelTemplateActionType} from "./actionTypes";

export const getTabs=(payload)=> {    
    return {
      type: manageExcelTemplateActionType.GET_TABS, 
      payload     
    };
}
export const getTabsSuccessed=(payload)=> {    
    return {
        type: manageExcelTemplateActionType.GET_TABS_SUCCESSED, 
      payload     
    };
}

export const getTabsFailed=(payload)=> {    
    return {
        type: manageExcelTemplateActionType.GET_TABS_FAILED, 
      payload     
    };
}

export const setTabs=(payload)=> {    
  return {
    type: manageExcelTemplateActionType.SET_TABS, 
    payload     
  };
}

export const saveTabs=(payload)=> {    
  return {
    type: manageExcelTemplateActionType.SAVE_TABS, 
    payload     
  };
}
export const saveTabsSuccessed=(payload)=> {    
  return {
      type: manageExcelTemplateActionType.SAVE_TABS_SUCCESSED, 
    payload     
  };
}

export const saveTabsFailed=(payload)=> {    
  return {
      type: manageExcelTemplateActionType.SAVE_TABS_FAILED, 
    payload     
  };
}

