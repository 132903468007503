/* eslint-disable */
import { manageFormActionTypes } from "./actionTypes";

export const setEditFormData = (payload) => {
  return {
    type: manageFormActionTypes.SET_EDIT_FORM,
    payload,
  };
};
export const editForm = (payload) => {
  return {
    // type: manageFormActionTypes.SET_EDIT_FORM,
    // payload,
  };
};
