import { httpPost } from "../../../../../../../Utils/apis/apis";
export const fileListWithDisplayName = (fileList) => {
  let listwithdisplayname = [];
  if (fileList) {
    fileList.map((file) => {
      let fileArr = file.file.split("/");
      file = { ...file, fileDisplayName: fileArr.slice(-1)[0] };
      listwithdisplayname.push(file);
    });
  }
  return listwithdisplayname;
};
export const sortFiles = (list, key) => {
  return list.sort((a, b) => a[key].localeCompare(b[key]));
};
export const downloadDocument = async (
  e,
  type = "generic",
  additionDownloadHelper
) => {
  let documentName = e.file;
  let url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  if (documentName.includes("//")) {
    documentName = documentName.replace(/\/\//g, "/");
  }
  let header = {
    filename: documentName,
    type: type,
    "file-operation": "download",
    "process-file": "yes",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
  };

  if (additionDownloadHelper) {
    let { extraHeaders = {}, apiUrl } = additionDownloadHelper;
    header = { ...header, ...extraHeaders };
    url = apiUrl || url;
  }
  await httpPost(
    url,
    {
      method: "GET",
    },
    { responseType: "blob", headers: header }
  ).then((response) => {
    const url1 = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", e.fileDisplayName);
    document.body.appendChild(link);
    link.click();
  });
};
export const onDeleteDocument = async (e, type = "generic") => {
  const documentName = e.file;
  const url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  let header = {
    filename: documentName,
    type: type,
    "file-operation": "delete",
    "process-file": "no",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
  };
  await httpPost(
    url,
    {
      method: "GET",
    },
    { responseType: "blob", headers: header }
  );
};
