import React from "react";
import { makeStyles } from "@material-ui/core";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { newsFields } from "../../../Organisms/Common/NewsPortal/newsportal.constant";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import { newsContentBasedOnType } from "../../../Organisms/Common/Newsportal/newPortal.helper";
import { httpGet } from "../../../../../Utils/apis/apis";
import { Skeleton } from "@material-ui/lab";
import propTypes from "prop-types";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
const useStyles = makeStyles({
  news: {
    padding: "1rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(213, 213, 213, 0.8)",
    },
  },

  header: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "grey",
    marginTop: "1rem",
  },
  head: {
    marginTop: "2rem",
  },
  subheading: {
    fontSize: "0.6rem",
    fontWeight: "500",
    color: "#2db0fa",
    fontStyle: "italic",
  },
  details: {
    fontSize: "0.8rem",
    color: "grey",
  },
  dialog: {
    margin: "auto",
    width: 300,
  },
});

const getDataFromInnerNewsApi = (newsDataSet) => {
  let finalHTMLDataForNewsDisplay = "";

  finalHTMLDataForNewsDisplay =
    newsDataSet &&
    newsDataSet.Articles &&
    newsDataSet.Articles.map((article) => {
      return [
        `<br/><h6>${
          article.Title
            ? article.Title.map((bodyData) =>
                bodyData.Items
                  ? bodyData.Items.map((item) =>
                      newsContentBasedOnType(item, true)
                    ).join("")
                  : ""
              ).join("<br/>")
            : ""
        }</h6>`,
        // article.Body
        //   ? article.Body.map((bodyData) =>
        //       bodyData.Items
        //         ? bodyData.Items.map((item) =>
        //             newsContentBasedOnType(item)
        //           ).join("")
        //         : ""
        //     ).join("")
        //   : "",
        article.LeadParagraph
          ? article.LeadParagraph.map((bodyData) =>
              bodyData.Items
                ? bodyData.Items.map((item) =>
                    newsContentBasedOnType(item, true)
                  ).join("")
                : ""
            ).join("<br/>")
          : "",

        article.TailParagraphs
          ? article.TailParagraphs.map((bodyData) =>
              bodyData.Items
                ? bodyData.Items.map((item) =>
                    newsContentBasedOnType(item, true)
                  ).join("")
                : ""
            ).join("<br/><br/>")
          : "",
      ].join("<br/><br/>");
    }).join("");
  return finalHTMLDataForNewsDisplay;
};
export default function M_NewsPortal({ newsData }) {
  const classes = useStyles();
  const [isModelOpen, setIsModelOpen] = React.useState(false);
  const [selectedDataModel, setSelectedDataMOdel] = React.useState({});
  const [finalData, setFinalData] = React.useState("");
  const [isContentLoading, setIsContentLoading] = React.useState(false);
  const closeModel = () => {
    setIsModelOpen(false);
    setSelectedDataMOdel({});
  };
  const fetchNewContent = async (articleRef) => {
    setIsContentLoading(true);
    try {
      const response = await httpGet(
        `/NewsPortal/getArticleByReference?articleRef=${articleRef}`
      );
      let finalResponse = getDataFromInnerNewsApi(response);
      setFinalData(finalResponse);
      setIsContentLoading(false);
    } catch (error) {
      setIsContentLoading(false);
    }
  };
  return (
    <div>
      {newsData &&
        newsData.map((news) => (
          <>
            <div
              className={classes.news}
              onClick={() => {
                setSelectedDataMOdel(news);
                fetchNewContent(
                  news[newsFields.ARTICLEREF] ? news[newsFields.ARTICLEREF] : ""
                );
                setIsModelOpen(true);
              }}
            >
              <div
                className={classes.header}
                dangerouslySetInnerHTML={{
                  __html: news[newsFields.TITLE]
                    ? news[newsFields.TITLE]
                        .map((title) => title.join(" "))
                        .join(" ")
                    : "",
                }}
              ></div>
              <div className={classes.subheading}>
                {news.category ? news.category.join(", ") : ""}
              </div>
              <div
                className={classes.details}
                dangerouslySetInnerHTML={{
                  __html: news[newsFields.SNIPPET]
                    ? news[newsFields.SNIPPET].map((title) => title).join(" ")
                    : "",
                }}
              ></div>
            </div>
            <A_Divider></A_Divider>
          </>
        ))}
      <>
        <A_SimpleDialog
          className={classes.dialog}
          open={isModelOpen}
          title={
            <div
              className={classes.header}
              dangerouslySetInnerHTML={{
                __html:
                  selectedDataModel && selectedDataModel[newsFields.TITLE]
                    ? selectedDataModel[newsFields.TITLE]
                        .map((title) => title.join(""))
                        .join(" ")
                    : "",
              }}
            ></div>
          }
          dialogActions={
            <div>
              <A_Button
                label="Close"
                onClick={() => {
                  setIsModelOpen(false);
                }}
              />
            </div>
          }
          maxWidth="md"
          onClose={closeModel}
          disableEscapeKeyDown={true}
          dialogContent={
            <>
              <div className={classes.subheading}>
                {selectedDataModel && selectedDataModel.category
                  ? selectedDataModel.category.join(", ")
                  : ""}
              </div>

              {isContentLoading ? (
                <>
                  <Skeleton variant="text" width={80} height={80} />
                  <Skeleton variant="text" width={"100%"} height={80} />
                  <Skeleton variant="rect" width={"100%"} height={118} />
                </>
              ) : (
                <div
                  className={classes.details}
                  dangerouslySetInnerHTML={{
                    __html: finalData,
                  }}
                >
                  {}
                </div>
              )}
            </>
          }
        ></A_SimpleDialog>
      </>
    </div>
  );
}
M_NewsPortal.propTypes = {
  newsData: propTypes.object,
};
