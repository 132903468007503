import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { httpPost } from "../../../../../Utils/apis/apis";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import A_Snackbar from "../../Feedback/Snackbars/A_Snackbar";
import { message } from "../../../../../Utils/config/messages";
import { Box } from "@material-ui/core";
import { propTypes } from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { getCurrentPage } from "../../../../../store/actions/currentPageActions";
const useStyles = makeStyles(() => ({
  add: {
    width: "44%",
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "white",
    "&:hover": {
      backgroundColor: "#ba202e",
    },
  },
}));
const Comment = (props) => {
  const [comments, setcomments] = React.useState("");
  const [commentApiRes, setCommentApiRes] = useState("");
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [status, setStatus] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    handleValidation();
    setcomments(event.target.value);
  };
  const { pageUrl, crmId } = useParams();
  const user = useSelector((state) => state.oidc.user);
  const userEmail = user && user.profile && user.profile.email;
  const userName = `${user && user.profile && user.profile.given_name} ${
    user && user.profile && user.profile.family_name
  }`;
  const getCommentData = async () => {
    let payload = {
      spName: props.content.getSpName,
      parameters: {
        ProposalGUID: crmId,
      },
    };
    let response = await httpPost(`/SQLBasedForm/SQLFormOperations`, payload);
    setCommentApiRes(response[0].Comment);
    if (!response[0].Comment) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCommentData();
  }, []);
  useEffect(() => {
    if (commentApiRes) {
      setDisable(true);
      setLoading(false);
    }
  }, [commentApiRes]);
  const handleValidation = () => {
    if (!comments || comments.trim() === "") {
      setError(true);
      setHelperText("Comment is required");
      return false;
    }
    setError(false);
    setHelperText("");
    return true;
  };
  const handleBtnStatus = async (value) => {
    if (handleValidation()) {
      let dataObj = {
        ProposalGUID: crmId,
        comment: comments,
        buttonClicked: value,
        UserName: userName,
        userEmail: userEmail,
      };
      let payload = {
        spName: props.content.spName,
        parameters: {},
        parametersAsJsonString: JSON.stringify(dataObj),
      };
      setDisable(true);
      await httpPost(`/SQLBasedForm/SQLFormOperations`, payload, "", true);
      setStatus(true);
      setTimeout(() => {
        setStatus(false);
        dispatch(getCurrentPage({ pageUrl, crmId }));
      }, 2000);
    }
  };
  return (
    <>
      {!loading ? (
        <div>
          <InputLabel>
            <div className="">
              <TextField
                id="standard-basic"
                label="Comments:"
                onChange={handleChange}
                className={classes.add}
                required
                error={error}
                helperText={helperText}
                value={commentApiRes ? commentApiRes : comments}
                disabled={disable}
              ></TextField>
            </div>
          </InputLabel>
          <div style={{ marginTop: 20 }}>
            <Button
              style={{
                marginRight: 10,
                backgroundColor: disable ? "" : "#66BB6A",
                color: disable ? "" : "white",
              }}
              variant="contained"
              disabled={disable}
              onClick={() => handleBtnStatus("Approved")}
            >
              Approved
            </Button>
            <Button
              style={{ marginRight: 10 }}
              className={classes.dangerButton}
              variant="contained"
              color="primary"
              disabled={disable}
              onClick={() => handleBtnStatus("Rejected")}
            >
              Rejected
            </Button>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="primary"
              disabled={disable}
              onClick={() => handleBtnStatus("Provide more information")}
            >
              Provide more information
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disable}
              onClick={() => handleBtnStatus("Counter offered")}
            >
              Counter offered
            </Button>
          </div>
          <A_Snackbar
            open={status}
            message={message.COMMENT_ACTION_MSG}
            autoHideDuration={2000}
            horizontal="center"
            type={"success"}
          />
        </div>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
Comment.propTypes = {
  content: propTypes.any,
};
function mapStateToProps() {
  return {};
}
export default connect(mapStateToProps)(Comment);
