/* eslint-disable */
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

const M_RenderSkeletion = (props) => {
  const { classes } = props;
  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <div style={{ display: "flex" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={40}
                height={40}
              />
              <Skeleton
                animation="wave"
                width={"100%"}
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {<Skeleton animation="wave" width={"100%"} />}
              </Typography>

              {<Skeleton animation="wave" width={"100%"} />}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
};

export default M_RenderSkeletion;
