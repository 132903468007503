import { manualUpdateActionType } from "../actions/actionTypes";

let initialState = {};
const getDTMetricReducer = (state = initialState, action) => {
  switch (action.type) {
    case manualUpdateActionType.GET_DT_VALUE:
      var currState = { ...state, isLoading: true };
      return currState;
    case manualUpdateActionType.SET_DT_VALUE_SUCCESSED:
      return action.payload;
    case manualUpdateActionType.SET_DT_DROPDOWN_VALUE_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default getDTMetricReducer;
