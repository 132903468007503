import { httpPut } from "../../../../../../Utils/apis/apis";
export const updateStatus = async (
  payload = {},
  id,
  label,
  edge = [],
  triggerEmail
) => {
  let convertedData = {
    id: id,
    label: label,
    type: "vertex",
    properties: {
      ...payload,
    },
    security: {},
    edges: edge,
    submitToStoredProc: "",
    triggerEmail: triggerEmail ? triggerEmail : {},
  };
  await httpPut("/CRMData/update", convertedData);
};