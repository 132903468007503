import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SurveyFilter, SurveyFilterYear } from "../components";
import * as esgApi from "../ESG_API/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TextField, CircularProgress } from "@material-ui/core";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { Alert } from "@material-ui/lab";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { SurveyStatusMap } from "../ESG_Config";


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#757474",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
      position: "relative",
    },
    button: {
      background: "#d7d7d7",
    },
    cell: {
      width: 400,
      whiteSpace: "nowrap", 
      overflow: "hidden", 
      textOverflow: "ellipsis", 
    },
    tableContainer: {
      height: 600,
      overflowY: "scroll",
    },
    tableHead: {
      backgroundColor: "#333", // Header background color
      color: "#fff", // Header text color
    },
    actionItem: {
      height: "80px",
      overflow:"auto",
      width: "100%", // Set to 100% to use the full width of the cell
      border: "1px solid #ccc", // Add border to create a box-like appearance
      padding: "4px 8px", // Add padding for better readability
    },
  });
  
const ESG_ScoreSummary = () => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [scoreSummaryResponses, setScoreSummaryResponses] = useState([
  ]);
  const [isScoreFreezed,setIsScoreFreezed]=useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [erromsg, setErrorMsg] = useState("");
  const [editActionPlanForm,setEditActionPlanForm]= useState([]);
  const [isFormEdited,setIsFormEdited]=useState(false);


 const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setErrorMsg("");
 };


 const handleScoreFreeze=async()=>{
  try {
    const response = await esgApi.setScoreFreezeBySurveyId(surveyId);
    if (response && response.statusCode != 200) {
      setFailure(true);
      setErrorMsg(
        response && response.errorMsg
          ? response && response.errorMsg
          : "Some issue occured while freezing Score"
      );
      setIsScoreFreezed(false);
    } else {
      setSuccess(true);
      setIsScoreFreezed(true);
      setErrorMsg(response.data);
    }
    window.location.reload();
  } catch (error) {
    console.error("Failed to Freeze survey score:", error);
  }
}


// to save nextsteps 
const handleFormChange=(row,value)=>{
  const newFormData = editActionPlanForm.map((item) => {
  if(item.investmentScoreSummaryId === row.investmentScoreSummaryId){
    item.nextSteps=value;
  }
  return item;
});
  setEditActionPlanForm(newFormData);
  setIsFormEdited(true);
}



const handleSaveActionPlanData=async()=>{
  try {
    if(isFormEdited){
      setIsFormEdited(false);
   const response = await esgApi.saveScoreNextPlan(editActionPlanForm);
    if (response && response.statusCode != 200) {
      setFailure(true);
      setErrorMsg(
        response && response.errorMsg
          ? response && response.errorMsg
          : "Some issue occured while saving data"
      );
    } else {
      //console.log("inside true")
      setSuccess(true);
      setErrorMsg(response.data);
    }
    window.location.reload();
  }
  } catch (error) {
    setErrorMsg("Some issue occured while saving data")
  }
}

const setExistingFormData=(data)=>{
  let formData=data.map((item)=>{
    return{
      investmentScoreSummaryId:item.investmentScoreSummaryId,
      nextSteps:item.nextSteps
    }
  })
  setEditActionPlanForm(formData);
}
  
useEffect(() => {
    if (surveyId != "") {    

      const scoreSummaryResponses = async () => {
        // check survey response freeze for score freeze
        setLoading(true);
        let survey= await esgApi.getSurveyBySurveyId(surveyId);
        setIsScoreFreezed(survey.status == SurveyStatusMap.ScoreFreeze?true:false);
        const assetData = await esgApi.getAllInvestment();
        let scoreData = await esgApi.getScoreSummary(surveyId);
        if(scoreData && assetData && scoreData.length !== 0){
          scoreData.forEach((item)=>{
           const asset= assetData.find((asset)=>asset.investmentId==item.portfolioInvestmentId);
           item.portfolioInvestmentName= asset?.investmentName?asset.investmentName:""
          })
        }
        setScoreSummaryResponses(scoreData?scoreData:[]);
         // set existing form data
        setExistingFormData(scoreData?scoreData:[]);
        setLoading(false);
     }
      scoreSummaryResponses();
    }
  }, [surveyId]);


  return (
  <div>
      <SurveyFilter
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        surveyClosetoggle={true}
        heading={"Closed Survey"}
      />
      <SurveyFilterYear
        selectedSurveyId={surveyId}
        filtersurveyyear={surveyYear}
        setSelectedSurveyId={setSurveyId}
        setSurveyFilterYear={setSurveyYear}
        surveyClosetoggle={true}
        heading={"Years"}
      />
     {!loading && scoreSummaryResponses.length !==0 ? (
       <div>
        <TableContainer component={Paper}>
         <Table className={classes.table} aria-label="customized table">
            <TableHead
              style={{
                zIndex: 0,
                position: "sticky",
                top: "0px",
              }}
            >
              <TableRow>
                <StyledTableCell >Assets</StyledTableCell>
                <StyledTableCell align="center">ESG Score</StyledTableCell>
                <StyledTableCell align="center">Action Plan</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "auto", maxHheight: "350px" }}>
              { scoreSummaryResponses.map((row, idx) => (
                <>
                 <StyledTableRow
                  key={row.investmentScoreSummaryId}  
                  >
                 <StyledTableCell className={classes.cell} component="th" scope="row">
                  {row.portfolioInvestmentName}
                 </StyledTableCell>
                 <StyledTableCell className={classes.cell} align="center">
                 {parseFloat(row.calculatedScore).toFixed(3) || 0}
                 </StyledTableCell>
                 <StyledTableCell className={classes.cell} align="center">
                 <TextField
                  label=""
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  defaultValue={row.nextSteps}
                  inputType="value"
                  type="string"
                  multiline
                  className={classes.actionItem}
                  onChange={(e) =>
                  handleFormChange(
                   row,
                   e.target.value ? e.target.value : ""
                  )}
                  />
                 </StyledTableCell>
                 </StyledTableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
         <div style={{ textAlign: "right" }}>
         <A_Button
           textAlign={"right"}
           label={"Save"}
           style={{ margin: "6px 0px" }}
           color={"primary"}
           onClick={handleSaveActionPlanData}
           disabled={ isScoreFreezed?true:(isFormEdited?false:true)}
         ></A_Button>
          <A_Button
           textAlign={"right"}
           label={"Freeze"}
           style={{ margin: "6px 0px" }}
           color={"primary"}
           onClick={handleScoreFreeze}
           disabled={
             isScoreFreezed
           }
         ></A_Button>
       </div>
       </div>
      ) : ( loading ? (
          <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
           >
          <CircularProgress />
        </div>): (
            !surveyId?(
            <Alert severity="info">
             No Survey found whoes responses are closed.
            </Alert>):(
            <Alert severity="error">
              Score Summary not found for this survey!
            </Alert>)
           )
      )}
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }}
      >
       </div>
       <A_Snackbar
        open={success}
        message={erromsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
  </div>
  );
};
ESG_ScoreSummary.propTypes = {
  content: PropTypes,
};

export default ESG_ScoreSummary;
