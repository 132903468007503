import { reloadResultTemplate } from "../actions/actionTypes";

const initialState = {
  reloadChild: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case reloadResultTemplate.RELOADCHILD:
      return {
        ...state,
        reloadChild: !state.reloadChild,
      };
    default:
      return state;
  }
};

export default reducer;
