import React from "react";
import { makeStyles } from "@material-ui/core";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { Divider } from "@material-ui/core";
const useStyles = makeStyles({
  news: {
    padding: "1rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(213, 213, 213, 0.8)",
    },
  },

  header: {
    fontSize: "1.2rem",
    fontWeight: "600",
    marginTop: "2rem",
  },
  subheading: {
    fontSize: "0.6rem",
    fontWeight: "500",
    color: "#2db0fa",
    fontStyle: "italic",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  details: {
    fontSize: "0.8rem",
    color: "grey",
  },
  dialog: {
    margin: "auto",
    width: 300,
  },
});
export default function AnnouncementDetailsHeader({ title, subTitle }) {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div> {title ? title : ""}</div>
      <div className={classes.subheading}>
        <div className="">{subTitle ? subTitle : ""}</div>
        <div className="">
          {/* TODO need this code for future development */}
          {/* <A_Button label="Get Direct Link" />
          <A_Button label="Print" /> */}
        </div>
      </div>
      <Divider></Divider>
    </div>
  );
}
