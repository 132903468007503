export let excludedClientsForAddEntity = [
  "quilam",
  "amitra",
  "rcapital",
  "tdrcapital",
  "blazehill",
  "novia",
  "wealthtime"
];
export let excludedClientsForProfileSetting = ["quilam","amitra","rcapital","tdrcapital","blazehill"]
export const isClientAuthorised = (domain,domainList)=>{
    let splitDomainList = domain.split(".")
    let result = domainList.indexOf(splitDomainList[0]) < 0
    return result
}