import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import A_DatePicker from "../../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import "../ESG.css";
import { convertEpochToDateString } from "../../../../../../Utils/Helpers/dateUtils";

const useStyles = makeStyles(() => ({
  addQuestionnaire: {
    position: "fixed",
    top: "106px",
    right: "26px",
    zIndex: "2",
  },
  customWidth: {
    width: "40.5ch",
  },
}));
const AddQuestionnaireModal = (props) => {
  const classes = useStyles();
  const handleStartDateChange = (e) => {
    props.formValues.startDate = convertEpochToDateString(e);
  };
  const handleEndDateChange = (e) => {
    props.formValues.endDate = convertEpochToDateString(e);
  };
  return (
    <div>
      <Dialog
        open={props.dialogopen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.handleModalClose;
          }
        }}
      >
        <div>
          <div className="dialogTitle">
            <div>
              <DialogTitle>Add Questionnaire</DialogTitle>
            </div>
            <div>
              {" "}
              <CloseIcon
                onClick={() => props.handleModalOpen()}
                className="closeIcon"
              />
              <Divider />
            </div>
          </div>

          <div style={{ marginLeft: "26px", width: "400px" }}>
            <div style={{ width: "89%" }}>
              <Divider />
            </div>
            <TextField
              label="Questionnaire Name"
              name="questionName"
              value={props.formValues.questionName}
              onChange={props.handleChange}
              required
              error={props.codeError.questionName}
              style={{ width: "40.5ch" }}
            />
            <br />
            <TextField
              label="Year"
              name="year"
              value={props.formValues.year}
              onChange={(e) => {
                // Allow only numeric values
                e.target.value = e.target.value.replace(/[^0-9]/g, "");

                // Limit the length to 4 characters
                if (e.target.value.length > 4) {
                  e.target.value = e.target.value.slice(0, 4);
                }

                props.handleChange(e);
              }}
              error={props.codeError.year}
              required
              style={{ width: "40.5ch" }}
            />
            <div style={{ width: "40.5ch" }}>
              <A_DatePicker
                className={classes.customWidth}
                onChange={handleStartDateChange}
                id="singleYear"
                label="Start Date"
                name="startDate"
                defaultValue={""}
                error={props.codeError.startDate}
                minDate={new Date()}
                required
              />
              <A_DatePicker
                className={classes.customWidth}
                id="singleYear"
                label="End Date"
                name="endDate"
                defaultValue={""}
                onChange={handleEndDateChange}
                error={props.codeError.endDate}
                minDate={new Date()}
                required
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              style={{ float: "right", margin: "15px" }}
              onClick={props.handleSubmit}
              disabled={props.isButtonDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
AddQuestionnaireModal.propTypes = {
  dialogopen: PropTypes.any,
  handleChange: PropTypes.any,
  handleModalClose: PropTypes.any,
  handleModalOpen: PropTypes.any,
  formValues: PropTypes.any,
  codeError: PropTypes.any,
  handleSubmit: PropTypes.any,
  isButtonDisabled: PropTypes.any,
  handleClose: PropTypes.any,
};
export default AddQuestionnaireModal;
