import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { SurveyFilter, SurveyFilterYear } from "../components";
import {
  getInvestmentByCrmId,
  getResponseApprovalByAssetId,
  getQuestionResponseCount,
  getRespondentActionPlanBySurveyIdAndInvesmentId,
  updateAllRespondentActionPlan,
} from "../ESG_API";
import {
  getQuestionsJsonFromSurvey,
  getAllPillar,
  getSubPillar,
} from "../ESG_API";
import { organizeQuestionsFlat } from "../utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { action } from "@storybook/addon-actions";
import { Dialog, DialogTitle } from "@material-ui/core";
import SubmissionForm from "./SubmissionForm";
import { Alert } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    border: "none",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  button: {
    textAlign: "center",
    padding: "10px",
    marginLeft: "50px",
  },
  addForm: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ESG_RespondentActionPlan = (props) => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");
  const [respondentData, setRespondentData] = useState([]);
  const [actionPlanMap, setActionPlan] = useState([]);
  const [editedTableData, setEditedTableData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [pillars, setPillars] = useState([]);
  const [subpillars, setSubpillars] = useState([]);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [erromsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [resCheck, setresCheck] = useState(false);
  const [isDisable, setIsDisable]= useState(true);
  const [bestPracticeError,setBestPracticeError]=useState({})

  let { crmId } = useParams();

  const setExistingPlanData = (responseData) => {
    let actionPlanData = responseData.map((item) => {
      return {
        scoreId: item.scoreId,
        targets: item.targets,
        initiatives: item.initiatives,
        bestpractices: item.bestpractices,
      };
    });
    setEditedTableData(actionPlanData);
  };
  useEffect(() => {
    if (surveyId != "") {
      setLoading(true);
      let surveyId = localStorage.getItem("Survey");
      const getSubmissionData = async () => {
        const crmData = await getInvestmentByCrmId(crmId);
        const investmentId = crmData.investmentId;
        const actionPlan =
          await getRespondentActionPlanBySurveyIdAndInvesmentId(
            surveyId,
            investmentId
          );
        if (actionPlan == undefined || actionPlan == null) {
          setresCheck(true);
        } else {
          setresCheck(false);
        }
        setActionPlan(actionPlan);
        setExistingPlanData(actionPlan);
        const questionsData = await getQuestionsJsonFromSurvey(surveyId);
        const pillarsData = await getAllPillar();
        const subPillarData = await getSubPillar();
        const flatQuestions = organizeQuestionsFlat(questionsData);
        const allSubPillers = [];
        pillarsData.forEach((pillar) => {
          const subPillersForPillar = subPillarData.filter(
            (subPillar) =>
              subPillar.questioncategoryid === pillar.questioncategoryid
          );
          allSubPillers.push({ ...pillar, subPillerList: subPillersForPillar });
        });
        setQuestions(flatQuestions);
        setPillars(pillarsData);
        setSubpillars(subPillarData);
        setRespondentData(allSubPillers);
        setLoading(false);
      };
      getSubmissionData();
    }
  }, [surveyId]);

  const handleChange = (value, actionValue, key) => {

    if (key === 'bestpractices') {
        // set error if field empty
        if (!value || value.trim() =="") {
          setBestPracticeError((prevErrors) => ({
            ...prevErrors,
            [actionValue.scoreId]: 'Best Practices is required',
          }));
        } else {
          setBestPracticeError((prevErrors) => ({
            ...prevErrors,
            [actionValue.scoreId]: '',
          }));
        }
    }

    const updatedActionPlan = editedTableData.map((item) => {
      if (item.scoreId !== actionValue.scoreId) {
        item;
      } else {
        item[key] = value;
      }
      return item;
    });
    setEditedTableData(updatedActionPlan);
    // enable update button
    setIsDisable(false);
  };
  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
  };

  const handleUpdate = async () => {
    setIsDisable(true);
    // set error
    let hasError = false;
    const updatedErrors = {};
    editedTableData.forEach((item)=>{
        if(!item?.bestpractices || item.bestpractices.trim() =="") {
            updatedErrors[item?.scoreId] = 'Best Practices is required';
            hasError = true;
          }
    });
    // set error
    if (hasError) {
      setBestPracticeError(updatedErrors);
   } 
   else{
    const response = await updateAllRespondentActionPlan(editedTableData);
    if (response?.statusCode && response.statusCode == 200) {
      setSuccess(true);
      setErrorMsg(
        response?.data
          ? response.data
          : "Respondent Action Plan updated Successfully."
      );
    } else if (response?.errorMessage) {
      setFailure(true);
      setErrorMsg(
        response?.errorMessage
          ? response.errorMessage
          : "Unable to update Respondent Action Plan for this SurveyId."
      );
    } else {
      setFailure(true);
      setErrorMsg(
        response?.message
          ? response.message
          : "Unable to update Respondent Action Plan for this SurveyId."
      );
    }
    window.location.reload();
   }
  };

  const openpop = (data) => {
    setOpenDialog(true);
    setSelectedRow(data);
  };

  const handlepopupClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div>
        <SurveyFilter
          selectedSurveyId={surveyId}
          filtersurveyyear={surveyYear}
          setSelectedSurveyId={setSurveyId}
          surveyClosetoggle={true}
          heading={"Closed Survey"}
        />
        <SurveyFilterYear
          selectedSurveyId={surveyId}
          filtersurveyyear={surveyYear}
          setSelectedSurveyId={setSurveyId}
          setSurveyFilterYear={setSurveyYear}
          surveyClosetoggle={true}
          heading={"Survey Years"}
        />
      </div>
      { !surveyId?(
         <Alert
         severity="info"
         style={{
           width: "auto",
           marginBottom: "10px",
           textAlign: "center",
           display: "flex",
           justifyContent: "center",
         }}
        >
         No Closed Survey found!
        </Alert>

        ):
        (resCheck ? (
        <Alert
          severity="info"
          style={{
            width: "auto",
            marginBottom: "10px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          There is no response to show,no rating questions found for this survey. 
        </Alert>
       ) : (
        <div>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress style={{ width: "30px", height: "30px" }} />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Pillar</StyledTableCell>
                    <StyledTableCell>Sub Pillar</StyledTableCell>
                    <StyledTableCell>Targets</StyledTableCell>
                    <StyledTableCell>Initiatives</StyledTableCell>
                    <StyledTableCell>Best Practices*</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {respondentData.map((piller, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        style={{
                          color: "white",
                          background: "rgba(38,52,64,1)",
                          textDecoration: "none",
                        }}
                        component="th"
                        scope="row"
                      >
                        {piller?.name}
                      </StyledTableCell>

                      <StyledTableCell
                        style={{ padding: "0px" }}
                        component="th"
                        scope="row"
                      >
                        {piller?.subPillerList &&
                          Object.keys(piller.subPillerList).map(
                            (key, index) => (
                              <div
                                key={index}
                                style={{
                                  border: "0.5px solid grey",


                                  padding: "0px",
                                  cursor: "pointer",
                                }}
                              >
                                <StyledTableCell
                                  style={{ color: "blue" }}
                                  onClick={() =>
                                    openpop(piller.subPillerList[key])
                                  }
                                >
                                  {piller?.subPillerList[key]?.name}
                                </StyledTableCell>
                              </div>
                            )
                          )}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ padding: "0px" }}
                      >
                        {piller?.subPillerList &&
                          Object.keys(piller.subPillerList).map(
                            (key, index) => {
                              const subPillar = piller.subPillerList[key];
                              const target = actionPlanMap.find(
                                (item) =>
                                  item.questionsubcategoryId ===
                                  subPillar.questionsubcategoryid
                              );
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid grey",
                                    padding: "10px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <TextField
                                    defaultValue={target ? target.targets : ""}
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.value,
                                        target,
                                        "targets"
                                      )
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {piller?.subPillerList &&
                          Object.keys(piller.subPillerList).map(
                            (key, index) => {
                              const subPillar = piller.subPillerList[key];
                              const initiative = actionPlanMap.find(
                                (item) =>
                                  item.questionsubcategoryId ===
                                  subPillar.questionsubcategoryid
                              );
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid grey",

                                    padding: "10px",
                                  }}
                                >
                                  <TextField
                                    defaultValue={
                                      initiative ? initiative.initiatives : ""
                                    }
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.value,
                                        initiative,
                                        "initiatives"
                                      )
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {piller?.subPillerList &&
                          Object.keys(piller.subPillerList).map(
                            (key, index) => {
                              const subPillar = piller.subPillerList[key];
                              const bestpractice = actionPlanMap.find(
                                (item) =>
                                  item.questionsubcategoryId ===
                                  subPillar.questionsubcategoryid
                              );
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: "1px solid grey",
                                    padding: "10px",
                                  }}
                                >
                                  <TextField
                                    defaultValue={
                                      bestpractice
                                        ? bestpractice.bestpractices
                                        : ""
                                    }
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.value,
                                        bestpractice,
                                        "bestpractices"
                                      )
                                    }
                                  />
                                <span style={{ color: "red", fontSize: "10px" }}>
                                {bestPracticeError[bestpractice?.scoreId]}</span>
                                </div>
                              );
                            }
                          )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div className={classes.button}>
            <Button
              variant="contained"
              style={{ margin: "13px 10px 10px 0", marginLeft: "90%" }}
              onClick={handleUpdate}
              disabled={isDisable}
            >
              Update
            </Button>
          </div>
          <A_Snackbar
            open={success}
            message={erromsg}
            autoHideDuration={1000}
            horizontal="center"
            handleClose={handleClose}
          />
          <A_Snackbar
            open={failure}
            message={erromsg}
            autoHideDuration={3000}
            horizontal="center"
            handleClose={handleClose}
            type="error"
          />
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth="xl"
            PaperProps={{ style: { width: "100%" } }}
            aria-labelledby="max-width-dialog-title"
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handlepopupClose();
              }
            }}
          >
            <div className={classes.addForm}>
              <div>
                <DialogTitle>{selectedRow?.name}</DialogTitle>
              </div>
              <div>
                <CloseIcon
                  onClick={() => handlepopupClose()}
                  className="closeIcon"
                />
              </div>
            </div>
            <SubmissionForm
              selectedRow={selectedRow}
              answers={[]}
              onFormClose={handlepopupClose}
            />
          </Dialog>
        </div>
      )
      )}
    </>
  );
};
ESG_RespondentActionPlan.propTypes = {
  content: PropTypes,
};

export default ESG_RespondentActionPlan;
