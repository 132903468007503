/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import userManager from "../../../../utils/oidc/userManager";
import { httpPost } from "../../../../Utils/apis/apis";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
const forceLogout = () => {
  const classes = useStyles();
  const handleLogout = async () => {
    let data = {
      activityType: "Logout",
      message: "Logged out from system",
    };

    let res = await httpPost(
      `/Common/WriteDBLog?activityType=logout&message=Logged out from system`,
      data
    );
    userManager.removeUser().catch((error) => {
      console.error(error)
    })
    userManager.signoutRedirect().catch((error) => {
      console.error(error)
    })
  };

  return (
    <>
      <div style={{ marginTop: "80px", marginLeft: "30px" }}>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            <h3>
              Current account does not have access to requested resource. Logout
              to use different account.
            </h3>
          </Typography>
          <Button onClick={handleLogout} className={classes.button}>
            Logout
          </Button>
        </Paper>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export default forceLogout;
