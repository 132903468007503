import M_ApprovalScreen from "../../../Molecules/Common/ConsentRequest/M_ApprovalScreen";
import M_ReviewerScreen from "../../../Molecules/Common/ConsentRequest/M_ReviewerScreen";
import { uiTypes } from "./ConsentRequest.const";
import React from "react";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import { httpPut, httpPost } from "../../../../../Utils/apis/apis";
import M_AssignmentScreen from "../../../Molecules/Common/ConsentRequest/M_AssignmentScreen";
export const verifyUserID = (listOfUserIds, currentUserId) => {
  try {
    if (Array.isArray(listOfUserIds)) {
      return listOfUserIds.includes(currentUserId);
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const getListOfDataFromMultiLookup = (lookupString, key = "id") => {
  let lookupValues = [];
  try {
    let lookupValueSet = JSON.parse(lookupString);
    if (Array.isArray(lookupValueSet)) {
      lookupValues = lookupValueSet.map((values) => values[key]);
    }
  } catch (e) {
    //Intensionally added comments
  }
  return lookupValues;
};

export const renderComponentBasedOnType = (
  type,
  title,
  content,
  configData
) => {
  let cardContent = "";
  switch (type) {
    case uiTypes.APPROVAL_SCREEN:
      cardContent = (
        <M_ApprovalScreen
          content={content}
          reviewerIds={configData.reviewerCRMIds}
        />
      );
      break;
    case uiTypes.REVIEWER_SCREEN:
      cardContent = <M_ReviewerScreen content={content} />;
      break;
    case uiTypes.ASSIGNMENT_SCREEN:
      cardContent = <M_AssignmentScreen content={content} />;
      break;
    default:
      return (
        <React.Fragment>
          <ErrorBoundary>
            <></>
          </ErrorBoundary>
        </React.Fragment>
      );
  }
  return (
    <A_Card
      header={{
        title: title ? title : "",
      }}
      content={
        <React.Fragment>
          <ErrorBoundary>
            <>{cardContent}</>
          </ErrorBoundary>
        </React.Fragment>
      }
    />
  );
};

export const insertDocumentDB = async (
  payload,
  container,
  id = null,
  onFinished = () => {}
) => {
  payload && payload.properties && delete payload.properties["undefined"];
  if (id) {
    await httpPut(`/GenericDocument/${container}/${id}/update`, payload);
  } else {
    await httpPost(`/GenericDocument/${container}/insert`, payload);
  }
  onFinished();
};
