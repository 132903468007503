import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import * as esgApi from "../ESG_API/index";
import "../ESG.css";

const InvestmentFilter = (props) => {
    const {
        selectedInvestmentId,
        setSelectedInvestmentId,
        style
    } = props;
    const [selectedInvestment, setSelectedInvestment] = useState("");
    const [investments, setInvestments] = useState([]);

    //Get Question Answer Type
    const getInvestment = async () => {
        let response = await esgApi.getAllInvestment();
        if (response) {
            let data = [];
            response.forEach(element => {
                let foundInvestment = data.find((item) => item.investmentId == element.investmentId);
                if (!foundInvestment) {
                    data.push({ name: element.investmentName, investmentId: element.investmentId });
                }
            });
            setInvestments(data);
            setSelectedInvestment(data.length > 0 ? data[0].name : "");
            setSelectedInvestmentId(data.length > 0 ? data[0].investmentId : "");
        }
    };
    useEffect(() => {
        getInvestment();
    }, []);

    const handleChange = (event) => {
        const foundInvestment = investments.find(
          (element) => element.name === event.target.value
        );
        setSelectedInvestment(event.target.value);
        setSelectedInvestmentId(foundInvestment.investmentId);
      };

    return (
        <div
            style={style}
        >
            <FormControl>
                <Select
                    labelId="dropdown-label"
                    id="dropdown"
                    value={selectedInvestment ? selectedInvestment : "Select"}
                    onChange={handleChange}
                    style={{ width: "125px", marginTop: "2px", paddingLeft: "14px" }}
                    disableUnderline
                >
                    {investments &&
                        investments.map((item) => {
                            return (
                                <MenuItem value={item.name} key={item.investmentId}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </div>
    );
};
InvestmentFilter.propTypes = {
    selectedInvestmentId: PropTypes.string,
    setSelectedInvestmentId: PropTypes.func,
    style: PropTypes.object
};
export default InvestmentFilter;    
