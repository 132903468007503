
export const addDynamicValueforHeader = (props, values) => {
    Object.keys(props).map((item) => {
        props[item][0] === "=" ? 
         values[props[item].substr(1, props[item].length-1)] ?
         props[item] =values[props[item].substr(1, props[item].length-1)] :
         ""
        : "";
    })
    return props
}