import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Drawer from "../../../Atoms/Navigation/Drawers/A_Drawer";
import A_List from "../../../Atoms/DataDisplay/Lists/A_List";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageMenusAction from "../../../../../store/actions/manageMenusAction";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({}));

const M_MainDrawer = (props) => {
  const { openDrawer } = props;
  const classes = useStyles();
  const [menusLoading, setMenusLoading] = useState(false);
  const [menus, setMenus] = useState(
    props.manageMenus &&
      props.manageMenus.data &&
      props.manageMenus.data.length > 0
      ? props.manageMenus.data
      : []
  );
  useEffect(() => {
    const areMenusLoaded =
      props.manageMenus &&
      props.manageMenus.data &&
      props.manageMenus.data.length > 0
        ? false
        : true;
    if (areMenusLoaded) {
      setMenusLoading(true);
      props.getMenus(props.user);
    }
  }, []);
  useEffect(() => {
    if (menusLoading && props.manageMenus.getMenusSucceeded) {
      setMenus(props.manageMenus.data);
      setMenusLoading(false);
    }
  }, [props.manageMenus && props.manageMenus.getMenusSucceeded]);
  useEffect(() => {
    setMenus(JSON.parse(JSON.stringify(menus).replaceAll(true, false)));
  }, [menus && menus.length > 0 && props.iconClicked]);

  return (
    <div>
      <A_Drawer
        open={openDrawer}
        onClose={props.handleDrawer}
        //anchor={this.state.anchor}
        content={
          <A_List
            className={classes.font}
            listItems={menus}
            handleDrawer={(e) => props.handleDrawer(e)}
          />
        }
      />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    manageMenus: state.manageMenus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(manageMenusAction, dispatch);
}
M_MainDrawer.propTypes = {
  iconClicked: PropTypes.any,
  openDrawer: PropTypes.any,
  manageMenus: PropTypes.any,
  user: PropTypes.any,
  getMenus: PropTypes.any,
  handleDrawer: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_MainDrawer);
