 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../../Themes/defaultTheme";

const A_Backdrop = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={props.open} onClick={props.handleClose}>
        {props.progressIcon}
      </Backdrop>
    </ThemeProvider>
  );
};
A_Backdrop.defaultProps = {
  open: false,
  color: "inherit",
  progressIcon: <CircularProgress color="inherit" />,
};
export default A_Backdrop;
