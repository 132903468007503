import { getIrcSlotActionTypes } from "./actionTypes";
export const getIrcSlot = (payload) => {
  return {
    type: getIrcSlotActionTypes.GET_IRC_SLOT,
    payload,
  };
};
export const getIrcSlotSucceeded = (payload) => {
  return {
    type: getIrcSlotActionTypes.GET_IRC_SLOT_SUCCEEDED,
    payload,
  };
};
export const getIrcSlotFailed = (payload) => {
  return {
    type: getIrcSlotActionTypes.GET_IRC_SLOT_FAILED,
    payload,
  };
};
