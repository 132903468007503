import React from 'react';
import './LoadingMessage.css';

const LoadingMessage = () => {
  return (
    <div>
           <div className='wrapper'>
        <div className="flexboxy">
        
  <div>
    <div className="ml-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
    </div>
    </div>
    </div>
  )
}

export default LoadingMessage