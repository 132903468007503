import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Box,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { typeJsonData } from "../ESG_Config";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import {
  // putSurveyQuestionMap,
  getSurveyQuestionId,
  getAllQuestion,
  getSubPillar,
  // postSurveyQuestionMap,
  deleteQuestion,
  getAllPillar,
  patchSurveyQuestionMap,
} from "../ESG_API";
import { organizeQuestions, organizeQuestionsFlat } from "../utils";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import emitter from "../components/Emitter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  inputcontrol: { minWidth: "20ch" },
  selectData: {
    minwidth: "20ch",
  },
  iconButton: {
    padding: "4px",
  },

  iconLabel: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "4px",
    cursor: "pointer",
  },
  iconLabelLarge: {
    fontSize: "16px",
    "& > *": {
      marginBottom: "6px",
    },
  },
  columnHeader: {
    color: "#fff",
  },
  iconButtonLabel: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      marginBottom: "4px",
    },
    iconButtonMainContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ESG_ExistingQuestions() {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [subPillarData, setSubpillarData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [surveyquestionresponse, setSurveyquestionresponse] = useState([]);
  const [openPopoverIndex, setOpenPopoverIndex] = React.useState(null);
  const [allPillar, setAllPilar] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [isPublishedPopup, setIsPublishedPopup] = useState(false);
  const [rowData, setRowData] = useState("");
  const [sendData, setSendData] = useState();
  const [refetch, setRefetch] = useState(false);
  const [delerrMsg, setDelErrMsg] = useState("");
  const [delsuccess, setDelSuccess] = useState(false);
  const [delfailure, setDelFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [open, setOpen] = React.useState(false);
  // const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    const onDataEvent = (receivedData) => {
      setSendData(receivedData);
    };

    emitter.on("dataEvent", onDataEvent);

    const onExistingQuestionReloadEvent = (receivedData) => {
      setRefetch(receivedData);
    };

    emitter.on("reloadExistingQuestionEvent", onExistingQuestionReloadEvent);

    setTimeout(() => {
      let surveyID = Number(localStorage.getItem("Survey"));
      if (surveyID) {
        const fetchData = async () => {
          try {
            const allQuestionResponse = await getAllQuestion();
            const subpillar = await getSubPillar();
            const response = await getSurveyQuestionId(surveyID);
            const allpillar = await getAllPillar();
            setSurveyquestionresponse(response.questionId);
            setApiData(organizeQuestionsFlat(allQuestionResponse));
            setSubpillarData(subpillar);
            setSelected(response.questionId);
            setAllPilar(allpillar);
          } catch (error) {
            console.error("Error fetching data:", error);
            setFailure(true);
          }
        };
        fetchData();
      }
    }, 0);
  }, [sendData]);

  useEffect(() => {
    setTimeout(() => {
      if (refetch) {
        let surveyID = Number(localStorage.getItem("Survey"));
        if (surveyID) {
          const fetchData = async () => {
            try {
              const allQuestionResponse = await getAllQuestion();
              const subpillar = await getSubPillar();
              const response = await getSurveyQuestionId(surveyID);
              const allpillar = await getAllPillar();
              setSurveyquestionresponse(response.questionId);
              setApiData(organizeQuestionsFlat(allQuestionResponse));
              setSubpillarData(subpillar);
              setSelected(response.questionId);
              setAllPilar(allpillar);
            } catch (error) {
              console.error("Error fetching data:", error);
              setFailure(true);
            }
          };
          fetchData();
        }
        setRefetch(false);
      }
    }, 0);
  }, [refetch]);

  const handleClick = (event, index, row) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];
    let selectedData = [...data];
    let selectedData1 = [...allData];
    if (
      surveyquestionresponse &&
      surveyquestionresponse.includes(row.questionId)
    ) {
      const c = surveyquestionresponse.filter((id) => id !== row.questionId);
      setSurveyquestionresponse(c);
      newSelected = c;
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
      selectedData.push({
        questionCategoryId: row.questionCategoryId,
        questionSubCategoryId: row.questionSubCategoryId,
        questionText: row.questionText,
        hasSubQuestions: row.hasSubQuestions,
        parentQuestionResponseTypeId: row.parentQuestionResponseTypeId,
      });
      selectedData1.push(row);
    } else {
      newSelected = selected.filter((item) => item !== index);
      selectedData = selectedData.filter(
        (item) => item.name !== row.questionCategoryId
      );
      selectedData1 = selectedData1.filter((item) => {
        return item.questionCategoryId !== row.questionCategoryId;
      });
    }
    setSelected(newSelected, ...surveyquestionresponse);
    setData(selectedData);
    setAllData(selectedData1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (row) => {
    const result = await deleteQuestion(row.questionId);
    if (result && result.statusCode && result.statusCode == 200) {
      setDelSuccess(true);
    } else if (result && result.errorMsg) {
      setDelErrMsg(result && result.errorMsg);
      setDelFailure(true);
    } else {
      setDelErrMsg(
        result.response.data && result.response.data.errorMsg
          ? result.response.data && result.response.data.errorMsg
          : "Unable to Delete"
      );
      setDelFailure(true);
    }
    setDeletePopUp(false);
    setRefetch(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePopoverClose = () => {
    setOpenPopoverIndex(null);
  };

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setDelFailure(false);
    setDelSuccess(false);
  };

  const getAllIds = (data,id) =>{
      const allids = [];

      const parent = data?.find(item => id == item.questionId)

    if(parent){
      const getIds = (items) =>{
        allids.push(id);
        items?.forEach((item)=>{
          allids.push(item.questionId)
          if(item?.children?.length > 0){
            getIds(item.children);
          }
        })
      }
      getIds(parent.children);
    }

      return allids;
  }

  const handleSaveClick = async () => {
    const idsWithChildren = organizeQuestions(apiData);
    setPending(true);
    let surveyID = Number(localStorage.getItem("Survey"));
    let questionid = [];
    if (allData.length > 0) {
      allData.forEach((item) => {
        const allIds = getAllIds(idsWithChildren,item.questionId)
         questionid=[...questionid,...allIds]
      });

      if (surveyquestionresponse.length === 0) {
        let payload = {
          surveyId: surveyID,
          questionids: questionid,
        };
        let response = await patchSurveyQuestionMap(payload);
        if (response.message) {
          setErrorMessage(response.response.data.errorMsg);
          setFailure(true);
        } else {
          setSuccess(true);
          emitter.emit("reloadSetupViewEvent", true);
        }
      } else {
        let payload = {
          surveyId: surveyID,
          questionids: [...surveyquestionresponse, ...questionid],
        };
        let response = await patchSurveyQuestionMap(payload);
        if (response.message) {
          setErrorMessage(response.response.data.errorMsg);
          setFailure(true);
        } else {
          setSuccess(true);
          emitter.emit("reloadSetupViewEvent", true);
        }
      }
    }
    setPending(false);
  };

  const isSelected = (index, questionId) => {
    const surveyQuestionResponseArray = surveyquestionresponse
      ? surveyquestionresponse || []
      : [];

    if (selected.indexOf(index) !== -1) {
      return true;
    }

    if (surveyQuestionResponseArray.indexOf(questionId) !== -1) {
      return true;
    }

    return false;
  };

  const handleDeleteConfirm = () => {
    handleDelete(rowData);
  };

  const getDeleteActionItem = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handleDeleteConfirm}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handleDeletePopUpCancel}
        />
      </>
    );
  };

  const getActionItemsIsPublished = () => {
    return (
      <A_Button
        color="primary"
        label="OK"
        autoFocus
        onClick={() => setIsPublishedPopup(false)}
      />
    );
  };

  const handleDeletePopUp = (row) => {
    setRowData(row);
    if (row.ispublished) {
      setIsPublishedPopup(true);
    } else {
      setDeletePopUp(true);
    }
  };

  const handleDeletePopUpCancel = () => {
    setDeletePopUp(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {selected.length > 0 ? (
          <EnhancedTableToolbar numSelected={selected.length} />
        ) : (
          ""
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={apiData.length}
            />
            <TableBody>
              {apiData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index, row.questionId);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const pillar = allPillar.find(
                    (item) => item.questioncategoryid === row.questionCategoryId
                  );
                  const response = typeJsonData.find(
                    (item) =>
                      item.questionanswertypeId === row.questionAnswerTypeId
                  );
                  const subpillar = subPillarData.find((item) => {
                    return (
                      item.questionsubcategoryid === row.questionSubCategoryId
                    );
                  });

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        onClick={(event) =>
                          !row.parentQuestionId
                            ? handleClick(event, index, row)
                            : null
                        }
                        padding="checkbox"
                      >
                        {!row.parentQuestionId ? (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {pillar ? pillar.name : "-"}
                        {/* {row.questionCategoryId} */}
                      </TableCell>
                      <TableCell align="left">
                        {subpillar ? subpillar.name : "-"}
                      </TableCell>
                      <TableCell align="left" width={300}>
                        {row.questionText}
                      </TableCell>
                      <TableCell align="left">
                        {row.hasSubQuestions === true ? "True" : "False"}
                      </TableCell>
                      <TableCell align="left">
                        {response ? response.name : "-"}
                      </TableCell>
                      <div>
                        {" "}
                        <span>
                          {" "}
                          <Box display="flex" flexDirection="column" p={2}>
                            <div className={classes.iconButtonMainContainer}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  handleDeletePopUp(row);
                                  handlePopoverClose();
                                }}
                                className={`${classes.iconButton} ${classes.deleteIconButton}`}
                              >
                                <DeleteIcon
                                  className={classes.iconLabelLarge}
                                />
                              </IconButton>
                            </div>
                          </Box>
                        </span>
                      </div>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={apiData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className={classes.wrapper}
      >
        <A_Button disabled={pending} onClick={handleSaveClick} label="Save" />
        {pending && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </div> */}
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={classes.wrapper}
      >
        {pending && (
          <CircularProgress
            size={30}
            className={classes.buttonProgress}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
        <A_Button
          disabled={pending}
          onClick={handleSaveClick}
          label="Add to Questionnaire"
        />
      </div>
      <A_Snackbar
        open={delfailure}
        message={delerrMsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
      <A_Snackbar
        open={success}
        message={"Successfully Saved"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_SimpleDialog
        open={deletePopUp}
        dialogContent=""
        closePopUp={handleDeletePopUp}
        title={`Are you sure you want to delete?`}
        dialogActions={getDeleteActionItem()}
        onClose={handleDeletePopUpCancel}
      />
      <A_Snackbar
        open={delsuccess}
        message={"Successfully Delete"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_SimpleDialog
        open={isPublishedPopup}
        dialogContent=""
        closePopUp={handleDeletePopUp}
        title={`You cannot delete this question as it is present in the published survey.`}
        dialogActions={getActionItemsIsPublished()}
        onClose={() => setIsPublishedPopup(false)}
      />
      <A_Snackbar
        open={failure}
        message={errorMessage}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
    </div>
  );
}
