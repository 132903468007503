import "date-fns";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Moment from "moment";
import {toEpochFormat, FromEpochFormat,customDateFormat} from "../../../../../Utils/Helpers/dateUtils";
import theme from "../../../../Themes/defaultTheme";
import { dateConfig } from "../../../../../Utils/config/config";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dateIcon: {
    "& svg": {
      marginRight: "10px",
    },
    "& button": {
      padding: "0px",
      width: "20px",
      marginRight: "20px",
    },
  },
  helperIcon: {
    marginTop: "-5px",
    marginLeft: "-15px",
    zIndex: "99",
  },
}));
const A_DatePicker = (props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(props.defaultValue? props.defaultValue != 0 ? 
    customDateFormat(props.defaultValue, "MM/DD/YY") : FromEpochFormat(props.defaultValue) : "");
  const [error, setError] = useState(false);
  const [helpertext, setHelpertext] = useState(
    props.helperText ? props.helperText : ""
  );
  const handleDateChange = (date) => {
    if (!date) {
      props.onChange(0, props.id)
      setSelectedDate(null);
      if (props.required) {
        setError(true);
        setHelpertext("This field is required");
      } else {
        setError(false);
        setHelpertext("");
        setSelectedDate(null)
        props.isValidDate ? props.isValidDate(false) : "";
      }
      return;
    }
        setSelectedDate(date);
    if (date.toString() === "Invalid Date") {
      setError(true);
      setHelpertext("Invalid date format");
      props.isValidDate && props.isValidDate(true);
      props.onChange(0, props.id)
    } else {
      let currDate = new Date();
      setError(false);
      setHelpertext("");
      if (
        (props.disableFuture &&
          toEpochFormat(date) > toEpochFormat(currDate)) ||
        (props.disablePast && toEpochFormat(date) < toEpochFormat(currDate))
      ) {
        date = currDate;
        setSelectedDate(currDate);
      }
      date === null
        ? props.onChange(0, props.id)
        : props.onChange(toEpochFormat(date), props.id);
      props.isValidDate ? props.isValidDate(false) : "";
  }
}
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <KeyboardDatePicker
            minDate={props.minDate}
            clearable={props.clearable}
            id={props.id}
            autoOk={props.autoOk}
            disableToolbar={props.disableToolbar}
            views={props.views}
            variant={props.variant}
            format={
              props.format && props.format == dateConfig.format
                ? undefined
                : props.format
            }
            margin={props.margin}
            label={props.label}
            value={
              props.lettersAllowed
                ? selectedDate
                : selectedDate
                ? Moment(selectedDate).toDate()
                : null
            }
            defaultValue={props.defaultValue}
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
            placeholder={props.placeholder}
            helperText={helpertext ? helpertext : props.helperText}
            error={error ? error : props.error}
            KeyboardButtonProps={{
              "aria-label": props.ariaLabel,
            }}
            onChange={(date) => handleDateChange(date)}
            onKeyDown={(e) => e.stopPropagation()}
            className={`${props.className} ${
              props.fieldHelperText ? classes.dateIcon : " "
            }`}
            required={props.required}
          />
          <span className={classes.helperIcon}>
            <A_FormHelper fieldHelperText={props.fieldHelperText} />
          </span>
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

A_DatePicker.defaultProps = {
  id: "date-picker",
  disableToolbar: false,
  variant: "inline",
  format: "dd/MM/yyyy",
  margin: "normal",
  label: "Select Date",
  value: new Date(),
  ariaLabel: "change date",
  views: ["date"],
  defaultValue: new Date(),
  helperText: "",
  clearable: true,
  className: {},
  minDate: new Date(0),
  lettersAllowed: false,
  placeholder: "Select Date",
  autoOk: true,
  disableFutureDate: false,
  disableFuture: false,
  disablePast: false,
  fieldHelperText: "",
};

A_DatePicker.propTypes = {
  variant: PropTypes.oneOf(["inline"]),
  //color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  //size: PropTypes.oneOf(["small", "medium"]),
  margin: PropTypes.oneOf(["normal", "medium"]),
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  defaultValue: PropTypes.any,
  helperText: PropTypes.string,
  isValidDate: PropTypes.func,
  id: PropTypes.any,
  clearable: PropTypes.bool,
  views: PropTypes.array,
  disableToolbar: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.object,
  minDate: PropTypes.any,
  lettersAllowed: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.any,
  error: PropTypes.any,
  autoOk: PropTypes.bool,
  disableFutureDate: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  fieldHelperText: PropTypes.string,
};

export default A_DatePicker;
