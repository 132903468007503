const translationEng = {
    "Introduction": "Introduction",
    "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop": "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop",
    "Plugins to detect the user language": "Plugins to detect the user language",
    "Plugins to load translations": "Plugins to load translations",
    "Optionally cache the translations": "Optionally cache the translations",
    "Advantages": "Advantages",
    "Flexibility to use other packages": "Flexibility to use other packages",
    "Welcome": "Welcome",
    "WelcomeText": "Great if you are seeing this page. OIDC is setup successfully",
    "ProfileSettings": "Profile Settings",
    "ApplicationSettings":"Application Settings",
    "ChangePassword":"Change Password",
    "Logout":"Log out"
}

export default translationEng
