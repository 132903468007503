import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { utils, writeFile } from "xlsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {  CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import emitter from "../components/Emitter";
import * as esgApi from "../ESG_API/index"



const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#757474",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      textDecoration:"none",
      border:"0px",
      whiteSpace: "wrap",
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
      position: "relative"
    },
    button: {
      background: "#d7d7d7",
    },
    cell: {
    width: 400,
    whiteSpace: "normal",
     wordWrap: "break-word",
     overflow: "hidden",
     textOverflow: "ellipsis",
    },
    tableContainer: {
      height: 400,
      overflowY: "scroll",
    }
  });

const ESG_AdminPractice = () => {
  const classes = useStyles();
  const [bestPracticesResponses, setBestPracticesResponses] = useState([
  ]);
  const [surveyId, setSurveyId] = useState("");
  const [selectedSubPillarId, setSelectedSubPillarId] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadSetupView,setReloadSetupView]=useState(false);

 
  
useEffect(() => {

  if(reloadSetupView){
   
  const surveyId = localStorage.getItem("Survey")!= undefined?JSON.parse(localStorage.getItem("Survey")):"";
   const selectedSubPillarId= localStorage.getItem("subPillarId")!= undefined?JSON.parse(localStorage.getItem("subPillarId")):"";
   let sectorId = localStorage.getItem("sectorId");
   if(sectorId && sectorId != "undefined"){
     sectorId=JSON.parse(sectorId)
   }
   setSurveyId(surveyId);
   setSelectedSubPillarId(selectedSubPillarId);
   setSelectedSectorId(sectorId?sectorId:0);
   setReloadSetupView(false)  
  }
  }, [reloadSetupView]);


  useEffect(()=>{
    if (surveyId != "" && selectedSubPillarId != "") {
      const getBestPracticesResponses = async () => {
        setLoading(true);
        let response = await esgApi.getBestPracticeData(surveyId,selectedSubPillarId,selectedSectorId);
        setBestPracticesResponses(response && response.length!==0?response:[]);
        setLoading(false);
      };
      getBestPracticesResponses();
    }

  }, [surveyId,selectedSubPillarId,selectedSectorId])


  useEffect(()=>{
    const onSetupViewReloadEvent = (receivedData) => {
      setReloadSetupView(receivedData);
    };
  
    emitter.on("reloadBestPracticeTable", onSetupViewReloadEvent);
  },[])


  return (
  <div>
    <div>
     {!loading && bestPracticesResponses.length !==0 ? (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead
              style={{
                zIndex: 0,
                position: "sticky",
                top: "0px",
              }}
            >
              <TableRow>
                <StyledTableCell>Assets</StyledTableCell>
                <StyledTableCell align="center">Best Practices</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "auto", maxHheight: "250px" }}>
              { bestPracticesResponses.map((row, idx) => (
                  <>
                    <StyledTableRow  key={row.portfolioinvestmentId}>
                      <StyledTableCell className={classes.cell} component="th" scope="row">
                        {row.portfolioinvestmentName}
                      </StyledTableCell>
                      <StyledTableCell className={classes.cell}>
                       <div style={{border:"1px solid #ccc", padding:"6px 6px 6px 6px",margin:"8px 8px 8px 8px"}}>
                      {row.bestpractices && row.bestpractices.split('\n').map((line, index) => (
                        <div key={index} style={{ marginBottom: "5px" }}>{line}</div>
                            ))}
                       </div>
                      </StyledTableCell> 
                    </StyledTableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : ( loading ? (
          <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
           >
          <CircularProgress />
        </div>): ( !surveyId?(
                  <Alert severity="info">
                   No Survey found whoes responses are closed.
                  </Alert>):(
                  <Alert severity="info">
                    No best practices to show, for choosen filters.
                  </Alert>)
                 )
      )}
     </div>
  </div>
  );
};
ESG_AdminPractice.propTypes = {
  content: PropTypes,
};

export default ESG_AdminPractice;
