export const organizeQuestions = (questions) => {
  const organized = [];

  function findChildren(parentId) {
    const children = [];
    for (const question of questions) {
      if (question.parentQuestionId === parentId) {
        const child = {
          ...question,
          children: findChildren(question.questionId),
        };
        children.push(child);
      }
    }
    return children.length > 0 ? children : null;
  }

  const topLevelQuestions = questions.filter(
    (question) => question.parentQuestionId === null
  );

  for (const question of topLevelQuestions) {
    const organizedQuestion = {
      ...question,
      children: findChildren(question.questionId),
    };
    organized.push(organizedQuestion);
  }

  return organized;
};

export const organizeQuestionsFlat = (questions) => {
  const organized = [];

  function findChildrenFlat(parentId) {
    for (const question of questions) {
      if (question.parentQuestionId === parentId) {
        organized.push(question);
        findChildrenFlat(question.questionId);
      }
    }
  }

  const topLevelQuestions = questions.filter(
    (question) => question.parentQuestionId === null
  );

  for (const question of topLevelQuestions) {
    organized.push(question);
    findChildrenFlat(question.questionId);
  }

  return organized;
};

export const getDate = (timestamp) => {
  const dateOnly = timestamp.split("T")[0];
  return dateOnly ? dateOnly : "";
};


export const reformatDateString = (str) =>{
  let b = str.split(/\D/);
  return b.reverse().join('-');
}

export const handleParsingValues = (value, type = 1) => {
  if (value) {
    switch (type) {
      case 2:
        return value.toString();
      case 1:
        return Number(value);
      case 3:
        return Boolean(value);
      default:
        return "";
    }
  }
  return false;
};

export const getTypeFromId = (type) => {
  switch (type) {
    case 2:
      return "text";
    case 1:
      return "number";
    case 3:
      return "boolean";
    default:
      return "";
  }
};
