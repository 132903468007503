import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import M_CreateOptionset from "./Comman/M_CreateOptionset";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  autocomplete: {
    backgroundColor: "rgba(248, 227, 238, 0.8)",
    width: "100%",
  },
  error: {
    paddingTop: "10px",
    paddingLeft: "10px",
    color: "rgba(148, 97, 116, 0.8)",
  },
  errormssg: {
    paddingLeft: "10px",
    color: "rgba(148, 97, 116, 0.8)",
  },
  button: {
    textAlign: "center",
    padding: "10px",
  },
  secondary: {
    background: "#f44336",
  },
}));
const M_LookupInfo = (props) => {
  const [show, setShow] = useState(props.open);
  const classes = useStyles();
  const handleValue = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const lookupCallBack = (item) => {
    props.lookupCallBack(item);
  };
  return (
    <>
      <div className={classes.autocomplete}>
        <h5 className={classes.error}>No Result</h5>
        <p className={classes.errormssg}>
          Please Check for alternative and or names. If you are sure this record
          does not exsist type the name in full, then hit the button below.
        </p>
        <div className={classes.button}>
          <A_Button
            // className={classes.secondary}
            color="secondary"
            label="Create this record"
            onClick={handleValue}
          />
        </div>
      </div>

      <A_SimpleDialog
        open={show}
        title=""
        onClose={handleClose}
        dialogContent={
          <M_CreateOptionset
            title={props.title}
            lookupCallBack={lookupCallBack}
            close={handleClose}
            data={props.inputData}
            label={props.label}
          />
        }
      />
    </>
  );
};
M_LookupInfo.propTypes = {
  open: PropTypes.bool,
  inputData: PropTypes.string,
  label: PropTypes.string,
  lookupCallBack: PropTypes.func,
  title: PropTypes.object,
};
export default M_LookupInfo;
