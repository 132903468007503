import React, { useState, useEffect } from "react";
import { SurveyFilter, SurveyFilterYear } from "../components";
import * as esgApi from "../ESG_API/index";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import {  CircularProgress, TextareaAutosize, TextField } from "@material-ui/core";

import {
  getQuestionsJsonFromSurvey,
  getAllPillar,
  getSubPillar,
} from "../ESG_API";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { responseLogic , responseLogicMapping,defaultValue, SurveyStatusMap } from "../ESG_Config";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    textDecoration:"none",
    border:"0px",
    whiteSpace: "wrap",
    padding:0,
    margin:0
  },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    position: "relative",
  },
  tableHead:{
      zIndex: 0,
      position: "sticky",
      top: "0px",
      minWidth:"150%"
  },
  tableRow:{
   display:"flex",
   marginBottom: "1px",
   margin:"2px"
  },
  tableContainer: {
    height: 600,
    overflowY: "scroll",
  },
  pillar:{
    color:"white",
    background:"rgba(38,52,64,1)",
    textDecoration:"none",
    border:"0px",
    padding:"10px"
  },
  subPillar:{
    color:"blue",
    padding:"10px"
  },
  cell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration:"none",
     border:"0px",
  },

  textField: {
    width: "80px",
    fontSize: 14,
    "& input": {
      caretColor: "black",
    },
  },
  displayStatement: {
    fontSize: 14,
    marginRight: "30px",
   // remove default css
      width: '100%', 
      resize: 'none',
      overflowY: 'auto', 
      padding: 0, 
      margin: 0, 
      backgroundColor: 'transparent',
      font: 'inherit',
  },
  flexCenter:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08)",
  },
  flexContainer: {
    display: "flex",
    flexDirection:"column",
    alignItems: "flex-start",
    justifyContent:"space-between",
    alignItems:"center",
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration:"none",
    border:"0px",
    padding:"0px",
    boxShadow: ""
  },

  responseScore: {
    flex: "0 0 80px", // Fixed width for response score
    "& input": {
      caretColor: "white",
    },
  },

  button: {
    background: "#d7d7d7",
  },

  removeBoder:{
  textDecoration:"none",
  border:"0px",
  },
  
  alternateColor: {
    width: "-webkit-fill-available",
    textAlign: "-webkit-center",
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
      margin:"2px"
    },
  },
  boxShadow:{
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08)"
  },
  scoringModel: {
    padding: "8px",
    background: "white",
    marginLeft:"5px",
    border: "1px solid #333",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
  },
  

}));



const QuestionnaireScore = () => {
  const classes = useStyles();
  const [surveyId, setSurveyId] = useState("");
  const [surveyYear, setSurveyYear] = useState("");  
  const [questionnaireScoreData, setQuestionnaireScoreData]=useState([])
  const [finalTableData,setFinalTableData]=useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [erromsg, setErrorMsg] = useState("");
  const [surveyClosed,setSurveyClosed]=useState(false);
  const [displayStatementError,setDisplayStatementError]=useState({})

  
  const getQuestionTitle=(responseLogic)=>{
  if(responseLogic === responseLogicMapping.AdminInput ){
    return "Non-Boolean Question";
   }
   else{
    return "Boolean Question";
   }
  }

 const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setErrorMsg("");
 };
  
  const getResponseCss=(id)=>{
    if(id !== responseLogicMapping.AdminInput){
      return {  width: "120px",fontSize: 14}
    }
      return {  width: "120px", fontSize: 14,color:"black",backgroundColor:"grey"}
  }


 const handleSave=async()=>{
      // set required error
      setIsDisable(true);

      let hasError = false;
      const updatedErrors = {};
      finalTableData.forEach((item)=>{
        item?.eSGQuestionInfoResponses.forEach((questionData)=>{
          if(!questionData?.displayStatement || questionData.displayStatement.trim() =="") {
            updatedErrors[questionData?.questionId] = 'This field is required';
            hasError = true;
          }
        })
      });
      // set error
      if (hasError) {
        setDisplayStatementError(updatedErrors);
     }
     else{
      try {
      // filter extra fields
      const formData=finalTableData.map((item)=>{
        return {
          surveySubCategoryWeightId: item.surveysubCategoryWeightId,
          subCategoryId: item.subCategoryId,
          weight: item.weight,
          eSGQuestionScoreRequests: item.eSGQuestionInfoResponses
       }});
      const response = await esgApi.putScoreLogicBySurveyId(formData);
        if (response && response.statusCode != 200) {
        setFailure(true);
        setErrorMsg(
        response && response?.errorMsg
          ? response && response.errorMsg
          : "Some issue occured while saving scores"
       );
       } else {
       setSuccess(true);
       setErrorMsg(response.data);
       }
      window.location.reload();
     }catch (error) {
      setErrorMsg( "Some issue occured while saving scores" );
    }
  }
}


  const addWeightBySubpillar=(scoreData,pillarSubPillar,allQuestions)=>{ 
       const finalData=[];
       scoreData.forEach((data)=>
            { const filteredData= pillarSubPillar.find((item)=>(item.questionSubcategoryId===data.subCategoryId));
              if(filteredData !== null){
              finalData.push({...filteredData,
              surveysubCategoryWeightId:data.surveySubCategoryWeightId,
              weight:data.weight?data.weight:defaultValue.weight,
              eSGQuestionInfoResponses:data.eSGQuestionInfoResponses
              })
          }
    });
    if(finalData.length !==0){
      finalData.forEach((item)=>{
        item.eSGQuestionInfoResponses.forEach((obj)=>{
          const questionText= getQuestionName(obj.questionId,allQuestions);
           obj["questionText"]=questionText;
        })
      });
    }
    return finalData;
  }

  const getQuestionName=(questionId,allQuestions)=>{
    const question= allQuestions?.find((item)=>(item.questionId === questionId));
    return question?.questionText;
  }
  
  const handleWeightChange = (e, subCategoryId) => {
    let value = e.target.value;
    if(e.target.type=='number'){
      // remove -ive and 
      let numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
      // Limit to 3 decimal places
      if (!isNaN(numericValue)) {
        value = numericValue.toFixed(3);
      }else{
        e.target.value = "";
      }
      }
    setFinalTableData((prevData) => {
      return prevData.map((item) => {
        if (item.subCategoryId === subCategoryId) {
          return {
            ...item,
            weight: value,
          };
        }
        return item;
      });
    });
    setIsDisable(true);
  };


  const handleChange = (e, subCategoryId, questionId, field) => {
    let value = e.target.value;
    if(e.target.type=='number'){
     // remove -ive and 
     let numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
     // Limit to 3 decimal places
     if (!isNaN(numericValue)) {
      value = numericValue.toFixed(3);
     }else{
      e.target.value = "";
     }
    }
    // set error for displaystatement required
    if (field === 'displayStatement') {
      if (!value || value.trim() =="") {
        setDisplayStatementError((prevErrors) => ({
          ...prevErrors,
          [questionId]: 'This field is required',
        }));
      } else {
        setDisplayStatementError((prevErrors) => ({
          ...prevErrors,
          [questionId]: '',
        }));
      }
     }


    // Update the finalJson array
    setFinalTableData((prevData) => {
      return prevData.map((item) => {
        if (item.subCategoryId === subCategoryId) {
          return {
            ...item,
            eSGQuestionInfoResponses: item.eSGQuestionInfoResponses.map((question) => {
              if (question.questionId === questionId) {
                return {
                  ...question,
                  [field]: value,
                };
              }
              return question;
            }),
          };
        }
        return item;
      });
    });
    setIsDisable(false);
  };
  
  
  const getQuestionnaireScoreBysurvey=async()=>{
     let response= await esgApi.getScoreLogicBySurveyId(surveyId);
     return response?response:[];
  }


  useEffect(() => {

  const getQuestionnaireDataBySurvey = async () => {
      // get survey status
      const surveyData= await esgApi.getSurveyBySurveyId(surveyId);
      const endDate = new Date(surveyData.endDate);
      const currentDate = new Date();
       // if survey  response closed
      let closed= endDate < currentDate?true:false;
      setSurveyClosed(closed)
     if(!closed)
     {
      setIsLoading(true);
      // get all score and weight data
      const getQuestionnaireScoreData= await getQuestionnaireScoreBysurvey();
      const allQuestions = await getQuestionsJsonFromSurvey(surveyId);
      const pillarsData = await getAllPillar();
      const subPillarData = await getSubPillar();
      setQuestionnaireScoreData(getQuestionnaireScoreData);
      const pillarSubPillars = [];
       pillarsData.forEach(pillar => {
       subPillarData.forEach(subPillar => {
          if(subPillar.questioncategoryid === pillar.questioncategoryid){
           const data={
             questionCategoryId: pillar.questioncategoryid,
             pillarName:pillar.name,
             questionSubcategoryId:subPillar.questionsubcategoryid,
             subpillarName:subPillar.name
               }
               pillarSubPillars.push(data);
           }
        });
       })
       if(pillarSubPillars?.length !==0 && getQuestionnaireScoreData?.length !==0 && allQuestions){
        const pillerSubpillarweight= addWeightBySubpillar(getQuestionnaireScoreData,pillarSubPillars,allQuestions)
        // set table data for piller subpiller & weight
        setFinalTableData(pillerSubpillarweight);
        setIsLoading(false);
        }else{
          setIsLoading(false);
        }
     }else{
      setFinalTableData([]);
    }
  };
    
  // get all data 
  if(surveyId){
            getQuestionnaireDataBySurvey();
     }
  }, [surveyId]);



  return (
    <div>
   <SurveyFilter
    selectedSurveyId={surveyId}
    filtersurveyyear={surveyYear}
    setSelectedSurveyId={setSurveyId}
    publishedtoggle ={true}
    heading={"Published Survey"}
   />
   <SurveyFilterYear
    selectedSurveyId={surveyId}
    filtersurveyyear={surveyYear}
    setSelectedSurveyId={setSurveyId}
    setSurveyFilterYear={setSurveyYear}
    publishedtoggle={true}
    heading={"Survey Years"}
   />
   {surveyClosed?(<Alert severity="error">Survey Closed</Alert>):
   ( isLoading ? ( 
         <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ width: "30px", height: "30px" }} />
         </div>
        ) : ( finalTableData.length !==0?(
          <>
           <div className="addIconAlignment">
             <span style={{fontWeight:"600"}}>Scoring Model:</span> 
             <span className={classes.scoringModel}> weightedAverage</span>
           
           </div>
          <TableContainer component={Paper}>
           <Table className={classes.table} aria-label="customized table">
            <TableHead className={classes.tableHead}>
             <TableRow className={classes.tableRow} style={{margin:"0"}}>
              <StyledTableCell style={{width:"180px"}}>Pillar Name</StyledTableCell>
              <StyledTableCell style={{width:"180px"}}>Sub Pillar Name</StyledTableCell>
              <StyledTableCell style={{width:"150px"}}>Weight</StyledTableCell>
              <StyledTableCell style={{width:"200px"}}>Question</StyledTableCell>
              <StyledTableCell style={{width:"180px"}}>Question Rating</StyledTableCell>
              <StyledTableCell style={{width:"180px"}}>Response Logic</StyledTableCell>
              <StyledTableCell style={{width:"350px"}}>Display statement *</StyledTableCell>
              </TableRow>
              </TableHead>
             <TableBody style={{ overflowY: "auto", maxHheight: "350px"}}>
             {finalTableData.map((data, idx) => (
              <StyledTableRow className={classes.tableRow} key={idx}>
                  <StyledTableCell style={{width:"180px"}} className={`${classes.flexCenter} ${classes.pillar}`} component="th" scope="row">
                  {data.pillarName}
                  </StyledTableCell>

                  <StyledTableCell  style={{width:"180px"}} className={`${classes.flexCenter} ${classes.subPillar}`} component="th" scope="row" >
                  {data.subpillarName}
                  </StyledTableCell>
                    
                    <StyledTableCell style={{width:"150px"}} className={`${classes.flexCenter}`} >
                    <TextField 
                     InputProps={{
                      disableUnderline: true,
                      }}
                     style={{textDecoration:"none"}}
                     className={classes.textField}
                     type='number' 
                     defaultValue={data.weight}
                     onChange={(e) =>{
                      handleWeightChange(e,data.subCategoryId)}}
                    />
                    </StyledTableCell>

                    <StyledTableCell  style={{width:"200px"}}>
                      {data.eSGQuestionInfoResponses.map((question) => (
                             <div key={question.questionId} className={`${classes.alternateColor}`}>
                                <TableCell  className={classes.cell} style={{color:"blue"}}
                                 title={getQuestionTitle(question.responseLogic)}
                                >{question.questionText}
                                </TableCell>
                             </div>
                              )
                       )}
                    </StyledTableCell>

                    <StyledTableCell style={{width:"180px"}} className={`${classes.flexContainer} ${classes.boxShadow}`}>
                      {data.eSGQuestionInfoResponses.map((question) => (
                         <div key={question.questionId} className={`${classes.alternateColor}`} >
                                <TextField 
                                 style={{padding: "10px"}}
                                 InputProps={{
                                  disableUnderline: true,
                                  min: 0 
                                  }}
                                 className={classes.textField}
                                 name="questionScore"
                                 key={question.questionId}
                                 type='number' 
                                 defaultValue={parseInt(question.questionScore)}
                                 onChange={(e) => {
                                  e.preventDefault();
                                   handleChange(e,data.subCategoryId, question.questionId, e.target.name)
                                 }}
                                 title="Enter number up to three decimal places"
                                  />
                          </div>    
                          ))}
                    </StyledTableCell>

                    <StyledTableCell  style={{width:"180px"}} className={`${classes.flexContainer} ${classes.boxShadow}`}>
                      {data.eSGQuestionInfoResponses.map((question) => (
                             <div key={question.questionId} className={`${classes.alternateColor}`} style={{padding: "10px"}}>
                                 <Select
                                  style={getResponseCss(question.responseLogic)}
                                  name="responseLogic"
                                  key={question.questionId}
                                  value={question.responseLogic === responseLogicMapping.AdminInput ? "Admin Input" : question.responseLogic}
                                  disabled={question.responseLogic === responseLogicMapping.AdminInput}
                                  onChange={(e) => handleChange(e,data.subCategoryId, question.questionId, e.target.name)}
                                  >
                                  {question.responseLogic === responseLogicMapping.AdminInput && (
                                  <MenuItem 
                                      disabled 
                                      value= "Admin Input">
                                       Admin Input
                                      </MenuItem>
                                  )}
                                  {responseLogic .filter((item) => item.responseLogicId !== responseLogicMapping.AdminInput)
                                  .map((item) => (
                                       <MenuItem 
                                        value={item.responseLogicId}
                                        key={item.responseLogicId}
                                        className="menuitemtext"
                                        style={{
                                        whiteSpace: "normal",
                                        overflowWrap: "break-word",
                                       }}
                                       >{item.name}</MenuItem>
                                   ))}
                                  
                                  </Select>
                             </div>
                              )
                       )}
                    </StyledTableCell>
                    
                    <StyledTableCell  style={{width:"350px"}} className={`${classes.flexContainer} ${classes.boxShadow}`}>
                      {data.eSGQuestionInfoResponses.map((question) => (
                        <div key={question.questionId} style={{padding: "10px"}} className={`${classes.alternateColor}`}>
                        <TextareaAutosize
                         style={{padding: "5px"}}
                         className={classes.displayStatement}
                         minRows={1}
                         maxRows={1}
                         name="displayStatement"
                         key={question.questionId}
                         defaultValue={question.displayStatement}
                         onChange={(e) => handleChange(e,data.subCategoryId, question.questionId, e.target.name)}
                         required={question?.displayStatement ? false : true}
                        /> 
                        <span style={{ color: "red", fontSize: "10px" }}>
                        {displayStatementError[question?.questionId]}</span>

                        </div>
                      ))}
                    </StyledTableCell> 
                </StyledTableRow>
               ))} 
             </TableBody>
          </Table>
          </TableContainer>
          <A_Button
           textAlign={"left"}
           label={"Save"}
           style={{ margin: "6px 0px" }}
           color={"primary"}
           onClick={handleSave}
           disabled={isDisable}
           ></A_Button>
          </>
              ):(
                !surveyId?(
                  <Alert severity="info">
                  No Published survey found.
                 </Alert>
                ):(
                 <Alert severity="info">
                 No rating questions are found for this survey.
                </Alert>)
               )
            )
    )}
       <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "10px" }} >
       </div>
       <A_Snackbar
        open={success}
        message={erromsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
  </div>
  )
};

export default QuestionnaireScore;
