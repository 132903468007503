import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import * as esgApi from "../ESG_API/index";
import "../ESG.css";
import emitter from "./Emitter";
import {SurveyStatusMap} from "../ESG_Config"

const SurveyFilter = (props) => {
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const [filteredata, setfilterData] = useState([]);
  const {
    selectedSurveyId,
    setSelectedSurveyId,
    filtersurveyyear,
    publishedtoggle,
surveyClosetoggle,
heading,
    publishedAndClosetoggle,
    setAreAllSurveyUnpublished,
  } = props;
  const [survey, setSurvey] = useState([]);
  const [sendData, setSendData] = useState();
  

  //Get Question Answer Type
  const getAllSurvey = async () => {
    let response = await esgApi.getSurvey();
    if (publishedtoggle) {
      response = response.filter((survey) => survey.status == 1);
    }
if(surveyClosetoggle){
      response = response.filter((survey) => {
        return (survey.status == SurveyStatusMap.Closed || survey.status == SurveyStatusMap.ScoreFreeze)
      });
    }

    if(publishedAndClosetoggle){
      response = response.filter((survey) => {
        return (survey.status == 1 || survey.status == 2);
      });

      if(response.length == 0){
        setAreAllSurveyUnpublished(true);
      }
    }
    localStorage.setItem("AllSurvey", JSON.stringify(response));
    setSurvey(response);
    setSelectedSurvey(response.length > 0 ? response[0].name : "");
    if (typeof props.setSurveyName === 'function') {
      props.setSurveyName(response.length > 0 ? response[0].name : "");
    } 
    setSelectedSurveyId(response.length > 0 ? response[0].surveyId : "");
  };
  useEffect(() => {
    getAllSurvey();
  }, []);
  useEffect(() => {
    if (selectedSurveyId) {
      localStorage.setItem("Survey", JSON.stringify(selectedSurveyId));
    }
  }, [selectedSurveyId]);
  useEffect(() => {}, []);
  var filterSurveyByYear = [];
  useEffect(() => {
    for (let i = 0; i < survey.length; i++) {
      const item = survey[i];
      if (filtersurveyyear && item.year === filtersurveyyear) {
        filterSurveyByYear.push(item);
        setfilterData(filterSurveyByYear);
        setSelectedSurvey(
          filterSurveyByYear.length > 0 ? filterSurveyByYear[0].name : ""
        );
        if (typeof props.setSurveyName === 'function') {
          props.setSurveyName(filterSurveyByYear.length > 0 ? filterSurveyByYear[0].name : "");
        }
        setSelectedSurveyId(
          filterSurveyByYear.length > 0 ? filterSurveyByYear[0].surveyId : ""
        );
        emitter.emit(
          "dataEvent",
          filterSurveyByYear.length > 0 ? filterSurveyByYear[0].surveyId : ""
        );
      }
    }
  }, [filtersurveyyear, survey]);

  useEffect(() => {
    setSelectedSurvey(filteredata.length > 0 ? filteredata[0].name : "");
    if (typeof props.setSurveyName === 'function') {
      props.setSurveyName(filteredata.length > 0 ? filteredata[0].name : "")
    }   
  }, [filteredata]);

  //Handle Change Survey
  const handleChange = (event) => {
    const filterId = survey.find(
      (element) => element.name === event.target.value
    );
    setSelectedSurvey(event.target.value);
    if (typeof props.setSurveyName === 'function') {
      props.setSurveyName(event.target.value)
    }    
    setSelectedSurveyId(filterId.surveyId);
    emitter.emit("dataEvent", filterId.surveyId);
  };
  return (
<>{ heading &&
      <div style={{
         position: "fixed",
         top: "90px",
         left: "436px",
         zIndex: "2",
         fontSize:"14px",
         color:"white",
         fontWeight:"600"
       }}>{heading}</div> }
    <div
      style={{
        position: "fixed",
        top: "108px",
        left: "436px",
        zIndex: "2",
        backgroundColor: "#ffff",
        width: "150px",
        height: "38px",
        borderRadius: "3px",
      }}
    >
      <FormControl>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={selectedSurvey ? selectedSurvey : "Select"}
          onChange={handleChange}
          style={{ width: "125px", marginTop: "2px", paddingLeft: "14px" }}
          disableUnderline
        >
          {filteredata &&
            filteredata.map((item) => {
              return (
                <MenuItem value={item.name} key={item.surveyId}>
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
</>
  );
};
SurveyFilter.propTypes = {
  selectedSurveyId: PropTypes.string,
  setSelectedSurveyId: PropTypes.func,
  filtersurveyyear: PropTypes.string,
  publishedtoggle: PropTypes.string,
  publishedAndClosetoggle: PropTypes.string,
  setSurveyName:PropTypes.any,
  surveyClosetoggle:PropTypes.any,
  setAreAllSurveyUnpublished:PropTypes.func,
};
export default SurveyFilter;