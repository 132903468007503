import { httpPut, httpPost } from "../../../../../Utils/apis/apis";
import { toEpochFormat } from "../../../../../Utils/Helpers/dateUtils";
import { InsiderButtons } from "../../../../../Utils/config/config";
export const insiderButtonsOpertions = async (
  allData,
  userid,
  pageUrl,
  loggedInID,
  callBack,
  type,
  setButtonDisabled
) => {
  const security = {
    read: {
      groupids: [""],
      users: [""],
    },
    update: {
      groupids: [""],
      users: [""],
    },
    full: {
      groupids: [""],
      users: [],
    },
    privateRecord: false,
  };
  const closebuttonProperty =
    allData &&
    allData.configurations &&
    allData.configurations.InsiderListClosed &&
    allData.configurations.InsiderListClosed.buttonKey
      ? allData.configurations.InsiderListClosed.buttonKey
      : "Insider List Closed";
  const closebuttonValue =
    allData &&
    allData.configurations &&
    allData.configurations.InsiderListClosed &&
    allData.configurations.InsiderListClosed.closeInsider
      ? allData.configurations.InsiderListClosed.closeInsider
      : "";
  const confirmButtonProperty =
    allData &&
    allData.configurations &&
    allData.configurations.confirmUptoDateButton &&
    allData.configurations.confirmUptoDateButton.buttonKey
      ? allData.configurations.confirmUptoDateButton.buttonKey
      : "Insider List Last Reviewed";
  let currentDate = toEpochFormat(new Date());
  const confirmMsg =
    allData &&
    allData.configurations &&
    allData.configurations.confirmUptoDateButton &&
    allData.configurations.confirmUptoDateButton.snackBarMsg
      ? allData.configurations.confirmUptoDateButton.snackBarMsg
      : "";

  const closeMsg =
    allData &&
    allData.configurations &&
    allData.configurations.InsiderListClosed &&
    allData.configurations.InsiderListClosed.snackBarMsg
      ? allData.configurations.InsiderListClosed.snackBarMsg
      : "";

  if (type == InsiderButtons.CLOSEBUTTON) {
    var updatedData = {
      id: userid,
      label: pageUrl,
      properties: { [closebuttonProperty]: closebuttonValue },
      security: security,
      edges: "",
    };
  }
  if (type == InsiderButtons.CONFIRMBUTTON) {
    setButtonDisabled(true);
    updatedData = {
      id: userid,
      label: pageUrl,
      properties: { [confirmButtonProperty]: currentDate },
      security: security,
      edges: "",
    };
  }
  await httpPut(`/CRMData/${"update"}?pageUrl=${pageUrl}`, updatedData);
  if (type == InsiderButtons.CLOSEBUTTON) {
    callBack(closeMsg);
  } else if (type == InsiderButtons.CONFIRMBUTTON) {
    callBack(confirmMsg);
    setButtonDisabled(false);
  }
};
export const insiderPDFDownload = async (dealID, fileName, setIsDisable) => {
  setIsDisable(true);
  let response = await httpPost(
    `/Pdf/insiderListPdfGeneration?dealId=${dealID}`
  );
  const url1 = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url1;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  setIsDisable(false);
};
