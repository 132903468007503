export const O_MeetingSlotCardCss = {
  cardBox: {
    display: "inline-grid",
    alignItems: "center",
    padding: "1rem",
    marginTop: "0.1rem",
    boxShadow: "1px 1px 8px #888888",
  },
  email: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  typoGraphBold: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  typoGraph: {
    fontSize: "0.875rem",
  },
  margin: {
    marginTop: "0.6rem",
  },

  detailFirstBox: {
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
  },

  detailSecondBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    minHeight: "5vh",
  },

  typographyStyle: {
    color: "#4687b8",
    fontWeight: "bold",
  },

  timePeriod: {
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
    fontStyle: "italic",
    display: "block",
    paddingTop: "0.2rem",
    width:"100%",
    "white-space":"initial"
  },

  labelEmail: {
    margin: "0 10px",
    marginTop: "10px",
    color: "#ffffff",
    backgroundColor: "#FFA500",
  },

  ircSlotsMap: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "3rem",
  },

  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
  },

  skeleton : {
    width : "100%"
  },

  inputcontrol: {
    width: "100%",
  },

  inputcontrolTextField: {
    width: "100%",
  },
  addSlot:{
    marginTop: "10px",
    marginLeft: "0.4rem",
  }
}