import React from "react";
import M_GenericChecklistView from "../GenericChecklist/M_GenericChecklistView";
import { Grid, makeStyles } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { updateStatus } from "./Helper/ConsentRequest.helper";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { status } from "../../../Organisms/Common/ConsentRequest/ConsentRequest.const";
import propTypes from "prop-types";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
const useStyles = makeStyles({
  successButton: {
    backgroundColor: "#4bb543",
    color: "white",
    "&:hover": {
      backgroundColor: "#389c30",
    },
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "white",
    "&:hover": {
      backgroundColor: "#ba202e",
    },
  },
  checklist: { padding: "1rem 0" },
  statusDetails: {
    marginBottom: "2rem",
    fontSize: "0.8rem",
  },
  title: {
    fontWeight: "700",
  },
});
function M_ApprovalScreen({
  getCurrentPage,
  content,
  reviewerIds,
  currentPage,
}) {
  const classes = useStyles();
  const { crmId, pageUrl } = useParams();
  const [isComplete, setIsComplete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [comments, setComments] = React.useState(
    getObjectFromJSONString(currentPage, ".data.approverComment", "")
  );
  const handleChangeStatue = async (statusData) => {
    setIsLoading(true);
    try {
      let triggerEmail = {
        emailCRMIds: reviewerIds ? reviewerIds : [],
        emailConfigId: getObjectFromJSONString(
          content,
          ".reviewer.emailTemplateId",
          ""
        ),
      };
      await updateStatus(
        {
          requestStatus: statusData,
          status: status.WAITING_FOR_REVIEW,
          approverComment: comments,
        },
        crmId,
        pageUrl,
        [],
        triggerEmail
      );
      getCurrentPage({ pageUrl, crmId });
    } catch (error) {
      //intensionally added comment
    }
    setIsLoading(false);
  };
  const handleOnChange = (event) => {
    let commentText = event.target.value;
    setComments(commentText ? commentText : "");
  };
  return (
    <div>
      <div className={classes.checklist}>
        <M_GenericChecklistView
          handleValidation={setIsComplete}
          onReload={getCurrentPage}
          content={content}
        />
      </div>
      <div className={classes.statusDetails}>
        {getObjectFromJSONString(currentPage, ".data.requestStatus") ? (
          <div className="">
            <span className={classes.title}>Approval Status: </span>
            {getObjectFromJSONString(currentPage, ".data.requestStatus", "")}
          </div>
        ) : (
          ""
        )}
        {getObjectFromJSONString(currentPage, ".data.requestStatus") &&
        getObjectFromJSONString(currentPage, ".data.reviewerComment") ? (
          <hr />
        ) : (
          ""
        )}
        {getObjectFromJSONString(currentPage, ".data.reviewerComment") ? (
          <div className="">
            <span className={classes.title}>Reviewer Comments: </span>
            {getObjectFromJSONString(currentPage, ".data.reviewerComment", "")}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="">
        <A_TextField
          label={"Comments"}
          fullWidth={true}
          onChange={handleOnChange}
          value={comments}
          defaultValue={comments}
        />
      </div>
      <Grid container>
        <Grid item>
          <A_Button
            className={classes.successButton}
            label={status.APPROVE}
            onClick={() => handleChangeStatue(status.APPROVED)}
            disabled={!isComplete || isLoading}
          />
        </Grid>
        <Grid item>
          <A_Button
            className={classes.dangerButton}
            label={status.REJECT}
            onClick={() => handleChangeStatue(status.REJECTED)}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}
M_ApprovalScreen.propTypes = {
  content: propTypes.object,
  getCurrentPage: propTypes.any,
  reviewerIds: propTypes.any,
  currentPage: propTypes.any,
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_ApprovalScreen);
