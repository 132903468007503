import React from "react";

const Tick = () => {
  return (
    <svg
      fill="#09be4e"
      height="15px"
      width="15px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      xmlSpace="preserve"
      stroke="#09be4e"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <polygon points="437.3,30 202.7,339.3 64,200.7 0,264.7 213.3,478 512,94 " />{" "}
      </g>
    </svg>
  );
};

export default Tick;
