import { httpPost } from "../../../../../../Utils/apis/apis";
export const getDocumentFromMultipleContainer = async (
  containerDetails,
  getFirstExistingRecord = false
) => {
  let containerResponse = await httpPost(
    `/GenericDocument/GetDocumentFromMultipleContainers?getFirstExistingRecord=${getFirstExistingRecord}`,
    containerDetails ? containerDetails : []
  );
  return containerResponse;
};
export const indexConst = {
  checklistTemplate: "checklistTemplate",
  checklistItems: "checklistItems",
  checklistId: "checklistId",
};
