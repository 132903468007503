import React from "react";
import { httpGet } from "../../../../../Utils/apis/apis";
import { constData, status, uiTypes } from "./ConsentRequest.const";
// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import {
  getListOfDataFromMultiLookup,
  renderComponentBasedOnType,
  verifyUserID,
} from "./ConsentRequest.helper";
import propTypes from "prop-types";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";

function O_ConsentRequest({ title, content, currentPage }) {
  const [uiType, setUiType] = React.useState("disable");
  const [configData, setConfigData] = React.useState([]);
  let { crmContactUserId } = getCRMContactInfo();
  var userId = crmContactUserId ? crmContactUserId : "";
  React.useEffect(() => {
    if (userId && userId !== "") {
      getConfigContent();
    }
  }, [userId]);
  const getConfigContent = async () => {
    try {
      let containerName =
        content && content.container ? content.container : constData.CONTAINER;
      let containerId =
        content && content.containerId ? content.containerId : "";
      let configData = await httpGet(
        `/GenericDocument/${containerName}/${containerId}`
      );
      setConfigData(configData);
      if (currentPage && currentPage.data) {
        let uiDefaultType = uiTypes.DISABLED;
        switch (currentPage.data.status) {
          case status.WAITING_FOR_ASSIGNMENT:
            if (
              verifyUserID(
                configData.assignerCRMIds ? configData.assignerCRMIds : [],
                userId
              )
            ) {
              uiDefaultType = uiTypes.ASSIGNMENT_SCREEN;
            }

            break;
          case status.WAITING_FOR_APPROVAL:
            if (
              verifyUserID(
                getListOfDataFromMultiLookup(currentPage.data.approver, "id"),
                userId
              )
            ) {
              uiDefaultType = uiTypes.APPROVAL_SCREEN;
            }

            break;
          case status.WAITING_FOR_REVIEW:
            if (
              verifyUserID(
                configData.reviewerCRMIds ? configData.reviewerCRMIds : [],
                userId
              )
            ) {
              uiDefaultType = uiTypes.REVIEWER_SCREEN;
            }
            break;
          default:
            break;
        }
        setUiType(uiDefaultType);
      }
    } catch (exception) {
      //intentionally added comments
    }
  };
  return <>{renderComponentBasedOnType(uiType, title, content, configData)}</>;
}
O_ConsentRequest.propTypes = {
  content: propTypes.object,
  getCurrentPage: propTypes.any,
  title: propTypes.any,
  currentPage: propTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(O_ConsentRequest);
