/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../../../Themes/defaultTheme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles(() => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  fullWidth: {
    width: "100%",
  },
  minWidth: {
    width: "180px",
  },
}));

const convertStringToArray = (val) => {
  let value = [];
  if (Array.isArray(val)) {
    val.map((item) => {
      try {
        let arrval = JSON.parse(item.value).value;
        if (!arrval) {
          value.push(item.value);
        } else {
          value.push(arrval);
        }
      } catch {
        value.push(item.value);
      }
    });
    return value;
  } else {
    return val.length === 0 ? [] : val ? val.split(",") : "";
  }
};
const A_TableMultiSelect = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    props.defaultValue &&
      props.defaultValue.value &&
      props.defaultValue.value.length > 0
      ? convertStringToArray(props.defaultValue.value)
      : []
  );

  const tryParseifPossible = (val) => {
    try {
      let newVal = JSON.parse(val).value;
      if (newVal) {
        return newVal;
      }
      return val;
    } catch {
      return val;
    }
  };
  const handleChange = (event) => {
    event.preventDefault();
    let currValue = event.target.value;
    let selectedItems = [];
    if (currValue.length > 0) {
      currValue.forEach((selectedItem) => {
        let selectedOption = props.options.filter(function (option) {
          let optionVal = tryParseifPossible(option.value);
          return optionVal == selectedItem;
        });
        if (selectedOption.length > 0) {
          selectedItems.push(selectedOption[0]);
        }
      });
      setValue(currValue);
      props.onChange(selectedItems, props.id);
    } else {
      setValue(event.target.value);
      props.onChange(event.target.value, props.id);
    }
  };

  //non-group by options.
  let options = props.options.map((option) => {
    return (
      <MenuItem
        key={handleOptionSetValue(option.value, false)}
        value={handleOptionSetValue(option.value, false)}
      >
        {handleOptionSetValue(option.label)}
      </MenuItem>
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        style={{ marginTop: "3px" }}
        error={props.error}
        variant={props.variant}
        className={`${classes.formControl} ${
          props.fullWidth ? classes.fullWidth : ""
        } ${props.minWidth ? classes.minWidth : ""}`}
        required={props.required}
      >
        <InputLabel id="demo-mutiple-name-label">{props.label}</InputLabel>
        <Select
          labelId={props.id + "_label"}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          id={props.id}
          value={value}
          native={props.native}
          variant={props.variant}
          multiple={props.multiple}
          disabled={props.disabled}
          required={props.required}
          autoWidth={props.autoWidth}
          displayEmpty={props.displayEmpty}
          inputProps={{ readOnly: props.readOnly }}
          color={props.color}
          className={props.className}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => (
            <div>
              <div
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {selected.map((value, index) => (
                  <span key={value}>
                    {value}

                    {index !== selected.length - 1 ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
          )}
        >
          {options}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
A_TableMultiSelect.defaultProps = {
  id: "xxxx",
  label: "Select",
  value: ["net", "javascript"],
  variant: "outlined",
  color: "primary",
  multiple: true,
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  //groupBy: "area", //uncomment this to see group by in action with default options given below.
  options: [
    { label: ".Net", value: "net", area: "backend" },
    { label: "Javascript", value: "javascript", area: "frontend" },
    { label: "Java", value: "java", area: "backend" },
    { label: "None of above", value: "none" },
    { label: "Jquery", value: "jquery", area: "frontend" },
    { label: "Accounts", value: "accounts" },
  ],
};
A_TableMultiSelect.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
};

export default A_TableMultiSelect;
