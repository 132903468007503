const getColorObject = (config, props, row, dimensionName) => {
  let availableColor =
    config &&
    config.varianceFormat &&
    config.varianceFormat.thresholdsColor &&
    config.varianceFormat.thresholdsColor.length > 0
      ? config.varianceFormat.thresholdsColor
      : ["red", "green"];
  let currentRowConfiguration =
    props.configurations &&
    props.configurations.rowFormats &&
    props.configurations.rowFormats[row[dimensionName]]
      ? props.configurations.rowFormats[row[dimensionName]]
      : null;
  return currentRowConfiguration &&
    (currentRowConfiguration.inverse || currentRowConfiguration.parity)
    ? availableColor.reverse()
    : availableColor;
};
export default getColorObject;
