
export const Operator = {
    hasValue:"hasValue",
    equal: "==",
    notEqual: "!==",
    lessThan: "<",
    greaterThan: ">",
    lessThanEqual: "<=",
    greaterThanEqual: ">=",
    
     
  };