import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { updateStatus } from "./Helper/ConsentRequest.helper";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { status } from "../../../Organisms/Common/ConsentRequest/ConsentRequest.const";
import propTypes from "prop-types";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
const useStyles = makeStyles({
  successButton: {
    backgroundColor: "#4bb543",
    color: "white",
    "&:hover": {
      backgroundColor: "#389c30",
    },
  },
  dangerButton: {
    backgroundColor: "#dc3545",
    color: "white",
    "&:hover": {
      backgroundColor: "#ba202e",
    },
  },
  checklist: { padding: "1rem 0" },
  statusDetails: {
    margin: "1rem 0",
    fontSize: "0.8rem",
  },
  title: {
    fontWeight: "700",
  },
});
function M_ReviewerScreen({ getCurrentPage, currentPage, content }) {
  const classes = useStyles();
  const { crmId, pageUrl } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [comments, setComments] = React.useState(
    getObjectFromJSONString(currentPage, ".data.reviewerComment", "")
  );
  const handleChangeStatue = async (statusData) => {
    setIsLoading(true);
    try {
      let triggerEmail = {
        emailCRMIds: [
          statusData === status.REVIEWED
            ? getObjectFromJSONString(
                getObjectFromJSONString(currentPage, ".data.creator"),
                "[0].id",
                []
              )
            : getObjectFromJSONString(
                getObjectFromJSONString(currentPage, ".data.approver"),
                "[0].id",
                []
              ),
        ],
        emailConfigId:
          statusData === status.REVIEWED
            ? getObjectFromJSONString(
                content,
                ".reviewer.reviewedEmailTemplateId",
                ""
              )
            : getObjectFromJSONString(
                content,
                ".reviewer.resetEmailTemplateId",
                ""
              ),
      };
      await updateStatus(
        {
          status:
            statusData === status.REVIEWED
              ? status.COMPLETED
              : status.WAITING_FOR_APPROVAL,
          reviewerComment: comments,
        },
        crmId,
        pageUrl,
        [],
        triggerEmail
      );
      getCurrentPage({ pageUrl, crmId });
    } catch (error) {
      //intensionally added comment
    }
    setIsLoading(false);
  };
  const handleOnChange = (event) => {
    let commentText = event.target.value;
    setComments(commentText ? commentText : "");
  };
  return (
    <div>
      <div className={classes.statusDetails}>
        {getObjectFromJSONString(currentPage, ".data.requestStatus") ? (
          <div className="">
            <span className={classes.title}>Approval Status: </span>
            {getObjectFromJSONString(currentPage, ".data.requestStatus", "")}
          </div>
        ) : (
          ""
        )}
        {getObjectFromJSONString(currentPage, ".data.approver") ? (
          <div className="">
            <span className={classes.title}>Approver: </span>
            {getObjectFromJSONString(
              getObjectFromJSONString(currentPage, ".data.approver", {}),
              "[0].title"
            )}
          </div>
        ) : (
          ""
        )}
        {getObjectFromJSONString(currentPage, ".data.requestStatus") &&
        getObjectFromJSONString(currentPage, ".data.approvalComment") ? (
          <hr />
        ) : (
          ""
        )}

        {getObjectFromJSONString(currentPage, ".data.approverComment") ? (
          <div className="">
            <span className={classes.title}>Approver Comment: </span>
            {getObjectFromJSONString(currentPage, ".data.approverComment", "")}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="">
        <A_TextField
          label={"Comments"}
          fullWidth={true}
          onChange={handleOnChange}
          value={comments}
          defaultValue={comments}
        />
      </div>
      <Grid container>
        <Grid item>
          <A_Button
            className={classes.successButton}
            label={status.REVIEW}
            onClick={() => handleChangeStatue(status.REVIEWED)}
            disabled={isLoading}
          />
        </Grid>
        <Grid item>
          <A_Button
            className={classes.dangerButton}
            label={status.RESET}
            onClick={() => handleChangeStatue(status.RESET)}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
}
M_ReviewerScreen.propTypes = {
  content: propTypes.object,
  getCurrentPage: propTypes.any,
  currentPage: propTypes.any,
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_ReviewerScreen);
