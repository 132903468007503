const checkDefaultColumnConfiguration = (columnConfig = {}, data) => {
  data && data.categories && data.categories.length > 0
    ? data.categories.map((item) => {
        if (!columnConfig[item]) {
          columnConfig[item] = {
            label: item,
            type: "text",
            sorting: false,
          };
        }
      })
    : "";

  return columnConfig;
};

export default checkDefaultColumnConfiguration;
