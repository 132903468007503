import * as React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from "@material-ui/core";
import PropTypes from "prop-types";
export default function A_SimpleMultiSelect(props) {
  let {
    id,
    label,
    defaultValue,
    options,
    optionLabel,
    optionValue,
    defaultMessage,
    onChange = () => {},
    style = {},
    error = false,
    helperText = "",
    disabled = false,
  } = props;
  const [value, setValue] = React.useState(defaultValue || []);
  const getOptions = () => {
    return options && Array.isArray(options)
      ? options.map((option, index) => (
          <MenuItem key={`option_${index}`} value={option[optionValue]}>
            {option[optionLabel] || ""}
          </MenuItem>
        ))
      : [];
  };
  const handleChange = (event) => {
    let { value } = event.target;

    setValue(value);
    onChange && onChange(value);
  };
  return (
    <FormControl
      style={{ marginBottom: 4, minWidth: 120, ...style }}
      variant="outlined"
      error={error}
    >
      <InputLabel id={label}> {label}</InputLabel>
      <Select
        labelId={label}
        id={id || "demo-simple-select-label"}
        name={id || "demo-simple-select-label"}
        value={value}
        label={label}
        multiple={true}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {defaultMessage && <MenuItem>{defaultMessage}</MenuItem>}
        {getOptions()}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
A_SimpleMultiSelect.propTypes = {
  id: PropTypes.any,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.any,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  defaultMessage: PropTypes.string,
  onChange: PropTypes.any,
  style: PropTypes.object,
  error: PropTypes.any,
  helperText: PropTypes.any,
  disabled: PropTypes.any,
};
