/* eslint-disable */
import React from "react";
import M_IRCdetails from "../DealAdministartion/M_IRCdetail";
import M_MeetingDetails from "../../../Pages/Common/MeetingPreferences/M_MeetingDetails";
const M_ListDetails = (props) => {
  switch (props.type) {
    case "meetings":
      return (
        <M_MeetingDetails
          data={props.data}
          loggedinUserData={props.loggedinUserData}
          loggedinUser={props.loggedinUser}
          modify={props.modify}
          callback={props.callback}
          default={props.default}
          {...props}
        />
      );

    case "irc":
      return (
        <M_IRCdetails
          id={props.id}
          ircData={props.ircData}
          resPayload={props.resPayload}
          config={props.config}
          content={props.content}
          record={props.record}
        />
      );

    default:
      break;
  }
};
M_ListDetails.defaultProps = {
  type: "",
  ircData: null,
  resPayload: {},
  config: {},
  content: {},
  data: [],
  loggedinUserData: {},
  loggedinUser: {},
  modify: false,
  callback: () => {},
  default: "",
};
export default M_ListDetails;
