/* eslint-disable */
import { dealTeamActionType } from "../actions/actionTypes";

let initialState = {};
const dealTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case dealTeamActionType.DEL_DEAL_TEAM_EDGE:
      return { data: [], loading: true };
    case dealTeamActionType.DEL_DEAL_TEAM_EDGE_SUCCEEDED:
      return action.payload;
    case dealTeamActionType.DEL_DEAL_TEAM_EDGE_FAILED:
      return action.payload;
    case dealTeamActionType.GET_DEAL_TEAM_EDGE:
      return { data: [], loading: true }; // returning loading true in intailly face
    case dealTeamActionType.GET_DEAL_TEAM_EDGE_SUCCEEDED:
      return action.payload;
    case dealTeamActionType.GET_DEAL_TEAM_EDGE_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default dealTeamReducer;
