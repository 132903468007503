import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
// import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { categories, subCategories, prefixMappings } from "./CodeConstant";
import { httpPost } from "../../../../../Utils/apis/apis";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { connect } from "react-redux";

const initialState = {
  category: "",
  codeNumber: "",
  label: "",
  category1: "",
  category2: "",
  category3: "",
  mapChecked: false,
  mapCategory1: "",
  mapCategory2: "",
  mapCodeId: "",
  quarter: "",
};
const QuarterlyCodeMapping = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { content, editData, endEditing, isEditing } = props;
  const [formData, setFormData] = useState(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [savedData, setSavedData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [spResponseData, setSPResponseData] = useState("");
  const [codeError, setCodeError] = useState(false);
  // const [quarterly, setQuarterly] = useState([]);
  const [filterData, setFilterData] = useState("");
  const fetchKtCodeMapping = async (selectedTabValue) => {
    try {
      let { spDetails, chartSPs } = content.optionConfig;
      const updatedSPDetails = spDetails.map((sp) => ({
        ...sp,
        parameters: {
          ...sp.parameters,
          KTCodeCategory: selectedTabValue,
          QuarterCD: filterData,
        },
      }));
      let spResponse = await getDataFromSQLSp({
        chartSPs,
        spDetails: updatedSPDetails,
      });
      setSavedData(spResponse);
      props.updateSavedData(spResponse);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (props.toolbarSelections && props.toolbarSelections.filters[0]) {
      setFilterData(props.toolbarSelections.filters[0].value);
      props.updateQuarterCode(props.toolbarSelections.filters[0].value);
    }
  }, [props.toolbarSelections]);
  useEffect(() => {
    if (formData.mapCategory1 && !isEditing) {
      setFormData((prevData) => ({
        ...prevData,
        mapCategory2: subCategories[prevData.mapCategory1][0],
      }));
    }
  }, [formData.mapCategory1]);
  useEffect(() => {
    fetchKtCodeMapping(props.selectedTab);
  }, [spResponseData]);
  useEffect(() => {
    if(formData.category){
      fetchKtCodeMapping(formData.category);
    }
  }, [isEditing]);
  // const quarterlyResponse = async () => {
  //   let query = "res";
  //   let QuarterResponse = await getDataFromSQLSp({
  //     chartSPs: true,
  //     spDetails: [
  //       {
  //         id: query,
  //         spName: "JLChart.Usp_GetQuarterParam",
  //         parameters: {},
  //       },
  //     ],
  //   });

  //   setQuarterly(QuarterResponse.res);
  // };
  // useEffect(() => {
  //   quarterlyResponse();
  // }, []);
  const convertCode = (code) => {
    for (const prefix in prefixMappings) {
      if (code && code.startsWith(prefix)) {
        return prefixMappings[prefix];
      }
    }
    return code;
  };
  const extractNumericPart = (code) => {
    if (typeof code !== "string") {
      return "";
    }

    let numericPart = "";
    for (let i = 0; i < code.length; i++) {
      if (!isNaN(parseInt(code[i]))) {
        numericPart += code[i];
      }
    }

    return numericPart;
  };
  const handleEditForm = () => {
    setFormData({
      category: convertCode(editData.Code),
      codeNumber: extractNumericPart(editData.Code),
      label: editData.Label,
      category1: editData.Category1,
      category2: editData.Category2,
      category3: editData.Category3,
      mapCategory2: editData.MappedCode,
      mapChecked: editData.MappedCode ? true : false,
      mapCategory1: convertCode(editData.MappedCode),
      mapCodeId: editData.MappedCodeID,
      CodeID: editData.id,
      // QuarterCD:editData.quarter,
    });
  };
  useEffect(() => {
    handleEditForm();
  }, [isEditing]);

  const handleAdd = () => {
    setCodeError(false);
    setIsModalOpen(true);
    setEditMode(false);
    setEditingIndex(null);

    if (isEditing && editData) {
      setFormData(editData);
      setEditMode(true);
    } else {
      setFormData(initialState);
    }
  };
  useEffect(() => {
    if (isEditing === true) {
      setEditMode(true);
    }
  }, [isEditing]);
  const handleCancel = () => {
    endEditing();
    setIsModalOpen(false);
  };
  const handleSave = async () => {
    endEditing();
    if (editMode) {
      // let spResponse = await getDataFromSQLSp({
      //   chartSPs,

      //   spDetails: "Client.UpdateKtCodesMapping",
      // });
      let query = "res";
      let spResponse = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "Client.UpdateKtCodesMapping",
            parameters: {
              KTCodeCategory: formData.category,
              KTCode: `${formData.category}${formData.codeNumber}`,
              CodeID: formData.CodeID,
              Label: formData.label,
              Category1: formData.category1,
              Category2: formData.category2,
              Category3: formData.category3,
              //   MappingCodeCategory:
              //   formData.mapCategory1 === "GE" ? "G" : formData.mapCategory1,
              // MappingCodeID: formData.mapCodeId,
              // QuarterCD:formData.quarter,
            },
          },
        ],
      });
      let requestPayload = {};
      setSPResponseData(spResponse.res);
      const result = await httpPost(spResponse, requestPayload);

      props.setSaveData(result);

      const updatedData = [...savedData];

      updatedData[editingIndex] = formData;

      setSavedData(updatedData);
    } else {
      if (!formData.category) {
        setCodeError(true);
        return; // Do not proceed with saving
      }
      let query = "res";
      let spResponse = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "Client.UspInsertKTCodes",
            parameters: {
              CodeCategory: formData.category,
              KTCode: `${formData.category}${formData.codeNumber}`,
              CodeTitle: formData.label,
              Category1: formData.category1,
              Category2: formData.category2,
              Category3: formData.category3,
              // QuarterCD: formData.quarter,
              // MappedWith: formData.mapCategory1,
              // MapCodeID: formData.mapCategory2,
            },
          },
        ],
      });
      let requestPayload = {};
      setIsModalOpen(false);
      setSPResponseData(spResponse.res);
      const result = await httpPost(spResponse, requestPayload);
      props.setSaveData(result);
    }

    setIsModalOpen(false);

    setEditMode(false);

    setEditingIndex(null);

    setFormData(initialState);
    setCodeError(false);
  };
  const handleCodeNumberChange = (event) => {
    const { name, value } = event.target;
    const addedZeroAtStartValue = value.replace(/\D/g, "").replace(/^0+/, "");
    const truncatedValue = addedZeroAtStartValue.slice(0, 3);
    const addedValue =
      truncatedValue.length === 1 ? `0${truncatedValue}` : truncatedValue;

    setFormData({
      ...formData,
      [name]: addedValue,
    });
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    if (name === "category") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "codeNumber") {
      const codeNumber = value ? String(value).padStart(3, "0") : "";
      setFormData({
        ...formData,
        [name]: value,
        codeNumber,
      });
    } else if (name === "mapCategory1") {
      setFormData({
        ...formData,
        mapCategory1: value,
        mapCategory2: "",
      });
    } else if (name === "mapCategory2") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "quarter") {
      setFormData({
        ...formData,
        quarter: newValue,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };
  return (
    <>
      <div>
        <IconButton
          color="primary"
          aria-label="add"
          onClick={() => handleAdd()}
          style={{ position: "absolute", top: "206px", right: "53px" }}
        >
          <AddIcon />
        </IconButton>
        <Dialog
          open={isEditing ? !isModalOpen : isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <DialogTitle>{isEditing ? "Edit Item" : "Add Item"}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel>
                Code Category <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                error={codeError}
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="codeNumber"
              label="Code"
              value={`${formData.category || ""}${formData.codeNumber || ""}`}
              onChange={handleCodeNumberChange}
              fullWidth
              required
              error={codeError}
              inputProps={{
                maxLength: 7,
                type: "text",
                min: 1,
                max: 999,
                placeholder: "Eg: 01",
              }}
              style={{ marginTop: "16px" }}
            />

            <TextField
              name="label"
              label="Label"
              defaultValue={formData.label}
              value={formData.label}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              name="category1"
              label="Category 1"
              value={formData.category1}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              name="category2"
              label="Category 2"
              value={formData.category2}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              name="category3"
              label="Category 3"
              value={formData.category3}
              onChange={handleChange}
              fullWidth
            />
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.mapChecked}
                    onChange={handleChange}
                    name="mapChecked"
                    color="primary"
                  />
                }
                label="Do you want to map"
              />
            </FormGroup>
            {formData.mapChecked && (
              <div>
                <FormControl fullWidth>
                  <InputLabel>Code Category</InputLabel>
                  <Select
                    name="mapCategory1"
                    value={formData.mapCategory1}
                    onChange={handleChange}
                  >
                    {categories
                      .filter((category) => category !== formData.category)
                      .map((subCat) => (
                        <MenuItem key={subCat} value={subCat}>
                          {subCat}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Code Subset</InputLabel>
                  <Select
                    name="mapCategory2"
                    value={formData.mapCategory2}
                    onChange={handleChange}
                  >
                    {formData.mapCategory1 &&
                      subCategories[formData.mapCategory1].map((subCat) => (
                        <MenuItem key={subCat} value={subCat}>
                          {subCat}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            )} */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button variant="contained" color="default" onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <br />
      </div>
    </>
  );
};
QuarterlyCodeMapping.propTypes = {
  content: PropTypes.any,
  editData: PropTypes.any,
  isEditing: PropTypes.any,
  selectedTab: PropTypes.any,
  endEditing: PropTypes.any,
  setSaveData: PropTypes.any,
  toolbarSelections: PropTypes.any,
  updateSavedData: PropTypes.any,
  updateQuarterCode: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}
export default connect(mapStateToProps)(QuarterlyCodeMapping);
