/* eslint-disable */
import { toastActionTypes } from "../actions/actionTypes";

let initialState = {};
const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case toastActionTypes.SET_ALERT_STATUS:
      return action.payload;
    default:
      return state;
  }
};
export default toastReducer;
