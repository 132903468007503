/* eslint-disable */
import * as React from "react";
import { useParams } from "react-router";
import * as httpCall from "../../../../../Utils/apis/apis";
import M_CheckList from "../../../Molecules/Common/Checklist/M_CheckList";
export default function O_PostCheckList(props) {
  const [data, setData] = React.useState([]);
  const { crmId } = useParams();
  const getData = async () => {
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
    );
    let responseData = await response;
    setData(responseData);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return data.length > 0 ? (
    data ? (
      <M_CheckList
        data={data}
        config={props.config}
        type="closing"
        formParam={props.data}
      />
    ) : (
      ""
    )
  ) : (
    <></>
  );
}
