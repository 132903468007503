const translationHin = {
    "Introduction": "Introduction",
    "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop": "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop",
    "Plugins to detect the user language": "Plugins to detect the user language",
    "Plugins to load translations": "Plugins to load translations",
    "Optionally cache the translations": "Optionally cache the translations",
    "Advantages": "Advantages",
    "Flexibility to use other packages": "Flexibility to use other packages",
    "Welcome":"स्वागत हे",
    "WelcomeText":"यदि आप इस पृष्ठ को देख रहे हैं। OIDC सफलतापूर्वक सेटअप है ",
    "ProfileSettings":"प्रोफ़ाइल सेटिंग",
    "ApplicationSettings":"एप्लिकेशन सेटिंग",
    "ChangePassword":"पासवर्ड बदलें",
    "Logout":"लॉग आउट"
}

export default translationHin