const templateData = [
  {
    label: "contact",
    header: "Contact",
    pageId: "",
    templateId: null,
    subtitle: ["Job Title", "displayTextLevel2", "Address City"],
    title: ["firstName", "lastName"],
  },
  {
    label: "investment",
    header: "Investment",
    pageId: "",
    templateId: null,
    subtitle: ["Sector Name", "Date Originated"],
    title: ["displayText"],
  },
  {
    label: "company",
    header: "Company",
    pageId: "",
    templateId: null,
    subtitle: [],
    title: ["displayText"],
  },
  {
    label: "meeting",
    header: "Meetings",
    pageId: "",
    templateId: null,
    subtitle: ["Location", "organiser", "Meeting Type", "StartDT"],
    title: ["Subject"],
  },
];
export default templateData;
