import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import A_Accordion from "../../../Atoms/Surfaces/Accordions/A_Accordion";
import * as httpCall from "../../../../../Utils/apis/apis";
import propTypes from "prop-types";
import "./M_Tabs.css";
export default function M_TabsResponse(props) {
  const [responseData, setResponseData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const getResponse = async () => {
    if (props.id) {
      setIsLoading(true);
      let payload =
        props.content && props.content.resPayload
          ? { ...props.content.resPayload }
          : {};
      if (
        payload &&
        payload.sourceId &&
        typeof payload.sourceId === "string" &&
        payload.sourceId.toLowerCase() == "=ircid"
      ) {
        payload.sourceId = props.id ? props.id : "";
      }
      payload = props.resPayload ? props.resPayload : payload;
      let response = await httpCall.httpPost(
        `/CRMChartData/getGenericCRMData`,
        payload
      );

      let res = [];
      for (let i in response) {
        let data = JSON.parse(response[i].responses);
        for (let i in data) {
          res.push({
            defaultExpanded: i == 0 ? true : false,
            summary: (
              <Box>
                <Box>{data[i].senderEmail}</Box>
              </Box>
            ),
            detail: data[i].body,
          });
        }
      }
      setResponseData(res);
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getResponse();
  }, [props.id]);
  return (
    <Box className="padding-top">
      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : Array.isArray(responseData) && responseData.length === 0 ? (
        "No Responses found"
      ) : (
        <A_Accordion accordions={responseData ? responseData : []} />
      )}
    </Box>
  );
}
M_TabsResponse.propTypes = {
  id: propTypes.string,
  content: propTypes.object,
};
