 /* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Slider from "@material-ui/core/Slider";
import theme from "../../../../Themes/defaultTheme";
const A_Slider = (props) => {
  function valuetext(value) {
    return `${value}°C`;
  }
  return (
    <ThemeProvider theme={theme}>
      <Slider
        color={props.color}
        defaultValue={props.defaultValue}
        aria-labelledby={props.ariaLabelledBy}
        step={props.step}
        marks={props.marks}
        min={props.min}
        max={props.max}
        valueLabelDisplay={props.valueLabelDisplay}
        orientation={props.orientation}
        track={props.tracks}
        disabled={props.disabled}
        getAriaValueText={valuetext}
        onChange={props.onChange}
        value={props.value}
      ></Slider>
    </ThemeProvider>
  );
};
A_Slider.defaultProps = {
  defaultValue: 0,
  color: "primary",
  step: 10,
  marks: true,
  min: 10,
  max: 100,
  disabled: false,
  valueLabelDisplay: "on",
  ariaLabelledBy: "",
};
A_Slider.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  onClick: PropTypes.func,
};
export default A_Slider;
