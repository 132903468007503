export const LayoutMenusStructure = (layouts) => {
  var resultLayout = [];
  const distinctSectionHeaders = [
    ...new Set(layouts.map((layout) => layout.sectionHeader)),
  ];
  for (let index = 0; index < distinctSectionHeaders.length; index++) {
    let sectionHeader = distinctSectionHeaders[index];
    let currLayout = { id: "-1", name: sectionHeader };
    currLayout["children"] = layouts.filter(
      (item) => item.sectionHeader == sectionHeader
    );
    if (
      currLayout &&
      currLayout["children"] &&
      currLayout["children"].length > 0
    ) {
      currLayout["sortOrder"] = currLayout["children"][0].headerSortIndex;
    }
    resultLayout.push(currLayout);
    continue;
  }
  return resultLayout;
};

export const SortedLayOuts = (layOuts) => {
  let objLayOut = LayoutMenusStructure(layOuts);
  let layOutList = [];
  objLayOut && objLayOut.length > 0
    ? objLayOut
        .sort(function (a, b) {
          return a.sortOrder - b.sortOrder;
        })
        .map((item) => {
          item && item.children && item.children.length > 0
            ? item.children
                .sort(function (a, b) {
                  return a.itemSortIndex - b.itemSortIndex;
                })
                .map((subItem) => {
                  layOutList.push(subItem);
                })
            : "";
        })
    : "";
  return layOutList;
};
