import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { propTypes } from "react-addons-css-transition-group";
const SearchInputField = (props) => {
  const {
    borrowerToggle,
    setAddToggle,
    dropSearchData,
    filterOptionLabel,
    setSelectedBorData,
    dropNewSearchData,
  } = props;
  //Default Props
  const defaultProps = {
    options:
      dropSearchData.length > 0
        ? dropSearchData
        : dropNewSearchData
        ? dropNewSearchData
        : [],
    getOptionLabel: (option) => option.Value,
  };
  const handleChange = (event) => {
    const inputEvent = event.target.innerText;
    setSelectedBorData(inputEvent);
    if (inputEvent != undefined) {
      setAddToggle(true);
    } else setAddToggle(false);
  };
  return (
    borrowerToggle == true && (
      <Autocomplete
        {...defaultProps}
        id="auto-complete"
        onChange={handleChange}
        autoComplete
        style={{ width: 200 }}
        includeInputInList
        renderInput={(params) => (
          <TextField {...params} label={filterOptionLabel} variant="standard" />
        )}
      />
    )
  );
};
SearchInputField.propTypes = {
  borrowerToggle: propTypes.any,
  setAddToggle: propTypes.any,
  dropSearchData: propTypes.any,
  filterOptionLabel: propTypes.any,
  setSelectedBorData: propTypes.any,
  dropNewSearchData: propTypes.any,
};

export default SearchInputField;
