import {
  AppBar,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import ErrorBoundary from "../../../Molecules/Common/error/ErrorBoundary";
import O_GSTTables from "./O_GSTTables";
import O_GSTPerformance from "./O_GSTPerformance";
import PropTypes from "prop-types";
import * as toastActions from "../../../../../store/actions/toastActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  localStorage.setItem("GSTFilter", Number(value));
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
};
function O_GSTTabs(props) {
  let { content } = props;
  let tabConfig = [...content.content];
  const [value, setValue] = React.useState(
    localStorage.getItem("GSTFilter")
      ? Number(localStorage.getItem("GSTFilter"))
      : Number(0)
  );
  const handleChange = (_, newValue) => {
    localStorage.removeItem("chartStorageKeys");
    setValue(newValue);
  };
  const getComponent = (type, content) => {
    switch (type.toLowerCase()) {
      case "chart":
        return (
          <div>
            {content.chart && content.chart.id ? (
              <div key={`chartDetails__${content.index}`}>
                <ErrorBoundary>
                  <M_ChartCard
                    Height={
                      !content.minHeight ? "300px" : `${content.minHeight}px`
                    }
                    content={
                      content && content.chart
                        ? {
                            ...content.chart,

                            chartId: content.chart.id,

                            id: undefined,
                          }
                        : {}
                    }
                  />
                </ErrorBoundary>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      case "gstinputchart":
        return <O_GSTTables />;
      case "gstperformancechart":
        return <O_GSTPerformance />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabConfig.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
        </AppBar>

        <Box>
          {tabConfig.map((tab, index) => {
            return (
              <TabPanel
                key={index}
                value={value}
                index={index}
                hidden={value !== index}
              >
                <div style={{ padding: "16px" }}>
                  <div className="">
                    {tab.contents &&
                      tab.contents.map((content, index) => (
                        <Paper
                          key={index}
                          style={{ padding: "16px", marginBottom: "16px" }}
                        >
                          {getComponent(content.type || "", {
                            ...content,

                            index,
                          })}
                        </Paper>
                      ))}
                  </div>
                </div>
              </TabPanel>
            );
          })}
        </Box>
      </div>
    </div>
  );
}

O_GSTTabs.propTypes = {
  content: PropTypes.any,
  setToast: PropTypes.any,
};
function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_GSTTabs);
