/* eslint-disable */
import { take, put, select } from "redux-saga/effects";
import { httpGet, httpPost } from "../../Utils/apis/apis";
import { esgRecordActionType } from "../actions/actionTypes";
import * as esgRecordAction from "../actions/esgActions";
import * as commonFunctions from "../../Utils/Helpers/common";
import handleEmailUsers from "../../components/Factory/Pages/Common/Esg/Helper/EsgHelper.api";

export function* getEsgRecordSagas() {
  while (true) {
    yield take(esgRecordActionType.GET_ESG_RECORD);
    yield select();

    try {
      const res = yield httpGet(
        `${process.env.PortalAdminAPIUrl}/api/Survey/esg/getall`
      );
      yield put(
        esgRecordAction.getEsgRecordSucceeded({
          data: res,
          getEsgRecordSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        esgRecordAction.getEsgRecordFailed({
          msg: "Some error occurred",
          getEsgRecordFailed: true,
        })
      );
    }
  }
}

export function* getEsgRecordByIdSagas() {
  while (true) {
    const action = yield take(esgRecordActionType.GET_ESG_RECORD_BY_ID);
    yield select();
    const { id } = action.payload;

    try {
      const res = yield httpGet(
        `${process.env.PortalAdminAPIUrl}/api/Survey/esg/get?id=${id}`
      );
      yield put(
        esgRecordAction.getEsgRecordSucceeded({
          data: res,
          getEsgRecordSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        esgRecordAction.getEsgRecordFailed({
          msg: "Some error occurred",
          getEsgRecordFailed: true,
        })
      );
    }
  }
}

export function* insertEsgFormSagas() {
  while (true) {
    const action = yield take(esgRecordActionType.INSERT_ESG_RECORD);

    const { questionObj, emailString, currentUser } = action.payload;
    console.log("insert esgform  is called:", action.payload);

    const state = yield select();
    // let config = commonFunctions.getHttpConfig();
    if ("undefined" in questionObj) {
      delete questionObj["undefined"];
    }
    if (questionObj && questionObj.properties) {
      delete questionObj.properties["undefined"];
    }

    try {
      const res = yield httpPost(
        `${process.env.PortalAdminAPIUrl}/api/Survey/esg/insert`,
        questionObj
      );
      handleEmailUsers(emailString, currentUser, res);

      yield put(
        esgRecordAction.insertEsgRecordSucceeded({
          insertData: res,
          insertEsgRecordSucceeded: true,
        })
      );
    } catch (e) {
      console.log("error", e);
      yield put(
        esgRecordAction.insertEsgRecordFailed({
          data: questionObj,
          insertEsgRecordFailed: true,
          errorMessage:
            e && e.response && e.response.data && e.response.data.Message,
        })
      );
    }
  }
}
