/* eslint-disable */
import React from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import { heatMapData } from "./heatMapData";
import HelpIcon from "@material-ui/icons/Help";
import CancelIcon from "@material-ui/icons/HighlightOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./map.scss";
import { coords } from "../../../../../Utils/config/config";

const containerStyle = {
  width: "100%",
  height: "88%",
};
function IrReportGoogleMap({ mapData, heatMapInfoWindow }) {
  const [infoWindow, setInfoWindow] = React.useState(null);
  let filteredMapData = mapData.filter((location) => {
    let res =
      !(location.geolocation.lat == null) && !(location.geolocation.lng == null)
        ? true
        : false;
    return res;
  });
  const centers = filteredMapData.map((opt) => opt["geolocation"]);

  const openInfoWindow = (mapData) => {
    mapData
      ? setInfoWindow({ show: true, data: { ...mapData } })
      : setInfoWindow(null);
  };

  const getIcons = (value) => {
    switch (value.toString()) {
      case "1":
        return <CheckCircleIcon style={{ color: "green" }} />;
      case "0":
        return <CancelIcon style={{ color: "red" }} />;
      default:
        return <HelpIcon style={{ color: "#1976d2" }} />;
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyD2u77z6reMmpdZxeL1cDkrv58d7SOpE0U">
      <GoogleMap mapContainerStyle={containerStyle} center={coords} zoom={2}>
        <MarkerClusterer enableRetinaIcons>
          {(clusterer) => {
            return (
              filteredMapData.length > 0 &&
              filteredMapData.map((location) => (
                <Marker
                  position={location["geolocation"]}
                  clusterer={clusterer}
                  // onMouseOver={() => openInfoWindow(location)}
                  // onMouseOut={() => openInfoWindow(null)}
                  onClick={() => openInfoWindow(location)}
                ></Marker>
              ))
            );
          }}
        </MarkerClusterer>
        {infoWindow && infoWindow.show && (
          <InfoWindow
            position={{ ...infoWindow.data.geolocation }}
            onCloseClick={() => openInfoWindow(null)}
          >
            <div className="infowindow">
              <>
                {heatMapInfoWindow.Investor ? (
                  <h6>{infoWindow.data.Investor}</h6>
                ) : (
                  ""
                )}
                {heatMapInfoWindow.Type ? <p>{infoWindow.data.Type}</p> : ""}
                {heatMapInfoWindow.PE ? (
                  <p>
                    <span>{getIcons(infoWindow.data.PE)}</span> PE Interest
                  </p>
                ) : (
                  ""
                )}
                {heatMapInfoWindow.AOF ? (
                  <p>
                    <span>{getIcons(infoWindow.data.AOF)}</span> AOF Interest
                  </p>
                ) : (
                  ""
                )}
                {heatMapInfoWindow.AFE ? (
                  <p>
                    <span>{getIcons(infoWindow.data.AFE)}</span> AFE Interest
                  </p>
                ) : (
                  ""
                )}
                {heatMapInfoWindow["PE Coinvest"] ? <p>
                  <span>{getIcons(infoWindow.data["PE Coinvest"])}</span> PE Coinvest
                </p> : ""}
              </>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default IrReportGoogleMap;
