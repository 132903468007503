import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { getDataFromSQLSp } from "../../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { getCRMContactInfo } from "../../../../../../Utils/Helpers/common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../../store/actions/toastActions";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textField: {
    marginBottom: "15px",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  addButton: {
    marginRight: "10px",
  },
};

const MasterMappingForm = ({
  formData,
  handleCloseForm,
  isEdited,
  setToast,
}) => {
  const [errors, setErrors] = useState({
    Code: "",
    Label: "",
    Category1: "",
    Category2: "",
    Category3: "",
  });
  const [NewFormData, setNewFormData] = useState({
    Code: formData.Code || "",
    Label: formData.Label || "",
    Category1: formData.Category1 || "",
    Category2: formData.Category2 || "",
    KTCodeMicroCategoryID: formData.KTCodeMicroCategoryID || "",
    Category3: formData.Category3 || "",
  });
  const [flag, setFlag] = useState(false);
  const { crmContactUserEmail } = getCRMContactInfo();
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!NewFormData.Code) {
      newErrors.Code = "This field is required";
      isValid = false;
    }

    if (!NewFormData.Label) {
      newErrors.Label = "This field is required";
      isValid = false;
    }

    if (!NewFormData.Category1) {
      newErrors.Category1 = "This field is required";
      isValid = false;
    }
    if (!NewFormData.Category2) {
      newErrors.Category2 = "This field is required";
      isValid = false;
    }
    if (!NewFormData.Category3) {
      newErrors.Category3 = "This field is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setButtonLoading(true);
      let query = "res";
      await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "Client.Usp_AddMasterKTCodeMicroCategory",
            parameters: {
              KTCode: NewFormData.Code,
              SubCategoryName: NewFormData.Label,
              Category1: NewFormData.Category1,
              Category2: NewFormData.Category2,
              Category3: NewFormData.Category3,
              UserName: crmContactUserEmail,
            },
          },
        ],
      });
      setToast({
        type: "success",
        severity: "success",
        message: "Add Sucessfully",
        status: true,
      });
      setButtonLoading(false);
      handleCloseForm(true);
    }
  };
  const handleUpdate = async () => {
    if (validateForm()) {
      setButtonLoading(true);
      let query = "res";
      await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "Client.Usp_UpdateMasterKtCodeMicroCategory",
            parameters: {
              NewCategory1: NewFormData.Category1,
              NewCategory2: NewFormData.Category2,
              NewCategory3: NewFormData.Category3,
              NewSubCategoryName: NewFormData.Label,
              MicroCategoryID: NewFormData.KTCodeMicroCategoryID,
              KTCode: NewFormData.Code,
              UserName: crmContactUserEmail,
            },
          },
        ],
      });
      setToast({
        type: "success",
        severity: "success",
        message: "Updated Sucessfully",
        status: true,
      });
      setButtonLoading(false);
      handleCloseForm(true);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFormData((prevData) => ({ ...prevData, [name]: value }));
    if (value) {
      setErrors((prevData) => ({ ...prevData, [name]: "", isValidate: false }));
    } else {
      setErrors((prevData) => ({
        ...prevData,
        [name]: "This field is Required",
        isValidate: true,
      }));
    }
    setFlag(true);
  };
  const handleClose = () => {
    handleCloseForm(false);
  };
  return (
    <>
      <div style={styles.formContainer}>
        <TextField
          label="Code*"
          name="Code"
          value={NewFormData.Code}
          disabled={isEdited}
          onChange={handleInputChange}
          margin="normal"
          style={styles.textField}
          error={!!errors.Code}
          helperText={errors.Code}
          id="standard-basic"
        />

        <TextField
          label="Label*"
          name="Label"
          value={NewFormData.Label}
          onChange={handleInputChange}
          margin="normal"
          style={styles.textField}
          error={!!errors.Label}
          helperText={errors.Label}
        />
        <TextField
          label="Category 1*"
          name="Category1"
          value={NewFormData.Category1}
          onChange={handleInputChange}
          margin="normal"
          style={styles.textField}
          error={!!errors.Category1}
          helperText={errors.Category1}
        />
        <TextField
          label="Category 2*"
          name="Category2"
          value={NewFormData.Category2}
          onChange={handleInputChange}
          margin="normal"
          style={styles.textField}
          error={!!errors.Category2}
          helperText={errors.Category2}
        />
        <TextField
          label="Category 3*"
          name="Category3"
          value={NewFormData.Category3}
          onChange={handleInputChange}
          margin="normal"
          style={styles.textField}
          error={!!errors.Category3}
          helperText={errors.Category3}
        />
      </div>
      <div style={styles.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={isEdited ? handleUpdate : handleSubmit}
          style={{ ...styles.addButton, marginRight: "10px" }}
          disabled={!flag && isEdited ? true : false}
          endIcon={
            buttonLoading ? (
              <A_CircularIndeternment color="secondary" size={20} />
            ) : (
              <></>
            )
          }
        >
          {isEdited ? "Update" : "Add"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </>
  );
};

MasterMappingForm.propTypes = {
  formData: PropTypes.shape({
    Code: PropTypes.string.isRequired,
    Label: PropTypes.string.isRequired,
    Category1: PropTypes.string.isRequired,
    Category2: PropTypes.string.isRequired,
    Category3: PropTypes.string.isRequired,
    KTCodeMicroCategoryID: PropTypes.any,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  isEdited: PropTypes.bool.isRequired,
  setToast: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterMappingForm);
