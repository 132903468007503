import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  makeStyles,
  Input,
  Button,
  TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Alert } from "@material-ui/lab";
import {
  putScoreResponseData,
} from "../ESG_API";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { responseLogicMapping } from "../ESG_Config";

const useStyles = makeStyles(() => ({
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  submitButtonContainer: {
    display: "flex",
    marginTop: "20px",
    marginRight: "20px",
    justifyContent: "flex-end",
    padding: "10px 0 50px 0",
  },
  disabledField: {
    backgroundColor: "#cbd5e1",
  },
}));

const ScoreSubmissionForm = (props) => {
  const { selectedRow, onFormClose, answers, child, setChild, esgScore } = props;

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  const classes = useStyles();
  const [changedFormData, setChangedFormData] = useState([]);
  const [successPost, setSuccessPost] = React.useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [failure, setFailure] = useState(false);

  const handleChange = (e, responseId) => {
    setIsDisable(false);
    let value=e.target.value;
    if(e.target.type=='number'){
      // remove -ive and 
      let numericValue = parseFloat(value.replace(/[^\d.]/g, ""));
      // Limit to 3 decimal places
      if (!isNaN(numericValue)) {
       value = numericValue.toFixed(3);
     }else{
      e.target.value = 0;
     }
    }
    let obj = {
        "ResponseId":responseId,
        "Score":value
    }
    setChangedFormData((prev)=>{
        let data=[...prev];
        let x = prev?.findIndex(id => responseId === id.ResponseId);
        if(x!==-1){
            data[x]["Score"] = value;
        }else{
            data.push(obj)
        }
        return data;
    });
  };


 
  const handleClose = () => {
    setSuccessPost(false);
    onFormClose();
  };

  const handleEsgScoreSubmit = async() =>{
    console.log(props,"props")
    const payload = {
        "SurveyId":props.selectedSurveyId,
        "InvestmentId":props.investmentId,
        "SubCategoryId":props.popUpSubPillarId,
        "investmentScores":changedFormData
    }

    let res = await putScoreResponseData(payload);
     setIsDisable(true)
    if (res && res.statusCode === 200) {
      setChangedFormData([]);
      setSuccessPost(true);
      setChild(!child);
      setTimeout(() => {
        onFormClose();
        setIsDisable(false);
      }, 2000);
    } else {
      setFailure(true);
      setTimeout(() => {
        setFailure(false);
        setIsDisable(false);
      }, 2000);
    }
    window.location.reload(); 
  }



  return (
    <div>
      <TableContainer
        component={Paper}
        style={{ display: "flex", flexDirection: "column" }}
      >
        
          <>
            <Table style={{ marginLeft: "10px", marginRight: "10px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Question
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Unit
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Answer
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Comments
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    Maximum Rating
                  </TableCell>
                  <TableCell
                    style={{ background: "#0284c7", color: "white" }}
                    align="center"
                  >
                    System Rating
                  </TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>

              {props.data && props.data?.length !==0 && props.data.map((item)=>{
                return (
                    <TableRow>
                        <TableCell align="center" style={{width:"200px"}}>{item.questionText}</TableCell>
                        <TableCell align="center" style={{width:"200px"}}>{item.unit || "-"}</TableCell>
                        <TableCell align="center" style={{width:"200px"}}>{item.response}</TableCell>
                        <TableCell align="center" style={{width:"200px"}}>{item.comment || "-"}</TableCell>
                        <TableCell align="center" style={{width:"200px"}}>{item.responseLogic == responseLogicMapping.AdminInput?item.maxScore:"-"}</TableCell>
                        <TableCell align="center" style={{width:"200px"}}>
                            <TextField 
                             type="number"
                             inputProps={{
                              min: 0,
                              max:  item?.maxScore,
                              disabled: item?.responseLogic !== responseLogicMapping.AdminInput?true:false // set disable for boolean question
                             }}
                             defaultValue={item.score}
                             onChange={(e)=>{
                               e.target.value;
                              if (isNaN(e.target.value)) {
                                e.target.value = 0;
                              } else {
                                e.target.value = Math.max(0, Math.min(e.target.value,item.maxScore));
                              }
                              handleChange(e,item.responseId)
                            }}
                            />
                        </TableCell>
                    </TableRow>
                )
              })}
              </TableBody>
            </Table>
            {!props.data.length && (
              <Alert
                severity="error"
                style={{
                  width: "auto",
                  marginLeft: "10px",
                  marginRight: "-10px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No question response found for this pillar and subpillar.
              </Alert>
            )}
          </>
        
          <div className={classes.submitButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              style={{ position: "absolute" }}
              disabled={isDisable}
              onClick={handleEsgScoreSubmit}
            >
              Save
            </Button>
           </div>
       
      </TableContainer>
      <A_Snackbar
        open={successPost}
        message={"Response successfully saved"}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        type="error"
        message={"Failed to submit data"}
        autoHideDuration={2000}
        horizontal="center"
      />
    </div>
  );
};

ScoreSubmissionForm.propTypes = {
  selectedRow: PropTypes.object,
  answers: PropTypes.array,
  onFormClose: PropTypes.func,
  child: PropTypes.bool,
  setChild: PropTypes.func,
};

export default ScoreSubmissionForm;
