import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { httpPost, httpDelete } from "../../../../../Utils/apis/apis";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import A_Chip from "../../../Atoms/DataDisplay/Chips/A_Chip";
import {
  getFormattedDate,
  FromEpochFormat,
  customDateFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import { Button } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { useParams } from "react-router";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_IconButton from "../../../Atoms/Inputs/Buttons/A_IconButton";
import EditIcon from "@material-ui/icons/Edit";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
const useStyles = makeStyles((theme) => ({
  ircHead: {
    width: "200%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1.4rem",
    alignItems: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonMargin: {
    margin: "1.2rem",
    textTransform: "none",
  },
  innerBox: {
    padding: "0.3rem",
    marginRight: "1rem",
  },
  link: {
    color: "primary",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  tablerow: {
    maxWidth: "100%",
  },
  border: {
    border: 1,
    borderBlockColor: "black",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  boxWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  EditIconStyle: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-7px",
    },
  },
  ButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#adaaaa2e",
      marginTop: "55px",
      marginLeft: "-20px",
    },
  },
}));

function TabPanel(item) {
  const { children, value, index, ...other } = item;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function O_LendersApproached(props) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let { crmId, pageUrl } = useParams();
  const [handleChange, setHandleChange] = useState(true);
  const [data, setData] = React.useState([]);
  const [currentLenderData, setCurrentLenderData] = React.useState({});
  const [openEditForm, setOpenEditform] = useState(false);
  const [entity, setEntity] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleEdit = (item) => {
    const templateIDs =
      props.cards &&
      props.cards[0] &&
      props.cards[0].content &&
      props.cards[0].content.content &&
      props.cards[0].content.content.edit &&
      props.cards[0].content.content.edit.parameters &&
      props.cards[0].content.content.edit.parameters.templateId;
    const templateID =
      item.approval && item.approval === "Approved"
        ? templateIDs[0]
        : templateIDs[1];
    const NewData = { templateId: templateID, recordId: item._partitionKey };
    setOpenEditform(!openEditForm);
    setEntity(NewData);
  };

  const handleCloseForm = () => {
    setOpenEditform(false);
    setEntity(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    apiCall();
  }, [handleChange]);

  React.useEffect(() => {
    apiCall();
  }, [props.manualRefresh]);

  const apiCall = async () => {
    let convertedFormData = {
      sourceId: crmId,
      sourceLabel: pageUrl,
      label: props.label,
      fields: [],
    };
    const response = await httpPost(
      `/CRMChartData/getGenericCRMData`,
      convertedFormData
    );
    setData(response);
    setCurrentLenderData(response ? { ...response[0], index: 0 } : {});
    setIsLoading(false);
  };

  const renderConfirmPopup = () => {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={() => {
              handleDelete();
              handlePopup();
            }}
            color="primary"
            label="Yes"
          />
          <A_Button onClick={handlePopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handlePopup = () => {
    let data = !isOpen;
    setIsOpen(data);
  };
  const handleDelete = async () => {
    const response = await httpDelete(
      `/CRMData/delete?pageUrl=deal&crmId=${selectedId}&softDelete=true`
    );
    setHandleChange(!handleChange);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changeLenderData = (item, index) => {
    setCurrentLenderData({ ...item, index });
  };
  return (
    <>
      <Grid container spacing={10}>
        <Grid item xs={12} lg={6}>
          <Box>
            {isLoading && (
              <>
                <Skeleton variant="text" width={80} height={80} />
                <Skeleton variant="text" width={"100%"} height={80} />
                <Skeleton variant="rect" width={"100%"} height={118} />
              </>
            )}
            {data && data.length > 0 ? (
              data.map((item, index) => {
                let companyUrl = `/${
                  item.lendersLabel ? item.lendersLabel : ""
                }/${item.lenderId ? item.lenderId : ""}`;
                return (
                  <>
                    <Box
                      className={classes.itemCenter}
                      style={{
                        backgroundColor:
                          currentLenderData.index === index ? "#f5f5f5" : "",
                        cursor: "pointer",
                      }}
                      onClick={() => changeLenderData(item, index)}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Box className={classes.innerBox}>
                          <AccountBalanceIcon color="primary" />
                        </Box>

                        <Box>
                          <Typography
                            style={{ color: "#0288d1", cursor: "pointer" }}
                            className={classes.link}
                          >
                            <a href={`${companyUrl}`}>{item.lender}</a>
                          </Typography>
                          {item.approval && item.approval === "Approved" ? (
                            <Typography
                              style={{ color: "grey", fontSize: "0.8rem" }}
                            >
                              Approved
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box
                          style={{ padding: "0.8rem", marginLeft: "0.8rem" }}
                        >
                          {item.approval && item.approval === "Approved" ? (
                            <A_Chip
                              label="Active"
                              color="primary"
                              size="small"
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        maxWidth={"40%"}
                      >
                        <Box>
                          <Typography style={{ fontSize: "0.9rem" }}>
                            {customDateFormat(
                              FromEpochFormat(item.dateshown),
                              2
                            )}
                          </Typography>
                        </Box>

                        <IconButton
                          aria-describedby={id}
                          variant="contained"
                          onClick={(event) => {
                            handleClick(event, item._partitionKey);
                          }}
                        >
                          <MoreVert></MoreVert>
                        </IconButton>
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Button
                            className={classes.buttonMargin}
                            onClick={() => {
                              handlePopup();
                              handleClose();
                            }}
                          >
                            Delete
                          </Button>
                        </Popover>
                        <A_SimpleDialog
                          open={isOpen}
                          title="Are you sure you want to delete?"
                          fullScreen={false}
                          height="400px"
                          fullwidth={true}
                          maxWidth={"xs"}
                          onClose={handlePopup}
                          closePopUp={handlePopup}
                          dialogContent={renderConfirmPopup()}
                        />
                      </Box>
                    </Box>
                    <Divider></Divider>
                  </>
                );
              })
            ) : (
              <>
                {!isLoading && (
                  <div className={classes.noData}>
                    <p>WE ARE SORRY, NO CONTENT FOUND!</p>
                  </div>
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.boxWrapper}>
            <Box
              border={1}
              borderColor={"black"}
              sx={{ width: "100%", height: "100%" }}
            >
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <Box>
                    <Box>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <CustomTab
                          key={0}
                          label={"Facility"}
                          {...a11yProps(0)}
                        ></CustomTab>
                        <CustomTab
                          key={1}
                          label={"Documentation"}
                          {...a11yProps(1)}
                        ></CustomTab>
                        <CustomTab
                          key={2}
                          label={"General Undertakings"}
                          {...a11yProps(2)}
                        ></CustomTab>
                      </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                      <h5>{"Parties"}</h5>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Facility Manager</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.facilityManager}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Borrower</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.borrower}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Guarantor</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.guarantor}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Lender 1</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.lender}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Lender 2</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.lender2}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <br />
                      <h5>{"Payments"}</h5>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Facility Start Date</TableCell>
                            <TableCell>
                              {currentLenderData &&
                              currentLenderData.facilityStartDate
                                ? customDateFormat(
                                    FromEpochFormat(
                                      currentLenderData.facilityStartDate
                                    ),
                                    2
                                  )
                                : ""}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Maturity</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.maturity
                                ? customDateFormat(
                                    FromEpochFormat(currentLenderData.maturity),
                                    2
                                  )
                                : ""}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>First Payment Date</TableCell>
                            <TableCell>
                              {currentLenderData &&
                              currentLenderData.firstPaymentDate
                                ? customDateFormat(
                                    FromEpochFormat(
                                      currentLenderData.firstPaymentDate
                                    ),
                                    2
                                  )
                                : ""}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Payment Frequency(months)</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.paymentFrequency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Facility Manager</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.facilityManager}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <br />
                      <h5>Facility Details</h5>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Commitment</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.commitment}
                            </TableCell>
                          </TableRow>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              overflow: "auto",
                            }}
                          >
                            <Grid item xs={5}>
                              <Typography
                                className={classes.label}
                                variant="p"
                                style={{ fontWeight: "bold" }}
                              >
                                Commitment
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    currentLenderData &&
                                    currentLenderData.commitmentDetails,
                                }}
                              ></div>
                            </Grid>
                          </Grid>
                          <TableRow>
                            <TableCell>Currency</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.currency}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Facility Type</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.facilityType}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Advance Rate</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.advanceRate}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Fixed/Floating</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.fixedFloating}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Benchmark Index</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.benchmarkIndex}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Interest Rate Notes</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.interestRateNotes}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Margin</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.margin}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Prepayment Provisions</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.prePaymentProvisions}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Financial Covenants</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.financialCovenants}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>assignment</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.assignment}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Guarantee</TableCell>
                            <TableCell>
                              {currentLenderData && currentLenderData.guarantee}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Information Undertakings</TableCell>
                            <TableCell>
                              {currentLenderData &&
                                currentLenderData.informationUndertakings}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <Table>
                        <TableRow>
                          <TableCell>Date Shown</TableCell>
                          <TableCell>
                            {currentLenderData && currentLenderData.dateshown
                              ? customDateFormat(
                                  FromEpochFormat(currentLenderData.dateshown),
                                  2
                                )
                              : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Term Sheet</TableCell>
                          <TableCell>
                            {currentLenderData && currentLenderData.termSheet}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>SFA Document</TableCell>
                          <TableCell>
                            {currentLenderData && currentLenderData.sfaDocument}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Appetite/Amount</TableCell>
                          <TableCell>
                            {currentLenderData &&
                              currentLenderData.appetiteAmount}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Leverage/Pricing</TableCell>
                          <TableCell>
                            {currentLenderData &&
                              currentLenderData.leveragePricing}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Other Conditions</TableCell>
                          <TableCell>
                            {currentLenderData &&
                              currentLenderData.otherConditions}
                          </TableCell>
                        </TableRow>
                      </Table>
                      <br />
                      <h5>Status</h5>
                      <Table>
                        <TableRow>
                          <TableCell>NDA</TableCell>
                          <TableCell>
                            {currentLenderData && currentLenderData.nda}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Approval</TableCell>
                          <TableCell>
                            {currentLenderData && currentLenderData.approval}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Documentation</TableCell>
                          <TableCell>
                            {currentLenderData &&
                              currentLenderData.documentation}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      <Table>
                        <TableRow>
                          <TableCell>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  currentLenderData &&
                                  currentLenderData.generalDocumentation,
                              }}
                            ></div>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </TabPanel>
                  </Box>
                </Grid>
                <Grid item xs={1} className={classes.EditIconStyle}>
                  <Box>
                    <A_IconButton
                      icon={<EditIcon />}
                      className={classes.ButtonContainer}
                      onClick={() => handleEdit(currentLenderData)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {openEditForm && (
        <M_ManageEntityForm
          crmData={currentLenderData}
          isEditable={true}
          entity={entity}
          open={openEditForm}
          editChart={true}
          onClose={() => handleCloseForm()}
        />
      )}
    </>
  );
}

O_LendersApproached.defaultProps = {
  cards: [],
  children: "",
  value: "",
  index: "",
};
O_LendersApproached.propTypes = {
  cards: PropTypes.array,
  children: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.string,
};
