import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core";
import { handleFootnotes } from "./Footernotes.helper";
import PropTypes from "prop-types";
const useStyles = makeStyles({
  hover: {
    display: "inline",
    fontSize: "15px",
    "&:hover": {
      color: "gray",
      transition: "0.2s",
      cursor: "pointer",
    },
  },
});
const M_Footernotes = (props) => {
  const classes = useStyles();
  const [isTextBoxOpen, setIsTextBoxOpen] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [footnotes, setFootnotes] = useState(
    props &&
      props.chartData &&
      props.chartData.chartConfiguration &&
      props.chartData.chartConfiguration.footnotes
      ? props.chartData.chartConfiguration.footnotes
      : ""
  );

  React.useEffect(() => {
    setCurrentInput(footnotes);
  }, []);
  const data =
    props &&
    props.chartData &&
    props.chartData.chartConfiguration &&
    props.chartData.chartConfiguration.configuration
      ? JSON.parse(props.chartData.chartConfiguration.configuration)
      : "";
  const Limit =
    data.footnotes && data.footnotes.limit ? data.footnotes.limit : 100;
  const chartId =
    props &&
    props.chartData &&
    props.chartData.chartConfiguration &&
    props.chartData.chartConfiguration.id
      ? props.chartData.chartConfiguration.id
      : "";

  const handleClick = () => {
    setIsTextBoxOpen(!isTextBoxOpen);
  };

  const handleSave = () => {
    setIsTextBoxOpen(!isTextBoxOpen);
    setFootnotes(currentInput);
    handleFootnotes(chartId, currentInput);
  };
  const handleInputChange = (event) => {
    let value = event.target.value;
    if (value.length > Limit) {
      setCurrentInput(value.substring(0, Limit));
    } else {
      setCurrentInput(value);
    }
  };
  const handleCancel = () => {
    setCurrentInput(footnotes);
    setIsTextBoxOpen(!isTextBoxOpen);
  };

  return (
    <div>
      {isTextBoxOpen ? (
        <div>
          <div>
            <TextareaAutosize
              data-testid="Textarea"
              autoFocus
              style={{ width: "100%", color: "grey" }}
              placeholder="Type something..."
              aria-label="empty textarea"
              minRows={2}
              ref={(ref) => ref && ref.focus()}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              value={currentInput}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ marginTop: "8px" }}>
            <A_Button
              disabled={false}
              label={"Save"}
              color="primary"
              onClick={() => handleSave()}
            ></A_Button>
            <A_Button
              disabled={false}
              label={"Cancel"}
              color="default"
              onClick={() => handleCancel()}
            ></A_Button>
            {isTextBoxOpen ? (
              <span
                style={{
                  color: Limit - currentInput.length === 0 ? "red" : "#666666",
                }}
              >
                Total Limit {Limit} remaining {Limit - currentInput.length}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div>
          {currentInput ? (
            <div>
              <Typography
                style={{
                  color: "#666666",
                  width: "100%",
                  wordBreak: "break-all",
                }}
                onClick={() => setIsTextBoxOpen(true)}
              >
                {currentInput}
              </Typography>
            </div>
          ) : (
            <div style={{ textAlign: "right" }}>
              <Typography
                data-testid="Text-onClick"
                color="primary"
                className={classes.hover}
                onClick={() => handleClick()}
              >
                Add Notes
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

M_Footernotes.propTypes = {
  chartData: PropTypes.object,
};
export default M_Footernotes;
