import React from "react";
import { useParams } from "react-router";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Tick } from "../SVGs";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getInvestmentByCrmId,
  getResponseApprovalByAssetId,
  getQuestionResponseCount,
} from "../ESG_API";
import { AssetRole } from "../ESG_Config";
import { getDate, reformatDateString } from "../utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function createData(
  role,
  completion,
  comment,
  submittedDate,
  percentCompletion,
  name
) {
  return {
    role: role,
    completion: completion,
    comment: comment,
    submittedDate: submittedDate,
    percentCompletion: percentCompletion,
    name: name,
  };
}

const initialData = [
  createData("AM", "", "", "", "", ""),
  createData("RAD", "", "", "", "", ""),
];

const ESGSubmission = () => {
  const [submissionData, setSubmissionData] = React.useState(initialData);
  const [isLoading, setIsLoading] = React.useState(true);

  const classes = useStyles();
  let { crmId } = useParams();

  React.useEffect(() => {
    let surveyId = localStorage.getItem("Survey");
    const getSubmissionData = async () => {
      const crmData = await getInvestmentByCrmId(crmId);
      const questionResCount = await getQuestionResponseCount(surveyId);
      if (crmData && crmData.investmentId && surveyId) {
        const investmentId = crmData.investmentId;
        const submittedResponse = await getResponseApprovalByAssetId(
          investmentId,
          surveyId
        );
        let percent = 0;
        if (questionResCount && questionResCount.responseCount) {
          questionResCount.responseCount.forEach((count) => {
            if (count.investmentId == crmData.investmentId) {
              percent = Math.floor(
                (count.responseCount / questionResCount.questionCount) * 100
              );
            }
          });
        }
        if (submittedResponse.length !== 0) {
          const finalData = submittedResponse
            .map((item) => {
              if (item.role === AssetRole.AssetManager) {
                return {
                  role: "AM",
                  completion: 1,
                  comment: item.comment,
                  submittedDate: item.createdAt,
                  percentCompletion: `${percent}%`,
                  name: `${item.firstname} ${item.lastname}`,
                };
              } else if (item.role === AssetRole.RegionalAssetDirector) {
                return {
                  role: "RAD",
                  completion: 1,
                  comment: item.comment,
                  submittedDate: item.createdAt,
                  percentCompletion: `${percent}%`,
                  name: `${item.firstname} ${item.lastname}`,
                };
              }
            })
            .filter(Boolean);
            if(finalData.length < 2 && finalData.length == 1){
                if(finalData[0].role == "AM"){
                  finalData.push(createData("RAD","","-","-","-","-"))
                }else if(finalData[0].role == "RAD"){
                  finalData.push(createData("AM","","-","-","-","-"))
                }
            }
          setSubmissionData(finalData);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    };
    getSubmissionData();
  }, [crmId]);

  return (
    <div>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ width: "30px", height: "30px" }} />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Stage</StyledTableCell>
                <StyledTableCell align="center">Reviewed</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">%Completion</StyledTableCell>
                <StyledTableCell align="center">Comments</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissionData.map((row) => (
                <StyledTableRow key={row.role}>
                  <StyledTableCell component="th" scope="row">
                    {row.role}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.completion ? <Tick /> : ""}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {reformatDateString(getDate(row.submittedDate))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.percentCompletion}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.comment}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ESGSubmission;
