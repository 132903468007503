import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

function A_CustomSelect(props) {
  const classes = useStyles();
  const [value, setvalue] = React.useState("");

  const handleChange = (event) => {
      const {name,value}=event.target
      setvalue(value)
      props.onChange(name,value)
  };

  return (
    <div>
      <FormControl style={{margin: "8px 0"}}  variant="outlined" className={classes.formControl} fullWidth error={!!props.helperText}>
        <InputLabel id="demo-simple-select-error-label">{props.name}</InputLabel>
        <Select
          labelId="demo-simple-select-error-label"
          id="demo-simple-select-error"
          label={props.name}
          value={value}
          onChange={handleChange}
          placeholder={props.name}
          name={props.name}
          required={props.required}
        >
            {props.options.map((option,index)=>{
                return(<MenuItem key={`${option.ID}_${index}`} value={option.ID}>{option.Value}</MenuItem>)
            })}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}

A_CustomSelect.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    className: PropTypes.any,
    id: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array,
    native: PropTypes.bool,
    required: PropTypes.bool,
  };
  export default A_CustomSelect;