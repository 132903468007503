import PropTypes from "prop-types";
import React from "react";
import * as httpCall from "../../../../../Utils/apis/apis";
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router";

import {
  numberWithCommas,
  fundYesOrNo,
  getInvestorType,
  getRelationship,
} from "./utils";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
const CommittedTable = ({ data }) => {
  const totalCommitted =
  data &&
  data.reduce((total, item) => {
    if (item.Fund !== "") {
      return total + item.Committed / item.exchangerate;
    }
    return total;
  }, 0);

  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
        <tr>
          <th
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              textAlign: "left",
              backgroundColor: "#012445",
              color: "white",
            }}
          >
            Fund
          </th>
          <th
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              textAlign: "left",
              backgroundColor: "#012445",
              color: "white",
            }}
          >
            Status Name
          </th>
          <th
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              textAlign: "left",
              backgroundColor: "#012445",
              color: "white",
            }}
          >
            Committed
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (item, index) =>
            item.Fund && (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.Fund}
                </td>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {handleOptionSetValue(item["Status Name"])}
                </td>
                <td
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.Committed !== 0
                    ? `$${numberWithCommas(
                        (item.Committed / item.exchangerate).toFixed(2)
                      )}`
                    : ""}
                </td>
              </tr>
            )
        )}
        <tr>
          <td
            colSpan={2}
            style={{
              fontWeight: "bold",
              borderTop: "2.5px solid #000",
              borderBottom: "2.5px solid #000",
              borderLeft: "1px solid #000",
            }}
          >
            Total
          </td>
          <td
            style={{
              fontWeight: "bold",
              borderTop: "2.5px solid #000",
              borderRight: "1px solid #000",
              borderLeft: "1px solid #000",
              borderBottom: "2.5px solid #000",
              padding: "8px",
            }}
          >
            {totalCommitted === 0
                ?  `$0.00`
              : `$${numberWithCommas(totalCommitted.toFixed(2))}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ContactTable = ({ data }) => {
  return (
    <div>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#012445",
                color: "white",
              }}
            >
              Name
            </th>
            <th
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#012445",
                color: "white",
                width: "30%",
              }}
            >
              Position
            </th>
            <th
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#012445",
                color: "white",
                width: "30%",
              }}
            >
              Email
            </th>
            <th
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#012445",
                color: "white",
              }}
            >
              Phone
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((employee, index) => (
            <tr key={index}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >{`${employee.firstName || ""} ${employee.lastName || ""}`}</td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {employee["Job Title"] || ""}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {employee.email || ""}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {employee.Mobile || ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Table = ({ data }) => {
  const tableHeaders = Object.keys(data[0]);

  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
        <tr>
          {tableHeaders.map((header) => (
            <th
              key={header}
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#012445",
                color: "white",
              }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {tableHeaders.map((header) => (
              <td
                key={header}
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {row[header]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const Meeting = ({ meeting }) => {
  //const tableHeaders = Object.keys(data[0]);
  function formatMeetingInfo(data) {
    const firstName = data && data["firstName"];
    const lastName = data && data["lastName"];
    const meetingDateEpoch = data && parseInt(data["Meeting Date"], 10);

    if (!firstName || !lastName || isNaN(meetingDateEpoch)) {
      return null; // Handle the case where data is missing or invalid
    }

    const owner = `${firstName} ${lastName}`;

    const meetingDate = new Date(meetingDateEpoch * 1000);
    const formattedDate = meetingDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const finalValue = `${owner}, ${formattedDate}`;

    return finalValue;
  }

  return (
    <>
      {meeting.map((row, index) => {
        const startDtEpoch = row["Meeting Date"]
          ? parseInt(row["Meeting Date"])
          : null;
        const targetDateEpoch = new Date("2022-12-31").getTime();
        const isBeforeDec31 =
          startDtEpoch !== null && startDtEpoch < targetDateEpoch / 1000;

        return (
          <div key={index}>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              {row["Subject"]}
            </div>
            <div
              style={{ fontSize: "14px", color: "#777", marginBottom: "10px" }}
            >
              {formatMeetingInfo(row)}
            </div>
            <div style={{ display: "flex" }}>
              {isBeforeDec31 && (
                <div style={{ width: "50%", display: "flex" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: "25%",
                      paddingRight: "8px",
                    }}
                  >
                    PE
                  </div>
                  <div style={{ width: "75%", paddingLeft: "8px" }}>
                    {fundYesOrNo(row, "PE") || ""}
                  </div>
                </div>
              )}
              {isBeforeDec31 && (
                <div style={{ width: "50%", display: "flex" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: "25%",
                      paddingRight: "8px",
                    }}
                  >
                    Real Estate
                  </div>
                  <div style={{ width: "75%", paddingLeft: "8px" }}>
                    {fundYesOrNo(row, "Real Estate") || ""}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Notes
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: row["Notes"] || "" }}
            ></div>      
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Next Steps
            </div>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: row["Next Steps"] || "" }}
            ></div>
            <hr
              style={{
                border: "1px solid #c2995d",
                margin: "4px 0px 12px 0px",
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const A_Tearsheet = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const { crmId } = useParams();
  let payload = { "@id": crmId };
  const fetchDataApi = async () => {
    setIsLoading(true);

    let data = await httpCall.httpPost(
      "/GenericGremlin/generic/getTearSheetNew",
      payload
    );
    setResponse(data);
    setIsLoading(false);
  };
  React.useEffect(() => {
    fetchDataApi();
  }, []);
  const d = new Date();
  const dateValue = d.toLocaleDateString();
  const timeValue = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  let dateTime = dateValue + ", " + timeValue;
  let firstIndex = "";

  firstIndex = response && response.length > 0 ? response[0] : {};

  const {
    Name,
    Address,
    AddressLine1,
    City,
    PinCode,
    Description,
    Notes,
    Country,
    investorType,
    relationship,
    bitesizeMin,
    bitesizeMax,
    AUM,
    currency,
  } = firstIndex;
  let bitesizeRange = "";

  if (bitesizeMin != null && bitesizeMax != null) {
    bitesizeRange = `${bitesizeMin}-${bitesizeMax}`;
  } else if (bitesizeMin != null) {
    bitesizeRange = `${bitesizeMin}-`;
  } else if (bitesizeMax != null) {
    bitesizeRange = `-${bitesizeMax}`;
  }
  const completeAddress =
    (City ? City + " " : "") +
    (PinCode ? PinCode + " " : "") +
    (Country ? Country : "");

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          {response && response.length > 0 && (
            <>
              <html id="TearsheetPDF">
                <head>
                  <style>
                    {`
          html {
            -webkit-print-color-adjust: exact;
          }
        `}
                  </style>
                </head>
                <div id="TearsheetPDF">
                  <div style={{ textAlign: "center" }}>
                    <span style={{ float: "right" }}>{dateTime}</span>
                  </div>
                  <h1 style={{ marginBottom: 0, fontSize: "2rem" }}>{Name}</h1>
                  <p>
                    {Address}, {AddressLine1}
                    <br />
                    {completeAddress}
                  </p>
                  <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                    Investor Tearsheet
                  </span>
                  <h2
                    style={{
                      color: "#c2995d",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Overview
                  </h2>
                  <hr
                    style={{
                      border: "1px solid #c2995d",
                      margin: "4px 0px 12px 0px",
                    }}
                  />
                  <div>
                    {Description ? (
                      Description.replace(/<[^>]*>/g, "")
                    ) : (
                      <p>Data Not Available</p>
                    )}
                  </div>
                  <h2
                    style={{
                      color: "#c2995d",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Investor Notes
                  </h2>
                  <hr
                    style={{
                      border: "1px solid #c2995d",
                      margin: "4px 0px 12px 0px",
                    }}
                  />{" "}
                  <div>
                    {Notes ? (
                      <div
                        className=""
                        dangerouslySetInnerHTML={{ __html: Notes }}
                      ></div>
                    ) : (
                      <p>Data Not Available</p>
                    )}
                  </div>
                  <h2
                    style={{
                      color: "#c2995d",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Investor Contacts
                  </h2>
                  <hr
                    style={{
                      border: "1px solid #c2995d",
                      margin: "4px 0px 12px 0px",
                    }}
                  />
                  {response[1] && response[1].length > 0 ? (
                    <ContactTable data={response[1]} />
                  ) : (
                    <p>Data Not Available</p>
                  )}
                  <h2
                    style={{
                      color: "#c2995d",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "1.5rem",
                    }}
                  >
                    {" "}
                    Investor Details
                  </h2>
                  <hr
                    style={{
                      border: "1px solid #c2995d",
                      margin: "4px 0px 12px 0px",
                    }}
                  />
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", display: "flex" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        Investor Type
                      </div>
                      <div
                        style={{
                          width: "75%",
                          paddingLeft: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {getInvestorType(investorType)}
                      </div>
                    </div>
                    <div style={{ width: "50%", display: "flex" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        Bitesize
                      </div>
                      <div
                        style={{
                          width: "75%",
                          paddingLeft: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {bitesizeRange}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", display: "flex" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        {" "}
                        Relationship
                      </div>
                      <div
                        style={{
                          width: "75%",
                          paddingLeft: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {getRelationship(relationship)}
                      </div>
                    </div>

                    <div style={{ width: "50%", display: "flex" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        AUM
                      </div>
                      <div
                        style={{
                          width: "75%",
                          paddingLeft: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {numberWithCommas(AUM)}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%", display: "flex" }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          width: "25%",
                          padding: "8px",
                        }}
                      >
                        Currency
                      </div>
                      <div
                        style={{
                          width: "75%",
                          paddingLeft: "8px",
                          paddingTop: "8px",
                        }}
                      >
                        {currency}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "1", marginRight: "20px" }}>
                      <h2
                        style={{
                          color: "#c2995d",
                          marginBottom: 0,
                          marginTop: "5px",
                          fontSize: "1.5rem",
                        }}
                      >
                        {" "}
                        Investments
                      </h2>
                      <hr
                        style={{
                          border: "1px solid #c2995d",
                          margin: "4px 0px 12px 0px",
                        }}
                      />
                      {response[2] && response[2].length > 0 ? (
                        <CommittedTable data={response[2]} />
                      ) : (
                        <p>Data Not Available</p>
                      )}
                    </div>

                    <div style={{ flex: "1", marginRight: "20px" }}>
                      <h2
                        style={{
                          color: "#c2995d",
                          marginBottom: 0,
                          marginTop: "5px",
                          fontSize: "1.5rem",
                        }}
                      >
                        {" "}
                        Investor Appetite
                      </h2>
                      <hr
                        style={{
                          border: "1px solid #c2995d",
                          margin: "4px 0px 12px 0px",
                        }}
                      />
                      {response[3] && response[3].length > 0 ? (
                        <Table data={response[3]} />
                      ) : (
                        <p>Data Not Available</p>
                      )}
                    </div>
                  </div>
                  <h2
                    style={{
                      color: "#c2995d",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Recent Activity
                  </h2>
                  <hr
                    style={{
                      border: "1px solid #c2995d",
                      margin: "4px 0px 12px 0px",
                    }}
                  />
                  {response[4] && response[4].length > 0 ? (
                    <Meeting meeting={response[4]} />
                  ) : (
                    <p>Data Not Available</p>
                  )}
                </div>
              </html>
            </>
          )}
        </>
      )}
    </>
  );
};
A_Tearsheet.propTypes = {
  data: PropTypes.any,
  meeting: PropTypes.any,
};

export default A_Tearsheet;
