 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../../../Themes/defaultTheme";
import { Slide, Zoom, Grow, Collapse } from "@material-ui/core";
const A_Menu = (props) => {
  const handleMenuItemClick = (menuOption) => {
    props.onOptionClick(menuOption);
  };
  return (
    <ThemeProvider theme={theme}>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted={props.keepMounted}
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
        TransitionComponent={Collapse}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.label}
            selected={option.selected}
            onClick={(e) => handleMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  );
};
A_Menu.defaultProps = {
  options: [
    { label: "Menu1", selected: true },
    { label: "Menu2" },
    { label: "Menu3" },
  ],
};
export default A_Menu;
