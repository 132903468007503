import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { makeStyles } from "@material-ui/core";
 
const useStyles = makeStyles(() => ({
errorLabel:{
  color:"#f44336",
},
withOutErrorLabel:{
  color:"rgba(0, 0, 0, 0.54)",
  marginLeft:"0px",
},
}));

const A_CheckBox = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(props.checked);
  const handleChange = (event) => {
    let newValue = !checked;
    setChecked(newValue);
    props.onChange(newValue, props.id, props.name);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        component="fieldset"
        required={props.required}
        error={props.required}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <FormControlLabel
           className={`${props.labelClass} ${props.error ? classes.errorLabel : classes.withOutErrorLabel}`}
           label={props.label + " " + (props.required ? '*' : '')}
            labelPlacement={props.labelPlacement}
            value={props.value}
            id={props.id}
            control={
              <Checkbox
                className={props.classes}
                classes={props.allClasses}
                checked={checked}
                name={props.name}
                color={props.color}
                //icon={props.icon}
                checkedIcon={props.checkedIcon}
                disabled={props.disabled}
                required={props.required}
                size={props.size}
                onChange={handleChange}
                onKeyDown={(e) => e.stopPropagation()}

              />
            }
          />
          <div>
            <A_FormHelper fieldHelperText={props.fieldHelperText} />
          </div>
        </div>
        {props.helperText ? (
          <FormHelperText>{props.helperText}</FormHelperText>
        ) : null}
      </FormControl>
    </ThemeProvider>
  );
};
A_CheckBox.defaultProps = {
  areaLabel: "",
  label: "",
  value: "",
  checked: false,
  name: "",
  color: "default",
  disabled: false,
  size: "small",
  labelClass: "",
  allClasses: {},
  fieldHelperText: "",
};
A_CheckBox.propTypes = {
  areaLabel: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["start", "top", "bottom", "end"]),
  checked: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  onChange: PropTypes.func,
  labelClass: PropTypes.string,
  allClasses: PropTypes.object,
  fieldHelperText: PropTypes.string,
};
export default A_CheckBox;
