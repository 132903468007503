import { CircularProgress, Dialog, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TabContainer } from 'react-bootstrap'
import { getAllPillar, getAllScore, getAllSubPillar, getInvestmentByCrmId, getQuestionsJsonFromSurvey, getSubPillar, getSurveyBySurveyId, getSurveyData, putScoreData } from '../ESG_API';
import { makeStyles } from '@material-ui/styles';
import { SurveyFilter, SurveyFilterYear } from '../components';
import CloseIcon from "@material-ui/icons/Close";
import A_Button from '../../../../Atoms/Inputs/Buttons/A_Button';
import { useParams } from 'react-router';
import ScoreSubmissionForm from './ScoreSubmissionForm';
import A_Snackbar from '../../../../Atoms/Feedback/Snackbars/A_Snackbar';
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
    body:{
        height:"100px",
    },
    pillar:{
        color:"white",
        background:"rgba(38,52,64,1)",
        textDecoration:"none",
        border:"0px",
        
    },
    removeBoder:{
        textDecoration:"none",
        border:"0px",
    },
    removePadding:{
        '& input':{
            padding:"10px",
        },
        '& textarea':{
            fontSize:"12px",
            textDecoration:"none",
            paddingTop:"2px"
        },
        '& div':{
            padding:"4px",
           // borderRadius:"0px",
        },
        padding: "2px",
    },
    addForm: {
        display: "flex",
        justifyContent: "space-between",
    },
    loader:{
        position:"absolute",
        left:"50%",
    },
    input:{
        '& div':{
            height:"80px"
        },
    },
    textbox: {
        resize:"none !important",
        border: "1px solid #ccc", 
        overflowY:"scroll",
        fontSize: "10px", 
      },

})

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

function ESG_Score() {
    const classes = useStyles();
    const [allPillars,setAllPillars] = useState([]);
    const [allSubPillars,setAllSubPillars] = useState([]);
    const [survrydata,setSurveyData] = useState([]);
    const [data,setData] = useState([]);
    const [subdata,setSubData] = useState([]);
    const [selectedSurveyId, setSelectedSurveyId] = useState("");
    const [filtersurveyyear, setSurveyFilterYear] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [popUpTitle,setPopUpTitle] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [child, setChild] = useState(false);
    const [loading,setLoading] = useState(false);
    const [payload,setPayload] = useState([]);
    const [allScore,setAllScore] = useState([]);
    const [investmentId,setInvestmentid] = useState(0);
    const [subPillarQuestionData,setSubPillarQuestionData] = useState([]);
    const [successPost, setSuccessPost] = React.useState(false);
    const [failure, setFailure] = useState(false);
    const {crmId} = useParams();
    const [showSaveButton,setShowSaveButton]=useState(false)
    const [bestPracticeError,setBestPracticeError]=useState({})
    const [totalScore,setTotalScore]=useState(null);
    const [popUpSubPillarId,setPopUpSubPillarId]=useState(null);

    const openRatingForm = async(subPillarQuestionData) =>{
        const res = await getSurveyData(selectedSurveyId,investmentId,subPillarQuestionData.questionsubcategoryid);
        setSubPillarQuestionData(res.data?res.data:[]);
        setOpenDialog(true);
        setPopUpSubPillarId(subPillarQuestionData.questionsubcategoryid)
        setPopUpTitle(subPillarQuestionData.name);
        setSelectedRow(subPillarQuestionData);
    }

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handlepopClose = () => {
        setSuccessPost(false);
    };

    const getSubPillarforPillar = (data) =>{

        const showData = data.reduce((acc,item)=>{
            const {questionCategoryId} = item;
            if(!acc[questionCategoryId]){
                acc[questionCategoryId] = [];
            }
            if(!acc[questionCategoryId].includes(item.questionSubCategoryId)){
                acc[questionCategoryId].push(item.questionSubCategoryId);
            }
            return acc;
        },{})

        setSubData(showData);
    }

    const mergeByPillars = (data)=>{
        const showData = data.reduce((acc,item)=>{
            const {questionCategoryId} = item;
            if(!acc[questionCategoryId]){
                acc[questionCategoryId] = [];
            }
            acc[questionCategoryId].push(item);
            return acc;
        },{})
        setData(showData)
    }

    useEffect(()=>{
        setLoading(true);
        const apiCalls = async() =>{

            const investmentid = await getInvestmentByCrmId(crmId);
            const pillars = await getAllPillar();
            const subpillars = await getSubPillar();
            const surveyData = await getQuestionsJsonFromSurvey(selectedSurveyId);
    
            const allData = await getAllScore(investmentid.investmentId,selectedSurveyId);
            setAllScore(allData.data);
            // set payload
            setPayload(allData.data);
            // set total score
            const totalReviewedScore = allData?.data?.reduce((sum, item) => {
                const score = parseFloat(item.reviewedScore);
                return isNaN(score) ? parseFloat(sum) : parseFloat(sum) + score;
            }, 0).toFixed(3);
            setTotalScore(totalReviewedScore)
            setInvestmentid(investmentid.investmentId)
            setAllPillars(pillars);
            setAllSubPillars(subpillars);
            setSurveyData(surveyData);
            mergeByPillars(surveyData);
            getSubPillarforPillar(surveyData);
            setLoading(false);
        }

    if(selectedSurveyId){
        apiCalls();     
    }  
    },[selectedSurveyId])

    const handleChange = (value, item ,scoreId) => {
        setShowSaveButton(true);
        if (item === 'bestPractices') {
            // set error if field empty
            if (!value || value.trim() =="") {
              setBestPracticeError((prevErrors) => ({
                ...prevErrors,
                [scoreId]: 'Best Practices is required',
              }));
            } else {
              setBestPracticeError((prevErrors) => ({
                ...prevErrors,
                [scoreId]: '',
              }));
            }
        }
            // create payload
        setPayload(prev => {
            const newPayload = [...prev];
            const existingPayloadIndex = newPayload?.findIndex(obj => obj.scoreId === scoreId);
            // change from payload
            if (existingPayloadIndex !== -1) {
              newPayload[existingPayloadIndex][item] = value;
            }
            return newPayload;
          });
    
    }


    const handleSubmit = async() =>{
        // disable save 
        setShowSaveButton(false);

        let hasError = false;
        const updatedErrors = {};
        payload.forEach((item)=>{
            if(!item?.bestPractices || item.bestPractices.trim() =="") {
                updatedErrors[item?.scoreId] = 'Best Practices is required';
                hasError = true;
              }
        });
        // set error
        if (hasError) {
          setBestPracticeError(updatedErrors);
       } 
       else {
          const finalPayload=[];
          payload.forEach((item)=>{
            finalPayload.push({
                "scoreId": item.scoreId,
                "questionSubcategoryId":item.questionSubCategoryId,
                "targets": item.targets,
                "initiatives":item.initiatives,
                "bestPractices": item.bestPractices
            })
           })
        const response = await putScoreData(finalPayload);
        if (response && response.statusCode != 200) {
            setFailure(true);
            setTimeout(()=>{
                setFailure(false)
            },2000)
        }else{
            console.log("inside sucess")
            setSuccessPost(true);
            setTimeout(()=>{
                setSuccessPost(false)
            },2000)
        }
        window.location.reload();
      }
    }

    console.log(bestPracticeError,"errpr")


  return (
    <>
    <div>
        <SurveyFilter
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        surveyClosetoggle={true}
        heading={"Closed Survey"}
        />
    </div>
    <div>
        <SurveyFilterYear
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        setSurveyFilterYear={setSurveyFilterYear}
        surveyClosetoggle={true}
        heading={"Survey Years"}
        />
    </div>
    {!loading &&
     allScore.length > 0 ? 
     <>
    <div style={{overflow:"scroll"}}>
        <TabContainer component={Paper} className={classes.body}>
            <Table aria-label="customized table">
                <TableHead style={{background:"grey"}}>
                    <TableRow>
                        <TableCell>
                            Pillar
                        </TableCell>
                        <TableCell>
                            Sub Pillar
                        </TableCell>
                        <TableCell>
                            ESG Score
                        </TableCell>
                        <TableCell>
                            Reviewed Score
                        </TableCell>
                        <TableCell>
                            Target
                        </TableCell>
                        <TableCell>
                            Initatives
                        </TableCell>
                        <TableCell>
                            Best Practices
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && Object.keys(data).map((items)=>{
                        const pillar = allPillars.find((item)=>items==item.questioncategoryid)
                        return(                      
                            <>
                            { pillar &&
                                <StyledTableRow>
                                    <TableCell className={classes.pillar}>
                                        {pillar ? pillar.name : ""}
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{width:"350px"}}>
                                       {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            const subpillars = allSubPillars?.find((item)=>item.questionsubcategoryid==subitem) 
                                            return(
                                                <div>
                                                    <TableCell style={{color:"blue"}} className={classes.removeBoder}>
                                                    <span onClick={()=>openRatingForm(subpillars)} style={{cursor:"pointer"}}>{subpillars ? subpillars.name : ""}</span>
                                                    </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{width:"110px"}}>
                                    {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            let entity = allScore?.find(item=>subitem==item.questionSubCategoryId)
                                            return(
                                                <div>
                                                    <TableCell className={classes.removeBoder}>
                                                    <h5><strong>{entity?.systemGeneratedScore}</strong></h5> 
                                                    </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{width:"110px"}}>
                                    {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            let entity = allScore?.find(item=>subitem==item.questionSubCategoryId)
                                            return(
                                                <div>
                                                    <TableCell className={classes.removeBoder}>
                                                    <h5><strong>{entity?.reviewedScore}</strong></h5>
                                                    </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{width:"200px",height:"80px", overflow: "hidden" }}>
                                    {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            let entity = allScore?.find(item=>subitem==item.questionSubCategoryId)
                                            return(
                                                <div>
                                                    <TableCell className={classes.removeBoder} style={{width:"200px",height:"80px", overflow: "hidden"}}>
                                                    <TextField 
                                                        className={`${classes.textbox} ${classes.removePadding}`}
                                                        style={{width:"200px",height:"80px"}}
                                                        multiline 
                                                        type='text' 
                                                        variant="standard"
                                                        InputProps={{ disableUnderline: true }}
                                                        defaultValue={entity?.targets}
                                                        onChange={(e)=>handleChange(e.target.value,'targets',entity?.scoreId)}
                                                        />
                                                    </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{width:"200px",height:"80px" ,overflow: "hidden" }}>
                                    {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            let entity = allScore?.find(item=>subitem==item.questionSubCategoryId)
                                            return(
                                                <div>
                                                <TableCell className={classes.removeBoder} style={{width:"200px",height:"80px", overflow: "hidden"}}>
                                                <TextField 
                                                    className={`${classes.textbox} ${classes.removePadding}`}
                                                    style={{width:"200px",height:"80px"}} 
                                                    multiline 
                                                    type='text' 
                                                    variant="standard"
                                                    InputProps={{ disableUnderline: true }}
                                                    defaultValue={entity?.initiatives}
                                                    onChange={(e)=>handleChange(e.target.value,'initiatives',entity?.scoreId)}
                                                    />
                                                </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                    <TableCell className={classes.removePadding} style={{ width: "200px", height: "80px", overflow: "hidden" }}>
                                    {
                                        subdata && subdata[items]?.map((subitem)=>{
                                            let entity = allScore?.find(item=>subitem==item.questionSubCategoryId)
                                            return(
                                                <div>
                                                <TableCell className={classes.removeBoder} style={{width:"200px",height:"80px", overflow: "hidden"}}>
                                                <TextField 
                                                    className={`${classes.textbox} ${classes.removePadding}`} 
                                                    style={{width:"200px",height:"80px"}} 
                                                    multiline 
                                                    type='text'
                                                    variant="standard"
                                                    InputProps={{ disableUnderline: true }}
                                                    defaultValue={entity?.bestPractices}
                                                    onChange={(e)=>handleChange(e.target.value,'bestPractices',entity?.scoreId)}
                                                    required={entity?.bestPractices ? false : true}
                                                    />
                                                    <span style={{ color: "red", fontSize: "10px" }}>
                                                        {bestPracticeError[entity?.scoreId]}</span>
                                                </TableCell>
                                                </div>
                                            )
                                        })
                                       }
                                    </TableCell>
                                </StyledTableRow>
                            }
                            </>                         
                        )
                    })}
                </TableBody>
                <TableHead style={{background:"grey",width:"100vw"}}>
                    <TableRow>
                        <TableCell>Table ESG Score</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{totalScore?totalScore:"-"}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>         
            </Table>
        </TabContainer>
    </div>
            <A_Button
              label='Save'
              variant="contained"
              color="primary"
              style={{ position: "absolute",right:"50px",margin:"5px"}}
              disabled={showSaveButton?false:true}
              onClick={handleSubmit}
            /> 
    </>
        : ( loading?
             <CircularProgress className={classes.loader}/>
            :(
                !selectedSurveyId?(
                    <Alert severity="info">
                    No Published survey found.
                   </Alert>
                ):
                <Alert severity="info">
                     No Rating questions are found for this survey.
                 </Alert>
            )
        )}
        <Dialog
            open={openDialog}
            fullWidth
            maxWidth="xl"
            PaperProps={{ style: { width: "100%" } }}
            aria-labelledby="max-width-dialog-title"
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }}
          >
            <div className={classes.addForm}>
              <div>
                <DialogTitle>{popUpTitle}</DialogTitle>
              </div>
              <div>
                <CloseIcon
                  onClick={() => handleClose()}
                  className="closeIcon"
                />
              </div>
            </div>
            <ScoreSubmissionForm
              data={subPillarQuestionData}
              selectedSurveyId={selectedSurveyId}
              investmentId={investmentId}
              popUpSubPillarId={popUpSubPillarId}
              esgScore={true}
              selectedRow={selectedRow}
              answers={[]}
              onFormClose={handleClose}
              setChild={setChild}
              child={child}
            />
          </Dialog>
          <A_Snackbar
            open={successPost}
            message={"Scores successfully saved"}
            autoHideDuration={2000}
            horizontal="center"
            handleClose={handlepopClose}
        />
        <A_Snackbar
            open={failure}
            type="error"
            message={"Failed to save data"}
            autoHideDuration={2000}
            horizontal="center"
        />
    </>
  )
}

export default ESG_Score