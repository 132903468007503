import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import {TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import { mathematicalSigns } from "../../../../../Utils/config/config";
import M_RadiosGroupConfig from "../../../Molecules/Common/RadioGroup/M_RadiosGroupConfig";
import { useEffect } from "react";
import { LookUpView } from "./LookUpView";
import { logicalOperators, lookUpTypes, radioOptions, sourceTypes } from "./excel.Helper";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    height : "100%",
    width: "100%",
    marginBottom : '24px' 
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  CancelButton: {
    marginLeft: "250px",
  },
  popUpContent: {
    display: "flex",
  },
  rightContent: {
    width: "100%",
  },
  leftContentSetting: {
    marginTop: "0.2rem",
    marginBottom: "0.8rem",
  },
  cancelButton: {},
  alignCenter: {
    marginTop : "0.4rem",
    display: "flex"
  },
  confirmationButtons: {
    marginLeft: "auto",
    marginRight : 0
  },
  rounding:{
    marginLeft : 0
  },
  radioGroup: {
    display: "flex",
    justifyContent: "center"  
  },
  filterSelect: {
    color: "white",
    "MuiInput-colorSecondary.MuiInput-underline": "Green !important",

    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    paddingLeft: "6px",
    paddingRight: "6px",
  },
}));

const LookUpMaterics = (props) => {
  const classes = useStyles();
  const openPopup = useState(props.openPopup);
  const tabData = props.tabData ? props.tabData : "";
  const groupedOptions = [];
  
  tabData.map((tab) => {
    const options = [];
    tab.templateMetrics.map((metric) => {
      options.push({label : metric.name, value : metric.id, category : metric.category, type : "metric" });
    })
    groupedOptions.push({title : tab.tabTitle, options : options})
  });
  
  const [equation, setEquation] = useState("");
  const [payloadEquation, setPayloadEquation] = useState("");
  const [equationElements, setEquationElements] = useState([]);
  const [payloadEquationElements, setPayloadEquationElements] = useState([]);
  const [operators, setOperators] = useState([]);
  const [incorrectEquation, setIncorrectEquation] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("");
  const [selectedLookUp, setSelectedLookup] = useState(lookUpTypes.ARITHMETIC_FORMULA);
  const [round, setRound] = useState(null);

  const searchMetricName = (metricId) => {
    let metricName = "";
    props.tabData.map((tab)=>{
      tab.templateMetrics.map((metric)=> {
        if(metric.id == metricId){
          metricName = metric.name
        }
      })
    });
    return metricName;
  }
  const checkOrderOfEquations = (mEquation, bracketsInfo = false) => {
    let correctEquation = false;
    if(bracketsInfo){
      mEquation.map((item, index) => {
        if(item.charAt(0) === ')' || item.charAt(0) === '('){
          mEquation.splice(index, 1);
        }
      });
    }
    for(let i=0; i<mEquation.length-1; i=i+2){
      if(((mEquation[i].charCodeAt(0) >= 48 && mEquation[i].charCodeAt(0) <= 57) || mEquation[i].charCodeAt(0) === 123) && 
      ((mEquation[i+1].charCodeAt(0) >= 42 && mEquation[i+1].charCodeAt(0) <= 47) || mEquation[i+1].charCodeAt(0) === 38 || mEquation[i+1].charCodeAt(0) === 124 || mEquation[i+1].charCodeAt(0) === 94)){
        correctEquation = true;
      }
      else{
        correctEquation = false;
        break;
      }
    }
    return correctEquation;
  }
  const checkBodmas = (mEquation) => {
    if(mEquation.length > 0){
      let leftCnt = 0, RightCnt = 0, numCnt = 0, OpCnt = 0, condCnt = 0;
      mEquation.map((item)=> {
          if(item.charAt(0) === '('){
              leftCnt++;
          }
          else if(item.charAt(0) === ')'){
              RightCnt++;
          }
          else if((item.charCodeAt(0) >= 48 && item.charCodeAt(0) <= 57) || item.charCodeAt(0) === 123){
              numCnt++;
          }
          else if((item.charCodeAt(0) >= 42 && item.charCodeAt(0) <= 47) || item.charCodeAt(0) === 38 || item.charCodeAt(0) === 124 || item.charCodeAt(0) === 94){
              OpCnt++;
          }
      });
      if((leftCnt > 0 || RightCnt > 0) && (numCnt > 0 || OpCnt > 0)){
      if(leftCnt === RightCnt){
        condCnt++;
      }
      if(numCnt-1 === OpCnt){
        condCnt++;
      }
      if(condCnt === 2 && checkOrderOfEquations(mEquation, true)){
        return true;
      }
      else{
        return false;
      }
      }
      else if(leftCnt > 0 && RightCnt > 0 && numCnt == 0 && OpCnt == 0){
          return false;
      }
      else if(leftCnt == 0 && RightCnt == 0 && numCnt > 0 && OpCnt > 0){
          if(numCnt-1 === OpCnt && checkOrderOfEquations(mEquation, false)){
              return true;
          }else{
              return false;
          }
      }
    }
    else{
      return true;
    }
  }

  const backSpace = () => {
    if(equationElements.length > 0){
      let editedEquation = "";
      let editedPayloadEquation = "";
      let equationElementsCopy = equationElements.filter((item, index) => {
        if(index !== equationElements.length - 1)
        return item;
      });
      let payloadEquationElementsCopy = payloadEquationElements.filter((item, index) => {
        if(index !== payloadEquationElements.length - 1)
        return item;
      });
      equationElementsCopy.map((item, index) => {
        if(index < equationElementsCopy.length){
          editedEquation += item+" ";
          editedPayloadEquation += payloadEquationElementsCopy[index]+" ";
        }
      })
      setEquationElements(equationElementsCopy);
      setPayloadEquationElements(payloadEquationElementsCopy);
      setEquation(editedEquation);
      setPayloadEquation(editedPayloadEquation);
      setIncorrectEquation(false);
    }
  }

  const deleteEquation = () => {
    setEquationElements([]);
    setPayloadEquationElements([]);
    setEquation("");
    setPayloadEquation("");
    setIncorrectEquation(false);
  }
  //TODO
  const handleChange = (event, source, lookUpView = lookUpTypes.ARITHMETIC_FORMULA) => {
    let values = [];
    let metricName = ""
    switch(lookUpView){
    case lookUpTypes.ARITHMETIC_FORMULA:
    
    if(source === sourceTypes.DROPDOWN){
      values = event.target.value.split(" ");
      setDropDownValue(values[0]);
    }
    else if(source === sourceTypes.BUTTON){
      if(event.target.innerText === mathematicalSigns.AND){
        values[0] = logicalOperators.AND; 
        values[1] = logicalOperators.AND;
      }
      else if(event.target.innerText === mathematicalSigns.OR){
        values[0] = logicalOperators.OR; 
        values[1] = logicalOperators.OR;
      }
      else if(event.target.innerText === mathematicalSigns.XOR){
        values[0] = logicalOperators.XOR; 
        values[1] = logicalOperators.XOR;
      }
      else if(event.target.innerText === mathematicalSigns.ONE){
        values[0] = logicalOperators.ONE; 
        values[1] = logicalOperators.ONE;
      }
      else{
        values[0] = event.target.innerText; 
        values[1] = event.target.innerText;
      }
    }
    values.map((item, index) => {
      if(index !== values.length - 1)
      if(item === logicalOperators.ONE){
        metricName += "1"+" ";      
      }
      else{
        metricName += item+" ";
      }
    });
    setEquationElements([...equationElements, metricName])
    setEquation(equation+metricName+" ");
    setPayloadEquationElements([...payloadEquationElements, values[values.length - 1]])
    setPayloadEquation(payloadEquation+values[values.length - 1]+" ");
    break;
    case lookUpTypes.COMPARISON:
      setPayloadEquation(payloadEquation ?  payloadEquation.replace(event.target.id, event.target.value.split(" ")[event.target.value.split(" ").length-1]) : "firstMetric conditionOperator secondMetric ? trueMetric : falseMetric ".replace(event.target.id, event.target.value.split(" ")[event.target.value.split(" ").length-1]));
      break;
    case lookUpTypes.CARRY_FORWARD:
      values = event.target.value.split(" ");
      setDropDownValue(event.target.value);
      setPayloadEquation(values[values.length-1]);
    break;
  }
  };

  //Required
  /*  const handleDelete = (chipToDelete) => () => {
    setName(
      NameSelectData.filter((chip, index) => {
        return index !== chipToDelete;
      })
    );
  }; */

  const handleFormChange = () => {
    switch(selectedLookUp){
      case lookUpTypes.ARITHMETIC_FORMULA:
        if(checkBodmas(payloadEquationElements)){
          props.handlelookup(props.lookupMetricId, [{formula : payloadEquation, orderFormula : props.lookupMetricValue.sortOrder, lookupMetricId : props.lookupMetricValue.id, roundOff : round ? round : null, carryForward : null }]);
          props.callBack(false);
        }
        else{
          setIncorrectEquation(true);
        }
      break;
      case lookUpTypes.COMPARISON:
        props.handlelookup(props.lookupMetricId, [{formula : payloadEquation, orderFormula : props.lookupMetricValue.sortOrder, lookupMetricId : props.lookupMetricValue.id, roundOff : null, carryForward : null }]);
        props.callBack(false);
      break;
      case lookUpTypes.CARRY_FORWARD:
        props.handlelookup(props.lookupMetricId, [
          {
            formula: payloadEquation,
            orderFormula: props.lookupMetricValue.sortOrder,
            lookupMetricId: props.lookupMetricValue.id,
            roundOff: null,
              carryForward:
              payloadEquation == ""
                ? props.lookupMetricValue.id
                : payloadEquation,
          },
        ]);
        props.callBack(false);
      break;
    }

  };

  const onClose = (e) => {
    e.stopPropagation();
    props.callBack(false);
  };

  let showGroupedOptions = () => {
    let options = groupedOptions.map((groupedOption) => (
      <optgroup label={groupedOption.title} key={groupedOption.title}>
        {groupedOption.options
          ? groupedOption.options.map((option) => (
              <option key={option.value} value={option.label + " " +option.value}>
                {option.label}
              </option>
            ))
          : ""}
      </optgroup>
    ));
    options.unshift(<option key={""} value={""}>{""}</option>);
    return options;
  };

  let handleRound = (event) => {
    setRound(event.target.value);
  }

  let radioChange = (selectedOption) => {
    if(selectedLookUp !== lookUpTypes.ARITHMETIC_EQUATION){
      setRound();
    }
    setEquationElements([]);
    setPayloadEquationElements([]);
    setEquation("");
    setPayloadEquation("");
    setIncorrectEquation(false);
    setDropDownValue("");
    setSelectedLookup(selectedOption.value);
  };
  const findLookUp = (elements) => {
    let signs = Object.keys(mathematicalSigns);
    for(let i=0; i<signs.length; i++){
      if(elements.includes(mathematicalSigns[signs[i]])){
        return lookUpTypes.ARITHMETIC_FORMULA; 
      }
    }
    return lookUpTypes.COMPARISON;
  };
  useEffect(() => {
    if(props.lookupMetricInfo && typeof props.lookupMetricInfo === sourceTypes.STRING){
      setPayloadEquation(props.lookupMetricInfo);
      let elements = props.lookupMetricInfo.split(" ");
      //handling lookUpView
      let lookUpViewName = findLookUp(elements);
      setSelectedLookup(lookUpViewName); 
      //handling lookUpView
      elements.pop();
      let elementsWithMetricNames = []; 
      elements.map((item) => {
        if(item.trim().charCodeAt(0) > 48 && item.trim().charCodeAt(0) < 58){
            props.tabData.map((tabMetrics) => {
              tabMetrics.templateMetrics.map((metric) => {
                if(item == metric.id){
                  elementsWithMetricNames.push(metric.name)
                }
              })
            })
        }
        else{
          elementsWithMetricNames.push(item);
        }
      })
      setPayloadEquationElements(elements);
      setEquationElements(elementsWithMetricNames);
      setEquation(() => {
        let eq = ""
        elementsWithMetricNames.map((item) => {
          if(item === '{'){
            eq += "1" + " ";
          }
          else{
            eq += item + " ";
          }
        })
        return eq;
      });
      setRound(props.lookupMetricValue.lookupMetricInfo[0].roundOff === null ? null:props.lookupMetricValue.lookupMetricInfo[0].roundOff);
    }
    if(props.carryForward && props.carryForward !== null) { 
      setSelectedLookup(lookUpTypes.CARRY_FORWARD);
        props.tabData.map((tabMetrics) => {
          tabMetrics.templateMetrics.map((metric) => {
            if(props.carryForward == metric.id){
              setDropDownValue(metric.name+" "+props.carryForward)
            }
          })
        })
        setPayloadEquation(props.carryForward);
    }
    setOperators([
      mathematicalSigns.PLUS,
      mathematicalSigns.MINUS,
      mathematicalSigns.MULTIPLY,
      mathematicalSigns.DIVIDE,
      mathematicalSigns.LEFTBRACKET,
      mathematicalSigns.RIGHTBRACKET,
      mathematicalSigns.AND,
      mathematicalSigns.OR,
      mathematicalSigns.XOR,
      mathematicalSigns.ONE
    ]);
  }, []);

  return (
    <div>
      <A_SimpleDialog
        open={openPopup}
        title={"Select metrics for Custom sum"}
        maxWidth="md"
        dialogContent={
          <React.Fragment>
            <div className={classes.radioGroup}>
            <M_RadiosGroupConfig
              className={classes.filterSelect}
              value={selectedLookUp}
              error={false}
              native={true}
              defaultValue={selectedLookUp}
              align="horizontal"
              options={radioOptions}
              onChange={radioChange}
            />
            </div>
            <LookUpView
              selectedLookUp = {selectedLookUp}
              dropDownValue = {dropDownValue}
              handleChange = {handleChange}
              showGroupedOptions = {showGroupedOptions}
              operators = {operators}
              equation = {equation}
              payloadEquation = {payloadEquation}
              incorrectEquation = {incorrectEquation}
              backSpace = {backSpace}
              deleteEquation = {deleteEquation}
              lookupMetricValue = {props.lookupMetricValue.name}
              searchMetricName = {searchMetricName}
            />
            <div className={classes.alignCenter}>
              {selectedLookUp === lookUpTypes.ARITHMETIC_FORMULA ?<div className={classes.rounding}>
                <TextField 
                  size = "small"
                  variant="outlined"
                  value={round}
                  type="number"
                  label={"Rounding(DP)"}
                  onChange={handleRound}
                />
              </div> : ""}
              <div className={classes.confirmationButtons}>
                <A_Button color="primary" label="Cancel" onClick={onClose} />
                <A_Button color="primary" label="Ok" onClick={handleFormChange} />
              </div>
            </div>
          </React.Fragment>
        }
        showclosebutton = {false}
      ></A_SimpleDialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(manageExcelTemplateActions, dispatch);
}

LookUpMaterics.defaultProps = {
  openPopup: true,
  content: [],
  tabData: [],
  tabdata : [],
  lookupMetricValue: {},
  lookupMetricId: 0,
  lookupMetricInfo : "",
  carryForward : null
};
LookUpMaterics.propTypes = {
  openPopup: PropTypes.bool,
  content: PropTypes.array,
  tabData: PropTypes.array,
  tabdata: PropTypes.array,
  lookupMetricValue: PropTypes.object,
  lookupMetricId: PropTypes.number,
  handlelookup: PropTypes.func,
  callBack: PropTypes.func,
  lookupMetricInfo : PropTypes.string,
  carryForward : PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(LookUpMaterics);
