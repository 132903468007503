import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
// import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_CheckBox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import { Close, CheckSharp } from "@material-ui/icons";
import { useParams } from "react-router";
import {
  getDocumentFromMultipleContainer,
  indexConst,
} from "./Helpers/Checklist.helper";
import { insertDocumentDB } from "../../../Organisms/Common/ConsentRequest/ConsentRequest.helper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import propTypes from "prop-types";

const useStyles = makeStyles({
  success: { color: "#42ba96" },
  danger: { color: "#dc3545" },
});
function M_GenericChecklistView(props) {
  const classes = useStyles();
  const { crmId, pageUrl } = useParams();
  const [checkList, setCheckList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    fetchChecklistContent();
  }, []);
  const fetchChecklistContent = async () => {
    let containerDetails = [
      {
        containerName: getObjectFromJSONString(
          props,
          ".content.checklist.primaryContainer",
          "ConsentRequestChecklistResponse"
        ),
        parameters: {
          checklistId: getObjectFromJSONString(
            props,
            ".currentPage.data.checkListID",
            ""
          ),

          responseCrmId: crmId,
        },
      },
      {
        containerName: getObjectFromJSONString(
          props,
          ".content.checklist.secondaryContainer",
          "ConsentRequestChecklist"
        ),
        parameters: {
          id: getObjectFromJSONString(
            props,
            ".currentPage.data.checkListID",
            ""
          ),
        },
      },
    ];
    let checkListRecord = await getDocumentFromMultipleContainer(
      containerDetails,
      true
    );

    setCheckList(checkListRecord ? checkListRecord : []);

    props.handleValidation(handleValidationRecords(checkListRecord));
  };
  const handleValidationRecords = (checklistRecords) => {
    let checkValidation = [];

    checklistRecords && Array.isArray(checklistRecords)
      ? checklistRecords.forEach((checklistElement) => {
          return checklistElement.checklistTemplate.forEach(
            (checklistContent) => {
              return checklistContent.checklistItems.forEach(
                (checklistItem) => {
                  checkValidation.push(
                    checklistItem.value ? checklistItem.value : false
                  );
                }
              );
            }
          );
        })
      : "";
    return !checkValidation.includes(false);
  };
  const handleValue = async (elementIndex, contentIndex, itemIndex, value) => {
    let checklistElement = [...checkList];
    setIsLoading(true);
    try {
      checklistElement[elementIndex][indexConst.checklistTemplate][
        contentIndex
      ][indexConst.checklistItems][itemIndex]["value"] = value;
      let payload = {};
      payload[indexConst.checklistTemplate] =
        checklistElement[elementIndex][indexConst.checklistTemplate];
      payload[indexConst.checklistId] = checklistElement[elementIndex][
        "isTemplate"
      ]
        ? checklistElement[elementIndex]["id"]
        : checklistElement[elementIndex][indexConst.checklistId];
      payload["responseCrmId"] = crmId;
      let id = checklistElement[elementIndex]["isTemplate"]
        ? null
        : checklistElement[elementIndex]["id"];
      const reloadPage = () => {
        props.getCurrentPage({ pageUrl, crmId });
      };

      await insertDocumentDB(
        payload,
        getObjectFromJSONString(
          props,
          ".content.checklist.primaryContainer",
          "ConsentRequestChecklistResponse"
        ),
        id,
        reloadPage
      );
    } catch (error) {
      //intentionally added comment
    }
    setIsLoading(false);
  };
  return (
    <div className={classes.checklist}>
      {checkList && Array.isArray(checkList)
        ? checkList.map((checklistElement, eleIndex) => {
            return checklistElement.checklistTemplate.map(
              (checklistContent, contentIndex) => {
                return checklistContent.checklistItems.map(
                  (checklistItem, itemIndex) => {
                    return (
                      <Grid key={itemIndex} container spacing={1}>
                        <Grid item xs={1}>
                          {checklistItem.value ? (
                            <CheckSharp className={classes.success} />
                          ) : (
                            <Close className={classes.danger} />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {checklistItem.title ? checklistItem.title : ""}
                        </Grid>

                        <Grid item xs={3}>
                          <A_CheckBox
                            disabled={
                              checklistItem.value || isLoading ? true : false
                            }
                            checked={
                              checklistItem.value ? checklistItem.value : false
                            }
                            onChange={(value) =>
                              handleValue(
                                eleIndex,
                                contentIndex,
                                itemIndex,
                                value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    );
                  }
                );
              }
            );
          })
        : ""}
    </div>
  );
}
M_GenericChecklistView.propTypes = {
  content: propTypes.object,
  getCurrentPage: propTypes.any,
  handleValidation: propTypes.any,
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_GenericChecklistView);
