/* eslint-disable */
import * as React from "react";
import { httpPost } from "../../../../../Utils/apis/apis";
import { Box, Typography, Tabs, Tab, AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as httpCall from "../../../../../Utils/apis/apis";
import A_Accordion from "../../../Atoms/Surfaces/Accordions/A_Accordion";
import { useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import O_CheckList from "../../../Organisms/Common/Checklist/O_Checklist";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import propTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
const useStyles = makeStyles({
  boxBorder: {
    borderBottom: 1,
    borderColor: "divider",
  },
  boxFlex: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    width: "45%",
    height: "2.4rem",
    display: "inline-flex",
    alignItems: "center",
  },
  marginAuto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2.4rem",
  },
  marginTop2: {
    marginTop: "2rem",
  },
  Label: {
    fontWeight: "600",
  },
  main: {
    height: "335px",
    width: "720px",
    paddingLeft: "20px",
    marginBottom: "10px",
  },
  calender: {
    border: "3px solid grey",
    backgroundColor: "#f5f5f0",
    display: "flex",
    padding: "20px 10px 0px 5px",
  },
  icon: {
    height: "80px",
    width: "60px",
    color: "#3d5afe",
    paddingBottom: "20px",
  },
  color: {
    color: "black",
  },
  selected: {
    backgroundColor: "rgba(1,1,1,0.05)",
  },
  buttonMargin: {
    margin: "1.2rem",
  },
  innerBox: {
    padding: "1.4rem",
    marginRight: "2.8rem",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function M_IRCdetail(props) {
  const classes = useStyles();

  const { crmId } = useParams();
  const [value, setValue] = React.useState(1);
  const [ircData, setircData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [responseData, setResponseData] = React.useState([]);
  const [remarkData, setRemarkData] = React.useState([]);
  const [checkList, setChecklist] = React.useState(false);
  let responseIndex = null;
  React.useEffect(() => {
    getDetails();
    getRemark();
    if (props.modify) {
      let outJson = [];
      outJson = JSON.parse(
        props.data && props.data.properties && props.data.properties.Attendees
          ? props.data.properties.Attendees
          : {}
      );
      let completeAddress = [];
      for (var i = 0; i < outJson.length; i++) {
        if (outJson[i].from == "External") {
          completeAddress.push(
            outJson[i].emailAddress && outJson[i].emailAddress.address
              ? outJson[i].emailAddress.address.split("@").length > 1
                ? outJson[i].emailAddress.address.split("@")[1]
                : ""
              : ""
          );
        }
      }
    }
  }, [props]);
  const getData = async () => {
    let check = false;
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=OnBoarding`
    );
    let responseData = response ? response : [];
    setChecklist(
      responseData && Array.isArray(responseData) && responseData.length > 0
        ? true
        : false
    );
    check =
      responseData && Array.isArray(responseData) && responseData.length > 0
        ? true
        : false;
    if (check) {
      if (props.record && props.record.type && props.record.type === "email") {
        setValue(2);
      } else {
        setValue(0);
      }
    } else {
      setValue(1);
    }
  };

  React.useEffect(() => {
    getData();
  }, [props]);
  let changeDateFormat = (i) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hours: "numeric",
      min: "numeric",
    };
    var today = new Date(i);
    return today.toLocaleDateString("en-US", options);
  };

  let getDetails = async () => {
    let irc = {};
    let response = [];
    if (props.ircData) {
      response = props.ircData;
    } else {
      let payload =
        props.content && props.content.payload ? props.content.payload : "";

      if (payload && payload.request && payload.request.sourceId) {
        if (
          payload.request.sourceId === "" ||
          payload.request.sourceId.toLowerCase() === "=crmid"
        ) {
          payload.request.sourceId = crmId;
        } else {
          payload.request.sourceId = "";
        }
      }
      response = await httpCall.httpPost(`/IRC/getIRCRecord`, payload);
    }

    for (let i in response) {
      if (response[i].id == props.id) {
        irc = { ...response[i] };
      }
    }
    setircData(irc);
  };
  const getResponse = async () => {
    if (props.id) {
      setIsLoading(true);
      let payload = props.resPayload ? props.resPayload : "";
      if (
        payload &&
        payload.sourceId &&
        typeof payload.sourceId === "string" &&
        payload.sourceId.toLowerCase == "=ircid"
      ) {
        payload.sourceId = props.id ? props.id : "";
      }
      if (
        payload &&
        payload.sourceLabel &&
        typeof payload.sourceLabel === "string" &&
        props.record &&
        props.record.type === "email"
      ) {
        payload.sourceLabel = "ircemail";
      }
      let response = await httpCall.httpPost(
        `/CRMChartData/getGenericCRMData`,
        payload
      );

      let res = [];
      for (let i in response) {
        let data = JSON.parse(response[i].responses);
        for (let i in data) {
          res.push({
            defaultExpanded: i == 0 ? true : false,
            summary: (
              <Box>
                <Box>{data[i].senderEmail}</Box>
                <Box>{changeDateFormat(Date())}</Box>
              </Box>
            ),
            detail: data[i].body,
          });
        }
      }
      setResponseData(res);
      setIsLoading(false);
    }
  };
  const getRemark = async () => {
    if (props.id) {
      let payload = {
        sourceId: props.id ? props.id : "",
        sourceLabel:
          props.record && props.record.type && props.record.type === "email"
            ? "ircemail"
            : "ircportal",
        label: "ircemail",
        fields: ["remark", "attachments"],
      };

      let response = await httpCall.httpPost(
        `/CRMChartData/getGenericCRMData`,
        payload
      );

      setRemarkData(response ? response : []);
    }
  };

  const renderDocument = (item) => {
    let files =
      item.attachments && typeof item.attachments === "string"
        ? item.attachments.split(",")
        : [];
    if (files.length == 0) {
      return <></>;
    }
    return (
      <div>
        <Typography style={{ fontSize: "1.8rem", color: "9c8662" }}>
          Documents
        </Typography>
        {files.map((value, index) => {
          let path = value.split("/");
          let fileName = path[path.length - 1];
          return (
            <div
              className=""
              key={index}
              onClick={() => {
                fileDownload(fileName, value);
              }}
            >
              <Typography style={{ fontSize: "1.2rem", color: "6ea1cc" }}>
                {fileName}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };
  const fileDownload = (filename, filepath) => {
    const url = `${process.env.DOCUMENT_HELPER}`;
    //important
    // let subdomain = "dev";
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: filepath,
      type: "email",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
    };
    const formData = {};
    httpPost(url, formData, { responseType: "blob", headers: header }).then(
      (response) => {
        const url1 = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    );
  };
  function a11yProps(index, isResponses) {
    if (isResponses) {
      responseIndex = index;
    }
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    if (responseIndex && newValue == responseIndex) {
      getResponse();
    }
    setValue(newValue);
  };
  React.useEffect(() => {
    setValue(0);
  }, [props.id]);

  return (
    <Box>
      <Box className={classes.boxBorder}>
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {props.config.map((item, index) => {
              if (item.id == "checklist") {
                if (checkList) {
                  return (
                    <CustomTab
                      label={item.label}
                      hidden={
                        props.record &&
                        props.record.type &&
                        props.record.type === "email"
                          ? true
                          : false
                      }
                      {...a11yProps(
                        index,
                        item.isResponses ? item.isResponses : false
                      )}
                    />
                  );
                } else {
                  return (
                    <CustomTab
                      label={""}
                      hidden={true}
                      {...a11yProps(
                        index,
                        item.isResponses ? item.isResponses : false
                      )}
                    />
                  );
                }
              }
              return (
                <CustomTab
                  key={index}
                  label={item.label}
                  hidden={
                    props.record &&
                    props.record.type &&
                    props.record.type === "email"
                      ? (item.type &&
                          typeof item.type === "string" &&
                          item.type.toLowerCase() === "email") ||
                        item.isResponses
                        ? false
                        : true
                      : item.type &&
                        typeof item.type === "string" &&
                        item.type.toLowerCase() === "email"
                      ? true
                      : false
                  }
                  {...a11yProps(
                    index,
                    item.isResponses ? item.isResponses : false
                  )}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </Box>

      {props.config.map((item, index) => {
        if (item.id == "checklist") {
          if (checkList) {
            if (
              props.record &&
              props.record.type &&
              props.record.type === "email"
            ) {
              return "";
            }
            return (
              <TabPanel value={value} index={index}>
                <O_CheckList
                  ircID={props.id}
                  config={props.content ? props.content : {}}
                />
              </TabPanel>
            );
          }
        }
        return (
          <TabPanel key={index} value={value} index={index}>
            <Table
              className={classes.table}
              style={{ minWidth: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {item.fields ? (
                  item.fields.map((data, index) => {
                    return (
                      <>
                        {!data.isHTML ? (
                          <TableRow key={index} className={classes.fullwidth}>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.Label}
                            >
                              {data.label}
                            </TableCell>
                            <TableCell align="left">
                              {ircData[data.field]
                                ? data.isDate
                                  ? changeDateFormat(
                                      ircData[data.field].split(" ")[0]
                                    )
                                  : ircData[data.field]
                                : ""}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: ircData[data.field],
                              }}
                            ></div>
                          </TableRow>
                        )}

                        {data.id &&
                        data.id.toLowerCase() == "remark" &&
                        remarkData
                          ? remarkData.map((item, index) => {
                              return (
                                <div key={index} className="">
                                  <div
                                    className={classes.marginTop2}
                                    dangerouslySetInnerHTML={{
                                      __html: item.remark ? item.remark : "",
                                    }}
                                  ></div>
                                  {renderDocument(item)}
                                </div>
                              );
                            })
                          : ""}
                      </>
                    );
                  })
                ) : item.isResponses ? (
                  <ResponsePanel
                    isLoading={isLoading}
                    classes={classes.marginAuto}
                    responseData={responseData ? responseData : []}
                  ></ResponsePanel>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TabPanel>
        );
      })}
    </Box>
  );
}
function ResponsePanel(props) {
  return (
    <>
      {props.isLoading ? (
        <Box className={props.classes}>
          <CircularProgress />
        </Box>
      ) : (
        <A_Accordion
          accordions={props.responseData ? props.responseData : []}
        />
      )}
    </>
  );
}
TabPanel.propTypes = {
  children: propTypes.any,
  value: propTypes.any,
  index: propTypes.any,
};
M_IRCdetail.propTypes = {
  modify: propTypes.any,
  data: propTypes.any,
  ircData: propTypes.any,
  content: propTypes.any,
  id: propTypes.any,
  resPayload: propTypes.any,
  config: propTypes.any,
};
ResponsePanel.propTypes = {
  isLoading: propTypes.any,
  classes: propTypes.any,
  responseData: propTypes.any,
};

export default M_IRCdetail;
