import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React from "react";
import A_SimpleSelect from "../../../Atoms/Inputs/Selects/A_SimpleSelect";
import { getDataFromSQLSp } from "../../../Molecules/Common/File Upload/M_fileUpload.helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import A_SimpleMultiSelect from "../../../Atoms/Inputs/Selects/A_SimpleMultiSelect";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
export default function O_GSTForms(props) {
  let { onFromDataChange, validation = false, setDisableSubmit } = props;
  const [sensitivityData, setSensitivityData] = React.useState([]);
  const [scenario, setScenario] = React.useState({});
  const [optionSets, setOptionSets] = React.useState({});
  const [loadings, setLoadings] = React.useState({});
  const [Region, setRegion] = React.useState({});
  const minValue = -100;
  const maxValue = 100;
  const handleChange = (event, callBack = () => {}) => {
    let tempFormData = { ...scenario };
    let { name, value } = event.target;
    tempFormData[name] = value;
    setScenario(tempFormData);
    callBack(value);
    if(name==="Region"){
      setRegion(value);
    }
  };
  React.useEffect(() => {
    getRegions();
  }, []);
  React.useEffect(() => {
    onFromDataChange({ ...scenario });
  }, [scenario]);
  const getSensitivity = async (getUpdatedValue) => {
    let query = "sensitivity";
    setLoadings({ ...loadings, sensitivity: true });
    let spResponse = await getDataFromSQLSp({
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: "[JLForm].[Usp_GST_GetGlobalSensitivityParam]",
          parameters: {
            AssetIds: (getUpdatedValue && getUpdatedValue.join(",")) || "",
            Region:Region,
          },
        },
      ],
    });
    setSensitivityData(spResponse[query]);
    setLoadings({ ...loadings, sensitivity: false });
  };
  const getRegions = async () => {
    let query = "regions";
    let spResponse = await getDataFromSQLSp({
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: "[JLForm].[Usp_GST_GetRegionParam]",
          parameters: {},
        },
      ],
    });
    setOptionSets({ ...optionSets, regions: spResponse[query] });
  };
  const getModels = async (region) => {
    let query = "models";
    let tempLoading = { ...loadings, models: true };
    let tempScenario = { ...scenario };
    let tempOptionSet = { ...optionSets };
    // eslint-disable-next-line no-prototype-builtins
    if (tempScenario.hasOwnProperty("Model")) {
      delete tempScenario["Model"];
      setScenario(tempScenario);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (tempOptionSet.hasOwnProperty("models")) {
      delete tempOptionSet["models"];
      setOptionSets(tempOptionSet);
    }
    setLoadings(tempLoading);
    let spResponse = await getDataFromSQLSp({
      chartSPs: true,
      spDetails: [
        {
          id: query,
          spName: "[JLForm].[Usp_GST_GetModelParam]",
          parameters: {
            RegionCode: region,
          },
        },
      ],
    });
    setLoadings({ ...tempLoading, models: false });
    setOptionSets({ ...optionSets, models: spResponse[query] });
  };

  const handleSensitivity = (event) => {
    let tempScenario = { ...scenario };
    let tempSensitivity = getObjectFromJSONString(
      tempScenario,
      ".sensitivity",
      {}
    );
    let { name, value } = event.target;
    tempSensitivity[name] = { ...tempSensitivity[name], value };
    tempScenario.sensitivity = tempSensitivity;
    setScenario(tempScenario);
    let hasValidValue = false;
    for (const key in tempSensitivity) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        tempSensitivity.hasOwnProperty(key) &&
        !isNaN(Number(tempSensitivity[key].value))
      ) {
        if (
          Number(tempSensitivity[key].value) > maxValue ||
          Number(tempSensitivity[key].value) < minValue
        ) {
          hasValidValue = true;
          break; // No need to continue checking if one value is greater than 100 or less than -100
        }
      }
    }
    hasValidValue ? setDisableSubmit(true) : setDisableSubmit(false);
  };

  const getCheckBoxValue = (id) => {
    let tempScenario = { ...scenario };
    let tempSensitivity =
      getObjectFromJSONString(tempScenario, ".sensitivity") || {};
    let { checkValue } = tempSensitivity[id] || {};
    return checkValue || false;
  };
  const setCheckBoxValue = (id) => {
    let tempScenario = { ...scenario };
    let tempSensitivity =
      getObjectFromJSONString(tempScenario, ".sensitivity") || {};
    let { checkValue } = tempSensitivity[id] || {};
    if (checkValue === true) {
      delete tempSensitivity[id];
    } else {
      tempSensitivity[id] = {
        checkValue: !checkValue,
      };
    }

    setScenario({ ...tempScenario, sensitivity: tempSensitivity });
  };

  // to check valid input ender (between -100 to 100)
  const getValidInput = (item) => {
    if (scenario.sensitivity) {
      // eslint-disable-next-line no-prototype-builtins
      if (scenario.sensitivity.hasOwnProperty(item.Sensitivities)) {
        let getValue = scenario.sensitivity[item.Sensitivities].value;
        if (
          getValue != undefined &&
          (getValue > maxValue || getValue < minValue)
        ) {
          return (
            <span style={{ color: "red" }}>
              The entered value must be within the range of -100 to 100.
            </span>
          );
        }
      }
    }
  };

  return (
    <div>
      <TextField
        style={{ marginBottom: 10, width: "100%" }}
        id="ScenarioName"
        name="ScenarioName"
        label="Scenario Name*"
        variant="outlined"
        onChange={handleChange}
        error={validation && !scenario["ScenarioName"]}
        helperText={
          validation && !scenario["ScenarioName"]
            ? "This field is required."
            : ""
        }
      />
      <A_SimpleSelect
        style={{ marginBottom: 10, width: "100%" }}
        id={"SP drop down"}
        label={"Region"}
        name={"Region*"}
        options={optionSets["regions"] || []}
        optionLabel={"RegionCode"}
        optionValue={"RegionCode"}
        error={validation && !scenario["Region"]}
        helperText={"This field is required."}
        onChange={(value) => {
          handleChange(
            {
              target: {
                name: "Region",
                value: value,
              },
            },
            getModels
          );
        }}
      />
      {optionSets["models"] && (
        <A_SimpleMultiSelect
          style={{ marginBottom: 10, width: "100%" }}
          id="Models"
          label={loadings.models ? "Loading..." : "Models*"}
          options={optionSets["models"] || []}
          optionLabel="AssetName"
          optionValue="AssetId"
          error={validation && !scenario["Model"]}
          helperText={"This field is required."}
          disabled={loadings.models || !optionSets["models"]}
          onChange={(value) => {
            handleChange(
              {
                target: {
                  name: "Model",
                  value: value,
                },
              },
              getSensitivity
            );
          }}
        />
      )}
      {loadings.sensitivity ? (
        <>
          <Skeleton variant="text" width={20} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          {sensitivityData && sensitivityData.length > 0 ? (
            <>
              Sensitivities
              {validation &&
              (!scenario["sensitivity"] ||
                Object.entries(scenario["sensitivity"]).length == 0) ? (
                <div style={{ color: "red" }}>
                  Please select at least one value
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {sensitivityData &&
            sensitivityData.map((item, index) => (
              <>
                <Grid container className="mb-2">
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={getCheckBoxValue(item.Sensitivities)}
                          value={getCheckBoxValue(item.Sensitivities)}
                          onChange={() => setCheckBoxValue(item.Sensitivities)}
                        />
                      }
                      label={item.Sensitivities}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={handleSensitivity}
                      type="number"
                      style={{ width: "100%" }}
                      id={`outlined-adornment-weight-${index}`}
                      name={item.Sensitivities}
                      label={`${item.Sensitivities} Value`}
                      variant="outlined"
                      disabled={!getCheckBoxValue(item.Sensitivities)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      aria-describedby={`outlined-adornment-weight-${index}`}
                      inputProps={{
                        min: minValue,
                        max: maxValue,
                        step: 1,
                        "aria-label": "weight",
                      }}
                    />
                    {getValidInput(item)}
                  </Grid>
                </Grid>
              </>
            ))}
        </>
      )}
    </div>
  );
}
O_GSTForms.propTypes = {
  onFromDataChange: PropTypes.any,
  validation: PropTypes.any,
  setDisableSubmit: PropTypes.any,
};
