import React from "react";
import { useState, useRef } from "react";
import "./dropzoneupload.scss";
import { httpPost } from "../../../../../Utils/apis/apis";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import PreviousTemplate from "../Renderers/Views/PreviousTemplate/PreviousTemplate";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { ErrorMsg } from "../../../../../Utils/config/config";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  chip: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
    "font-size": "12px",
    "border-radius": "25px",
    "background-color": "#f1f1f1",
    marginRight: "3px",
    marginBottom: "3px",
  },
}));
const M_PortfolioFileUpload = (props) => {
  const classes = useStyles();
  const { content } = props;
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);
  let fileName = "";
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState([]);
  const [fileNameList, setFilenameList] = useState([]);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
  let list =
    props.content && props.content.formData && props.content.formData.pdfFile
      ? props.content.formData.pdfFile
      : "";
  let { pageUrl, crmId } = useParams();
  const dataDoc = [];
  const dataList = () => {
    if (list == "" || list == "undefined") {
      return;
    } else {
      list.split(",").map((item) => {
        let itemlist = {
          name: item.split("/")[1],
          path: item,
        };
        dataDoc.push(itemlist);
      });

      let fileNamePath = [];
      let fileNameData = [];
      for (let i in dataDoc) {
        fileNamePath.push(dataDoc[i].path);
        fileNameData.push({ name: dataDoc[i].name, type: "edit" });
      }
      setFilenameList(fileNamePath);
      setFile(fileNameData);
    }
  };

  React.useEffect(() => {
    dataList();
  }, [props.content && props.content.type]);
  var inputProps = {
    accept:
      content && content.allowedExtensions
        ? content.allowedExtensions
        : "pdf,pptx,docx,xlsx",
    multiple: content && content.multiple ? content.multiple : false,
    directory: content && content.directory ? content.directory : null,
  };
  var ext;
  const handleFileUpload = async (e, dropedFiles = null) => {
    let resultFiles = [];
    let resultFileName = [];
    let filesList = dropedFiles ? dropedFiles : e.target.files;
    let directory =
      inputProps && inputProps.directory ? inputProps.directory + "/" : "";

    for (let file of filesList) {
      fileName = pageUrl + "/" + crmId + "/" + directory + file.name;
      resultFileName.push(fileName);
      resultFiles.push(file);
    }
    ext = fileName.split(".").pop();
    if (content.allowedExtensions.includes(ext.toLowerCase())) {
      let reader = new FileReader();
      reader.readAsDataURL(resultFiles[0]);
      reader.onload = (e) => {
        let subdomain = new URL(window.location.href).hostname.split(".")[0];
        let header = {};
        setUploading(true);

        header = {
          filename: fileName,
          "x-functions-key": process.env.EXCEL_UPLOAD_API_KEY,
          subdomain: subdomain,
        };
        const url = `${process.env.EXCEL_UPLOAD_API_URL}/UploadAndProcess`;
        const formData = { file: e.target.result };
        return httpPost(url, formData, { headers: header }).then(
          (response) => {
            setFile([...file, ...resultFiles]);
            setFilenameList([...fileNameList, ...resultFileName]);
            if (props.onUpload) {
              props.onUpload(
                true,
                [...fileNameList, ...resultFileName],
                props.content.type
              );
            }

            let message = ErrorMsg.MESSAGE;
            if (response && response.message) {
              message = response.message;
            } else if (
              response &&
              response.response &&
              response.response.data &&
              response.response.data.message
            ) {
              message = response.response.data.message;
            }
            if (
              response.response &&
              response.response.status &&
              response.response.status > 499
            ) {
              setChangesSaved({
                status: true,
                type: toastSeverity.ERROR,
                msg: message,
              });
            } else if (
              response.response &&
              response.response.status &&
              response.response.status > 299
            ) {
              setChangesSaved({
                status: true,
                type: toastSeverity.WARNING,
                msg: message,
              });
            } else {
              setChangesSaved({
                status: true,
                type: toastSeverity.SUCCESS,
                msg: message,
              });
            }
            setUploading(false);
          },
          (error) => {
            let message = error && error.message ? error.message : "Error";
            setChangesSaved({
              status: true,
              type: toastSeverity.ERROR,
              msg: message,
            });
            setUploading(false);
          }
        );
      };
      e.preventDefault();
    } else {
      let message = "only excel files are supported";
      setChangesSaved({
        status: true,
        type: "error",
        msg: message,
      });
    }
  };
  const handleSnackbarClose = () => {
    props.getCurrentPage({ pageUrl, crmId });
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const handleClick = () => {
    inputFile.current.click();
  };

  const handleDropFiles = (event) => {
    stopEvent(event);
    setHover(false);
    const { files } = event.dataTransfer;
    handleFileUpload(event, files);
  };

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };

  const downloadPreview = (e, file) => {
    e.preventDefault;
    const url1 = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", file.name);
    link.click();
  };

  const removeFile = (event, fileIndex) => {
    let newFile = file.filter((item, index) => index !== fileIndex);
    let newFileList = fileNameList.filter((item, index) => index !== fileIndex);
    setFile(newFile);
    setFilenameList(newFileList);
    props.onUpload(true, newFileList, props.content.type);
    event.preventDefault();
  };
  return (
    <div>
      <section style={{ minWidth: "20px" }}>
        <div
          className={
            hover ? "drop-zone-container hover" : "drop-zone-container"
          }
          onDrop={handleDropFiles}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <CloudUpload color="primary" />
          {!uploading ? (
            <p style={{ color: "#054af7" }}>
              Drag or Drop Files here to upload{" "}
            </p>
          ) : (
            ""
          )}
          {uploading ? <CircularDeterminate /> : ""}
          {!uploading ? (
            <A_Button
              onClick={handleClick}
              onDrop={handleDropFiles}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              color="primary"
              label="Click to Upload"
            />
          ) : (
            ""
          )}
          <input
            {...inputProps}
            className={"fileinput"}
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
          />
        </div>
        {props.enableChips
          ? file.map((item, index) => {
              return (
                <div
                  key={index}
                  className=""
                  style={{
                    height: "2.6rem",
                    margin: "0.2rem",
                    display: "inline",
                  }}
                >
                  <div className={classes.chip}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        downloadPreview(e, item);
                      }}
                    >
                      {item.name}
                    </div>

                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        removeFile(e, index);
                      }}
                    >
                      <CloseRounded></CloseRounded>
                    </IconButton>
                  </div>
                </div>
              );
            })
          : ""}

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={3000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
      </section>
      <Divider />
      <section>
        <PreviousTemplate
          currentPage={props.currentPage}
          content={props.content}
        />
      </section>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
M_PortfolioFileUpload.propTypes = {
  content: PropTypes.object,
  onUpload: PropTypes.func,
  getCurrentPage: PropTypes.func,
  enableChips: PropTypes.any,
  currentPage: PropTypes.object,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_PortfolioFileUpload);
