import React from "react";
import PropTypes from "prop-types";
import { getNewSearchResult } from "./search.healper";
import M_ScrollView from "./ScrollView/M_ScrollView";
import M_FullPageView from "./FullPageView/M_FullPageView";

const setSearchLimit = "10";
const M_SearchResult = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [isScrollView, setIsScrolView] = React.useState(true);
  const [isFullPageView, setIsFullPageView] = React.useState(false);
  const [allCrmResult, setAllCrmResult] = React.useState([]);

  const setIsFullPageHandler = () => {
    setIsFullPageView(true);
    setIsScrolView(false);
  };
  const setIsFullPageViewFalse = () => {
    setIsScrolView(true);
    setIsFullPageView(false);
  };
  const getData = async (searchKey) => {
    setSearchText(searchKey);
    setIsLoading(true);
    let result = await getNewSearchResult(searchKey);
    setIsLoading(false);
    let newAllValues = [];
    let allCrmKeys = [];
    const sortedResult = result
      ? result.sort((a, b) => a.pageUrl - b.pageUrl)
      : [];
    for (let crmRecord of sortedResult) {
      let currentPageUrl = crmRecord.header;
      if (!allCrmKeys.includes(currentPageUrl)) {
        allCrmKeys.push(currentPageUrl);
        newAllValues.push({
          crmRecordType: currentPageUrl,
          crmRecordDetails: [crmRecord],
        });
      } else {
        for (let i = 0; i < newAllValues.length; i++) {
          if (newAllValues[i].crmRecordType === currentPageUrl) {
            newAllValues[i].crmRecordDetails.push(crmRecord);
          }
        }
      }
    }

    setAllCrmResult(newAllValues);
  };

  React.useEffect(() => {
    getData(props.searchKey);
  }, [props.searchKey]);

  return (
    <>
      {searchText !== "" && (
        <>
          {isScrollView && (
            <M_ScrollView
              allCrmResult={allCrmResult}
              setIsFullPage={setIsFullPageHandler}
              searchKey={props.searchKey}
              handleClick={props.handleClick}
              isLoading={isLoading}
              setSearchLimit={setSearchLimit}
            />
          )}
          {isFullPageView && (
            <M_FullPageView
              allCrmResult={allCrmResult}
              searchKey={props.searchKey}
              handleClick={props.handleClick}
              setIsFullPage={setIsFullPageViewFalse}
            />
          )}
        </>
      )}
    </>
  );
};

M_SearchResult.propTypes = {
  searchKey: PropTypes.string,
  handleClick: PropTypes.func,
  user: PropTypes.any,
};

export default M_SearchResult;
