/* eslint-disable */
import {
    pptDownloadActionTypes
} from "./actionTypes";
export const getPPTDownload = (payload) => {
    return {
        type: pptDownloadActionTypes.GET_PPT_DOWNLOAD,
        payload,
    };
};
export const getPPTDownloadSucceeded = (payload) => {
    return {
        type: pptDownloadActionTypes.GET_PPT_DOWNLOAD_SUCCEEDED,
        payload,
    };
};
export const getPPTDownloadFailed = (payload) => {
    return {
        type: pptDownloadActionTypes.GET_PPT_DOWNLOAD_FAILED,
        payload,
    };
};