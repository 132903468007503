import React from "react";
import PropTypes from "prop-types";
import {makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import theme from "../../../../themes/defaultTheme";
import "../../../../../Assets/css/main.scss";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result = stabilizedThis.map((el) => el[0]);
  return result;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "750",
  },
  tableRow: {
    height: 40,
  },
  tableCell: {
    padding: "0px 16px",
  },
  tableContainer: {
    maxHeight: "40vh",
  },
  italic: {
    fontStyle: "italic",
  },
}));

const A_CustomTable = (props) => {
  const classes = useStyles();
  let ele = null;
  const { configurations } = props;
  const dataOrignal = props.data;
  const { action } = props.configurations;
  const { dragable } = props.configurations;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(dragable ? "sortOrder" : "");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    configurations && configurations.paginate
      ? 5
      : props.data && props.data.length > 5
      ? props.data.length + 1
      : 5
  );
  const [data, setData] = React.useState(props.data);

  React.useEffect(() => {
    setData(dataOrignal);
    if (!configurations || !configurations.paginate) {
      setRowsPerPage(
        dataOrignal && dataOrignal.length > 5 ? dataOrignal.length + 1 : 5
      );
    }
  }, [dataOrignal]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDragStart = (e) => {
    ele = e.target;
    // e.target.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
    // e.dataTransfer.setData('text/html', e.target.innerHTML);
  };
  const handleDragOver = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.dataTransfer.dropEffect = "move";
    return false;
  };
  const handleDrop = (e) => {
    //sort order values
    let dragedElementOrderStr = ele.getAttribute("sortOrder");
    let dropedElementOrderStr =
      e.target.parentElement.getAttribute("sortOrder") == null
        ? e.target.parentElement.parentElement.getAttribute("sortOrder")
        : e.target.parentElement.getAttribute("sortOrder");
    if (dragedElementOrderStr == dropedElementOrderStr) {
      return;
    }
    // draged and droped elements
    let dragedElementArray = data.find(
      (item) => item.sortOrder == dragedElementOrderStr
    );
    let dropedElementArray = data.find(
      (item) => item.sortOrder == dropedElementOrderStr
    );

    const dragedElementOrder = dragedElementArray.sortOrder;
    const dropedElementOrder = dropedElementArray.sortOrder;

    for (let i = 0; i < data.length; i++) {
      const currentItem = data[i];
      if (currentItem.id === dragedElementArray.id) {
        currentItem.sortOrder = dropedElementOrder;
      } else {
        if (dragedElementOrder > dropedElementOrder) {
          //Dragged up
          if (
            dragedElementOrder >= currentItem.sortOrder &&
            currentItem.sortOrder >= dropedElementOrder
          ) {
            currentItem.sortOrder = currentItem.sortOrder + 1;
          }
        } else {
          //Dragged down
          if (
            dragedElementOrder <= currentItem.sortOrder &&
            currentItem.sortOrder <= dropedElementOrder
          ) {
            currentItem.sortOrder = currentItem.sortOrder - 1;
          }
        }
      }
    }

    let resArray = [...data];
    setData([...resArray]);
    ele.style.opacity = "";
    e.target.style.opacity = "";
    if (props.isDraged) {
      props.isDraged();
    }
    return false;
  };
  const isLinkTypeData = (row, configurations, key) => {
    return (
      <a href={row[configurations.columnConfig[key].linkName]}>{row[key]} </a>
    );
  };

  //nested Table column
  const isChildItem = (list) => {
    return list.map((item, index) => {
      return (
        <>
          <TableRow key={index}>
            <TableCell>{item.label}</TableCell>
            <br></br>
          </TableRow>
        </>
      );
    });
  };

  const formatDataBasedOnType = (data, config, variance, row, cell) => {
    if (row.cellStyle && cell in row.cellStyle) {
      let classesArry = row.cellStyle[cell].split(",");
      let classesSeperated = "";
      for (let i = 0; i < classesArry.length; i++) {
        classesSeperated = classesSeperated + ` ${classes[classesArry[i]]}`;
      }
      return <span className={classesSeperated}> {data}</span>;
    }
    if (!config || !config.type) {
      return data;
    } else if (config && config.type == "text") {
      return data;
    } else if (config && config.clickable) {
      return (
        <a
          onClick={(event) => loadChart(event, config, data, row)}
          href="javascript:void(0)"
        >
          {" "}
          {data}
        </a>
      );
    } else if (config && config.type == "number") {
      let val = !isNaN(data) ? parseInt(data) : data;
      let res = val.toFixed(config.format ? config.format : 2);
      let color = "";

      if (variance) {
        color = res > 0 ? "green" : "red";
      }

      if (config.currency) {
        res = config.currency + res;
      }
      return <span style={{ color: color }}> {res}</span>;
    } else if (config && config.type == "date") {
      return getFormattedDate(data, config.format, "dd/dd/yyyy");
    } else if (config && config.type == "percentage") {
      let val = !isNaN(data) ? parseInt(data) : data;
      val = val * 100;
      let res = val.toFixed(config.format ? config.format : 1);
      let color = "";
      if (variance) {
        color = res > 0 ? "green" : "red";
      }
      return <span style={{ color: color }}> {res + "%"}</span>;
    }
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Table
          className={props.minWidth ? props.minWidth : classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="Custom table"
        >
          <TableBody>
            {data && data.length > 0 ? (
              stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // Table column
                  //row style
                  let defaultStyle = {
                    cursor: dragable ? "move" : "default",
                  };
                  let rowStyle = row.style
                    ? { ...defaultStyle, ...row.style }
                    : defaultStyle;

                  return (
                    <TableRow
                      className={classes.tableRow}
                      draggable={dragable ? "true" : ""}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragStart={handleDragStart}
                      style={rowStyle}
                      key={index}
                      sortOrder={row.sortOrder ? row.sortOrder : ""}
                    >
                      {action ? (
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ cursor: "pointer" }}
                        >
                          {action.edit ? (
                            <EditIcon
                              key={row.id}
                              id={row.id}
                              onClick={props.handleEditButtonClick}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {row
                        ? Object.keys(
                            configurations && configurations.columnConfig
                              ? configurations.columnConfig
                              : {}
                          )

                            //.sort()
                            .map((key) =>
                              configurations &&
                              configurations.columnConfig &&
                              configurations.columnConfig[key] &&
                              !configurations.columnConfig[key].hide ? (
                                <TableCell
                                  className={classes.tableCell}
                                  key={key}
                                  style={configurations.columnConfig[key].style}
                                >
                                  {configurations.columnConfig[key].link
                                    ? isLinkTypeData(row, configurations, key)
                                    : Array.isArray(row[key])
                                    ? isChildItem(row[key])
                                    : formatDataBasedOnType(
                                        row[key],
                                        configurations.columnConfig[key],
                                        configurations.columnConfig[key]
                                          .variance,
                                        row,
                                        key
                                      )}
                                </TableCell>
                              ) : (
                                ""
                              )
                            )
                        : ""}

                      {action ? (
                        <TableCell
                          className={classes.tableCell}
                          align="right"
                          style={{ cursor: "pointer" }}
                        >
                          {action.delete ? (
                            <DeleteIcon
                              key={index}
                              id={index}
                              dataId={row.id}
                              onClick={props.handleDeleteButtonClick}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} className={classes.tableRow}>
                  {configurations.noDataText}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {configurations && configurations.paginate ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </div>
    </ThemeProvider>
  );
};
A_CustomTable.defaultProps = {
  configurations: {
    title: "Heading",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: true,
    striped: true,
    columnConfig: {
      uname: {
        id: "uname",
        label: "User Name",
        sorting: false,
        hide: false,
      },
      ContactNo: {
        id: "ContactNo",
        label: "Contact No",
        sorting: true,
        hide: false,
      },
      email: {
        id: "email",
        label: "Email",
        sorting: true,
        hide: false,
      },
    },
  },

  data: [],
};
A_CustomTable.propTypes = {
  handleEditButtonClick: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  minWidth: PropTypes.string,
  handleAddButtonClick: PropTypes.object,
  configurations: PropTypes.object,
  data: PropTypes.array,
  isDraged: PropTypes.func,
};
export default A_CustomTable;
