const handlePositiveNumber = (num) => {
  if (typeof num == "number") {
    return Math.abs(num);
  } else if (typeof num == "string" && num && num.length > 0 && num[0] == "-") {
    return num.substring(1);
  } else {
    return num;
  }
};

export default handlePositiveNumber;
