import React from "react";
import { makeStyles } from "@material-ui/core";
import A_SimpleDialog from "../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getFormattedDate } from "../../../../../../../Utils/Helpers/dateUtils";
import { dateFormat } from "../../../../../../../Utils/config/config";
const useStyles = makeStyles({
  news: {
    padding: "1rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(213, 213, 213, 0.8)",
    },
  },

  header: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "grey",
  },
  head: {
    marginTop: "2rem",
  },
  subheading: {
    fontSize: "0.6rem",
    fontWeight: "500",
    color: "#2db0fa",
    fontStyle: "italic",
  },
  details: {
    fontSize: "0.8rem",
    color: "grey",
  },
  dialog: {
    margin: "auto",
    width: 300,
  },
});
export default function M_NewsDetails({
  heading,
  category,
  date,
  subheading,
  content,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div className={classes.news} onClick={handleOpen}>
        <div className={classes.header}>{heading ? heading : ""}</div>
        <div className={classes.subheading}>
          {category ? category : ""} ,{" "}
          {date
            ? getFormattedDate(
                date,
                content && content.dateFormat
                  ? content.dateFormat
                  : dateFormat["DD-MM-yyyy"]
              )
            : ""}
        </div>
        <div className={classes.details}>
          {subheading
            ? subheading.length > 150
              ? `${subheading.substring(0, 147)}...`
              : subheading
            : ""}
        </div>
      </div>
      <>
        <A_SimpleDialog
          className={classes.dialog}
          open={open}
          title={<div className={classes.head}>{heading ? heading : ""}</div>}
          maxWidth="md"
          onClose={handleClose}
          disableEscapeKeyDown={true}
          dialogContent={
            <>
              <div className={classes.subheading}>
                {category ? category : ""} ,{" "}
                {date
                  ? getFormattedDate(
                      date,
                      content && content.dateFormat
                        ? content.dateFormat
                        : dateFormat["DD-MM-yyyy"]
                    )
                  : ""}
              </div>
              <div className={classes.details}>
                {subheading ? subheading : ""}
              </div>
            </>
          }
        ></A_SimpleDialog>
      </>
    </>
  );
}
