import { filterRange } from "../../../../../Utils/config/config";

export const hasPageFilter = (currentPage,templateId) => {
    // check if page has the parameters
    let result = true;
    if (
      currentPage &&
      currentPage.pageConfig &&
      currentPage.pageConfig.toolbar
    ) {
      let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
      let filters =
      toolbar && toolbar.primary && toolbar.primary.filters
      ? toolbar.primary.filters
      : [];
      let tampletFilters=[] 
      filters.forEach(templets=>{
        templets.templateIds.forEach(id=>{
          if(id===templateId){
            tampletFilters.push(id)
          }
        })
      })
     
      if (!(tampletFilters.length > 0)) {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  };

  export const filterPropsData = (data) => {
    let resArray = [];
    if (data && data.series && data.series.length > 0) {
        const _series = data.series[0];
        if (data && data.dimensions && data.dimensions.length > 0) {
        resArray.push({
            label: data.dimensions[0],
            value: _series.name[0]
        })
    }
        for (let index = 0; index < _series.data.length; index++) {
            resArray.push({
                label: data.categories[index],
                value: _series.data[index]
            })
        }
    }
    return resArray;
  };

  export const getPagelLevelFilter=(pageFilters,hasFilters)=>{
    const PageLevelFilter={}
   if(pageFilters.length>0 && hasFilters){
     pageFilters.forEach((pageFilter) => {
       if (pageFilter.type && pageFilter.type === filterRange.RANGE) {
         PageLevelFilter["to"] = pageFilter.to;
         PageLevelFilter["filterOnDateField"] =
           pageFilter && pageFilter.filterOnDateField
             ? pageFilter.filterOnDateField
             : undefined;
       }
       PageLevelFilter[pageFilter.filterName] = pageFilter.value;
     });
   }
   return PageLevelFilter
 }