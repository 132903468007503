/* eslint-disable */
import { previousUploadDeleteActionTypes } from "./actionTypes";
export const getPreviousUploadDelete = (payload) => {
  return {
    type: previousUploadDeleteActionTypes.GET_PREVIOUS_UPLOAD_DELETE,
    payload,
  };
};
export const getPreviousUploadDeleteSucceeded = (payload) => {
  return {
    type: previousUploadDeleteActionTypes.GET_PREVIOUS_UPLOAD_DELETE_SUCCEEDED,
    payload,
  };
};
export const getPreviousUploadDeleteFailed = (payload) => {
  return {
    type: previousUploadDeleteActionTypes.GET_PREVIOUS_UPLOAD_DELETE_FAILED,
    payload,
  };
};
