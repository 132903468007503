import { take, put } from "redux-saga/effects";
import { httpPost } from "../../Utils/apis/apis";
import { irReportActionType } from "../actions/actionTypes";
import {
  getIrReportMailingListFail,
  getIrReportMailingListSuccess,
  getIrReportValueFail,
  getIrReportValueSuccess,
} from "../actions/irReportActions";

export function* getIrReportSaga() {
  while (true) {
    const action = yield take(irReportActionType.REQUEST_IRREPORT_VALUE);
    const { payload } = action;

    try {
      const investmentList = yield httpPost(
        "/InvReports/GetInvListReports",
        payload
      );
      if (investmentList.message) {
        yield put(getIrReportValueFail([]));
      } else {
        yield put(getIrReportValueSuccess(investmentList));
      }
      const mailingList = yield httpPost(
        "/InvReports/GetMailingListReports",
        payload
      );
      if (mailingList.message) {
        yield put(getIrReportMailingListFail([]));
      } else {
        yield put(getIrReportMailingListSuccess(mailingList));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
}
