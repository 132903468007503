import { irReportActionType } from "../actions/actionTypes";

let initialState = {
  apiCall: false,
  InvListloading: false,
  MailListloading: false,
  investmentList: [],
  mailingList: [],
  isFlag: false,
};
const getIrReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case irReportActionType.REQUEST_IRREPORT_VALUE:
      return {
        ...state,
        InvListloading: true,
        MailListloading: true,
        apiCall: true,
      };

    case irReportActionType.GET_IRREPORT_VALUE_SUCCESSED:
      return {
        ...state,
        InvListloading: false,
        investmentList: action.payload,
        apiCall: true,
      };

    case irReportActionType.GET_IRREPORT_VALUE_FAIL:
      return {
        ...state,
        InvListloading: false,
        investmentList: action.payload,
        apiCall: true,
      };

    case irReportActionType.GET_IRREPORT_MILING_LIST_SUCCESSED:
      return {
        ...state,
        MailListloading: false,
        mailingList: action.payload,
        apiCall: true,
      };

    case irReportActionType.GET_IRREPORT_MILING_LIST_FAIL:
      return {
        ...state,
        MailListloading: false,
        mailingList: action.payload,
        apiCall: true,
      };
    case irReportActionType.IRREPORT_TOGGLE_VALUE_ON:
      return { ...state, isFlag: true };

    case irReportActionType.IRREPORT_TOGGLE_VALUE_OFF:
      return { ...state, isFlag: false };
    default:
      return state;
  }
};
export default getIrReportReducer;
