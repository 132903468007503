import React from "react";
import { useState, useRef } from "react";
import "./dropzoneupload.scss";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import PreviousUploads from "../Renderers/Views/PreviousTemplate/PreviousUploads";
import { makeStyles } from "@material-ui/core/styles";
import { getDataFromSQLSp, handleFileUpload } from "./M_fileUpload.helper";
import { IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { toastSeverity } from "../../../../../Utils/config/toastConfig";
import { message } from "../../../../../Utils/config/messages";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import axios from "axios";
import PropTypes from "prop-types";
import O_HistoricalDocumentUpload from "../../../Organisms/Common/HistoricalDocumentUpload/O_HistoricalDocumentUpload";
import { connect } from "react-redux";
import A_SimpleSelect from "../../../Atoms/Inputs/Selects/A_SimpleSelect";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import { Skeleton } from "@material-ui/lab";
import { enhanceEvalString } from "../Renderers/Views/ResultCards/M_ResultCardHelper";
import { validateFileByTypes } from "./fileValidation.helper";
import { executeJSString } from "../../../Pages/Common/Layouts/layout.Helper";
const useStyles = makeStyles(() => ({
  chip: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
    "font-size": "12px",
    "border-radius": "25px",
    "background-color": "#f1f1f1",
    marginRight: "3px",
    marginBottom: "3px",
  },
  validation: {
    color: "#f44336",
    fontSize: "0.75rem",
  },
}));
const M_DropableFileUpload = (props) => {
  const classes = useStyles();
  const { content } = props;
  let { crmId, pageUrl,templateId } = useParams();
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);
  const user = useSelector((state) => state.oidc.user);
  const [uploading, setUploading] = useState(false);
  const [handleChange, setHandleChange] = useState(false);
  const [file, setFile] = useState(
    props && props.defaultValue && props.defaultValue.files
      ? props.defaultValue.files
      : []
  );
  const [fileNameList, setFilenameList] = useState([]);
  const messages = message;
  const [errorMessage, setErrorMessage] = useState("");
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: toastSeverity.SUCCESS,
    msg: "",
  });
  const [filesFetched] = useState(
    props.defaultValue && props.defaultValue.length > 0
      ? props.defaultValue
      : []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [pathOptions, setPathOptions] = React.useState([]);
  const [isPathSelected, setIsPathSelected] = React.useState(false);
  const [validationChecklist, setValidationChecklist] = React.useState(
    content.validations
  );
  const [isValidationChecked, setIsValidationChecked] = React.useState(false);
  const [fileName, setFileName] = React.useState(
    crmId == 0 ? pageUrl + "/" : crmId + "/"
  );
  const { crmContactUserEmail, crmContactUserId } =
    commonFunctions.getCRMContactInfo();
  var fileConfig = {
    allowedExtensions: props["allowedExtensions"]
      ? props["allowedExtensions"]
      : "",
    containerType: props["containerType"] ? props["containerType"] : "",
    processFile: props["processFile"] ? props["processFile"] : "",
  };
  let list =
    props.content && props.content.formData && props.content.formData.pdfFile
      ? props.content.formData.pdfFile
      : "";

  const dataDoc = [];
  const dataList = () => {
    if (filesFetched.length > 0) {
      setFile(
        filesFetched.map((f) => {
          return {
            name: f.split("/")[1].split("__")[1],
            uploadedDoc: true,
            fullName: f,
          };
        })
      );
    } else if (list == "" || list == "undefined") {
      return;
    } else {
      list.split(",").map((item) => {
        let itemlist = {
          name: item.split("/")[1],
          path: item,
        };
        dataDoc.push(itemlist);
      });

      let fileNamePath = [];
      let fileNameData = [];
      for (let i in dataDoc) {
        fileNamePath.push(dataDoc[i].path);
        fileNameData.push({ name: dataDoc[i].name, type: "edit" });
      }
      setFilenameList(fileNamePath);
      setFile(fileNameData);
    }
  };
  const getPathOptions = async () => {
    setIsLoading(true);
    try {
      let { spDetails, chartSPs } = content.optionConfig;
      let spResponse = await getDataFromSQLSp({
        chartSPs,
        spDetails: [spDetails],
      });
      setPathOptions(spResponse[spDetails["id"]]);
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  React.useEffect(() => {
    dataList();
  }, [props.content && props.content.type]);
  React.useEffect(() => {
    getPathOptions();
    setFileName(
      content && content.directory
        ? `${content.directory}/${fileName}`
        : fileName
    );
  }, []);
  var multiple =
    props && props.content && props.content.multiple
      ? props.content.multiple
      : false;
  var inputProps = {
    accept:
      content && content.allowedExtensions
        ? content.allowedExtensions
        : "pdf,pptx,docx,xlsx",
    multiple: content && content.multiple ? content.multiple : false,
    directory: content && content.directory ? content.directory : null,
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const checkValidation = (value) => {
    var validation =
      props && props.createNew
        ? props.createNew
        : props.allowedExtensions || [];
    var result = true;
    for (let i = 0; i <= validation.length - 1; i++) {
      if (validation[i] == value) {
        result = true;
        break;
      }
      if (validation.includes("*")) {
        return true;
      } else {
        result = false;
      }
    }
    return result;
  };

  const handlefile = (event) => {
    let newFiles = file.filter((f) => {
      return !f.deleteFile;
    });
    if (
      (!multiple &&
        event &&
        event.dataTransfer &&
        event.dataTransfer.items &&
        event.dataTransfer.items.length > 1) ||
      (!multiple && newFiles.length == 1)
    ) {
      setErrorMessage(message.MULTIPLE_FILES_NOT_ALLOWED);
      stopEvent(event);
      setHover(false);
      return;
    } else {
      errorMessage && setErrorMessage("");
    }
    stopEvent(event);
    setHover(false);
    let filesList = event.target.files || event.dataTransfer.files || [];
    let resultFiles = [];
    try {
      for (let fileData of filesList) {
        const index = fileData.name.lastIndexOf(".");
        var afterDot = fileData.name.slice(index + 1);
        const fileExtensionName = afterDot.replaceAll(".", "");
        if (props && props.fileNameAllowed) {
          if (fileData.name) {
            if (fileData.name.includes(props.fileNameAllowed)) {
              setErrorMessage("");
            } else {
              setErrorMessage(`*${fileData.name} not Valid`);
              break;
            }
          }
        }
        if (checkValidation(fileExtensionName)) {
          if (props.id) {
            fileData.field = props.id;
          }
          resultFiles.push(fileData);
          setErrorMessage("");
        } else {
          setErrorMessage(`*${fileExtensionName} Format is Not Allowed`);
        }
      }
    } catch (e) {
      for (let fileData of filesList) {
        if (props.id) {
          fileData.field = props.id;
        }
        resultFiles.push(fileData);
      }
    }

    setFile([...file, ...resultFiles]);
    props.onChange([...file, ...resultFiles], props.id, fileConfig);
    event.target.value = "";
  };

  const handleClick = () => {
    let newFiles = file.filter((f) => {
      return !f.deleteFile;
    });
    if (!multiple && newFiles.length > 0) {
      setErrorMessage(message.MULTIPLE_FILES_NOT_ALLOWED);
    }
    inputFile.current.click();
  };
  const dragAndDropHandler = (event) => {
    let newFiles = file.filter((f) => {
      return !f.deleteFile;
    });
    if (!multiple && newFiles.length > 0) {
      stopEvent(event);
      setHover(false);
      setErrorMessage(message.MULTIPLE_FILES_NOT_ALLOWED);
      return;
    }
    props.enableChips ? handlefile(event) : handleDropFiles(event);
  };

  const handleDropFiles = async (e) => {
    stopEvent(e);
    setHover(false);
    if (content.validations) {
      let filesList = [{ files: e.dataTransfer.files }];
      const reader = new FileReader();
      reader.onload = async (event) => {
        let { externalDetails } = props.content;
        if (externalDetails && externalDetails.payload) {
          externalDetails.payload = {
            ...externalDetails.payload,
            crmId,
            pageUrl,
            templateId,
            userEmail: crmContactUserEmail,
            userCRMId: crmContactUserId,
          };
        }
        const arrayBuffer = event.target.result;
        if (content.optionConfig && !isPathSelected) {
          return;
        }
        if (await verifyValidationChecklist(arrayBuffer)) {
          inputFile.current.value = "";
          return;
        }
        let resultFiles = filesList;
        await handleFileUpload(
          null,
          crmId,
          user,
          resultFiles,
          props.content,
          fileName,
          onfileLoad,
          onMessage,
          true,
          pageUrl,
          true,
          props.content.directory,
          props.content.containerType,
          externalDetails
        );
        setValidationChecklist(content.validations);
        setHandleChange(!handleChange);
        inputFile.current.value = "";
        setIsValidationChecked(false);
      };
      reader.readAsArrayBuffer(e.dataTransfer.files[0]);
    } else {
      let resultFiles = [{ files: e.dataTransfer.files }];
      await handleFileUpload(
        null,
        crmId,
        user,
        resultFiles,
        props.content,
        fileName,
        onfileLoad,
        onMessage,
        true,
        pageUrl,
        true,
        props.content.directory,
        props.content.containerType,
        externalDetails
      );
      setHandleChange(!handleChange);
      inputFile.current.value = "";
    }
  };

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };

  const downloadPreview = (e, file) => {
    if (file.uploadedDoc === true) {
      const documentName = file.fullName;
      const url = `${process.env.DOCUMENT_HELPER}`;
      const httpConfig = commonFunctions.getHttpConfig();
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        filename: documentName,
        type: "generic",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Authorization: httpConfig.headers.Authorization,
      };
      axios
        .post(
          url,
          {
            method: "GET",
          },
          { responseType: "blob", headers: header }
        )
        .then((response) => {
          const url1 = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url1;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        });
    } else {
      e.preventDefault;
      const url1 = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", file.name);
      link.click();
    }
  };

  const onfileLoad = (status) => {
    setUploading(status);
  };
  const onMessage = (message) => {
    if (message === true) {
      setChangesSaved({
        status: true,
        type: toastSeverity.SUCCESS,
        msg: messages.FILE_UPLOAD_SUCESSFUL,
      });
    } else
      setChangesSaved({
        status: true,
        type: toastSeverity.ERROR,
        msg: messages.FILE_FORMAT_NOT_SUPPORTED,
      });
  };
  const delfile = () => {
    setChangesSaved({
      status: true,
      type: toastSeverity.SUCCESS,
      msg: messages.DELETE_SUCCESS,
    });
  };

  const removeFile = (event, fileIndex, fileData) => {
    let newFile = file.filter((item, index) => index !== fileIndex);
    let newFileList = fileNameList.filter((item, index) => index !== fileIndex);
    if (fileData.uploadedDoc === true) {
      let fileCopy = file;
      fileCopy.map((f) => {
        if (f.uploadedDoc === true && f.name === fileData.name) {
          f["deleteFile"] = true;
        }
      });
      props.onChange([...fileCopy], props.id, fileConfig);
      setFile(fileCopy);
    } else {
      props.onUpload(true, newFile, props.id);
      event.preventDefault();
      let fileCopy = [...newFile];
      if (!multiple) {
        fileCopy.map((f) => {
          f["deleteFile"] = true;
        });
      }
      setFile(fileCopy);
    }
    setFilenameList(newFileList);
    if (!multiple && file.length > 0) {
      setErrorMessage("");
    }
  };
  const handleSingleOrMultipleFileUpload = async (e) => {
    let { externalDetails } = props.content;
    if (externalDetails && externalDetails.payload) {
      externalDetails.payload = {
        ...externalDetails.payload,
        crmId,
        pageUrl,
        templateId,
        userEmail: crmContactUserEmail,
        userCRMId: crmContactUserId,
      };
    }
    if (content.validations) {
      let filesList = [{ files: e.target.files }];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const arrayBuffer = event.target.result;

        if (content.optionConfig && !isPathSelected) {
          return;
        }
        if (await verifyValidationChecklist(arrayBuffer)) {
          inputFile.current.value = "";
          return;
        }
        let resultFiles = filesList;

        await handleFileUpload(
          null,
          crmId,
          user,
          resultFiles,
          props.content,
          fileName,
          onfileLoad,
          onMessage,
          true,
          pageUrl,
          true,
          props.content.directory,
          props.content.containerType,
          externalDetails
        );
        setValidationChecklist(content.validations);
        setHandleChange(!handleChange);
        inputFile.current.value = "";
        setIsValidationChecked(false);
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    } else {
      let filesList = [{ files: e.target.files }];
      await handleFileUpload(
        e,
        crmId,
        user,
        filesList,
        props.content,
        fileName,
        onfileLoad,
        onMessage,
        true,
        pageUrl,
        true,
        props.content.directory,
        props.content.containerType,
        externalDetails
      );
      setHandleChange(!handleChange);
      inputFile.current.value = "";
    }
  };
  const handleDynamicFilePath = (value) => {
    let targetOption = pathOptions.find(
      (option) =>
        option[
          getObjectFromJSONString(content, ".optionConfig.optionValue")
        ] === value
    );
    let dynamicFilePath = enhanceEvalString(
      getObjectFromJSONString(content, ".optionConfig.filePath"),
      {
        ...targetOption,
        crmId: crmId == 0 ? pageUrl : crmId,
      },
      false
    );
    setFileName(
      `${content.directory ? `${content.directory}/` : ""}${dynamicFilePath}/`
    );
    setIsPathSelected(true);
  };

  const verifyValidationChecklist = async (file) => {
    let tempValidationList = [...validationChecklist];

    for (let validations of validationChecklist) {
      if (validateFileByTypes(file, validations) === validations.value) {
        tempValidationList = tempValidationList.filter(
          (item) => item.type !== validations.type
        );
      }
    }

    setIsValidationChecked(true);
    setValidationChecklist(tempValidationList);
    return tempValidationList.length > 0;
  };
  return (
    <>
      {isLoading ? (
        <>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Skeleton
              animation="wave"
              height={30}
              width="30%"
              style={{ marginBottom: 10 }}
            />
          </div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={96}
            width={"100%"}
          />
        </>
      ) : (
        <div>
          {props.content.uploadHide !== true &&
          props.currentPage &&
          props.currentPage.pageConfig &&
          props.currentPage.pageConfig.pageSecurity &&
          (props.currentPage.pageConfig.pageSecurity.Update ||
            props.currentPage.pageConfig.pageSecurity.Full ||
            props.content.secured === false ||
            props.content.secured === undefined) &&
          executeJSString(
            getObjectFromJSONString(props, ".content.readOnlyTemplate"),
            getObjectFromJSONString(props, ".currentPage.data")
          ) != true ? (
            <section style={{ width: "auto" }}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {getObjectFromJSONString(
                  content,
                  ".optionConfig.optionLabel",
                  false
                ) && (
                  <A_SimpleSelect
                    id={"SP drop down"}
                    label={getObjectFromJSONString(
                      content,
                      ".optionConfig.name"
                    )}
                    defaultValue={pathOptions && pathOptions[0]}
                    options={pathOptions || []}
                    optionLabel={getObjectFromJSONString(
                      content,
                      ".optionConfig.optionLabel"
                    )}
                    optionValue={getObjectFromJSONString(
                      content,
                      ".optionConfig.optionValue"
                    )}
                    onChange={handleDynamicFilePath}
                  />
                )}
              </div>
              <div
                className={
                  hover ? "drop-zone-container hover" : "drop-zone-container"
                }
                style={{ width: "auto" }}
                onDrop={dragAndDropHandler}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
              >
                <CloudUpload color="primary" />
                {!uploading ? (
                  <p style={{ color: "#054af7" }}>
                    Drag or Drop Files here to upload{" "}
                  </p>
                ) : (
                  ""
                )}
                {uploading ? <CircularDeterminate /> : ""}
                {!uploading ? (
                  <A_Button
                    onClick={handleClick}
                    onDrop={dragAndDropHandler}
                    onDragLeave={onDragLeave}
                    onDragOver={onDragOver}
                    color="primary"
                    disabled={content.optionConfig && !isPathSelected}
                    label="Click to Upload"
                  />
                ) : (
                  ""
                )}
                <input
                  {...inputProps}
                  className={"fileinput"}
                  type="file"
                  ref={inputFile}
                  disabled={
                    !multiple &&
                    file.filter((f) => {
                      return !f.deleteFile;
                    }).length > 0
                  }
                  onChange={(event) => {
                    props.enableChips
                      ? handlefile(event)
                      : handleSingleOrMultipleFileUpload(event);
                    //event, null, props.content, inputProps, crmId, fileName
                  }}
                />
              </div>
              {content.validations ? (
                <div className="mt-2">
                  {content.validations.map((validation, index) => (
                    <div
                      key={`validation__${index}`}
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        opacity: "0.6",
                        color: isValidationChecked
                          ? validationChecklist.find(
                              (item) => item.label === validation.label
                            )
                            ? "red"
                            : "green"
                          : "grey",
                      }}
                    >
                      {validation.label}{" "}
                      {isValidationChecked
                        ? validationChecklist.find(
                            (item) => item.label === validation.label
                          )
                          ? validation.failure || "🗙"
                          : validation.success || "✔"
                        : ""}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {props.enableChips
                ? file
                    .filter((f) => {
                      return !f.deleteFile;
                    })
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=""
                          style={{
                            height: "2.6rem",
                            margin: "0.2rem",
                            display: "inline",
                          }}
                        >
                          <div className={classes.chip}>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                downloadPreview(e, item);
                              }}
                            >
                              {item.name}
                            </div>
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => {
                                removeFile(e, index, item);
                              }}
                            >
                              <CloseRounded></CloseRounded>
                            </IconButton>
                          </div>
                        </div>
                      );
                    })
                : ""}

              <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                type={changesSaved.type}
                autoHideDuration={2000}
                horizontal="center"
                handleClose={handleSnackbarClose}
              />
            </section>
          ) : (
            ""
          )}

          {props.enableChips ? (
            ""
          ) : props && props.content && props.content.showFiles === false ? (
            ""
          ) : props &&
            props.content &&
            props.content.historicalDocs === true ? (
            <O_HistoricalDocumentUpload
              change={handleChange}
              content={props.content}
            />
          ) : (
            <section>
              <PreviousUploads
                delfile={delfile}
                currentPage={props.currentPage}
                content={props.content}
              />
            </section>
          )}
        </div>
      )}
      <p className={classes.validation}>{errorMessage}</p>
    </>
  );
};

M_DropableFileUpload.propTypes = {
  previousUpload: PropTypes.any,
  getPreviousUpload: PropTypes.any,
  getPreviousUploadDelete: PropTypes.any,
  delfile: PropTypes.func,
  currentPage: PropTypes.any,
  content: PropTypes.any,
  enableChips: PropTypes.any,
  onChange: PropTypes.any,
  onUpload: PropTypes.any,
  defaultValue: PropTypes.any,
  createNew: PropTypes.any,
  id: PropTypes.any,
  processFile: PropTypes.any,
  containerType: PropTypes.any,
  allowedExtensions: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

export default connect(mapStateToProps, undefined)(M_DropableFileUpload);
