import React from "react";
import M_PDFReader from "../../../Molecules/Common/PDFReader/M_PDFReader";
import { connect } from "react-redux";
import { handleStringValue } from "../GenericList/Helper/GenericList.Helper";
import propTypes from "prop-types";
function O_InvestmentCase({ content, currentPage }) {
  const [fileName, setFileName] = React.useState("");
  React.useEffect(() => {
    let tempFileName = "";
    let configFileName = content.fileName ? content.fileName : "";
    tempFileName = `${handleStringValue(configFileName, currentPage.data)}${
      content.suffix ? content.suffix : ""
    }`;
    setFileName(tempFileName);
  }, []);
  return (
    <div>
      <M_PDFReader
        showDefaultMessage={true}
        pdf={fileName ? fileName : ""}
        container={content.type ? content.type : "generic"}
      />
    </div>
  );
}
O_InvestmentCase.propTypes = {
  content: propTypes.object,
  currentPage: propTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

export default connect(mapStateToProps, undefined)(O_InvestmentCase);
