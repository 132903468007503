/* eslint-disable no-console */
import { httpPost } from "../../../../../../Utils/apis/apis";
import { cardType } from "../../../../../../Utils/config/cardType";
const edgeUpdate = async (card = "", edgeId, data) => {
  let response = null;
  let payload = [];
  try {
    switch (card) {
      case cardType.DEAL_TEAM:
        payload = [
          {
            edgeId: edgeId,
            edgeProperties: data.properties,
          },
        ];
        break;
      default:
        break;
    }
    let message = await httpPost(`/CRMData/updateEdge`, payload);
    response = {
      status: true,
      id: message,
    };
    return response;
  } catch (e) {
    console.log("Error in updating edge ", e);
    return response;
  }
};

export default edgeUpdate;
