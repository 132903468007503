import React from "react";
import propTypes from "prop-types";
import O_Checklist from "../../../Organisms/Common/Checklist/O_Checklist";
export default function M_TabsCheckList(props) {
  return (
    <div>
      <O_Checklist {...props} reload={props.reload}></O_Checklist>
    </div>
  );
}
M_TabsCheckList.propTypes = {
  reload: propTypes.func
};
