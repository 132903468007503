/* eslint-disable */
import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

 function A_CustomMultiSelectChip({ label, options, onChange, name, width = "300px" }) {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = React.useState([]);

// TODO : To be Removed in Future
//   const getOptionValue = (value) => {
//       return value.map(val => val.id)
//   }

  const handleOnChange = (event, values) => {
      setSelectedValue(values);
      onChange(values, name)
      
  }

  return (
      <div>
          <FormControl style={{ minWidth: width, margin: "10px 0" }}>
              <Autocomplete
                  multiple
                  id="size-small-outlined"
                  options={options}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                      <TextField {...params} label={label} variant="outlined" />
                  )}
                  renderTags={(value, getTagProps) => value.map((option, index) => (<Chip key={option.id} label={option.label}
                      {...getTagProps({ index })} />))}
                  onChange={handleOnChange} value={selectedValue}
              />
          </FormControl>
      </div>
  );
}
 
export default A_CustomMultiSelectChip

