/* eslint-disable */
import * as React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import { HistoryRounded } from "@material-ui/icons";
import { ArrowForward, ArrowBack, Close } from "@material-ui/icons";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import { guidelineData } from "./dealStatus.Helper";
export default function O_DealStatus(props) {
  let [guideline, setGuideline] = React.useState("");

  const [data, setData] = React.useState(guidelineData);
  React.useEffect(() => {
    setButton();
  }, []);

  let setButton = async () => {
    for (let i in data) {
      if (data[i].state == props.data.currentStatus) {
        setGuideline(data[i].guideline);
        break;
      }
    }
  };
  return (
    <Box sx={{ p: "1.2rem 0" }}>
      <p dangerouslySetInnerHTML={{ __html: guideline }}></p>
    </Box>
  );
}
