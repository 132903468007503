import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    margin: theme.spacing(0.1),
    marginBottom: theme.spacing(2),
  },
  checkCustom: {
    background: "white",
    padding: 0,
    margin: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkBoxSelected: {
    background: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  total_row: {
    fontWeight: "bold",
    borderTop: "2px solid #888 !important",
    borderBottom: "2px solid #888 !important",
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
  inactive: {
    opacity: "0.4",
  },
  indent: {
    paddingLeft: "30px !important",
  },
  indent1: {
    paddingLeft: "20px !important",
  },
  indent2: {
    paddingLeft: "40px !important",
  },
  indent3: {
    paddingLeft: "60px !important",
  },
  small: {
    fontSize: "10px",
  },
  small1: {
    fontSize: "75%",
  },
  small2: {
    fontSize: "65%",
  },
  densetable: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  TopBottomBorder: {
    borderTop: "2px solid #888 !important",
    borderBottom: "2px solid #888 !important",
  },
  blurRow: {
    filter: "opacity(50%)",
  },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  saveButton: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    backgroundColor: "Green",
  },
  highlightChart: {
    background: "rgba(209, 209, 209, 0.4) !important",
  },
  popUpChart: {
    color: "blue",
    cursor: "pointer",
  },
  tableContentWrapper: {
    marginTop: "2em",
  },
  tableContentWrapperToggle: {
    marginTop: "0px",
  },
  headerFreeze: {
    marginTop: "0px",
    maxHeight: "60vh",
  },
}));

export default useStyles;
