/* eslint-disable */
import { manualUpdateActionType } from "./actionTypes";

export const getDropdownValue = (payload) => {
  return {
    type: manualUpdateActionType.GET_DROPDOWN_VALUE,
    payload,
  };
};
export const getDropdownValueSucceeded = (payload) => {
  return {
    type: manualUpdateActionType.GET_DROPDOWN_VALUE_SUCCESSED,
    payload,
  };
};

export const getDropdownValueFailed = (payload) => {
  return {
    type: manualUpdateActionType.GET_DROPDOWN_VALUE_FAILED,
    payload,
  };
};

export const getMetricDTValue = (payload) => {
  return {
    type: manualUpdateActionType.GET_DT_VALUE,
    payload,
  };
};
export const getMetricDTValueSucceeded = (payload) => {
  return {
    type: manualUpdateActionType.SET_DT_VALUE_SUCCESSED,
    payload,
  };
};

export const getMetricDTValueFailed = (payload) => {
  return {
    type: manualUpdateActionType.SET_DT_DROPDOWN_VALUE_FAILED,
    payload,
  };
};
