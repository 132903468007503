/* eslint-disable */
import {
  esgRecordActionType
} from "../actions/actionTypes";

let initialState = {};
const esgRecordReducer = (state = initialState, action) => {
  let currState = {
    ...state
  };
  switch (action.type) {
    case esgRecordActionType.GET_ESG_RECORD:
      currState.isLoading = true;
      return currState;
    case esgRecordActionType.GET_ESG_RECORD_BY_ID:
      currState.isLoading = true;
      currState.payload = action.payload;
      return currState;
    case esgRecordActionType.GET_ESG_RECORD_SUCCEEDED:
      return action.payload;
    case esgRecordActionType.GET_ESG_RECORD_FAILED:
      return action.payload;
    case esgRecordActionType.INSERT_ESG_RECORD:
      return action.payload;
    case esgRecordActionType.INSERT_ESG_RECORD_SUCCEEDED:
      return action.payload;
    case esgRecordActionType.INSERT_ESG_RECORD_FAILED:
      return action.payload;
    case esgRecordActionType.UPDATE_ESG_RECORD:
      return action.payload;
    case esgRecordActionType.UPDATE_ESG_RECORD_SUCCEEDED:
      return action.payload;
    case esgRecordActionType.UPDATE_ESG_RECORD_FAILED:
      return action.payload;

    default:
      return state;
  }
};
export default esgRecordReducer;