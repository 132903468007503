/* eslint-disable */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import "./kpicallbox.scss";
import Card from '@material-ui/core/Card';
import { useParams } from "react-router";
import { useEffect } from "react";
import { httpPost } from "../../../../../Utils/apis/apis";
import PollIcon from '@material-ui/icons/Poll'; import { useSelector } from "react-redux";
import { useState } from "react";
import { filterPropsData, getPagelLevelFilter, hasPageFilter } from "./kpi.helper";
import { Skeleton } from '@material-ui/lab';

export default function KpiSqlBox(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  let { templateId } = useParams();
  const { item: {
    content: {
      content: {
        chart: {
          id
        }
      }
    }
  } } = props
  const pageFilters = useSelector(
    (state) =>
      state.manageToolbarSelections && state.manageToolbarSelections.filters
  )
  const currentPage = useSelector(state => state.currentPage)
  let hasFilters = hasPageFilter(currentPage, templateId)

  const getChart = async () => {
    let url = "/ChartData/getchartdata";
    setLoading(true)
    const payload = {
      ChartId: id,
      parameters: getPagelLevelFilter(pageFilters, hasFilters)
    }
    const result = await httpPost(url, payload);
    setLoading(false)
    if (!result.message) {
      let resultData = filterPropsData(result.data)
      setData(resultData)
    }
  };

  useEffect(() => {
    if (pageFilters.length > 0 && hasFilters) {
      getChart()
    } else if (!hasFilters) {
      getChart()
    }
  }, [pageFilters])

  if (loading) {
    return <div style={{ margin: "5px 0" }}>
      <Skeleton variant="rectangular" style={{ margin: "5px 0 10px 0" }} width="100%" height={80} />
      <Skeleton variant="rectangular" style={{ margin: "5px 0" }} width="100%" height={80} />
    </div>
  }

  return (
    <div id="kpiCallBox">
      <Grid container spacing={3} className="kpiCallBox">
        {data && data.length ? data.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${item.value}_${index}`}>
              <Box className="flexContainer">
                <Box className="flex1stItem" style={{ background: `#276890` }}>
                  <PollIcon className="iconFOnt" />
                </Box>
                <Box className="flex2ndItem" style={{ background: `#377ca7` }}>
                  <Typography className="content2nd1st">
                    {item.label}
                  </Typography>
                  <Typography className="content2st2nd">
                    {item.value}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        }) :
          (<Grid item xs={12} sm={12} md={12} lg={12}>
            <Card style={{ height: '160px' }} className='noData'>
              <div>WE ARE SORRY, NO CONTENT FOUND!</div>
            </Card>
          </Grid>)
        }
      </Grid>
    </div>
  );
}