 /* eslint-disable */
import React from "react";
import { Box } from "@material-ui/core";
import M_MultiValueList from "./M_MultiValueList";
import M_ListDetails from "./M_ListDetails";
import { makeStyles } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import defLogo from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
});
const M_ListDetailCard = (props) => {
  const classes = useStyles();
  var logo = defLogo;

  return (
    <>
      <Box display={"flex"}>
        <>
          {props.content && props.content.displayIRCList ? (
            <Box
              width={"40%"}
              borderRight={"2px solid #f0f0f0"}
              style={{ maxHeight: "50vh", overflow: "auto" }}
            >
              <Box
                //key={index}
                onClick={() => {
                  props.callBack(props.id);
                }}
              >
                <M_MultiValueList
                  title={logo}
                  heading={props.heading}
                  id={props.id}
                  subHeading={props.subHeading}
                  //data={item}
                  moreOptions={props.moreOptions}
                  onDelete={props.onDelete}
                  selected={props.selected}
                ></M_MultiValueList>
                <Divider></Divider>
              </Box>
            </Box>
          ) : (
            ""
          )}
          {props.id == props.selectedIRC && (
            <Box
              width={
                props.content && props.content.displayIRCList ? "60%" : "100%"
              }
              style={{ maxHeight: "50vh", overflow: "auto" }}
            >
              <M_ListDetails
                type="irc"
                id={props.selectedIRC}
                ircData={props.ircData}
                resPayload={
                  props.content && props.content.resPayload
                    ? props.content.resPayload
                    : ""
                }
                config={
                  props.content && props.content.ircTabs
                    ? props.content.ircTabs
                    : []
                }
                content={props.content}
              />
            </Box>
          )}
        </>
      </Box>
    </>
  );
};

export default M_ListDetailCard;
