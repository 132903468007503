import axios from "axios";
import { getHttpConfig } from "../Helpers/common";

const baseUrl = process.env.API_URL;

//http get calls handler
// config = {headers: {}, responseType}
export const httpGet = async (url, config) => {
  if (!config) {
    config = { headers: {} };
  }
  if (!("headers" in config)) {
    config.headers = {};
  }
  const httpConfig = getHttpConfig();
  config.headers.Authorization = httpConfig.headers.Authorization;
  let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
  const result = await axios
    .get(finalUrl, config)
    .then((response) => response.data)
    .catch((error) => error);

  return result;
};

//http post calls handler
export const httpPost = async (
  url,
  data = {},
  config,
  completeResReturn = false
) => {
  if (!config) {
    config = { headers: {} };
  }
  if (!("headers" in config)) {
    config.headers = {};
  }
  const httpConfig = getHttpConfig();
  config.headers.Authorization = httpConfig.headers.Authorization;
  let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
  const result = await axios
    .post(finalUrl, data, config)
    .then((response) => (completeResReturn ? response : response.data))
    .catch((error) => error);
  return result;
};

//http put calls handler
export const httpPut = async (url, data = {}, config) => {
  if (!config) {
    config = { headers: {} };
  }
  if (!("headers" in config)) {
    config.headers = {};
  }
  const httpConfig = getHttpConfig();
  config.headers.Authorization = httpConfig.headers.Authorization;
  let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
  const result = await axios
    .put(finalUrl, data, config)
    .then((response) => response.data)
    .catch((error) => error);
  return result;
};

//http patch calls handler
export const httpPatch = async (url, data = {}, config) => {
  if (!config) {
    config = { headers: {} };
  }
  if (!("headers" in config)) {
    config.headers = {};
  }
  const httpConfig = getHttpConfig();
  config.headers.Authorization = httpConfig.headers.Authorization;
  let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
  const result = await axios
    .patch(finalUrl, data, config)
    .then((response) => response.data)
    .catch((error) => error);
  return result;
};

//http delete calls handler
export const httpDelete = async (url, config) => {
  if (!config) {
    config = { headers: {} };
  }
  if (!("headers" in config)) {
    config.headers = {};
  }
  const httpConfig = getHttpConfig();
  config.headers.Authorization = httpConfig.headers.Authorization;
  let finalUrl = url.indexOf("http") > -1 ? url : `${baseUrl}${url}`;
  const result = await axios
    .delete(finalUrl, config)
    .then((response) => response.data)
    .catch((error) => error);
  return result;
};
