import ErrorBoundary from "../../../../Molecules/Common/error/ErrorBoundary";
import ViewFields from "../../Fields/ViewFields";
import DisplaySections from "../../DisplaySection/DisplaySection";
import DisplayTabs from "../../DisplayTabs/DisplayTabs";
import React from "react";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import { httpPost } from "../../../../../../Utils/apis/apis";

const M_CustomView = React.memo((props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const { toolbarSelectionsFilter } = props;
  let spName = props.spName;
  let sqlFilterString = JSON.stringify(
    toolbarSelectionsFilter && toolbarSelectionsFilter.filters
  );

  const getViewData = React.useCallback(
    async (param = {}) => {
      const convertedFormData = {
        spName: spName,
        parameters: { ...param, id: props.params.crmId },
      };
      let response = await httpPost(
        `/SQLBasedForm/SQLFormOperations`,
        convertedFormData
      );
      setData(response && response[0] ? response[0] : []);
      setIsLoading(false);
    },
    [
      spName,
      props.params.crmId,
      toolbarSelectionsFilter && toolbarSelectionsFilter.filters,
    ]
  );

  React.useEffect(() => {
    if (
      toolbarSelectionsFilter &&
      toolbarSelectionsFilter.filters &&
      toolbarSelectionsFilter.filterLength > 0 &&
      Array.isArray(toolbarSelectionsFilter.filters) &&
      toolbarSelectionsFilter.filters.length > 0 &&
      toolbarSelectionsFilter.filterLength ===
        toolbarSelectionsFilter.filters.length &&
      toolbarSelectionsFilter.filters.length > 0
    ) {
      setIsLoading(true);
      let params = {};
      toolbarSelectionsFilter.filters.forEach((item) => {
        params[item.filterName] = item.value;
      });
      getViewData(params);
    }
  }, [toolbarSelectionsFilter.filterLength, sqlFilterString]);

  React.useEffect(() => {
    if (!toolbarSelectionsFilter) {
      setIsLoading(true);
      getViewData({});
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <Skeleton variant="text" width={80} height={80} />
        <Skeleton variant="text" width={"100%"} height={80} />
        <Skeleton variant="rect" width={"100%"} height={118} />
      </>
    );
  } else if (props.content && props.content.tabs) {
    return (
      <ErrorBoundary>
        <DisplayTabs
          tabs={props.content.tabs}
          chartFilter={props.chartFilter}
          data={data}
          CurrentCard={props.CurrentCard}
          crmData={data}
          isSqltype={true}
        />
      </ErrorBoundary>
    );
  } else if (props.content && props.content.sections) {
    return (
      <ErrorBoundary>
        <DisplaySections
          sections={props.content.sections}
          crmData={data}
          isSqltype={true}
        />
      </ErrorBoundary>
    );
  } else if (props.content && props.content.fields) {
    return (
      <ErrorBoundary>
        <ViewFields
          fields={props.content.fields}
          syncEdgeLabel={props.syncEdgeLabel}
          content={props.content}
          CurrentCard={props.CurrentCard}
          crmData={data}
          isSqltype={true}
          {...props}
        />
      </ErrorBoundary>
    );
  }
});

M_CustomView.displayName = "M_CustomView";

M_CustomView.propTypes = {
  item: PropTypes.shape({
    content: PropTypes.shape({
      customCardName: PropTypes.string,
    }),
  }),
  content: PropTypes.object,
  state: PropTypes.object,
  syncEdgeLabel: PropTypes.any,
  chartFilter: PropTypes.any,
  data: PropTypes.any,
  CurrentCard: PropTypes.any,
  toolbarSelections: PropTypes.any,
  params: PropTypes.any,
  toolbarSelectionsFilter: PropTypes.any,
  spName: PropTypes.any,
};

export default M_CustomView;
