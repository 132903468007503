const getBorderGroupBased = (groups, index, isDimention) => {
  if (
    isDimention &&
    groups &&
    groups.length > 0 &&
    groups[0] &&
    groups[0] == 1
  ) {
    return "1px solid rgba(224, 224, 224, 1)";
  } else if (
    !isDimention &&
    groups &&
    groups.length > 0 &&
    groups.find((element) => element == index + 1)
  ) {
    return "1px solid rgba(224, 224, 224, 1)";
  } else {
    return null;
  }
};

export default getBorderGroupBased;
