 /* eslint-disable */
 import React, {useState} from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel"; 
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText';
import theme from "../../../../Themes/defaultTheme";
import A_FormHelper from "../../../Atoms/DataDisplay/Tooltips/A_FormHelper";

const M_RadiosGroupConfig = (props) => {
  const [value, setValue] = useState(props.defaultValue);
  const [roww, setRow] = useState(props.defaultValue);

  const handleChange = (event) => {
    let selectedRadio =  props.options.filter(function(radio) {
      return radio.value == event.target.value;
    })[0];
    setValue(selectedRadio.value);
    props.onChange(selectedRadio,props.id);
  };
  let radios = props.options.map((radio) => (
    <FormControlLabel
      key={radio.id || radio.value}
      value={radio.value}
      disabled={radio.disabled}
      control={<Radio color="primary" />}
      label={radio.label}
      labelPlacement={radio.labelPlacement}

    />
  ));
  return (
    <div style={{paddingTop:"10px" }}>
    <ThemeProvider theme={theme}>
    <FormControl component="fieldset" required={props.required} error={props.error} >
      <FormLabel component="legend" >{props.label} <span><A_FormHelper fieldHelperText={props.fieldHelperText}/></span></FormLabel>
      <RadioGroup
        color="primary"
        id={props.id}
        aria-label={props.areaLabel}
        name={props.name}
        row = {props.align == "horizontal" ? "true" : ""}
        value={value}
        onChange={handleChange}
        
      >
        {radios}
      </RadioGroup>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
    </ThemeProvider>
    </div>
  );
};
M_RadiosGroupConfig.defaultProps = {
  radios: [],
  areaLabel: "",
  name: "noname",
  defaultValue: "",
  row: true,
  value: "",
  disabled: false,
  label: "",
  labelPlacement: "start",
  fieldHelperText:""
};
M_RadiosGroupConfig.propTypes = {
  areaLabel: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  row: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onChange: PropTypes.func,
  fieldHelperText:PropTypes.string
};
export default M_RadiosGroupConfig;