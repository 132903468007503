 export const radioOptions = [
   {id : "AssetCo", value : "489c44e0-41c3-4687-a248-dd549477b311", name : "reportType", label : "AssetCo", labelPlacement : "end"},
   {id : "DevCo", value : "2efedfb5-8afb-4345-9344-68a3fc7e46a8", name : "reportType", label : "DevCo", labelPlacement : "end"},
 ];

 export const payload=[
   {
     "id": "1",
     "spName": "JlChart.Usp_GetCurrencyParam",
     "parameters": {
     }
   },{
     "id": "2",
     "spName": "JLChart.Usp_GetQuarterParam",
     "parameters": {
     }
   },{
     "id": "3",
     "spName": "JLChart.Usp_GetQuarterParam",
     "parameters": {
     }
   },{
     "id": "4",
     "spName": "JlChart.Usp_GetOpeningClosingBalance",
     "parameters": {
     }
   }
 ]
  const labels=[
    {
      "id": "1",
      "spName": "JlChart.Usp_GetCurrencyParam",
      "label" : "Currency",
    },{
      "id": "2",
      "spName": "JLChart.Usp_GetQuarterParam",
      "label" : "Quarter1",
      
    },{
    },{
      "id": "3",
      "spName": "JLChart.Usp_GetQuarterParam",
      "label" : "Quarter2",
    },{
      "id": "4",
      "spName": "JlChart.Usp_GetOpeningClosingBalance",
      "label" : "SortOrder"
    }
  ]

    const getLable=(id)=>labels.find(element => id===element.id);

 
 export function dataFun(data) {
    return Object.entries(data).map(([key, value]) => {
        const {name,label}=getLable(key)
        return {
            name:name,
            label:label,
            options: value
        }
    })
  }
