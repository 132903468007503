/* eslint-disable */
import { checklistActionTypes } from "../actions/actionTypes";

let initialState = {};
const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case checklistActionTypes.GET_CHECKLIST:
      return { getChecklistStarted: true };
    case checklistActionTypes.GET_CHECKLIST_SUCCEEDED:
      return action.payload;
    case checklistActionTypes.GET_CHECKLIST_FAILED:
      return action.payload;
   
    default:
      return state;
  }
};
export default checklistReducer;
