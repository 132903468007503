/* eslint-disable */
import { take, put } from "redux-saga/effects";
import { httpGet, httpPost } from "../../Utils/apis/apis";
import { checklistActionTypes } from "../actions/actionTypes";
import * as checklistActions from "../actions/checklistActions";
export function* getChecklistSaga() {
  while (true) {
    const action = yield take(checklistActionTypes.GET_CHECKLIST);
    const { pageUrl, workflowConfig } = action.payload;

    try {
      const res = yield httpPost(
        `${process.env.API_URL}/CRMChartData/getChecklistMasterData`,
        null
      );
      let convertedData = res === "Empty" ? [] : res;
      const fields = yield httpGet(
        `${process.env.API_URL}/Common/getChecklistMetaData?type=${workflowConfig}`
      );
      let payload = {
        sourceId: "",
        sourceLabel: pageUrl,
        label: pageUrl,
        fields: [pageUrl],
      };
      const itemsResponse = yield httpPost(
        `${process.env.API_URL}/CRMChartData/getGenericCRMData`,
        payload
      );
      let item = itemsResponse === "Empty" ? [] : itemsResponse;
      let workFlowData = fields === "Empty" ? [] : fields;
      yield put(
        checklistActions.getChecklistSucceeded({
          data: convertedData,
          workFlow: workFlowData,
          itemResponse: item,
          getChecklistStarted: false,
        })
      );
    } catch (e) {
      checklistActions.getChecklistFailed({
        msg: "Some error occured",
        getChecklistStarted: false,
      });
    }
  }
}
