
import * as httpCall from "../../../../../../Utils/apis/apis";

export default async function handleEmailUsers(emailString, user,id) {
  let response = {};
  let payload = {
    subject: "Testing Survey Email",
    recipients: emailString,
    templateId: 585,
    from: user,
    templateParam: {
      user: "Team",
      link: `https://dev.equipped.digital/esgresponse/${id}/3e58d657-d176-4198-91d9-56485899985d/`,
      buttonText: "Click here to redirect to survey",
    },
    queryParam: "",
  };

  try {
    let url = `${process.env.API_URL}/EmailNotification/send-email`;

    response = await httpCall.httpPost(url, payload);
    return response;
  } catch (e) {
    return (response = { message: e.message });
  }
}

export async function handleResponse(crmId) {
  let response = {};
  try {
  
    let url = `${process.env.PortalAdminAPIUrl}/api/Survey/esg/get?id=${crmId}`;

    response = await httpCall.httpGet(url);
    return response;
  } catch (e) {
    return (response = { message: e.message });
  }
}
export async function handleQuestionnaireUpdate(pay) {
  let response = {};
  let payload= pay
  try {
   
    let url = `${process.env.PortalAdminAPIUrl}/api/Survey/esg/update`;

    response = await httpCall.httpPut(url,payload);
    return response;
  } catch (e) {
    return (response = { message: e.message });
  }
}

export  async function handleResponseSave(pay) {
  let response = {};
  let payload = pay;
  try {
    let url = `${process.env.PortalAdminAPIUrl}/api/SurveyResponse/esg/insert`;

    response = await httpCall.httpPost(url, payload);
    return response;
  } catch (e) {
    return (response = { message: e.message });
  }
}
