import { read, utils } from "xlsx";
export const validateFileByTypes = (file, config) => {
  let { type, sheetName, cellName, cellValue } = config;
  switch (type) {
    case "file-password-protection":
      return passwordProtectionCheck(file);
    case "all-sheet-password-protection":
      return allSheetPasswordProtectionCheck(file);
    case "sheet-password-protection":
      return singleSheetPasswordProtectionCheck(file, sheetName);
    case "cell-name-validation":
      return cellNameValidation(file, sheetName, cellName, cellValue);
    default:
      return true;
  }
};

const passwordProtectionCheck = (file) => {
  try {
    const data = new Uint8Array(file);
    read(data, { type: "array" });
    return false;
  } catch (error) {
    return true;
  }
};
const allSheetPasswordProtectionCheck = (file) => {
  try {
    const data = new Uint8Array(file);
    const workbook = read(data, { type: "array" });
    const sheetNames = workbook.SheetNames;
    const protectedSheets = sheetNames.filter((sheetName) => {
      try {
        const sheet = workbook.Sheets[sheetName];
        utils.decode_range(sheet["!ref"]); // This line may trigger an error if sheet is protected
        return true;
      } catch (error) {
        return false;
      }
    });
    return protectedSheets.length === 0;
  } catch (error) {
    return false;
  }
};

const singleSheetPasswordProtectionCheck = (file, sheetName) => {
  try {
    const data = new Uint8Array(file);
    const workbook = read(data, { type: "array" });
    const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);
    return sheetData ? false : true;
  } catch (error) {
    return true;
  }
};

const cellNameValidation = (file, sheetName, cellName, cellValue) => {
  try {
    const data = new Uint8Array(file);
    const workbook = read(data, { type: "array" });
    const sheetData = workbook.Sheets[sheetName];

    var datesIndex = workbook.Workbook.Names.findIndex((elt) => {
      return elt.Name === cellName && elt.Sheet !== 0;
    });
    var myDates = workbook.Workbook.Names[datesIndex].Ref;
    myDates = myDates.split("!")[1];
    var cell = myDates.replaceAll("$", "");
    return sheetData[cell].v === cellValue;
  } catch (error) {
    return false;
  }
};
