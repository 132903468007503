/* eslint-disable */

import { createBrowserHistory } from "history";
const history = createBrowserHistory();
import { getCurrentUser, getClientBrowser } from "../Helpers/common";
let user = getCurrentUser();
let browser = getClientBrowser();
let browserInfo = `${browser.name} version ${browser.version}`;

//handle info logs
export const log_info = (msg) => {
  let msgRow = {
    category: "info",
    message: msg,
    datetime: new Date(),
    user: user.displayName,
    browser: browserInfo,
    url: history.location.pathname,
  };
  console.log(msgRow);
};

//handle warning logs
export const log_warning = (msg) => {
  let msgRow = {
    category: "warning",
    message: msg,
    datetime: new Date(),
    user: user.displayName,
    browser: browserInfo,
    url: history.location.pathname,
  };
  console.log(msgRow);
};

//handle error logs
export const log_error = (error, description) => {
  let msgRow = {
    error: error,
    description: description,
    datetime: new Date(),
    user: user.displayName,
    browser: browserInfo,
    url: history.location.pathname,
  };
  console.log(msgRow);
};
