import React from "react";
import { customDateFormat, FromEpochFormat } from "./dateUtils";

export const handleOptionSetValue = (
  value,
  sendLabel = true,
  epochToDate = false
) => {
  let tempValue = value;
  try {
    let tempValueAsJSON = JSON.parse(tempValue);
    let finalValue = {
      label: "",
      value: "",
    };
    if (Array.isArray(tempValueAsJSON)) {
      for (let option in tempValueAsJSON) {
        finalValue["label"] += `${option > 0 ? "," : ""} ${handleOptionSetValue(
          tempValueAsJSON[option]
        )}`;
        finalValue["value"] += `${option > 0 ? "," : ""} ${handleOptionSetValue(
          tempValueAsJSON[option],
          false
        )}`;
      }
    } else {
      finalValue = tempValueAsJSON;
    }
    tempValue = finalValue.title
      ? finalValue.title
      : sendLabel
      ? finalValue.label
      : finalValue.value;
  } catch {
    /* empty */
  }

  if (tempValue) {
    return tempValue;
  } else if (
    epochToDate &&
    tempValue === undefined &&
    typeof value == "number" &&
    `${value}`.length >= 10
  ) {
    return customDateFormat(FromEpochFormat(value), "MMMM YYYY");
  } else {
    return value;
  }
};
export const currency = {
  "pound sterling": "£ ",
  rupee: "₹ ",
  euro: "€ ",
  "us dollar": "$ ",
  "dansk krone": "kr ",
  "svensk krona": "kr ",
  "schweizer franken": "CHF ",
  "new zealand dollar": "$ ",
  złoty: "zł ",
  "norwegian krone": "kr ",
  leu: "lei ",
  "canadian dollar": "$ ",
  "australian dollar": "$ ",
  "koruna česká": "Kč ",
  usd: "$ ",
  aud: "A$ ",
  eur: "€ ",
  gbp: "£ ",
  aed: "د.إ ",
  ars: "$ ",
  bdt: "৳ ",
  bgn: "лв ",
  brl: "R$ ",
  cad: "$ ",
  chf: "₣ ",
  clp: "$ ",
  cny: "¥ ",
  czk: "Kč ",
  dkk: "kr ",
  egp: "E£ ",
  hkd: "$ ",
  hrk: "kn ",
  huf: "Ft ",
  ils: "₪ ",
  inr: "₹ ",
  isk: "kr ",
  jpy: "¥ ",
  kes: "KSh ",
  kzt: "₸ ",
  mxn: "$ ",
  myr: "RM ",
  ngn: "₦ ",
  nok: "kr ",
  nzd: "$ ",
  pen: "S/ ",
  php: "₱ ",
  pkr: "Rs ",
  pln: "zł ",
  ron: "lei ",
  rub: "₽ ",
  sar: "ر.س ",
  sek: "kr ",
  sgd: "$ ",
  thb: "฿ ",
  try: "₺ ",
  uah: "₴ ",
  zar: "R ",
};
export const getIcon = (crmData, val) => {
  let icon, value;
  try {
    value = JSON.parse(crmData.currency || crmData.Currency);
    value = value[0].title.toLowerCase();
  } catch (e) {
    value =
      crmData && crmData.currency
        ? crmData.currency.toLowerCase()
        : crmData.Currency.toLowerCase();
  }
  var newVal = handleOptionSetValue(value, false);
  icon = currency[newVal] == undefined ? "" : currency[newVal];
  try {
    let orgValue = val;
    if (!isNaN(val)) {
      val = val ? Number(val).toLocaleString("en-GB") : "";
    } else {
      val = orgValue;
    }
  } catch {
    /* empty */
  }
  return (
    <>
      {val ? icon : ""}
      {val}
    </>
  );
};
const currencywithCommaSeprationPoint = [
  {
    id: "pound sterling",
    CommaSeprationPoint: 3,
  },
  {
    id: "rupee",
    CommaSeprationPoint: 2,
  },
  {
    id: "euro",
    CommaSeprationPoint: 3,
  },
  {
    id: "us dollar",
    CommaSeprationPoint: 3,
  },
  {
    id: "dansk krone",
    CommaSeprationPoint: 3,
  },
  {
    id: "svensk krona",
    CommaSeprationPoint: 3,
  },
  {
    id: "schweizer franken",
    CommaSeprationPoint: 3,
  },
  {
    id: "new zealand dollar",
    CommaSeprationPoint: 3,
  },
  {
    id: "złoty",
    CommaSeprationPoint: 3,
  },
  {
    id: "norwegian krone",
    CommaSeprationPoint: 3,
  },
  {
    id: "leu",
    CommaSeprationPoint: 3,
  },
  {
    id: "canadian dollar",
    CommaSeprationPoint: 3,
  },
  {
    id: "australian dollar",
    CommaSeprationPoint: 3,
  },
  {
    id: "koruna česká",
    CommaSeprationPoint: 3,
  },
  {
    id: "usd",
    CommaSeprationPoint: 3,
  },
  {
    id: "aud",
    CommaSeprationPoint: 3,
  },
  {
    id: "eur",
    CommaSeprationPoint: 3,
  },
  {
    id: "gbp",
    CommaSeprationPoint: 3,
  },
  // Add more currencies as needed
];

export const currencyIconWithCommaSepration = (type, val) => {
  let icon = currency[type] == undefined ? "" : currency[type];
  const currencyInfo = currencywithCommaSeprationPoint.find(
    (currency) => currency.id === type
  );
  const commaSeprationPoint = currencyInfo
    ? currencyInfo.CommaSeprationPoint
    : 3;
  if (commaSeprationPoint && val) {
    //const userLocale = navigator.language || "en-US";
    const userLocale = "en-US";
    val = Number(val).toLocaleString(
      commaSeprationPoint == 2 ? "en-IN" : userLocale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  }
  return (
    <>
      {val ? icon : ""}
      {val}
    </>
  );
};
