/* eslint-disable */
import React, { useState } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { httpPost } from "../../../../../Utils/apis/apis";
import { customDateFormat, FromEpochFormat } from "../../../../../Utils/Helpers/dateUtils";

const useStyles = makeStyles((theme) => ({
  container: {},
  left: {
    float: "left",
    width: "80%",
  },
  right: {
    float: "right",
  },
  hideSchedule: {
    display: "none",
  },
  alignSchedule: {
    marginTop: "12px",
  },
}));
function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'short',
  });
}

export default function O_activeFacility(props) {
  const classes = useStyles();
  let { crmId, pageUrl } = useParams();
  const [data, setData] = useState({});


  React.useEffect(() => {
    apiCall();
  }, []);
  const nextPaymentDate = () => {
    if (data.firstPaymentDate && data.paymentFrequency) {

      var firstPaymentDate = new Date(data.firstPaymentDate * 1000); // Thu Apr 09 2020 14:28:32 GMT+0100 (British Summer Time)
      let firstPaymentYear = firstPaymentDate.getFullYear(); // 2020
      let firstPaymentMonth = firstPaymentDate.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
      var currentDate = new Date(); // Thu Apr 09 2020 14:28:32 GMT+0100 (British Summer Time)
      let currentDateYear = currentDate.getFullYear(); // 2020
      let currentDateMonth = currentDate.getMonth() + 1; // 4 (note zero index: Jan = 0, Dec = 11)
      if (currentDateYear - firstPaymentYear > 0) {
        currentDateMonth = currentDateMonth + ((currentDateYear - firstPaymentYear) * 12);
      }

      let differenceMonth = currentDateMonth - firstPaymentMonth;

      if (differenceMonth > JSON.parse(data.paymentFrequency)) {

        let ceilValue = Math.ceil(differenceMonth / JSON.parse(data.paymentFrequency));
        let month = ceilValue * JSON.parse(data.paymentFrequency);
        let nextPaymentYear = Math.floor((month / 12) + firstPaymentYear);
        let nextPaymentMonth = (month % 12) + firstPaymentMonth;
        data.nextPaymentDate = toMonthName(nextPaymentMonth) + "-" + nextPaymentYear
      }
      else if (differenceMonth < JSON.parse(data.paymentFrequency)) {
        let nextPaymentMonth = (JSON.parse(data.paymentFrequency) + firstPaymentMonth) % 12;
        let xcyear = Math.floor((JSON.parse(data.paymentFrequency) + firstPaymentMonth) / 12);
        if (nextPaymentMonth === 0) {
          nextPaymentMonth = firstPaymentMonth;
        }
        data.nextPaymentDate = toMonthName(nextPaymentMonth) + "-" + (xcyear + firstPaymentYear);
      }
      else {
        data.nextPaymentDate = customDateFormat(currentDate, 2);
      }
    }

    return data.nextPaymentDate ? data.nextPaymentDate : null;
  };

  const apiCall = async () => {
    let convertedFormData = {
      sourceId: crmId,
      sourceLabel: pageUrl,
      label: "lendersapproached",
      fields: [],
    };



    const response = await httpPost(
      `/CRMChartData/getGenericCRMData`,
      convertedFormData
    );
    let filtered = response.filter((item) => item.approval === "Approved");
    if (filtered.length > 0) {
      setData(filtered[0]);
    }
  };

  return (
    <>
    
      <section className={classes.container}>
        <h5>Parties</h5>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Borrower
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {data.borrower ? data.borrower : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Guarantor
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {data.guarantor ? data.guarantor : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Lender(s)
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.lender ? data.lender2 ? data.lender + "," + data.lender2 : data.lender : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Commitment
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.commitmentDetails ? (
              <div
                dangerouslySetInnerHTML={{ __html: data.commitmentDetails }}
              ></div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Divider></Divider>
        <br />
        <h5>Details</h5>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Facility Start Date
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.facilityStartDate
              ? customDateFormat(FromEpochFormat(data.facilityStartDate), 2)
              : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Maturity
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.maturity ? customDateFormat(FromEpochFormat(data.maturity), 2) : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Fixed/Floating
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.fixedFloating ? data.fixedFloating : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Benchmark Index
            </Typography>
          </Grid>
          <Divider></Divider>
          <Grid item xs={7}>
            {data.benchmarkIndex ? data.benchmarkIndex : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Payment Frequency
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.paymentFrequency ? data.paymentFrequency : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Next Payment Date
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {
              data && data.firstPaymentDate && data.paymentFrequency ? nextPaymentDate() : ""
            }
          </Grid>
        </Grid>
        <Divider></Divider>
      </section>
    </>
  );
}
