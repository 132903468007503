import { take, put, select } from "redux-saga/effects";
import { httpGet, httpPost } from "../../Utils/apis/apis";
import { manageExcelTemplateActionType } from "../actions/actionTypes";
import * as manageExcelTemplateActions from "../actions/manageExcelTemplateActions";

import { manageExcelTemplateDownloadActionType } from "../actions/actionTypes";
import * as manageExcelTemlateDownloadActions from "../actions/manageExcelTemlateDownloadActions";

export function* saveTabSaga() {
  while (true) {
    const action = yield take(manageExcelTemplateActionType.SAVE_TABS);
    const { data, investmentId } = action.payload;
    console.log("save excel template called is called:", action.payload);

    const state = yield select();
    try {
      const res = yield httpPost(
        `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/update/${investmentId}`,
        data
      );

      // yield put(

      //     manageExcelTemplateActions.setTabs(res.data)
      // );
      yield put(
        manageExcelTemplateActions.saveTabsSuccessed({
          data: res,
          saveTabsSuccessed: true,
        })
      );
    } catch (e) {
      // manageExcelTemplateActions.saveTabsFailed({msg: 'Some error occured',currentPageSaved: true})
      // console.log("can not save template.");
      // console.log(e.message);

      yield put(
        manageExcelTemplateActions.saveTabsFailed({
          data: data,
          saveTabsSuccessed: true,
          errorMessage:
            e && e.response && e.response.data && e.response.data.Message,
        })
      );
    }
  }
}

export function* getTabsSaga() {
  while (true) {
    const action = yield take(manageExcelTemplateActionType.GET_TABS);
    const { investmentId } = action.payload;
    console.log("get excel template called is called:", action.payload);

    const state = yield select();
    try {
      const res = yield httpGet(
        `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/${investmentId}`
      );

      yield put(manageExcelTemplateActions.setTabs(res));
      yield put(manageExcelTemplateActions.getTabsSuccessed(res));
    } catch (e) {
      manageExcelTemplateActions.getTabsFailed({
        msg: "Some error occurred",
        currentPageSaved: true,
      });
      console.log("can not save template.");
      console.log(e.message);
    }
  }
}

export function* downloadExcelSaga() {
  while (true) {
    const action = yield take(
      manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL
    );
    const {
      seriesList,
      series,
      frequency,
      fromYear,
      incrementMonth,
      dealId,
      startMonth,
      requestPayload,
      InvestmentName,
      is_financial_year
    } = action.payload;

    console.log("get excel template called is called:", action.payload);

    const state = yield select();
    try {
      const res = yield httpPost(
        `${process.env.EXCEL_UPLOAD_API_URL}/ExcelTemplateGeneration`,
        {
          ...requestPayload,
          category: requestPayload.Tabs,
          series_list: seriesList,
          series,
          frequency,
          year: fromYear,
          increment: incrementMonth,
          investment_id: dealId,
          startmonth: startMonth,
          investment_name: InvestmentName,
          is_financial_year:is_financial_year
        },
        {
          responseType: "blob",
          headers: {
            "x-functions-key": process.env.EXCEL_UPLOAD_API_KEY,
          },
        }
      );

      // var blob = new Blob([res.data],{type:headers['application/xlsx']});
      // var link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "Your_file_name";
      // link.click();

      yield put(
        manageExcelTemlateDownloadActions.downloadExcelSuccessed({
          year: fromYear,
          data: res,
          downloadExcelSuccessed: true,
          InvestmentName: InvestmentName,
        })
      );
    } catch (e) {
      yield put(
        manageExcelTemlateDownloadActions.downloadExcelFailed({
          msg: "Some error occurred",
          downloadFailed: true,
        })
      );
    }
  }
}
