import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as httpCall from "../../../../Utils/apis/apis";
import { useParams } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import SearchinRecord from "./SearchinRecord";
import {
  FormControl,
  RadioGroup,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },

  searchBox: {
    left: 120,
  },
}));

const RecordMergeTable = (props) => {
  let searchVariable = props.searchOn;
  let { pageUrl, crmId, templateId } = useParams();
  const classes = useStyles();
  const [secCRMId, setSecCRMId] = useState("");
  const [defaultValue, setDefaultValue] = React.useState([]);
  const [finalSearchData, setFinalSearchData] = React.useState([]);
  const [recordMergeData, setRecordMergeData] = React.useState([]);

  var finalList = [];
  const [value, setValue] = React.useState({});

  const handleOptions = (key, e) => {
    value[key] = e.target.value;

    setValue(value);
  };

  React.useEffect(() => {
    const empObj = {};
    var mainFunction = compareFunction();
    setRecordMergeData(mainFunction);
    finalList.map(
      (item) => (empObj[Object.keys(item)[0]] = item[Object.keys(item)[0]])
    );
    setValue(empObj);
  }, []);

  const handledata = async (value) => {
    if (value != null) {
      let requestParam = {
        label: pageUrl,
        properties: {
          id: value.id,
        },
      };
      setSecCRMId(value.id);

      let url = "/CRMData/getRecordByProperty";
      let requestPayload = requestParam;
      const result = await httpCall.httpPost(url, requestPayload);

      setFinalSearchData(result);
      var def = result.map((item) => item.properties.projectName);
      setDefaultValue(def);
    }
  };

  const handleConfirm = async () => {
    let requestParam = {
      pageId: props.pageId,
      primary: crmId,
      secondary: secCRMId,
      properties: value,
      edges: [],
    };

    let url = "/CRMData/mergeCRMRecord";
    let requestPayload = requestParam;
    const result = await httpCall.httpPost(url, requestPayload);

    setFinalSearchData(result);
    location.reload();
  };

  const mainData = props.secondary.map((item) => item.name);
  var secondaryDatakeys;
  var secondDataFilter = [];
  var secondList = [];

  var keys = props && props.primaryData ? Object.keys(props.primaryData) : null;

  function EnhancedTableHead(props) {

    return (
      <TableHead>
        <TableRow>
          <TableCell>Merge Record</TableCell>
          <Grid container>
            <Grid item sm={6}>
              <TableCell style={{ minWidth: "320px" }}>
                {" "}
                <Autocomplete
                  disabled
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Record"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </TableCell>
            </Grid>
            <Grid>
              <TableCell>
                <SearchinRecord
                  seachValue={props}
                  handledata={handledata}
                  value={defaultValue.length > 0 ? defaultValue.join("") : ""}
                />
              </TableCell>
            </Grid>
          </Grid>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,

    rowCount: PropTypes.number.isRequired,
  };

  var filteredData = mainData.filter(function (e) {
    return keys && keys.indexOf(e) > -1;
  });
  finalList = filteredData.map((item) => {
    let obj = {};

    obj[item] = props.primaryData[item];
    //let values =  obj[item]

    return obj;
  });

  var secondaryData =
    finalSearchData != "success"
      ? finalSearchData && finalSearchData.map((item) => item.properties)
      : "";
  {
    if (secondaryData.length > 0) {
      secondaryDatakeys = Object.keys(secondaryData[0]);
      secondDataFilter = mainData.filter(function (e) {
        return secondaryDatakeys.indexOf(e) > -1;
      });
      secondList = secondDataFilter.map((item) => {
        let obj1 = {};

        obj1[item] = secondaryData[0][item];
        //let values =  obj[item]
        return obj1;
      });

      var mergedRows = [...finalList, ...secondList];
      var test = mergedRows.map((item) => {
        for (var i in item) {
          var label;
          item.label = i;
          return item;
        }
      });
    }
  }

  var keyList = [];
  for (let i in finalList) {
    var key = Object.keys(finalList[i]);
    keyList.push(key[0]);
  }

  const compareFunction = () => {
    var mainArray = [];
    var Primarykeys =
      props && props.primaryData ? Object.keys(props.primaryData) : null;

    var secondaryData =
      finalSearchData != "success"
        ? finalSearchData.map((item) => item.properties)
        : "";
    if (secondaryData.length > 0) {
      secondaryDatakeys = Object.keys(secondaryData[0]);
      secondDataFilter = mainData.filter(function (e) {
        return secondaryDatakeys.indexOf(e) > -1;
      });
    }

    var allKeys = [...keyList, ...secondDataFilter];

    let unique = [...new Set(allKeys)];
    const getData = (key, array) => {
      const found = array.find((element) => element[key] != undefined);

      return found && found[key] ? found[key] : "";
    };
    for (var i in unique) {
      let initialdata = {
        key: unique[i],
        primary: getData(unique[i], finalList),
        secondary: getData(unique[i], secondList),
      };
      mainArray.push(initialdata);
    }

    return mainArray;
  };
  const renderCell = (item) => {
    for (let rowName in item) {
      return (
        <TableRow hover>
          <TableCell style={{ width: "160px" }}>{item.key}</TableCell>
          <TableCell colSpan="2">
            <RadioGroup
              defaultValue={item.primary}
              row
              style={{ justifyContent: "space-around" }}
            >
              <Grid container>
                <Grid item sm={6}>
                  <FormControl>
                    <FormControlLabel
                      value={item.primary}
                      id={item.key}
                      control={
                        <Radio
                          onChange={(event) => handleOptions(item.key, event)}
                        />
                      }
                      label={item.primary}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  {item.secondary != "" ? (
                    <FormControl>
                      <FormControlLabel
                        value={item.secondary}
                        id={item.key}
                        control={
                          <Radio
                            onChange={(event) => handleOptions(item.key, event)}
                          />
                        }
                        label={item.secondary}
                      />
                    </FormControl>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </RadioGroup>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Merge Records
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead seachOn={searchVariable} classes={classes} />
          <TableBody>
            {compareFunction().map((row, index) => {
              return renderCell(row);
            })}
          </TableBody>
        </Table>
        <div style={{ textAlign: "center", padding: "12px" }}>
          <A_Button
            color="primary"
            label="Cancel"
            onClick={props.handleClosePopup}
          />
          <A_Button onClick={handleConfirm} color="primary" label="Ok" />
        </div>
      </TableContainer>
    </div>
  );
};
export default RecordMergeTable;
