const translationGer = {
    "Introduction": "Introduction",
    "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop": "is an internationalization-framework which offers a complete solution to localize your product from web to mobile and desktop",
    "Plugins to detect the user language": "Plugins to detect the user language",
    "Plugins to load translations": "Plugins to load translations",
    "Optionally cache the translations": "Optionally cache the translations",
    "Advantages": "Advantages",
    "Flexibility to use other packages": "Flexibility to use other packages",
    "Welcome":"Herzlich willkommen",
    "WelcomeText":"wenn Sie diese Seite sehen. OIDC wurde erfolgreich eingerichtet",
    "ProfileSettings":"Profileinstellung",
    "ApplicationSettings":"Anwendungseinstellungen",
    "ChangePassword":"Passwort ändern",
    "Logout":"Ausloggen"
}
export default translationGer