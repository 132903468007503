export const ircSlotsEnums={
    OTHER:"other",
    ADD:"add",
    IRC_DATE:"ircDate",
    IRC_PORTAL:"ircportal",
    OPPORTUNITY_ID:"opportunityId",
    DATE_FORMATE:"MMM DD yyyy HH:mm",
    GTE:"gte",
    IRC_PURPOSE: 'IRC Purpose',
    OTHER_IRC_PURPOSE: 'Other IRC Purpose',
    NO_SLOT_AVALIABLE:"No Slots available"
}

export const IS_PE=[{ Id: 5, label: "Other", value: "Other" }]

export const IS_NOT_PE=[
    {
      Id: 1,
      label: "PIR / PIT",
      value: "PIR / PIT",
    },
    {
      Id: 2,
      label: "Project Update",
      value: "Project Update",
    },
    {
      Id: 3,
      label: "Binding Offer Approval",
      value: "Binding Offer Approval",
    },
    {
      Id: 4,
      label: "Signing Approval",
      value: "Signing Approval",
    },
    {
      Id: 5,
      label: "Other",
      value: "Other",
    },
  ]

export const RESULT= {
    label: "ircslots",
    fields: [],
    filter: {
      field: "ircDate",
      operator: "gte",
    },
  }

export const RESULT2= {
    label: "ircportal",
    fields: [],
    filter: {
      field: "ircDate",
      operator: "gte",
    },
  }

export const FIELDS=["ircDate", "deal", "opportunityId", "investmentId"]