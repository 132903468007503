import React from "react";
import { connect } from "react-redux";
import { SETTING_MESSAGE } from "./applicationSettings.Helper";

function ApplicationSettings() {
  return <h3>{SETTING_MESSAGE}</h3>;
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings);
