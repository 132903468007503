/* eslint-disable */

import { irReportActionType } from "./actionTypes";

export const getIrReportValue = (payload) => {
  return {
    type: irReportActionType.REQUEST_IRREPORT_VALUE,
    payload,
  };
};

export const getIrReportValueSuccess = (payload) => {
  return {
    type: irReportActionType.GET_IRREPORT_VALUE_SUCCESSED,
    payload,
  };
};

export const getIrReportMailingListSuccess = (payload) => {
  return {
    type: irReportActionType.GET_IRREPORT_MILING_LIST_SUCCESSED,
    payload,
  };
};

export const getIrReportValueFail = (payload) => {
  return {
    type: irReportActionType.GET_IRREPORT_VALUE_FAIL,
    payload,
  };
};

export const getIrReportMailingListFail = (payload) => {
  return {
    type: irReportActionType.GET_IRREPORT_MILING_LIST_FAIL,
    payload,
  };
};

export const irReportToggleOn = () => {
  return {
    type: irReportActionType.IRREPORT_TOGGLE_VALUE_ON,
  };
};
export const irReportToggleOff = () => {
  return {
    type: irReportActionType.IRREPORT_TOGGLE_VALUE_OFF,
  };
};
