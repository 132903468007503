import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import {
  customDateFormat,
  FromEpochFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "222px",
    [theme.breakpoints.down("sm")]: {
      width: "222px",
    },
    //margin: theme.spacing(1),
    marginTop: 5,
  },
  selectLabel: {
    //marginTop: 7,
    color: "primary",
    marginTop: "-1.8px",
    // paddingLeft:"8px"
    // commenting this code.keeping this for future
    // refrence as it is a atoms which will be used in multiple places
  },
  fullWidth: {
    width: "100%",
  },
  select: {
    fontSize: "15px",
    height: "30px",
  },
  minWidth: {
    width: "180px",
  },
  helperIcon: {
    "& svg": {
      marginRight: "20px",
    },
    "& .helperIcon": {
      marginRight: "0px",
    },
  },
}));
const A_Select = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    handleOptionSetValue(props.defaultValue, false, true)
  );
  const handleChange = (event) => {
    if (event.target.value !== "") {
      let selectedOption = props.options.filter(function (option) {
        return handleOptionSetValue(option.value, false) == event.target.value;
      })[0];
      setValue(selectedOption.value);
      if (
        props.id === "reportSection" &&
        props.label === "Report Section" &&
        props.optionSet === "26"
      ) {
        props.onChange(selectedOption, props.id, true);
      } else {
        props.onChange(selectedOption, props.id, false);
      }
    } else {
      setValue(event.target.value);
      props.onChange({ value: event.target.value }, props.id);
    }
  };

  let options = props.options.map((option) => (
    // if(props.displayEmpty){

    // }
    <MenuItem
      key={handleOptionSetValue(option.value, false)}
      value={handleOptionSetValue(option.value, false)}
    >
      {handleOptionSetValue(option.label)}
    </MenuItem>
  ));
  let optionsNative = () => {
    let options = props.options.map((option) => (
      <option
        key={handleOptionSetValue(option.value, false)}
        value={handleOptionSetValue(option.value, false)}
      >
        {handleOptionSetValue(option.label)}
      </option>
    ));
    if (props.displayEmpty) {
      options.unshift(<option key={null} value=""></option>);
    }
    if (
      props.label === "Date" &&
      typeof props.defaultValue == "number" &&
      `${props.defaultValue}`.length >= 10 &&
      options.every((option) => {
        return !customDateFormat(
          FromEpochFormat(props.defaultValue),
          "MMMM YYYY"
        ).includes(option.props.children);
      })
    ) {
      let dateOption = customDateFormat(
        FromEpochFormat(props.defaultValue),
        "MMMM YYYY"
      );
      dateOption &&
        options.push(
          <option
            key={handleOptionSetValue(dateOption, false, true)}
            value={handleOptionSetValue(dateOption, false, true)}
          >
            {handleOptionSetValue(dateOption, false, true)}
          </option>
        );
    }
    return options;
  };

  let showGroupedOptions = () => {
    let options = props.groupedOptions.map((groupedOption) => (
      <>
        <option aria-label="None" value="" />
        <optgroup label={groupedOption.title} key={groupedOption.title}>
          {groupedOption.options
            ? groupedOption.options.map((option) => (
                <option
                  key={handleOptionSetValue(option.value, false)}
                  value={handleOptionSetValue(option.value, false)}
                >
                  {handleOptionSetValue(option.label)}
                </option>
              ))
            : ""}
        </optgroup>
      </>
    ));
    return options;
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={props.error}
        key={"formcontrol" + props.id}
        id={"formcontrol" + props.id}
        variant={props.variant}
        className={`${
          props.className ? props.className : classes.formControl
        } ${props.fullWidth ? classes.fullWidth : ""} ${
          props.minWidth ? classes.minWidth : ""
        }`}
        required={props.required}
        style={props.style}
      >
        <InputLabel
          className={classes.selectLabel}
          key={props.id + "_label"}
          id={props.id + "_label"}
        >
          {props.label}
        </InputLabel>
        <Select
          key={props.id}
          id={props.id}
          labelId={props.id + "_label"}
          value={
            props.isCascading
              ? handleOptionSetValue(props.defaultValue, false, true)
              : value && handleOptionSetValue(value)
          }
          native={props.native}
          style={props.style}
          //variant={props.variant}
          endAdornment={
            <InputAdornment position="end">
              <A_FormHelper fieldHelperText={props.fieldHelperText} />
            </InputAdornment>
          }
          disabled={props.disabled}
          required={props.required}
          autoWidth={props.autoWidth}
          displayEmpty={props.displayEmpty}
          inputProps={{
            readOnly: props.readOnly,
            style:
              props.inputProps && props.inputProps != ""
                ? props.inputProps
                : {},
          }}
          color={props.color}
          className={`${props.className} ${classes.select} ${
            props.fieldHelperText ? classes.helperIcon : ""
          }`}
          onChange={handleChange}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {props.groupedOptions
            ? showGroupedOptions()
            : props.native
            ? optionsNative()
            : options}
        </Select>
        <FormHelperText key={"helptext" + props.id} id={"helptext" + props.id}>
          {props.helperText}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
A_Select.defaultProps = {
  id: "xxxx",
  //label: "Select",
  value: "",
  variant: "outlined",
  color: "primary",
  inputProps: "",
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  error: false,
  required: false,
  options: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  native: true,
  style: {},
  fieldHelperText: "",
  isCascading: false,
};
A_Select.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  className: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.array,
  native: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.any,
  groupedOptions: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  fullWidth: PropTypes.any,
  minWidth: PropTypes.any,
  optionSet: PropTypes.any,
  inputProps: PropTypes.any,
  fieldHelperText: PropTypes.string,
  isCascading: PropTypes.bool,
};
export default A_Select;
