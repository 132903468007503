export const guidelineData = [
  {
    state: "Developing",
    guideline:
      "Requirements for moving this deal to pipeline:<br/>Willing seller<br/>Indicative valuation<br/>Business plan that meets minimum investment size and returns<br/>",
  },
  {
    state: "Pipeline",
    guideline:
      "Requirements for moving this deal to priority:<br/>Willing Seller<br/>Defined price range<br/>Defined process<br/>Investable management team and business plan<br/>Detailed information available<br/>",
  },
  {
    state: "Priority",
    guideline:
      "Requirements for moving this deal to completion:<br/>Deal terms agreed<br/>Structuring and legal<br/>",
  },
  {
    state: "Completion",
    guideline: "Requirements for moving this deal to portfolio :<br/>",
  },
];

export const dealStatusEnums = {
  COMPLETION: "Completion",
  KILLED: "Killed",
  TRACKING: "Tracking",
  DEAD: "Dead",
  CONFIRM_KILL: "Are you sure you want to kill this deal?",
  REASON_LABEL: "Reason to kill",
};

export const changeButtonLabel = (currentLabel, content) => {
  try {
    return content.dealStatusLabels[currentLabel] || currentLabel;
  } catch (error) {
    //please remove this line and log error in case of debugging
  }
  return currentLabel;
};
export const changeButtonValue = (currentLabel, content) => {
  try {
    return content.dealStatusValues[currentLabel] || currentLabel;
  } catch (error) {
    //please remove this line and log error in case of debugging
  }
  return currentLabel;
};
