 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import theme from "../../../../themes/defaultTheme";

const A_Drawer = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor={props.anchor} open={props.open} onClose={props.onClose}>
        {props.content}
      </Drawer>
    </ThemeProvider>
  );
};
A_Drawer.defaultProps = {
  open: false,
};
export default A_Drawer;
