/* eslint-disable */
import React from "react";
import { Grid } from "@material-ui/core";
import { updateStatus } from "./Helper/ConsentRequest.helper";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import propTypes from "prop-types";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import { status } from "../../../Organisms/Common/ConsentRequest/ConsentRequest.const";
function M_AssignmentScreen({ getCurrentPage, content }) {
  const { crmId, pageUrl } = useParams();

  const handleLookup = async (selectedValue, id) => {
    let edgeObj = {};
    if (selectedValue.data && selectedValue.data.id)
      edgeObj["label"] =
        content && content.approver && content.approver.edgeLabel
          ? content.approver.edgeLabel
          : "has_approver";
    edgeObj["guid"] = [selectedValue.data.id];
    let triggerEmail = {
      emailCRMIds: edgeObj["guid"],
      emailConfigId: getObjectFromJSONString(
        content,
        ".approver.emailTemplateId",
        ""
      ),
    };
    await updateStatus(
      { requestStatus: status.PENDING, status: status.WAITING_FOR_APPROVAL },
      crmId,
      pageUrl,
      [edgeObj],
      triggerEmail
    );
    getCurrentPage({ pageUrl, crmId });
  };
  return (
    <div>
      <Grid container>
        <Grid item xs="12">
          <A_Autocomplete
            fullWidth={true}
            size="medium"
            entity={{}}
            variant="standard"
            label={"Approver"}
            onChange={handleLookup}
            multiple={
              content.approver && content.approver.multiple
                ? content.approver.multiple
                : false
            }
            id="approver"
            resultTemplate={content.approver && content.approver.resultTemplate}
            destinationVertex={
              content.approver && content.approver.destinationVertex
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
M_AssignmentScreen.propTypes = {
  content: propTypes.object,
  getCurrentPage: propTypes.any,
  id: propTypes.any,
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_AssignmentScreen);
