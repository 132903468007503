import React, { useEffect } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";

const CommonFilter = (props) => {
  const { selectedDataValue, list, setSelectedDataValue, style, defaultText ,heading, headerStyle} =
    props;

  const handleChange = (event) => {
    setSelectedDataValue(event.target.value);
  };

  useEffect(() => {
    if (selectedDataValue == "") {
      setSelectedDataValue(defaultText);
    }
  }, [selectedDataValue]);

  return (
    <>{ heading &&
      <div  style={headerStyle} 
       >{heading}</div> }
    <div style={style}>
      <FormControl>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={selectedDataValue !== "" ? selectedDataValue : defaultText}
          onChange={handleChange}
          style={{ width: "125px", marginTop: "2px", paddingLeft: "14px" }}
          disableUnderline
        >
          {list &&
            list.map((item) => {
              return (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
    </>
  );
};

CommonFilter.propTypes = {
  selectedDataValue: PropTypes.string,
  list: PropTypes.any,
  style: PropTypes.any,
  defaultText: PropTypes.string,
  setSelectedDataValue: PropTypes.func,
  heading:PropTypes.string,
  headerStyle:PropTypes.any
};
export default CommonFilter;
