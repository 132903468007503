export const ApiEndpoints = {
  GET_PILLAR: "/esg/question/questioncategory",
  GET_SUBPILLAR: "/esg/question/questionsubcategory/",
  GET_MATURITY_ASSESSMENT: "/esg/question/maturityassessment",
  GET_PAI_FRAMEWORK: "/esg/question/paiframework",
  GET_DATA_CONVERGENCE: "/esg/question/dataconvergenceframework",
  GET_QUESTION_ANS_TYPE: "/esg/question/questionanswertype",
  ADD_QUESTION: "/esg/question",
  GET_PARENT_QUESTION: "/esg/question/parent",
  UPDATE_QUESTION: "/esg/question/",
  SURVEY_QUESTION: "/esg/survey",
  ADD_SURVEY: "/esg/survey",
  GET_BOOLEANTYPE_QUESTION: "/esg/question/survey",
  GET_SURVEY: "/esg/survey/",
  POST_SURVEY_QUESTION_MAP: "/esg/survey/questions",
  PUT_SURVEY_QUESTION_MAP: "/esg/survey",
  DELETE_QUESTION: "/esg/question/",
  PUBLISH_SURVEY: "/esg/survey",
  GET_INVESTMENT: "/esg/investment",
  GET_INVESTMENT_CRMID: "/esg/investment/crm/",
  GET_RESPONSE_APPROVAL_ASSETID: "/esg/responseapproval/investment/",
  RESPONSE_APPROVAL_SURVEYID: "/esg/responseapproval/survey/",
  RESPONSE_APPROVAL: "/esg/responseapproval",
  RESPONSE_QUESTION: "/esg/response",
  GET_RESPONSE_QUESTION: "/esg/response/survey",
  GET_REPORT_DATA: "/esg/response/report",
  GET_RESPONDENTACTIONPLAN:"/esg/score/actionplan",
  PUT_RESPONDENTACTIONPLAN:"/esg/score/respondentactionplan",
  GET_OTHER_INVESTMENT:"/esg/survey/investment",
  GET_SCORE:'/esg/score',
  PUT_SCORE:'/esg/score/actionplan',
  GET_SCORE_SUMMARY:"/esg/score/scoresummary",
  PUT_SCORE_NEXTPLAN:"/esg/score/scoresummary",
  GET_BEST_PRACTICE: "/esg/score/investment/bestpractices",
  GET_CURRENT_PRACTICE: "/esg/score/currentpractices",
  GET_CURRENT_PRACTICE_CHART_DATA: "/esg/score/currentpractices",
  PUT_SURVEY_FREEZE:"/scorefreeze",
  GET_MATURITY_MATRIX: "/esg/score/maturitymatrix",
  PUT_SCORE_LOGIC:"/esg/score",
  GET_SCORE_LOGIC:"/esg/score",
};
