export const fieldType = {
  DOCUMENT: "document",
  DATE: "date",
  INTEGER: "integer",
  TABLE: "table",
  BIT: "bit",
  OPTIONSET: "optionset",
  LOOKUP: "lookup",
  KEYDOCUMENTS:"documents"
};
