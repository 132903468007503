export const css = {
  content: {
    padding: "0 1rem",
  },
  link: {
    color: "#337AB7",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
  },
  ircHead: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  selected: {
    backgroundColor: "rgba(1,1,1,0.2)",
  },
  unSelected: {
    backgroundColor: "rgba(1,1,1,0.05)",
  },
  buttonMargin: {
    margin: "1.2rem",
    // border:"1px solid black"
    "box-shadow": "1px 1px 2px #acacac",
  },
  innerBox: {
    padding: "0.4rem",
    marginRight: "1 rem",
  },
  icon: {
    height: "60px",
    width: "60px",
    borderRadius: "0.4rem",
    objectFit: "contain",
  },
};
