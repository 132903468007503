// Custom Validation for Veld Deal Form

export const FixingData = (fieldData, allData, fieldDataNext, Fid, Sid) => {
  if (fieldData.id === Fid) {
    if (
      allData &&
      allData[Sid] &&
      allData[Fid] &&
      parseFloat(allData[Fid]) < parseFloat(allData[Sid])
    ) {
      fieldData.helperText = [true, ""];
      fieldData.helperText[1] = `${allData[Fid]}<${allData[Sid]}, Please correct.`;
    }
  }
  if (fieldData.id === Sid) {
    if (
      allData &&
      allData[Sid] &&
      allData[Fid] &&
      parseFloat(allData[Fid]) >= parseFloat(allData[Sid])
    ) {
      fieldDataNext.helperText = [false, ""];
    } else if (allData[Fid] && !allData[Sid]) {
      fieldDataNext.helperText = [false, ""];
    }
  }
};
export const CustomValidationForDealForm = (data, allData) => {
  const tableData = data.filter((item) => item.fieldType == "table");
  try {
    if (tableData && tableData.length > 0) {
      for (let i = 0; i < tableData.length; i++) {
        for (let j = 0; j < tableData[i].rows.length; j++) {
          for (let k = 0; k < tableData[i].rows[j].fields.length; k++) {
            let fieldData = tableData[i].rows[j].fields[k];
            let fieldDataNext = tableData[i].rows[j].fields[k + 1];
            if (fieldData.id === "Secondary Fund Investment") {
              if (
                !allData["Secondary Fund"] ||
                allData["Secondary Fund"].data === ""
              ) {
                fieldData.disabled = true;
                fieldData.required = false;
                 fieldData.helperText = [false, ""];
              } else {
                fieldData.disabled = false;
                fieldData.required = true;
              }
            }
            if (fieldData.id === "Tertiary Fund Investment") {
              if (
                !allData["Tertiary Fund"] ||
                allData["Tertiary Fund"].data === ""
              ) {
                fieldData.disabled = true;
                fieldData.required = false;
                 fieldData.helperText = [false, ""];
              } else {
                fieldData.disabled = false;
                fieldData.required = true;
              }
            }
            if (
              fieldData.id === "IRR Levered" ||
              fieldData.id === "Gross MM levered" ||
              fieldData.id === "NMM Levered"
            ) {
              if (
                (allData["Mezz Debt"] && allData["Mezz Debt"] > "0") ||
                (allData["Senior Debt"] && allData["Senior Debt"] > "0")
              ) {
                fieldData.required = true;
                fieldData.disabled = false;
              } else if (
                (!allData["Mezz Debt"] || allData["Mezz Debt"] === "0") &&
                (!allData["Senior Debt"] || allData["Senior Debt"] === "0")
              ) {
                fieldData.required = false;
                fieldData.disabled = true;
                 fieldData.helperText = [false, ""];
              } else {
                fieldData.required = false;
                fieldData.disabled = false;
              }
            }
            FixingData(
              fieldData,
              allData,
              fieldDataNext,
              "IRR Levered",
              "IRR Unlevered"
            );
            FixingData(
              fieldData,
              allData,
              fieldDataNext,
              "Gross MM levered",
              "Gross MM unlevered"
            );
            FixingData(
              fieldData,
              allData,
              fieldDataNext,
              "NMM Levered",
              "NMM Unlevered"
            );
          }
        }
      }
      return data;
    } else {
      return data;
    }
  } catch {
    return data;
  }
};
