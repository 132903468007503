import * as React from "react";
import PropTypes from "prop-types";
import "./A_TableChart.css";
export default function A_ReadMore(props) {
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    setShowAll(false);
  }, [props.data]);
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;

    return span.textContent || span.innerText;
  }
  let regexValueRemove = extractContent(props.data);
  const getStringData = (stringData) => {
    if (stringData && stringData.length > props.limit) {
      let originalData = props.data;
      let flag = false;
      let count = 0;
      let tag = "";
      for (let i = 0; i < originalData.length; i++) {
        if (originalData[i] === "<") {
          flag = true;
          tag = tag + originalData[i];
        } else if (!flag) {
          if (count != props.limit) {
            tag = tag + originalData[i];
            ++count;
          }
        } else if (originalData[i] === ">") {
          flag = false;
          tag = tag + originalData[i];
        } else {
          tag = tag + originalData[i];
        }
      }
      return (
        <>
          {showAll ? (
            <>
              <span
                cursor="pointer"
                style={{
                  cursor: "pointer",
                }}
              >
                {<div id="htmlText" dangerouslySetInnerHTML={{ __html: props.data }}></div>}
              </span>{" "}
            </>
          ) : (
            <>
              {
                <div id="htmlText"
                  dangerouslySetInnerHTML={{
                    __html: tag,
                  }}
                ></div>
              }
              <span
                cursor="pointer"
                style={{
                  cursor: "pointer",
                  color: "black",
                  fontWeight: "900",
                }}
              >
                ...
              </span>{" "}
            </>
          )}
        </>
      );
    } else {
      return (
        <>{<div id="htmlText" dangerouslySetInnerHTML={{ __html: props.data }}></div>}</>
      );
    }
  };
  return (
    <div
      onClick={() => {
        setShowAll(!showAll);
      }}
    >
      {getStringData(regexValueRemove)}
    </div>
  );
}
A_ReadMore.propTypes = {
  data: PropTypes.any,
  limit: PropTypes.any,
};
