import React, { useState, useEffect } from "react";
import IcEmail from "../../../Organisms/Common/IcEmail/icEmail";
import Email from "@material-ui/icons/Mail";
import { Box, Typography } from "@material-ui/core";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import * as httpCall from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core";
import propTypes from "prop-types";
import { O_MeetingSlotCardCss } from "./O_MeetingSlotCardCss";
import * as getIrcSlotAction from "../../../../../store/actions/getIrcSlotAction";
import { labelName } from "../../../../../Utils/config/config";
import { Skeleton } from "@material-ui/lab";
import IrcSlotComponent from "./IrcSlotComponent";
import { mettingSlotEnums } from "./mettingSlotCard.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import { isClientAuthorised } from "../../../Molecules/Common/Navigations/M_NavBarhelper";
const useStyles = makeStyles(O_MeetingSlotCardCss);
const O_MeetingSlotCard = (props) => {
  const classes = useStyles();
  const [recordId, setRecordId] = useState();
  const [currentIRCDate, setCurrentIRCDate] = useState({});
  const [currentDate, setCurrentDate] = useState({});
  const [currentTime, setCurrentTime] = useState({});
  const [isBookIRCOpen, setIsBookIRCOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [entity1, setEntity1] = useState({});
  const [entity2, setEntity2] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [IRCSlotDefault, setIRCSlotDefault] = useState([]);
  const [IRCSlotsValues, setIRCSlotsValues] = useState(
    props.getIrcSlot && props.getIrcSlot.data ? props.getIrcSlot.data : []
  );
  const [emailOption, setEmailOption] = React.useState([]);
  const [formResize, setFormResize] = React.useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const prevEntity = useSelector((state) => state.previousEntity.prevEntity);
  const dispatch = useDispatch();
  //Getting the EndPoint
  const endpoint = !isClientAuthorised(
    window.location.port ? window.location.port : window.location.hostname,
    ["johnlaing", "johnlaingqa", "5000"]
  );
  // update state if anything changed
  useEffect(() => {
    setIsLoading(false);
    setIRCSlotsValues(props.getIrcSlotData.data);
  }, [props.getIrcSlotData && props.getIrcSlotData.data]);

  // default useEffect
  React.useEffect(() => {
    // call current menthod
    getIrcDetails();
    getOptionList();
  }, []);
  const getOptionList = () => {
    const optionList =
      props.currentPage &&
      props.currentPage.pageConfig &&
      props.currentPage.pageConfig.optionSets &&
      Array.isArray(props.currentPage.pageConfig.optionSets) &&
      props.content &&
      props.content.emailOptionSet
        ? props.currentPage.pageConfig.optionSets.filter(
            (optionSet) =>
              optionSet.optionSetName === props.content.emailOptionSet
          )
        : null;
    setEmailOption(optionList);
  };
  const getIrcDetails = async () => {
    setIsLoading(true);
    setIsOpen(false);
    setIsBookIRCOpen(false);
    let payload =
      props.content && props.content.payload ? props.content.payload : "";
    let labelIrcSlot = await httpCall.httpPost(`/IRC/getIRCSlot`, {
      label: labelName.IRC_SLOTES,
      fields: [],
      filter: {
        field: "ircDate",
        operator: "gte",
      },
    });
    let labelIrcPortal = await httpCall.httpPost(`/IRC/getIRCSlot`, {
      label: labelName.IRC_PORTAL,
      fields: [],
      filter: {
        field: "ircDate",
        operator: "gte",
      },
    });

    setIRCSlotDefault([...labelIrcSlot, ...labelIrcPortal]);

    props.getIrcSlot({ payload });
  };

  // set Entity data
  useEffect(() => {
    let templateID =
      props.content && props.content.bookSlotTemplateId
        ? props.content.bookSlotTemplateId
        : "";
    const data =
      props.currentPage && props.currentPage.pageConfig
        ? props.currentPage.pageConfig
        : {};
    setEntity1({
      pageName: data.url ? data.url : "",
      templateId: templateID,
      pageId: data.id ? data.id : "",
      parameters:
        props.content && props.content.bookIRCParams
          ? props.content.bookIRCParams
          : {},
    });
    setEntity2({
      pageName: "ircslots",
      recordId: "",
      templateId:
        props.content && props.content.bookIRCTemplateId
          ? props.content.bookIRCTemplateId
          : "",
      pageId: data.id ? data.id : "",
    });
  }, []);

  const handleClose = () => {
    setShowWarning(true);
  };
  const handleOpen = () => {
    setOpenForm(true);
  };

  const handleAddIrcSlot = () => {
    if (prevEntity && prevEntity.templateId !== entity2.templateId) {
      dispatch(resetManagePage());
    }
    setIsBookIRCOpen(true);
  };

  function checkBookIRCSlot() {
    let isBookIRCSlotDisabled =
      (props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.pageSecurity &&
        props.currentPage.pageConfig.pageSecurity.Full === true) ||
      props.currentPage.pageConfig.pageSecurity.Update === true
        ? false
        : true;

    return isBookIRCSlotDisabled;
  }
  return (
    <Box>
      <Typography>
        {props.content && props.content.description
          ? props.content.description.map((e, index) => {
              if (e.highlight) {
                return (
                  <Typography key={index} className={classes.typoGraphBold}>
                    {e.text ? e.text : ""}
                  </Typography>
                );
              } else {
                return (
                  <Typography key={index} className={classes.typoGraph}>
                    {e.text ? e.text : ""}
                  </Typography>
                );
              }
            })
          : ""}
      </Typography>

      <div className={classes.email}>
        {props.content &&
        props.content.approvalText &&
        props.content.approvalText.isShow &&
        props.content.approvalText.text &&
        props.content.approvalText.isShow === true
          ? props.content.approvalText.text
          : ""}
        {props.content &&
        (props.content.showEmail === undefined ||
          props.content.showEmail === true) ? (
          <A_Button
            label="Email"
            className={classes.labelEmail}
            icon={<Email />}
            onClick={handleOpen}
          />
        ) : (
          ""
        )}
        <IcEmail
          isOpen={openForm}
          options={emailOption}
          onClose={handleClose}
          content={props.content}
          formData={{}}
          setOpenForm={setOpenForm}
          showWarning={showWarning}
          setShowWarning={setShowWarning}
        />
        {((endpoint &&
          props &&
          props.content &&
          !props.content.addSlotBtnHide) ||
          !endpoint) && (
          <A_Button
            onClick={handleAddIrcSlot}
            label="Add Slot"
            disabled={checkBookIRCSlot()}
            color="primary"
            className={classes.addSlot}
          />
        )}

        {isBookIRCOpen && (
          <M_ManageEntityForm
            edgeLabel={
              props.content && props.content.relationship
                ? props.content.relationship
                : null
            }
            isEditable={false}
            entity={entity2}
            isAddEdge={true}
            isIRCSlot={false}
            open={isBookIRCOpen}
            refresh={false}
            onClose={() => {
              setIsBookIRCOpen(false);
            }}
            IRCSlotPayloadCallback={getIrcDetails}
            IRCSlotCardType={mettingSlotEnums.IRCSlotCardType}
          />
        )}
        {isOpen && (
          <M_ManageEntityForm
            IRCRecordId={recordId}
            isEditable={false}
            IRCdate={currentIRCDate ? currentIRCDate : ""}
            IRCSlotDate={currentDate ? currentDate : ""}
            IRCTime={currentTime ? currentTime : ""}
            entity={entity1}
            isIRCSlot={true}
            isAddEdge={true}
            open={isOpen}
            refresh={false}
            formResize={formResize}
            onClose={() => {
              setIsOpen(false);
            }}
            responseMessage={getObjectFromJSONString(
              props,
              ".content.responseMessage"
            )}
            IRCSlotPayloadCallback={getIrcDetails}
            IRCSlotCardType={mettingSlotEnums.IRCSlotCardType}
          />
        )}
      </div>
      {
        <Box className={classes.ircSlotsMap}>
          {isLoading ? (
            <>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </>
          ) : IRCSlotsValues != undefined ? (
            <IrcSlotComponent
              IRCSlotDefault={IRCSlotDefault}
              IRCSlotsValues={IRCSlotsValues}
              entity={entity1}
              content={props.content}
              setRecordId={setRecordId}
              setCurrentIRCDate={setCurrentIRCDate}
              setCurrentTime={setCurrentTime}
              setCurrentDate={setCurrentDate}
              setIsOpen={setIsOpen}
              setFormResize={setFormResize}
            />
          ) : (
            <>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </>
          )}
        </Box>
      }
    </Box>
  );
};
O_MeetingSlotCard.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  maxHeight: propTypes.any,
  ownProps: propTypes.any,
  getIrcSlotData: propTypes.any,
  getIrcSlot: propTypes.any,
  addSlotBtnHide: propTypes.any,
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
    getIrcSlotData: state.getIrcSlotData,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, getIrcSlotAction), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_MeetingSlotCard);