import { proposalTemplate } from "../actions/actionTypes";

const initialState = {
  apiData: null,
  borrowerData: null,
  error: null,
  filterData: {
    portfolio: "",
    borrower: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case proposalTemplate.FETCH_API_DATA:
      return {
        ...state,
        apiData: action.payload,
      };
    case proposalTemplate.FETCH_API_SELECTED_VALUE:
      return {
        ...state,
        borrowerData: action.payload,
      };
    case proposalTemplate.FETCH_API_SELECTED_VALUE_SUCCESS:
      return {
        ...state,
        borrowerData: action.payload,
        error: null,
      };
    case proposalTemplate.FETCH_API_DATA_SUCCESS:
      return {
        ...state,
        apiData: action.payload,
        error: null,
      };
    case proposalTemplate.FETCH_API_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case proposalTemplate.FETCH_FILTER_DATA:
      return {
        ...state,
        filterData:{
        portfolio: action.payload.portfolio,
        borrower: action.payload.borrower,
        }
      };
    default:
      return state;
  }
};

export default reducer;
