import React from "react";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardContent,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as esgActions from "../../../../../../store/actions/esgActions";
import PageNotFound from "../../../../../../Routes/PageNotFound";
import { useParams } from "react-router";
import { esgBodyCss } from "../Helper/Esg.css";
import EsgResponseForm from "./EsgResponseForm";
import { handleResponse, handleResponseSave } from "../Helper/EsgHelper.api";
import { toEpochFormat } from "../../../../../../Utils/Helpers/dateUtils";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(esgBodyCss);

function EsgResponsePage(props) {
  let tempVal = "";
  const { crmId } = useParams();
  React.useEffect(() => {
    handleData();
  }, []);

  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [showTextField, setShowTextField] = React.useState(false);
  const [sectionResponse, setSectionResponse] = React.useState([]);
  const [tabNames, setTabNames] = React.useState([]);
  const [assignUsers, setAssignUsers] = React.useState();
  const [isAuth, setIsAuth] = React.useState(false);
  const [success, setSucces] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const handleData = async () => {
    try {
      setIsLoading(true);
      let editData;
      editData = await handleResponse(crmId);
      setTabNames(editData.sections);
      setAssignUsers(editData.assignedUsers);
      setSectionResponse(editData.sections);
      let currentUser =
        editData &&
        editData.assignedUsers &&
        Array.isArray(editData.assignedUsers) &&
        editData.assignedUsers.filter(
          (data) => data.emailId.toLowerCase() === props.user.toLowerCase()
        );
      if (currentUser.length > 0) {
        setIsAuth(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleSectionResponse = (sectionInd, questionInd, type, value) => {
    let newSectionResponses = [...sectionResponse];
    let question = newSectionResponses[sectionInd].questions[questionInd];
    question[type] = value;
    newSectionResponses[sectionInd].questions[questionInd] = question;
    setSectionResponse(newSectionResponses);
  };
  const handleFormData = () => {
    let newAssignedUsers = [...assignUsers];
    let currentUser = newAssignedUsers.filter(
      (data) => data.emailId == props.user
    );

    let currentDate = toEpochFormat(new Date());
    if (currentUser.length > 0) {
      let payload = {
        surveyResponseBaseInfo: {
          userEmail: props.user,
          userCrmId: currentUser[0].crmId,
          completionDate: currentDate,
          surveyId: crmId,
          isDeleted: false,
        },
        sections: [...sectionResponse],
      };
      handleResponseSave(payload);
      setSucces(true);
    }
  };
  const handleClose = () => {
    setSucces(false);
  };

  return (
    <div>
      {isLoading ? (
        <Card>
          <div className={classes.skeleton}>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </div>
        </Card>
      ) : isAuth ? (
        <Card className={classes.cardCss}>
          <>
            <AppBar
              position="static"
              color="default"
              className={classes.appbar}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                {tabNames && tabNames.length > 0
                  ? tabNames.map((item, index) => (
                      <CustomTab
                        key={index}
                        label={item && item.title ? item.title : ""}
                        onClick={(e) => {
                          handleChange(index);
                        }}
                        {...a11yProps(index)}
                      />
                    ))
                  : ""}
              </Tabs>
            </AppBar>
            <CardContent>
              {Array.isArray(tabNames) &&
                tabNames.map((section, sectionInd) => (
                  <TabPanel value={value} index={sectionInd}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="60%">Questions</TableCell>
                          <TableCell width="10%">Score</TableCell>
                          <TableCell width="30%">Comments</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {section.questions.map(
                          (responseData, responseIndex) => (
                            <EsgResponseForm
                              responseData={responseData}
                              sectionIndex={sectionInd}
                              questionIndex={responseIndex}
                              handleSectionResponse={handleSectionResponse}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                    <div className={classes.button}>
                      <A_Button
                        label="Submit"
                        color="primary"
                        onClick={handleFormData}
                        disabled={props.disabled}
                      />
                    </div>
                  </TabPanel>
                ))}
            </CardContent>
          </>

          <A_Snackbar
            open={success}
            message={"Questionnaire response successfully saved"}
            autoHideDuration={2000}
            horizontal="center"
            handleClose={handleClose}
          />
        </Card>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    esgRecord: state.esgRecord,
    user: state.oidc.user.profile.email,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(esgActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EsgResponsePage);
