import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  statusBar: {
    overflow: "auto",
    height: 40,
    margin: "5px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  statusBarMobile: {
    width: "100%",
    height: "29px",
    display: "flex",
    overflow: "hidden",
    fontSize: "10px",
    [theme.breakpoints.down("sm")]: { fontSize: "7px", height: "25px" },
  },
  stepWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  stepWrapperKiiled: {
    display: "flex",
    flexDirection: "row",
  },
  statusStep: {
    padding: "7px 15px",
    color: "white",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stepWrapperBoxMobile: {
    display: "flex",
    flexDirection: "row",
    width: "30%",
  },

  statusCompleted: {
    background: "#263440",
  },
  statusInProgress: {
    background: "#5d6a74",
  },
}));
