/* eslint-disable */
import {
  pptDownloadActionTypes
} from "../actions/actionTypes";

let initialState = {};
const pptReducer = (state = initialState, action) => {
  switch (action.type) {
    case pptDownloadActionTypes.GET_PPT_DOWNLOAD:
      let currState = {
        ...state,
        payload: action.payload
      };
      return currState;
    case pptDownloadActionTypes.GET_CURRENT_PAGE_SUCCESSED:
      return action.payload;
    case pptDownloadActionTypes.GET_CURRENT_PAGE_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default pptReducer;