
export const categories = ['KT','UP','VE','G','FS'];
export const subCategories = {
  KT: ['KT01', 'KT02', 'KT03', 'KT04', 'KT05', 'KT06', 'KT07', 'KT08', 'KT09', 'KT10', 'KT12', 'KT50', 'KT99'],
  UP: ['UP02', 'UP03', 'UP05', 'UP06', 'UP07', 'UP11', 'UP12', 'UP17', 'UP19', 'UP21', 'UP22', 'UP25', 'UP26', 'UP30', 'UP31', 'UP32', 'UP99'],
  VE: ['VE01', 'VE02', 'VE03', 'VE06', 'VE07', 'VE10', 'VE11', 'VE12', 'VE13', 'VE14', 'VE15', 'VE16', 'VE18', 'VE20', 'VE21', 'VE22', 'VE23', 'VE24', 'VE25', 'VE26', 'VE99'],
  GE: ['G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G12', 'G13', 'G14', 'G15', 'G16', 'G17'],
  FS: ['FS02', 'FS03', 'FS04', 'FS05', 'FS06', 'FS07', 'FS07b', 'FS08', 'FS09', 'FS10', 'FS11', 'FS12', 'FS14', 'FS15', 'FS20', 'FS21', 'FS22', 'FS23', 'FS24', 'FS25', 'FS26', 'FS27', 'FS30', 'FS31', 'FS32', 'FS33', 'FS34', 'FS35', 'FS36', 'FS37', 'FS38', 'FS39', 'FS40', 'FS41', 'FS42', 'FS47', 'FS48', 'FS49', 'FS50', 'FS51', 'FS52', 'FS53', 'FS54', 'FS55', 'FS56', 'FS57', 'FS58', 'FS59', 'FS60', 'FS61', 'FS62', 'FS63', 'FS64', 'FS65', 'FS66', 'FS67', 'FS68', 'FS69', 'FS70', 'FS71', 'FS72', 'FS73'],
};
export const prefixMappings = {
  'KT': 'KT',
  'UP': 'UP',
  'VE': 'VE',
  'G': 'G',
  'FS': 'FS'
};
export const tabConfig = [
  { value: "1", label: "KT Codes" },
  { value: "2", label: "UP Codes" },
  { value: "3", label: "VE Codes" },
  { value: "4", label: "G Codes" },
  { value: "5", label: "FS Codes" },
];

export const columnHeaders = [
  "Code",
  "Label",
  "Category 1",
  "Category 2",
  "Category 3",
  // "Mapped Code",
  "MoreVertIcon",
];

export const columnKeys = [
  "Code",
  "Label",
  "Category1",
  "Category2",
  "Category3",
  // "MappedCode",
];
export const columnNameToKeyMap = {
  "Code": "Code",
  "Label": "Label",
  "Category 1": "Category1", 
  "Category 2": "Category2",
  "Category 3": "Category3",
  // "Mapped Code": "MappedCode",
};