export const css = {
  detail: {
    backgroundColor: "white",
    marginBottom: "1rem",
    borderLeft: "1px solid rgba(1,1,1,0.2)",
    width: "60%",
  },
  list: {
    display: "flex",
    "align-items": "stretch",
  },
  fullWidth: {
    width: "100%",
    cursor: "pointer",
  },
  activeWidth: {
    width: "40%",
  },
  hide: {
    display: "none",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
};
