/* eslint-disable */
import { previousUploadActionTypes } from "./actionTypes";
export const getPreviousUpload = (payload) => {
  return {
    type: previousUploadActionTypes.GET_PREVIOUS_UPLOAD,
    payload,
  };
};
export const getPreviousUploadSucceeded = (payload) => {
  return {
    type: previousUploadActionTypes.GET_PREVIOUS_UPLOAD_SUCCEEDED,
    payload,
  };
};
export const getPreviousUploadFailed = (payload) => {
  return {
    type: previousUploadActionTypes.GET_PREVIOUS_UPLOAD_FAILED,
    payload,
  };
};
export const getPreviousUploadReset=(payload)=> {    
  return {
    type: previousUploadActionTypes.GET_PREVIOUS_UPLOAD_RESET, 
    payload     
  };
}
