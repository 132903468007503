import * as React from "react";
import { useStyles } from "./M_StatusBar.styles";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import * as httpCall from "../../../../../Utils/apis/apis";
import { killedDealStatus } from "../../../../../Utils/config/config";
import { getObjectFromJSONString } from "../Navigations/AddEntity/M_ManageEntityForm.helper";
import PropTypes from "prop-types";
const Stepper = (props) => {
  const classes = useStyles();
  const renderStep = (step, index, stepCount) => {
    let currentState = props.currentStateIndex || 0;
    let currentIndex = index || 0;
    let currentStep = step || "";
    let currentStepCount = stepCount || 1;
    const isCompleted = currentIndex <= currentState;
    const isLastCompleted = currentIndex >= currentState;
    const statusStyle = isCompleted
      ? classes.statusCompleted
      : classes.statusInProgress;
    return (
      <div className={classes.stepWrapper}>
        <div
          className={`${statusStyle} ${classes.statusStep}`}
          title={currentStep}
        >
          {currentStep}
        </div>
        {currentIndex < currentStepCount - 1 &&
          renderArrow(isCompleted, isLastCompleted)}
      </div>
    );
  };
  const renderKilledStep = (step) => {
    let currentStep = step ? step : "";
    return (
      <div className={classes.stepWrapperKiiled}>
        <div
          className={`${classes.statusCompleted} ${classes.statusStep}`}
          title={currentStep}
        >
          {currentStep}
        </div>
      </div>
    );
  };
  const renderDoneStepsMobile = () => {
    return (
      <div className={classes.stepWrapperBoxMobile}>
        <div
          className={`${classes.statusCompleted} ${classes.statusStep}`}
        ></div>
        {renderArrow(true, false)}
      </div>
    );
  };
  const renderProgressStepsMobile = () => {
    return (
      <div className={classes.stepWrapperBoxMobile}>
        <div
          className={`${classes.statusInProgress} ${classes.statusStep}`}
        ></div>
      </div>
    );
  };

  const renderMobile = (selectedStep, stepCount) => {
    let currentIndexData = props.currentIndex ? props.currentIndex : 0;
    const isAllCompleted = currentIndexData === stepCount - 1;
    const isFirstStep = currentIndexData === 0;
    return (
      <div className={classes.statusBar}>
        {!isFirstStep && renderDoneStepsMobile("", 1, stepCount)}
        {renderStep(selectedStep, currentIndexData, stepCount)}
        {!isAllCompleted && renderProgressStepsMobile()}
      </div>
    );
  };

  const renderArrow = (isCompleted, isLastCompleted) => {
    const fillColor = isCompleted ? "#263440" : "#5d6a74";
    const backgroundStyle = isLastCompleted
      ? classes.statusInProgress
      : classes.statusCompleted;
    return (
      <div className={backgroundStyle}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 11 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M0.000, 0.0 L10,10 L0,20"
              id="a"
              stroke="white"
              fill={fillColor}
            />
          </g>
        </svg>
      </div>
    );
  };

  const renderSteps = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
      <>
        {/* {props.isMobile &&
          renderMobile(
            props.currentStatus ? props.currentStatus : "",
            props.pipelineData ? props.pipelineData.length : 0
          )} */}
        {isMobile ? (
          <div className={classes.statusBarMobile}>
            {getObjectFromJSONString(props, ".currentStatusData") &&
            getObjectFromJSONString(props, ".content.singlePipelineContent") &&
            Array.isArray(props.content.singlePipelineContent) &&
            props.content.singlePipelineContent.includes(
              props.currentStatusData
            )
              ? renderKilledStep(props.currentStatusData)
              : (props.currentStatusData &&
                  props.currentStatusData == killedDealStatus.KILLED) ||
                props.currentStatusData == killedDealStatus.DEAD
              ? renderKilledStep(props.currentStatusData)
              : props.pipelineData
              ? props.pipelineData.map((step, index) =>
                  renderStep(
                    step,
                    index,
                    props.pipelineData ? props.pipelineData.length : 0
                  )
                )
              : ""}
          </div>
        ) : (
          <div className={classes.statusBar}>
            {getObjectFromJSONString(props, ".currentStatusData") &&
            getObjectFromJSONString(props, ".content.singlePipelineContent") &&
            Array.isArray(props.content.singlePipelineContent) &&
            props.content.singlePipelineContent.includes(
              props.currentStatusData
            )
              ? renderKilledStep(props.currentStatusData)
              : (props.currentStatusData &&
                  props.currentStatusData == killedDealStatus.KILLED) ||
                props.currentStatusData == killedDealStatus.DEAD
              ? renderKilledStep(props.currentStatusData)
              : props.pipelineData
              ? props.pipelineData.map((step, index) =>
                  renderStep(
                    step,
                    index,
                    props.pipelineData ? props.pipelineData.length : 0
                  )
                )
              : ""}
          </div>
        )}
      </>
    );
  };
  return <div>{props.pipelineData && renderSteps()}</div>;
};
const M_StatusBar = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pipelineData, setPipelineData] = React.useState([]);
  const [currentStateIndex, setCurrentStateIndex] = React.useState(0);
  React.useEffect(() => {
    getStepper();
  }, []);

  let getStepper = async () => {
    let step = [];
    let payload =
      props.currentPage &&
      props.currentPage.data &&
      props.currentPage.data.statusPipeline
        ? props.currentPage.data.statusPipeline
        : "";
    let response = await httpCall.httpGet(
      `/Common/GetDealWorkFlow?WorkFlowID=${payload}`
    );
    if (response && response.items) {
      for (let i in response.items) {
        step.push(response.items[i].state);
      }
      setPipelineData(step);
      var currentStatusData =
        props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data["currentStatus"]
          ? props.currentPage.data.currentStatus
          : killedDealStatus.DEVELOPING;
      let statusIndex = step.indexOf(currentStatusData);
      setCurrentStateIndex(statusIndex);
    }
  };

  return (
    <Stepper
      pipelineData={pipelineData}
      currentStateIndex={currentStateIndex}
      isMobile={isMobile}
      currentStatusData={
        props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data["currentStatus"]
          ? props.currentPage.data.currentStatus
          : killedDealStatus.DEVELOPING
      }
      content={props.content}
    />
  );
};
M_StatusBar.propTypes = {
  logo: PropTypes.any,
  title: PropTypes.any,
  downloadExcelTemplatePopUp: PropTypes.any,
  subtitle: PropTypes.any,
  onReload: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
  };
}

export default connect(mapStateToProps)(M_StatusBar);
