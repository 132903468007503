import React from "react";
import { onDownload } from "./helper/download.helper";
import { PictureAsPdf } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  iconDisable: {
    color: "grey",
  },
});
export default function A_TablePdfDownload({ filename }) {
  const user = useSelector((state) => state.oidc.user);
  const classes = useStyles();
  let { pageUrl } = useParams();
  const [isEnable, setIsEnable] = React.useState(true);
  const downloadFile = async () => {
    setIsEnable(false);
    await onDownload(filename,pageUrl=='finance'?'inboundinvoice':"invoice", user);
    setIsEnable(true);
  };
  return (
    <div>
      {isEnable ? (
        <>
          <img
            src="https://stminervaclouddev.blob.core.windows.net/images-store/adobe-pdf-icon.webp"
            key={filename}
            onClick={downloadFile}
            style={{ width: "35px", cursor: "pointer" }}
          ></img>
        </>
      ) : (
        <>
          <img
            src="https://stminervaclouddev.blob.core.windows.net/images-store/adobe-pdf-icon.webp"
            key={`${filename}_disabled`}
            style={{ width: "35px", filter: "opacity(0.5)" }}
          ></img>
        </>
      )}
    </div>
  );
}
