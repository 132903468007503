 /* eslint-disable */
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import theme from "../../../../Themes/defaultTheme";

const A_Chip = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Chip
        label={props.label}
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        variant={props.variant}
        avatar={props.avatar}
        icon={props.icon}
        deleteIcon={props.deleteIcon}
        onClick={props.onClick}
        onDelete={props.onDelete}
      />
    </ThemeProvider>
  );
};
A_Chip.defaultProps = {
  label: "Finance",
  color: "primary",
  size: "medium",
  variant: "default",
  disabled: false,
};

export default A_Chip;
