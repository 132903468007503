/* eslint-disable */
import React from "react";
import "./M_Layout.scss";
// import A_Card from"../../../Atoms/Surfaces/Cards/A_Card";
import A_Card from "../../../Atoms/Surfaces/Cards/A_Card";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import InlineRecords from "../../../../../Assets/Images/Defaults/inline-records.JPG";
import tableRecord from "../../../../../Assets/Images/Defaults/table-record.JPG";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";

class M_Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openCardZoom: false, openEdit: false };
  }
  handleZoom = (card) => {
  
    if (!this.state.openCardZoom) {
      card.header.zoom = false;
    }
    this.setState((prevState, prevProps) => {
      return {
        openCardZoom: !prevState.openCardZoom,
        cardProps: prevState.openCardZoom === true ? null : card,
      };
    });
  };
  handleEdit = (card) => {
   
    if (!this.state.openEdit) {
      card.header.edit = false;
    }
    this.setState((prevState, prevProps) => {
      return {
        openEdit: !prevState.openEdit,
        cardProps: prevState.openEdit === true ? null : card,
      };
    });
  };
  render() {
    const card1Style = {
      gridColumn: "span 1",
      gridRow: "span 2",
    };
    const card2Style = {
      gridColumn: "span 2",
      gridRow: "span 3",
    };

    return (
      <>
        <main className="grid" style={{ margin: "10px" }}>
          <article style={card1Style}>
            <A_Card
              elevation={2}
              width={50}
              header={{
                title: "Yearly Invested Capital",
                zoom: false,
                secondaryMenu: false,
                add: true,
              
                export: true,
              }}
              content={
                <React.Fragment>
                  <img src={InlineRecords} width={"500px"} height={"500px"} />
                </React.Fragment>
              }
              handleZoom={this.handleZoom}
            />
          </article>
          <article style={card2Style}>
            <A_Card
              elevation={2}
              width={50}
              header={{
                title: "Outstanding Invoices",
                export: true,
                secondaryMenu: true,
              }}
              content={
                <React.Fragment>
                  <img src={tableRecord} height={"400px"} />
                </React.Fragment>
              }
              action={
                <>
                  <A_Button color="primary" label="Approve" />
                  <A_Button color="secondary" label="Reject" />
                </>
              }
            />
          </article>
          <article>
            <A_Card
              elevation={2}
              width={50}
              header={{
                title: "Deal Description",
                edit: { show: true },
                secondaryMenu: true,
                zoom: false,

              }}
              content={
                <React.Fragment>
                  Equa bank offers simple, comprehensible and transparent
                  banking services for retail and business customers. Products
                  include current and savings accounts with free maintenance,
                  multi-currency debit cards, mortgages, term deposits, consumer
                  loans, insurance and business loans.
                </React.Fragment>
              }
            />
            handleZoom={this.handleZoom}
            handleEdit={this.handleEdit}
          </article>
        </main>
        <A_SimpleDialog
          open={this.state.openCardZoom}
          //title="Dialog Title"
          fullScreen={false}
          fullWidth={true}
          maxWidth={"md"}
          dialogContent={
            // <React.Fragment>This is dialog content area.</React.Fragment>
            <A_Card {...this.state.cardProps} />
          }
          dialogActions={
            <div>
              <A_Button
                label="Close"
                size="small"
                onClick={() => this.handleZoom("close")}
              />
              <A_Button
                label="Close"
                size="small"
                onClick={() => this.handleEdit("close")}
              />
            </div>
          }
        />
      </>
    );
  }
}

export default M_Layout;
