import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Cross, Tick } from "../SVGs";
// import { isResponseSubmitted } from "./ESG_Config";
import {
  getAllInvestment,
  getResponseApprovalBySurveyId,
  getSurveyBySurveyId,
  closeSurvey,
  getQuestionResponseCount,
  getAllRegion,
  getAllSector,
} from "../ESG_API";
import { CommonFilter, SurveyFilter, SurveyFilterYear } from "../components";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { getDate, reformatDateString } from "../utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  active: {
    color: "white !important",
    "& .MuiTableSortLabel-icon": {
      color: "white !important",
    },
  },
  table: {
    minWidth: 700,
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    marginTop: "-2%",
    display: "flex",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%,0%)",
    zIndex: "1",
  },
});

const ESGSubmissionSummary = () => {
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  const [investmentNames, setInvestmentNames] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("assetName");
  const [rows, setRows] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [showSummary, setShowSummary] = useState(true);
  const [selectedRegionValue, setSelectedRegionValue] = useState("All Regions");
  const [selectedSectorValue, setSelectedSectorValue] = useState("All Sectors");
  const [filterRegionTextValues, setFilterRegionTextValues] = useState([]);
  const [filterSectorTextValues, setFilterSectorTextValues] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [failure, setFailure] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState([]);
  const [allSector, setAllSector] = useState([]);
  const [allRegion, setAllRegion] = useState([]);
  const [errormsg, setErrorMsg] = useState("");


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    const getsectors = async () => {
      const res = await getAllSector();
      setAllSector(res?.data);
    };

    const getregions = async () => {
      const res = await getAllRegion();
      setAllRegion(res?.data);
    };

    const getAssetData = async () => {
      const data = await getAllInvestment();
      setAssetData(data);
      if (data) {
        const uniqueInvestmentNames = [
          ...new Set(data.map((dta) => dta.investmentName)),
        ];
        setInvestmentNames(uniqueInvestmentNames);
      }
    };

    const getReponseApprovalBySurvey = async () => {
      const data = await getResponseApprovalBySurveyId(selectedSurveyId);
      if (data) {
        setApprovalData(data);
      }
    };
    getregions();
    getsectors();
    getAssetData();
    getReponseApprovalBySurvey();
  }, []);

  useEffect(() => {
    const getSurveyById = async () => {
      const res = await getSurveyBySurveyId(selectedSurveyId);
      if (res.status == 0) {
        setShowSummary(false);
      } else if (res.status == 1) {
        setShowSummary(true);
        setIsDisable(false);
        setShowError(false);
      } else if (res.status == 2) {
        setShowSummary(true);
        setIsDisable(true);
        setShowError(true);
      }
    };

    const getReponseApprovalBySurvey = async () => {
      const data = await getResponseApprovalBySurveyId(selectedSurveyId);
      if (data) {
        setApprovalData(data);
      } else {
        setApprovalData([]);
      }
    };

    const getCount = async () => {
      let res = await getQuestionResponseCount(selectedSurveyId);
      setCount(res);
    };

    getCount();
    getSurveyById();
    getReponseApprovalBySurvey();
  }, [selectedSurveyId]);

  useEffect(() => {
    if (investmentNames.length != 0 && count && approvalData) {
      investmentNames.forEach((assetName) => {
        let AMdate = haveAssetManager(assetName);
        let RMdate = haveRegionalManager(assetName);
        let percent = countPercent(assetName);
        let crmId = getCRMid(assetName);
        let asset = assetData.find(
          (investment) => investment.investmentName == assetName
        );
        let row = {
          assetName,
          region: asset.region,
          sector: asset.sector,
          percent,
          AMdate,
          RMdate,
          crmId,
        };
        setRows((prev) => [...prev, row]);
      });
    }

    return () => {
      setRows([]);
    };
  }, [investmentNames, count, approvalData]);

  useEffect(() => {
    let sectors = Array.from(
      new Set(allSector.map((item) => item?.value))
    ).sort();
    let regions = Array.from(
      new Set(allRegion.map((item) => item?.value))
    ).sort();

    regions.unshift("All Regions");
    sectors.unshift("All Sectors");
    setFilterRegionTextValues(regions);
    setFilterSectorTextValues(sectors);
    setSelectedRegionValue("All Regions");
    setSelectedSectorValue("All Sectors");
  }, [assetData, rows]);

  useEffect(() => {
    if (
      selectedRegionValue == "All Regions" &&
      selectedSectorValue == "All Sectors"
    ) {
      setFilteredRows(rows);
    } else if (
      selectedRegionValue !== "All Regions" &&
      selectedSectorValue == "All Sectors"
    ) {
      let filteredRows = rows.filter(
        (row) => row.region == selectedRegionValue
      );
      setFilteredRows(filteredRows);
    } else if (
      selectedRegionValue == "All Regions" &&
      selectedSectorValue !== "All Sectors"
    ) {
      let filteredRows = rows.filter(
        (row) => row.sector == selectedSectorValue
      );
      setFilteredRows(filteredRows);
    } else if (
      selectedRegionValue !== "All Regions" &&
      selectedSectorValue !== "All Sectors"
    ) {
      let filteredRows = rows.filter(
        (row) =>
          row.sector == selectedSectorValue && row.region == selectedRegionValue
      );
      setFilteredRows(filteredRows);
    }
  }, [selectedRegionValue, selectedSectorValue, rows]);

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
  };
  const handleFreezeResponse = async () => {
    setIsDisable(true);
    let response = await closeSurvey(selectedSurveyId);

    if (
      response?.response?.data?.statusCode &&
      response.response.data.statusCode === 200
    ) {
      setSuccess(true);
      setErrorMsg(
        response?.response?.data?.data
          ? response?.response.data.data
          : "Successfully Closed."
      );
    } else {
      setFailure(true);
      setIsDisable(false);
      setErrorMsg(
        response?.response?.data?.errorMsg
          ? response.response.data.errorMsg
          : "Failed to close."
      );
    }
    window.location.reload();
  };

  const navigateToAsset = (crmId) => {
    window.open(
      `/${process.env.ASSETS_PATH}/${crmId}/d784643e-5f8a-4891-b422-e08ad19d65b4`,
      "_blank"
    );
  };

  const getCRMid = (row) => {
    for (let i of assetData) {
      if (i.investmentName === row) {
        return i.crmId;
      }
    }
  };

  const haveAssetManager = (row) => {
    let date = "-";
    if (approvalData) {
      for (let i = 0; i < approvalData.length; i++) {
        if (
          row == approvalData[i].investmentName &&
          approvalData[i].role == "Asset Manager"
        ) {
          let date = getDate(approvalData[i].updatedAt);
          return date;
        }
      }
    }
    return date;
  };

  const haveRegionalManager = (row) => {
    let date = "-";
    if (approvalData) {
      for (let i = 0; i < approvalData.length; i++) {
        if (
          row == approvalData[i].investmentName &&
          approvalData[i].role == "Regional Asset Director"
        ) {
          date = getDate(approvalData[i].updatedAt);
          return date;
        }
      }
    }
    return date;
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const countPercent = (row) => {
    if (count && count.responseCount && count.responseCount.length > 0) {
      for (let i = 0; i < assetData.length; i++) {
        for (let j = 0; j < count.responseCount.length; j++) {
          if (
            row == assetData[i].investmentName &&
            count.responseCount[j].investmentId == assetData[i].investmentId
          ) {
            return Math.floor(
              (count.responseCount[j].responseCount / count.questionCount) * 100
            );
          }
        }
      }
    }
    return 0;
  };

  return (
    <div>
      <SurveyFilter
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
      />
      <SurveyFilterYear
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        setSurveyFilterYear={setSurveyFilterYear}
      />
      <div>
        <CommonFilter
          selectedDataValue={selectedRegionValue}
          list={filterRegionTextValues}
          setSelectedDataValue={setSelectedRegionValue}
          defaultText={"All Regions"}
          style={{
            position: "absolute",
            top: "227px",
            left: "634px",
            border: "1px solid black",
            backgroundColor: "#ffff",
            width: "150px",
            height: "38px",
            borderRadius: "3px",
          }}
        />
      </div>
      <div>
        <CommonFilter
          selectedDataValue={selectedSectorValue}
          list={filterSectorTextValues}
          setSelectedDataValue={setSelectedSectorValue}
          defaultText={"All Sectors"}
          style={{
            position: "absolute",
            top: "227px",
            left: "834px",
            border: "1px solid black",
            backgroundColor: "#ffff",
            width: "150px",
            height: "38px",
            borderRadius: "3px",
          }}
        />
      </div>
      {showSummary ? (
        <>
          {showError && <Alert severity="error">Survey Closed</Alert>}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === "assetName"}
                      className={classes.active}
                      direction={orderBy === "assetName" ? order : "asc"}
                      onClick={createSortHandler("assetName")}
                    >
                      Assets
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TableSortLabel
                      active={orderBy === "percent"}
                      className={classes.active}
                      direction={orderBy === "percent" ? order : "asc"}
                      onClick={createSortHandler("percent")}
                    >
                      % Completion
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">AM</StyledTableCell>
                  <StyledTableCell align="center">
                    AM Submitted Date
                  </StyledTableCell>
                  <StyledTableCell align="center">RAD</StyledTableCell>
                  <StyledTableCell align="center">
                    RAD Submitted Date
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(filteredRows, getComparator(order, orderBy)).map(
                  (row, index) => {
                    // let AMdate = haveAssetManager(row);
                    // let RMdate = haveRegionalManager(row);
                    // let percent = countPercent(row);
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          <strong
                            style={{ cursor: "pointer", color: "#3b82f6" }}
                            onClick={() => navigateToAsset(row.crmId)}
                          >
                            {row.assetName}
                          </strong>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {`${row.percent}%`}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.AMdate !== "-" ? <Tick /> : <Cross />}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {reformatDateString(row.AMdate)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.RMdate !== "-" ? <Tick /> : <Cross />}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {reformatDateString(row.RMdate)}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <A_Button
            color="primary"
            style={{
              marginTop: "10px",
              position: "absolute",
              right: "40px",
            }}
            label="Freeze Responses"
            onClick={handleFreezeResponse}
         disabled={isDisable}
          />
        </>
      ) : (
        <Alert severity="info">
          There is no response to show, either the survey is not yet published
          or response is not submitted yet
        </Alert>
      )}
      <A_Snackbar
        open={success}
        message={errormsg}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={errormsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
    </div>
  );
};

export default ESGSubmissionSummary;
