import { lighten, makeStyles } from "@material-ui/core/styles";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),

    paddingRight: theme.spacing(1),
  },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,

          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,

          backgroundColor: theme.palette.secondary.dark,
        },

  title: {
    flex: "1 1 100%",

    marginTop: "30px",

    marginBottom: "20px",
  },

  searchBox: {
    height: "2rem",
    marginRight: "142px",
    marginLeft: "52.4%",
    minWidth: "50%",
    position: "relative",
    right: "13%",
    [theme.breakpoints.down("sm")]: {
      height: "2rem",
      marginRight: "142px",
      marginLeft: "35.3%",
      minWidth: "69%",
      position: "relative",
      right: "13%",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "52%",
    },
  },

  formControl: {
    margin: theme.spacing(1),

    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  addButton: {
    marginRight: "0px",

    width: "30px",

    height: "30px",
  },

  selectBox: {
    margin: "10px",
    height: "3rem",
    float: "left",
  },
}));

export default useToolbarStyles;
