import React from "react";
import A_Button from "../../../../../../Atoms/Inputs/Buttons/A_Button";
import { makeStyles } from "@material-ui/core/styles";
import A_TableChart from "../../../../../../Atoms/DataDisplay/DataGrid/A_TableChart";
import M_ManageEntityForm from "../../../../Navigations/AddEntity/M_ManageEntityForm";
import A_SimpleDialog from "../../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import * as httpCall from "../../../../../../../../Utils/apis/apis";
import * as helpers from "../../../../../../../../Utils/Helpers/chartHelpers";
import * as managePageActions from "../../../../../../../../store/actions/managePageActions";
import { connect } from "react-redux";
import { httpDelete } from "../../../../../../../../Utils/apis/apis";
import { bindActionCreators } from "redux";
import A_Snackbar from "../../../../../../../Factory/Atoms/Feedback/Snackbars/A_Snackbar";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";

function executeJS(obj) {
  return Function(
    " return (function(configs,chartData,helpers,chartParameters){let newConfig=configs; " +
      obj +
      " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return chartData; })"
  )();
}

const useStyles = makeStyles(() => ({
  main: {
    height: "100vh",
  },
  weareSorry: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  weareSorryToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
    marginTop: "7%",
    marginLeft: "35%",
    position: "absolute",
  },
}));
const filterPropsData = (data) => {
  let resArray = [];
  if (data && data.series && data.series.length > 0) {
    for (let index = 0; index < data.series.length; index++) {
      let object = {};
      for (let j = 0; j < data.series[index].data.length; j++) {
        if (data && data.dimensions && data.dimensions.length > 0) {
          let dimentionKey = data.dimensions[0];
          let dimentionValue = data.series[index].name[0];
          object[dimentionKey] = dimentionValue;
        }

        let keyName =
          data.categories && data.categories.length > 0 && data.categories[j]
            ? data.categories[j]
            : "_";
        let keyValue = data.series[index].data[j];
        object[keyName] = keyValue;
      }
      resArray.push(object);
    }
  }
  return resArray;
};

const IsJsonString = (str) => {
  let config;
  try {
    config = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return config;
};

const M_TableChart = (props) => {
  const classes = useStyles();
  const [isPopupClick, setIsPopupClick] = React.useState(false);
  const [popChartData, setPopChartData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [entity, setEntity] = React.useState({});
  const { javaScript } = props.chart.chartConfiguration;
  const parameters = props.parameters;
  const setDeleting = false;
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    msg: "",
    type: "success",
  });
  const { callback } = props;
  try {
    var chartData = JSON.parse(JSON.stringify(props.chart.data));
  } catch (e) {
    console.log(e);
  }
  const [configurations, setConfigurations] = React.useState(
    props.chart &&
      props.chart.chartConfiguration &&
      props.chart.chartConfiguration.configuration
      ? IsJsonString(props.chart.chartConfiguration.configuration)
      : {}
  );
  const chartParameters =
    props.chart &&
    props.chart.chartConfiguration &&
    props.chart.chartConfiguration.query &&
    props.chart.chartConfiguration.query.parameters
      ? JSON.parse(
          JSON.stringify(props.chart.chartConfiguration.query.parameters)
        )
      : {};

  // let chartOptions = javaScript
  //   ? executeJS(javaScript)(configurations, props.chart.data, helpers)
  //   : props.chart.data;
  const chartId =
    props &&
    props.chart &&
    props.chart.chartConfiguration &&
    props.chart.chartConfiguration.id
      ? props.chart.chartConfiguration.id
      : "";
  let chartOptions = React.useMemo(() => {
    try {
      return javaScript
        ? executeJS(javaScript)(
            configurations,
            chartData,
            helpers,
            chartParameters
          )
        : chartData;
    } catch (e) {
      console.log(e);
    }
  }, [props.chart.javaScript]);

  const [data, setData] = React.useState(
    chartOptions ? filterPropsData(chartOptions) : {}
  );

  const [resultData, setResultData] = React.useState(
    chartOptions ? chartOptions : {}
  );

  React.useEffect(() => {
    setData([...filterPropsData(chartOptions)]);
    setResultData({ ...chartOptions });
  }, [chartOptions]);

  const handleAddButtonClick = () => {
    setOpen(true);
  };
  let currentData = [];

  const handleEditButtonClick = (e) => {
    const recordId = data && data.length > 0 ? [e.currentTarget.id] : "";
    setEntity({
      templateId: props.parameters.templateId,
      recordId: recordId.toString(),
      hasContainer: props.parameters.containerName
        ? props.parameters.containerName
        : null,
      updatePayloadDocumentDb: props.parameters.updatePayloadDocumentDb
        ? props.parameters.updatePayloadDocumentDb
        : null,
    });

    setOpen(true);
  };

  const getChart = async (chartRequest) => {
    if (!popChartData) {
      setIsLoading(true);
      let url = "/ChartData/getchartdata";
      let requestPayload = chartRequest;
      const result = await httpCall.httpPost(url, requestPayload);
      let chData = result;
      if (!("message" in result)) {
        setPopChartData(chData);
        setIsLoading(false);
      }
    }
  };

  const getRequestParamData = (event, cellConfig, showDetails) => {
    let rowKey = showDetails.row;
    let columnKey = showDetails.column;
    let rowValue =
      event.target.parentElement.parentElement.firstElementChild
        .firstElementChild.innerText;
    let columnValue = cellConfig && cellConfig.id ? cellConfig.id : "";

    let parameters = {};

    if (rowKey) {
      parameters[rowKey] = rowValue;
    }
    if (columnKey) {
      parameters[columnKey] = columnValue;
    }
    if (showDetails.parameters) {
      parameters = { ...parameters, ...showDetails.parameters };
    }
    return {
      chartId: showDetails.chartID,
      parameters: parameters,
    };
  };
  const handleClickableCell = (event, cellConfig, showDetails) => {
    let requestParam = getRequestParamData(event, cellConfig, showDetails);
    setPopChartData(null);
    setIsPopupClick(true);
    getChart(requestParam);
  };

  const handleClosePopup = () => {
    setPopChartData(null);
    setIsPopupClick(false);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };
  const handleConfirmDelete = async () => {
    setDeleting(true);
    setDeletePopup(false);
    let hasContainer = props.parameters.containerName
      ? props.parameters.containerName
      : null;
    if (hasContainer && hasContainer !== null) {
      await httpDelete(`/GenericDocument/${hasContainer}/${deleteId}/delete`);
    }
    setChangesSaved({
      status: true,
      msg: " Data deleted successfully",
      type: "success",
    });
    setTimeout(location.reload.bind(location), 2000);
  };
  const handleCancelDelete = () => {
    setDeletePopup(false);
  };
  const handleSnackbarClose = () => {
    setChangesSaved({ status: false, msg: "", type: "success" });
  };
  const handleDeleteButtonClick = (e) => {
    currentData = data.filter((item) => e.currentTarget.id == item.id);
    const idFilter = currentData.map((item) => item.id);
    setDeleteId(idFilter);
    setDeletePopup(true);
  };

  const getActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label={"Ok"}
          autoFocus
          onClick={handleConfirmDelete}
        />

        <A_Button
          color="default"
          label={"Cancel"}
          onClick={handleCancelDelete}
        />
      </>
    );
  };
  return (
    <>
      {data.length > 0 ? (
        <Grid container>
          <Grid item xs={12} style={{ overflow: "hidden" }}>
            <Box>
              <div>
                <A_TableChart
                  className={classes.main}
                  header={props.header ? props.header : null}
                  data={data}
                  callback={callback}
                  resultData={resultData}
                  configurations={configurations}
                  handleAddButtonClick={handleAddButtonClick}
                  handleEditButtonClick={(data) => handleEditButtonClick(data)}
                  handleDeleteButtonClick={(data) =>
                    handleDeleteButtonClick(data)
                  }
                  handleClickableCell={handleClickableCell}
                  onFilterChange={props.handleFilterChange}
                  parameters={parameters}
                  currentPage={props.currentPage}
                  chartId={chartId}
                />
                {open && (
                  <M_ManageEntityForm
                    entity={entity}
                    open={open}
                    onClose={() => handleCloseForm()}
                    isEditable={true}
                    editChart={true}
                    addEdge={
                      props.parameters && props.parameters.addEdge
                        ? props.parameters.addEdge
                        : ""
                    }
                  />
                )}

                <A_SimpleDialog
                  open={isPopupClick}
                  fullScreen={false}
                  height="400px"
                  fullWidth={true}
                  maxWidth={"md"}
                  onClose={handleClosePopup}
                  dialogContent={
                    !isLoading ? (
                      <A_TableChart
                        className={classes.main}
                        callback={callback}
                        header={props.header ? props.header : null}
                        data={
                          popChartData &&
                          popChartData.data &&
                          popChartData.data.series
                            ? filterPropsData(popChartData.data)
                            : {}
                        }
                        resultData={
                          popChartData && popChartData.data
                            ? popChartData.data
                            : {}
                        }
                        configurations={
                          popChartData &&
                          popChartData.chartConfiguration &&
                          popChartData.chartConfiguration.configuration
                            ? IsJsonString(
                                popChartData.chartConfiguration.configuration
                              )
                            : {}
                        }
                        chartId={chartId}
                      />
                    ) : (
                      <p>Loading</p>
                    )
                  }
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div
          className={
            !props.header ? classes.weareSorry : classes.weareSorryToggle
          }
        >
          <p>WE ARE SORRY, NO CONTENT FOUND!</p>
        </div>
      )}
      {deletePopup && (
        <A_SimpleDialog
          id="2"
          open={deletePopup}
          dialogContent={"Are you sure you want to delete this data ?"}
          dialogActions={getActionItems()}
        />
      )}
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleSnackbarClose}
        type={changesSaved.type}
      />
    </>
  );
};
M_TableChart.propTypes = {
  chart: PropTypes.any,
  parameters: PropTypes.any,
  content: PropTypes.array,
  handleFilterChange: PropTypes.func,
  currentPage: PropTypes.object,
  header: PropTypes.any,
  callback: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(managePageActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_TableChart);
