import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box, Grid } from "@material-ui/core";
import * as common from "../../../../Utils/Helpers/common";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { message } from "../../../../Utils/config/messages";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import { httpPost } from "../../../../Utils/apis/apis";
import { useParams } from "react-router";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";

const useStyles = makeStyles((theme) => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  columnHeader: {
    color: "#fff",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ImpactData = (props) => {
  const { content } = props;
  const [impactData, setImpactData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const user = useSelector((state) => state.oidc.user) || {};
  const [isValid, setIsValid] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const crmContactUserInfo =
    JSON.parse(localStorage.getItem("contact_crm")) || {};
  const Approverid = crmContactUserInfo.id || "";
  const { crmId } = useParams();
  const { chartTheme } = common.getTheme() || {};
  const { TableChart } = chartTheme || {};

  const handleSave = async () => {
    if (!formData.ApproverComments) {
      setIsValid(true);
    } else {
      try {
        setIsLoading(true);
        setIsOpen(false);
        const idObject = {
          investmentid: crmId,
        };
        const convertedFormData = {
          spName: "dbo.sp_Auto_Approve_Impact_Data",
          parameters: {
            ...formData,
            ...idObject,
          },
        };
        await httpPost(`/SQLBasedForm/SQLFormOperations`, convertedFormData);
        setSuccess(true);

        setFormData({});
        fetchImpactData();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setFormData({});
  };

  const handlePopup = () => {
    setIsOpen(true);
    setFormData({});
  };

  const handleClick = (data) => {
    setIsOpen(true);
    const userInfo = {
      ApproverName:
        user.profile && user.profile.given_name && user.profile.family_name
          ? `${user.profile.given_name} ${user.profile.family_name}`
          : "",
      ApproverMail:
        user.profile && user.profile.email ? user.profile.email : "",
      Approverid,
    };

    setFormData({ ...userInfo, ...data });
  };

  const getActionItems = () => (
    <>
      <A_Button color="primary" label="Save" autoFocus onClick={handleSave} />
      <A_Button color="default" label="Cancel" onClick={handleCancel} />
    </>
  );

  const fetchImpactData = async () => {
    setIsLoading(true);
    try {
      const convertedFormData = {
        spName: "getImpactApprovalData",
        parameters: {
          investmentid: crmId,
          category: content.parameters.category,
        },
      };
      const response = await httpPost(
        `/SQLBasedForm/SQLFormOperations`,
        convertedFormData
      );
      setImpactData(response);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const inputComment = (event) => {
    const newFormData = { ...formData };
    newFormData["ApproverComments"] = event.target.value;
    setFormData(newFormData);
    setIsValid(false);
    if (event.target.value === "") {
      setIsValid(true);
    }
  };

  useEffect(() => {
    fetchImpactData();
  }, []);

  const handleClose = () => {
    setSuccess(false);
  };

  const removeNegativeSign = (input) => input.toString().replace(/^-/, "");

  const numberWithCommas = (num) => {
    const str = num.toString().split(".");
    str[0] = str[0] ? str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
    return str.join(".");
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          {impactData.length > 0 ? (
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: TableChart.header.style.background,
                      paddingRight: "0px",
                    }}
                  >
                    <TableRow>
                      {content.columnLabelAndValue.map((header) => (
                        <StyledTableCell
                          key={header}
                          className={classes.columnHeader}
                          style={{
                            background: TableChart.header.style.background,
                            paddingRight: "0px",
                            color: TableChart.header.style.color,
                          }}
                        >
                          {header === "Current_Year"
                            ? impactData[0] && impactData[0]["Current_Year_1"]
                            : header === "Previous_Year"
                            ? impactData[0] && impactData[0]["Previous_Year_1"]
                            : header}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {impactData.map((data, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        {content.columnLabelAndValue.map((key) => (
                          <StyledTableCell key={key}>
                            {key === "Approval Status" ? (
                              data[key] ? (
                                data[key]
                              ) : (
                                <A_Button
                                  variant="contained"
                                  color="Primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick(data)}
                                  label="Approve"
                                />
                              )
                            ) : key === "Variance" ? (
                              parseInt(data[key]) > 0 ? (
                                <div
                                  id="htmlText"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div id="htmlText"><div style="color: green;">${data[key]}%</div></div>`,
                                  }}
                                />
                              ) : (
                                <div
                                  id="htmlText"
                                  dangerouslySetInnerHTML={{
                                    __html: `<div id="htmlText"><div style="color: red;">(${removeNegativeSign(
                                      data[key]
                                    )}%)</div></div>`,
                                  }}
                                />
                              )
                            ) : isNaN(data[key]) || data[key] === null ? (
                              data[key] || ""
                            ) : (
                              numberWithCommas(data[key])
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <div className={classes.noData}>
              <p>WE ARE SORRY, NO CONTENT FOUND!</p>
            </div>
          )}
        </>
      )}
      <A_Snackbar
        open={success}
        message={"Approved"}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_SimpleDialog
        id="2"
        open={isOpen}
        closePopUp={handlePopup}
        title="Add Comment"
        dialogContent={
          <Grid container alignItems="center">
            <Grid item xs="12">
              <TextField
                className={classes.inputField}
                id="standard-basic"
                size="large"
                label="Comment"
                fullWidth
                placeholder=""
                variant="standard"
                onChange={inputComment}
                value={formData.ApproverComments || ""}
                required
                error={isValid}
                helperText={isValid ? message.REQUIRED_FIELD : ""}
              />
            </Grid>
          </Grid>
        }
        dialogActions={getActionItems()}
        onClose={handleCancel}
      />
    </>
  );
};

ImpactData.propTypes = {
  content: PropTypes.object,
};

export default ImpactData;
