import PropTypes from "prop-types";
import React from "react";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { httpPost } from "../../../../../Utils/apis/apis";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useHistory } from "react-router";
import * as pptActions from "../../../../../store/actions/pptActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { dealConfig } from "../../../../../Utils/config/config";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  loadermrg: {
   marginLeft:"20rem"
  },
}));

function M_CheckList(props) {
  const classes = useStyles();
  const history = useHistory();
  const inputFile = React.useRef([]);
  const { crmId, pageUrl } = useParams();
  const [uploading, setUploading] = React.useState(false);
  const [loadingId, setLoadingId] = React.useState(false);
  const [isDisabled, setIsDisabled]=React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSucces] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const { version } = props && props.config && props.config.drawDown;
  const handleClick = async (
    event,
    description,
    name,
    id,
    dealid,
    index,
    ircID
  ) => {
    switch (name.toLowerCase()) {
      case "download":
        if (description.toLowerCase() == "irc slides") {
          setLoadingId(id);
          handlePptDownload(id, dealid, ircID);
        } else {
          handleDownload(id, dealid, description.split(" ").join(""));
        }
        return;
      case "review":
        handleUpdate(id, dealid).then(() => {
          let templateId = _.get(props, "config.reviewId", "");
          history.push(`/${pageUrl}/${crmId}/${templateId}`);
          //TO-DO below line was previously present and it has been removed due to coderefectoring for current bug again adding this line.
          location.reload()
        });

        return;
      case "upload":
        handleUploadClick(event, index);
        return;
    }
  };

  const isJSON = (str) => {
    if (typeof str === "string") {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  const getParams = (value) => {
    let finalValue;
    if (isJSON(value)) {
      const arr = JSON.parse(value);
      return (finalValue = arr && arr.length > 0 ? arr[0].title : arr.label);
    } else if (value == "" || value === undefined) {
      finalValue === "";
    }
    return (finalValue = value);
  };

  const getValue = (value) => {
    const obj = props.formParam;
    const mappedValue = obj[value] === undefined ? "" : getParams(obj[value]);
    return mappedValue;
  };

  const getRelevantHeaders = (fileName, subdomain, fileOperation) => {
    let header = {
      filename: fileName,
      type: "irc-checklist",
      "file-operation": fileOperation,
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      version: version ? version : "",
    };

    return header;
  };

  const handleUpload = async (event, id, dealid, tag) => {
    setUploading(true);
    let drawDown =
      props.config && props.config.drawDown && props.config.drawDown.fileName
        ? props.config.drawDown.fileName
        : "";
    let lineLevel =
      props.config && props.config.lineLevel && props.config.lineLevel.fileName
        ? props.config.lineLevel.fileName
        : "";
    let resultFiles = [];
    let files =
      event && event.target && event.target.files ? event.target.files : [];
    let fileName = "";
    for (let file of files) {
      let newFile = file.name ? file.name.split(".") : [];
      if (newFile.length == 0) {
        continue;
      }
      let fileTag = tag;
      let type = props.type;

      if (tag === "LineLevelOnBoardingPack") {
        let newFileName = lineLevel.split(".").join(`_${dealid}.`);
        fileTag = newFileName;
        type =
          props.config &&
          props.config.lineLevel &&
          props.config.lineLevel.filePath
            ? props.config.lineLevel.filePath
            : "";
      } else if (tag === "DrawDownNotice") {
        type =
          props.config &&
          props.config.drawDown &&
          props.config.drawDown.filePath
            ? props.config.drawDown.filePath
            : "";
        let newFileName = drawDown.split(".").join(`_${dealid}.`);
        fileTag = newFileName;
      }
      fileName = pageUrl + "/" + dealid + "/" + type + "/" + fileTag;
      resultFiles.push(file);
    }

    const paramData = props && props.config && props.config.parameters;
    if (!paramData) {
      setFailure(true);
      setTimeout(()=>{
        setIsDisabled(false);
      },2000);
      return;
    }
    const mappedObj = Object.fromEntries(
      Object.entries(paramData).map(([key, value]) => [key, getValue(value)])
    );

    //TODO - There are some hard code value, we can move it to some constants.
    if (
      mappedObj.strategy &&
      mappedObj.strategy_name &&
      "strategy_name" in mappedObj &&
      Number.isInteger(mappedObj.strategy)
    ) {
      mappedObj.strategy = mappedObj.strategy_name;
    }
    const url = `${process.env.DOCUMENT_HELPER}`;
    let reader = new FileReader();
    reader.readAsDataURL(resultFiles[0]);
    reader.onload = (e) => {
      const formData = {
        file: e.target.result,
        record_id: id ? id : "",
        deal_id: dealid,
        ...mappedObj,
      };
      //important
      //   let subdomain = "dev";
      // new URL(window.location.href).hostname.split(".")[0]
      let subdomain = new URL(window.location.href).hostname.split(".")[0];

      httpPost(url, formData, {
        headers: getRelevantHeaders(fileName, subdomain, "upload"),
      })
        .then(() => {
          setSucces(true);
          setFailure(false);
        })
        .then(() => {
          handleUpdate(id, dealid).then(() => {
            window.location.reload();
          });
        })
        .catch(() => {
          setTimeout(()=>{
            setIsDisabled(false);
          },2000);
          setFailure(true);
        });
    };
    setUploading(false);
  };
  const getDependancy = (id) => {
    let obj = props.data.find((item) => item.checlistItemId === id);
    if (!obj) {
      return false;
    } else {
      return obj.dealChecklist.checklistCompleted ? false : true;
    }
  };

  const handleDownload = async (id, dealid, tag) => {
    setIsDisabled(true);
    setLoading(true);
    let documentName = pageUrl + "/" + dealid + "/" + props.type;
    let output = `${tag}.xlsx`;
    let drawDown =
      props.config && props.config.drawDown && props.config.drawDown.fileName
        ? props.config.drawDown.fileName
        : "";
    let lineLevel =
      props.config && props.config.lineLevel && props.config.lineLevel.fileName
        ? props.config.lineLevel.fileName
        : "";
    if (tag === "LineLevelOnBoardingPack") {
      let filepath =
        props.config &&
        props.config.lineLevel &&
        props.config.lineLevel.filePath
          ? props.config.lineLevel.filePath
          : "";
      documentName = pageUrl + "/" + dealid + "/" + filepath;
      output = lineLevel;
    } else if (tag === "DrawDownNotice") {
      let filepath =
        props.config && props.config.drawDown && props.config.drawDown.filePath
          ? props.config.drawDown.filePath
          : "";
      documentName = pageUrl + "/" + dealid + "/" + filepath;
      output = drawDown;
    }

    const url = `${process.env.DOCUMENT_HELPER}`;
    //important
    // let subdomain = "dev";
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    // let header = {
    //   filename: documentName,
    //   type: "irc-checklist",
    //   "file-operation": "download",
    //   "process-file": "yes",
    //   "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    //   subdomain: subdomain,
    // };

    const paramData = props && props.config && props.config.parameters;
    if (!paramData) {
      setFailure(true);
      setTimeout(()=>{
        setIsDisabled(false);
      },2000);
      return;
    }
    const mappedObj = Object.fromEntries(
      Object.entries(paramData).map(([key, value]) => [key, getValue(value)])
    );
    if (
      mappedObj.strategy &&
      mappedObj.strategy_name &&
      "strategy_name" in mappedObj &&
      Number.isInteger(mappedObj.strategy)
    ) {
      mappedObj.strategy = mappedObj.strategy_name;
    }
    const formData = {
      record_id: id ? id : "",
      deal_id: dealid,
      ...mappedObj,
    };
    httpPost(url, formData, {
      responseType: "blob",
      headers: getRelevantHeaders(documentName, subdomain, "download"),
    })
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", output);
        document.body.appendChild(link);
        link.click();
        handleUpdate(id, dealid).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        setLoadingId(false);
        setLoading(false);
        setTimeout(()=>{
          setIsDisabled(false);
        },2000);
        setFailure(true);
      });
      setIsDisabled(false);
    setLoading(false);
    setLoadingId(false);
  };
  const handleUploadClick = async (event, index) => {
    await inputFile.current[index].click();
  };
  const handlePptDownload = async (id, dealid, ircId = "") => {
    const pptName = _.get(props, "config.ppt.pptName", "");
    const pptId = _.get(props, "config.ppt.pptId", "");
    let convertedFormData = {};
    let placeholders = _.get(props, "config.ppt.pptPlaceholders", []);
    let convertedPlaceholder =
      Array.isArray(placeholders) &&
      placeholders.map((placeholder) => {
        return {
          id: placeholder,
          crmId: placeholder === "minutes_of_meeting" ? ircId : crmId,
        };
      });
    convertedFormData = {
      parameters: { section: "" },
      map: convertedPlaceholder,
    };
    props.getPPTDownload({
      pptId,
      pptName,
      payload: convertedFormData,
      id,
      dealid,
    });
      setIsDisabled(false);
      setLoading(false);
  };
  const handleUpdate = async (rid, deal_id) => {
    setIsDisabled(true);
    setLoading(true);
    let payload = {
      checklistCompleted: true,
      action: "update",
      id: rid,
      dealId: deal_id,
    };
    await httpCall.httpPut("/DealChecklist/Update", payload);
    setIsDisabled(false);
    setLoading(false);
    return;
  };
  var inputProps = {
    accept: "pdf,pptx,docx,xlsx",
  };
  const handleClose = () => {
    setSucces(false);
    setFailure(false);
  };

  return (
    <>
      {props.type === "closing" && (
        <Box
          bgcolor={"#fcf8e3"}
          fontWeight={"500"}
          width={"100%"}
          padding={"1rem"}
          marginTop={"1rem"}
        >
          {props.config && props.config.dealClosingMsg
            ? props.config.dealClosingMsg
            : dealConfig.closingDealInfo}
        </Box>
      )}
      <span className={classes.loadermrg}>
        {loading && <CircularProgress size={35} color="primary" />}{" "}
      </span>
      {props.data ? (
        props.data.map((item, index) => {
          return (
            <Box paddingTop={"2rem"}  key={index}>
              <Grid container padding="2rem">
                <Grid item xs={2}>
                  {item.dealChecklist &&
                  item.dealChecklist.checklistCompleted ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )}
                </Grid>
                <Grid item xs={5}>
                  {item.itemName}
                </Grid>
                <Grid item xs={5}>
                  <A_Button
                    onClick={(event) => {
                      handleClick(
                        event,
                        item.itemName ? item.itemName : "",
                        item.description ? item.description : "",
                        item.dealChecklist && item.dealChecklist.id
                          ? item.dealChecklist.id
                          : "",
                        item.dealChecklist && item.dealChecklist.dealId
                          ? item.dealChecklist.dealId
                          : "",
                        index,
                        props.id
                      );
                    }}
                    label={
                      item.description &&
                      item.description.toLowerCase() == "upload"
                        ? !uploading
                          ? " Upload "
                          : "Please Wait..."
                        : item.description
                    }
                    disabled={
                      (item.dependency != ""
                        ? getDependancy(item.dependency)
                        : false) ||
                      loadingId === item.dealChecklist.id ||
                      isDisabled
                    }
                    color={"primary"}
                  ></A_Button>

                  <input
                    {...inputProps}
                    className={"fileinput"}
                    type="file"
                    ref={(el) => (inputFile.current[index] = el)}
                    onChange={(e) => {
                      handleUpload(
                        e,
                        item.dealChecklist && item.dealChecklist.id
                          ? item.dealChecklist.id
                          : "",
                        item.dealChecklist && item.dealChecklist.dealId
                          ? item.dealChecklist.dealId
                          : "",
                        item.itemName ? item.itemName.split(" ").join("") : ""
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <A_Snackbar
                open={failure}
                message={dealConfig.downloadFailMsg}
                autoHideDuration={2000}
                horizontal="center"
                handleClose={handleClose}
                type="error"
              />
              <A_Snackbar
                open={success}
                message={dealConfig.successClosingMsg}
                autoHideDuration={2000}
                horizontal="center"
                handleClose={handleClose}
              />
            </Box>
          );
        })
      ) : (
        <>No Chekclist Found</>
      )}{" "}
      <A_Snackbar
        open={success}
        message={dealConfig.successClosingMsg}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleClose}
      />
    </>
  );
}
function mapStateToProps(state) {
  return {
    pptData: state,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, pptActions), dispatch);
}

M_CheckList.propTypes = {
  id: PropTypes.any,
  ownProps:PropTypes.any,
  data: PropTypes.any,
  config: PropTypes.any,
  getPPTDownload: PropTypes.any,
  type: PropTypes.any,
  formParam: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_CheckList);
